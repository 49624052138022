import { memo, useCallback, useEffect, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { addBid, cancelBid, setBudget } from "../../services/room.service";
import useTimeLeft from "../../hooks/useTimeLeft";
import { roundValue, checkCanSubmitVal } from "../../utils";
import { useUA } from "../../contexts/userTracking";
import {
  BiddableNft,
  getHighestBid,
  useRoom,
} from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import TopBids from "./TopBids";
import ModalImages from "./ModalImages";
import DetailsTab from "./DetailsTab";
import BiddingTab from "./BiddingTab";
import FooterButtons from "./FooterButtons";
import Tabs from "./Tabs";
import { useChain } from "../../contexts/chainsContext";

interface Props {
  nfts: BiddableNft[];
}

const createInitialBidVal = (
  reservePrice: number,
  decimals: number,
  truncate: number,
  bid?: number
) => {
  if (bid) {
    return bid / 10 ** decimals;
  }
  return roundValue(reservePrice / 10 ** decimals, truncate, 'up');
};

const BiddingModal = memo(({ nfts }: Props) => {
  const cancelRef = useRef<HTMLDivElement>(null);
  const { uid, balance } = useAppContext();
  const {
    bids,
    roomId,
    step,
    abbr,
    handlePrev,
    handleNext,
    modalId,
    setModalId,
    highestBids,
    truncate,
    isSilentAuction,
    budgets,
    handlePass,
    availableForBid,
    canList,
    room,
    incrementToken,
    incrementPct,
    decimals,
    isAdmin,
    budgetMargin,
    isPassing
  } = useRoom();

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  const suggestedPrice =
    room?.info?.suggestedPrice || "Floor";

  const isSeller = isSilentAuction && canList;
  const budget = budgets?.budget;

  const { gaSelectNft, gaSetBidPrice, gaSetBudget } = useUA();
  const toast = useToast();
  const [error, setError] = useState<string | undefined>();
  const [fetching, setFetching] = useState(false);
  const [currentTab, setCurrentTab] = useState(() => {
    if (isPassing) {
      return "details";
    } else if (isSeller || isAdmin) {
      return "top-bids";
    } else {
      return "bidding";
    }
  });

  useEffect(() => {
    if (isPassing) {
      setCurrentTab("details");
    } else if (isSeller || isAdmin) {
      setCurrentTab("top-bids");
    } else {
      setCurrentTab("bidding");
    }
  }, [isPassing]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const nft = nfts?.find((nft) => nft?.itemId === modalId);
  const id = nft?.itemId;
  const itemIndex = nfts?.findIndex((nft) => nft?.itemId === modalId) + 1;
  const bid = bids?.[id || ""]?.bidPrice;

  const reservePrice = nft?.reservePrice?.value || 0;
  const startingBid = nft?.startingBid;

  const hasBid = typeof bid === "number";

  const min = roundValue(
    Number(startingBid) / 10 ** decimals,
    truncate,
    "up"
  );

  const [bidVal, setBidVal] = useState<number | string>();

  const floor = nft?.collection?.floorPrice?.floorPrice?.valueConverted || 0;

  const { isHighBidder, highestBidAmount, isTied, place } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isSecretReserve = room.info.secretReserve;

  const isBidReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const { getChainDetails } = useChain()
  const tokenChain = getChainDetails(room.blockchain.name, room.info.token)
  let margin = (tokenChain.budgetMargin !== null && tokenChain.budgetMargin !== undefined)
    ? tokenChain.budgetMargin / 10 ** decimals
    : 0.05;

  const max =
    !balance || balance < margin
      ? 0
      : roundValue(Number(balance - margin || 0), decimals, "down") *
      10 ** decimals;

  useEffect(() => {
    setError(undefined);

    setBidVal(
      isSecretReserve && !bid
        ? 0
        : createInitialBidVal(reservePrice, decimals, truncate, bid)
    );
  }, [nft?.itemId]);

  const cancel = async () => {
    try {
      if (!id) return;
      if (fetching) return;
      setFetching(true);
      await cancelBid(roomId, uid!, id);
    } catch (e) {
      return toast({
        title: "Something went wrong!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setFetching(false);
    }
  };

  const handleKeyPress = useCallback(
    (event: any) => {
      if (nfts.length < 2) return;
      if (event.key === "ArrowRight") {
        handleNext(nfts);
      } else if (event.key === "ArrowLeft") {
        handlePrev(nfts);
      }
    },
    [handleNext, handlePrev]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleBid = async (
    value: string | number | undefined,
    ignoreCheck?: boolean
  ) => {
    try {
      if (!id) return;

      const theValue = roundValue(Number(value), truncate, "down");

      setBidVal(theValue);
      let submissionVal = Number(theValue);
      submissionVal = submissionVal * 10 ** decimals;
      submissionVal = Math.floor(submissionVal);

      if (fetching) return;
      setFetching(true);

      const { canSubmit, reason } = checkCanSubmitVal({
        theValue,
        min,
        max: ignoreCheck ? submissionVal : max,
        reservePrice,
        decimals,
        isSecretReserve,
        isSilentAuction,
        currentHighestBid: highestBidAmount,
        incrementToken: incrementToken,
        incrementPct: incrementPct,
        abbr,
        truncate,
        roomType: room.info.roomType,
        maxbalance: budget,
      });

      if (!canSubmit && reason[0] === "Bid is more than spending limit!") {
        onOpen();
        setCurrentTab("bidding");
      } else if (!canSubmit) {
        setError("lowbid");
      }

      if (!canSubmit) throw new Error(reason[0]);

      await addBid(roomId, uid!, id, Math.ceil(submissionVal));
      gaSelectNft(roomId, id);
      gaSetBidPrice(roomId, id, submissionVal);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${Number(theValue)} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setError(undefined);
    } catch (e: any) {
      if (e.message) {
        setError(e.message);
      }
    } finally {
      setFetching(false);
    }
  };

  const setAsIncrementAboveHighestBid = () => {
    // const value =
    //   (highestBidAmount * incrementPct + highestBidAmount) / 10 ** decimals;
    const value =
      (highestBidAmount +
        Math.max(
          room.info.incrementToken || incrementToken,
          highestBidAmount * room.info.incrementPct || incrementPct
        )) /
      10 ** decimals;
    setBidVal(value);
    handleBid(value);
  };

  const handleClose = () => setModalId(undefined);

  const bidMaxBudget = async () => {
    try {
      if (max === 0) {
        toast({
          title: "Insufficient balance",
          description: `Sorry! You need a little more ${abbr}.`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        await setBudget(roomId, uid!, { budget: max });
        gaSetBudget(roomId, max);
        toast({
          title: "Spending Limit Updated",
          description: "Spending Limit Set Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        // onClose();
        // handleBid(bidVal, true);
      }
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleSetBudget = async () => {
    try {
      if (max === 0)
        return toast({
          title: "Insufficient balance",
          description: `Sorry! You need a little more ${abbr}.`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

      await setBudget(roomId, uid!, { budget: max });
      gaSetBudget(roomId, max);
      toast({
        title: "Spending Limit Updated",
        description: "Spending Limit Set Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      handleBid(bidVal, true);
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const timeLeft = useTimeLeft();
  const finalMinute = timeLeft < 60;

  const canBid = !isSeller && !isAdmin;

  const setBudgetAndBid = async (value: number) => {
    // convert from large value to user value
    const humanValue = value / 10 ** decimals;
    try {
      setBidVal(humanValue);
      await setBudget(roomId, uid!, { budget: value });
      gaSetBudget(roomId, max);
      toast({
        title: "Spending Limit Updated",
        description: "Spending Limit Set Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      await addBid(roomId, uid!, id!, value);
      gaSelectNft(roomId, id!);
      gaSetBidPrice(roomId, id!, value);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${humanValue} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setError(undefined);
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit and Bid",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div className="ns-modal">
      <AlertDialog
        id="listing-modal"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        isOpen={!!nft}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxWidth="1050px" className="ns-dialog-content">
          <AlertDialogHeader>
            {!isPassing && (
              <div className="listing-modal-header">
                <h3>Bid</h3>
                <p>Place your bid here</p>
              </div>
            )}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <div className="listing-modal-body">
              <ModalImages
                nft={nft}
                hasBid={hasBid}
                nftIndex={itemIndex}
                total={availableForBid?.length ?? 0}
                handleNext={() => handleNext(nfts)}
                handlePrev={() => handlePrev(nfts)}
              />
              <div className="tabs-wrapper bidding-modal">
                <Tabs
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  isSeller={isSeller}
                  isAdmin={isAdmin}
                  roomType={room.info.roomType}
                  isPassing={isPassing}
                />
                {currentTab === "details" && nft && (
                  <DetailsTab nft={nft} abbr={abbr} truncate={truncate} isOrdinals={isOrdinals} suggestedPrice={suggestedPrice} />
                )}
                {currentTab === "bidding" &&
                  ((!isSeller && !isAdmin) || room.info.roomType === "smartAuction") &&
                  !isPassing &&
                  nft ? (
                  <BiddingTab
                    nft={nft}
                    finalMinute={finalMinute}
                    min={min}
                    max={max}
                    budget={budget}
                    isHighBidder={isHighBidder}
                    isTied={isTied}
                    hasBid={hasBid}
                    place={place}
                    highestBidAmount={highestBidAmount}
                    total={availableForBid?.length ?? 0}
                    abbr={abbr}
                    truncate={truncate}
                    isSilentAuction={isSilentAuction}
                    decimals={decimals}
                    isSecretReserve={isSecretReserve}
                    isBidReserveMet={isBidReserveMet}
                    incrementPct={incrementPct}
                    incrementToken={incrementToken}
                    startingBid={startingBid}
                    handleBid={handleBid}
                    floor={floor}
                    setAsIncrementAboveHighestBid={
                      setAsIncrementAboveHighestBid
                    }
                    bidVal={bidVal}
                    canBid={canBid}
                    onClose={onClose}
                    cancel={cancel}
                    step={step}
                    isOpen={isOpen}
                    setBidVal={setBidVal}
                    bidMaxBudget={bidMaxBudget}
                    error={error}
                    setBudgetAndBid={setBudgetAndBid}
                    isOrdinals={isOrdinals}
                    suggestedPrice={suggestedPrice}

                  />
                ) : null}

                {currentTab === "top-bids" && uid && nft && !isPassing ? (
                  <TopBids
                    uid={uid}
                    nft={nft}
                    highestBids={highestBids}
                    abbr={abbr}
                    truncate={truncate}
                    isSecretReserve={isSecretReserve}
                    isSeller={isSeller}
                    isAdmin={isAdmin}
                    handleBid={handleBid}
                  />
                ) : null}

                <FooterButtons
                  isSeller={isSeller}
                  isAdmin={isAdmin}
                  roomType={room.info.roomType}
                  hasBid={hasBid}
                  handlePass={() => handlePass(id!)}
                  handleClose={() => handleClose()}
                  handleBid={() => handleBid(bidVal)}
                  isPassing={isPassing}
                />
              </div>
            </div>
          </AlertDialogBody>
          <AlertDialogFooter className="modal-footer" />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
});

export default BiddingModal;
