import React, { ReactNode } from 'react';

type PartnerFooterProps = {
    children: ReactNode;
};

const PartnerFooter: React.FC<PartnerFooterProps> = ({ children }) => {
    return (
        <div className="partner-footer">
            {children}
        </div>
    );
};

export default PartnerFooter;
