import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  useBreakpointValue,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";

import {
  removeAllListings,
  removeListing,
  saveProgress,
} from "../../services/room.service";
import BottomBar from "./BottomBar";
import { createFloor, filterCompleted } from "../../utils";
import ListingComplete from "./ListingComplete";
import { useRoom, Nft, BiddableNft } from "../../contexts/roomContext";
import { useUA } from "../../contexts/userTracking";
import { useAppContext } from "../../contexts/appContext";
import NftCollection from "../../components/NftCollection";
import GridItem from "../../components/NftCollection/ListingGridItem";
import { createColumnHelper } from "@tanstack/react-table";
import ListingImageCell from "../../components/Table/ListingImageCell";
import ReserveCell from "../../components/Table/ReserveCell";
import ListButton from "../../components/ListingDataTable/ListButton";
import LockedOverlay from "../../components/NftCollection/LockedOverlay";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useEffect } from "react";

const columnHelper = createColumnHelper<any>();

const ListCell = (props: any) => {
  const { listings } = useRoom();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const nft = props.row.original;
  const listing = listings?.[props.row.original.itemId];
  const lockedRoomId = nft.locks.length
    ? nft.locks?.[0]?.lockedRoom_id
    : undefined;
  const isLocked = !!lockedRoomId;

  return isLocked ? (
    <>
      {isLocked && (
        <LockedOverlay
          roomId={lockedRoomId}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
      <Button
        size="sm"
        className="listing-button"
        onClick={onOpen}
        minWidth="90px"
        bg={"linear-gradient(45deg, #4c31e3, #fb4bdb)"}
        _dark={{
          color: "white",
        }}
      >
        List
      </Button>
    </>
  ) : (
    <ListButton nft={nft} listing={listing} />
  );
};

const columns = [
  columnHelper.accessor("itemId", {
    cell: ListingImageCell,
    header: "",
  }),
  columnHelper.accessor("collectionName", {
    cell: (info) => {
      return info.row.original.collection.name;
    },
    header: "Collection Name",
  }),
  columnHelper.accessor("collectionFloorPrice", {
    cell: (info) => {
      return createFloor(info.row.original);
    },
    header: "Collection Floor Price",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("collection.verified.spamScore", {
    header: "Collection Spam Score",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("reservePrice", {
    cell: ReserveCell,
    header: "Reserve Price",
    meta: {
      isNumeric: true,
    },
  }),
  {
    header: "Set Reserve Value",
    meta: {
      isNumeric: true,
    },
    cell: ListCell,
  },
];

export default function Listing() {
  const { uid } = useAppContext();
  const { gaRemoveNft, gaFinishListing } = useUA();
  const {
    room,
    roomId,
    stepProgress,
    listings,
    availableToList,
    nftsLoading,
    getAvailableToList,
  } = useRoom();
  const hasJoined = room?.users &&  room?.users.includes(uid || "") ?  true: false;

  useEffect(() => {
    if (uid) getAvailableToList();
  }, [uid]);

  const handleSaveProgress = () => {
    saveProgress(roomId, "listings", uid!, true);
    gaFinishListing(roomId);
  };

  const handleRemove = (id: string) => {
    removeListing(roomId, uid!, id);
    gaRemoveNft(roomId, id);
  };

  const removeAll = () => {
    removeAllListings(roomId, uid!);
    gaRemoveNft(roomId, Object.keys(listings));
  };

  const createBottomNfts = () => {
    return Object.keys(listings || {}).sort((a, b) => a.localeCompare(b));
  };

  const part = filterCompleted(stepProgress);

  if (part.includes(uid!)) {
    return <ListingComplete />;
  }

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  return (
    <>
      {nftsLoading ? (
        <div className="nft-loader">
          <Spinner color="#ed6cd8" />
          <p className="loadingtext">
            {" "}
            Loading {isOrdinals ? "Ordinals" : "items"}, please wait...{" "}
          </p>
        </div>
      ) : (
        <Box marginBottom="140px">
          <Flex
            gap="20px"
            justifyContent="space-between"
            flexDirection={{ lg: "row", base: "column-reverse" }}
            alignItems="stretch"
          >
            <NftCollection
              GridComponent={({ nft }: { nft: Nft | BiddableNft }) => (
                <GridItem nft={nft} />
              )}
              data={availableToList || []}
              title={`Choose your ${
                isOrdinals ? "Ordinals" : "items"
              } to list...`}
              columns={columns}
              indexOptions={["name", "collection.name"]}
              groupBy="collection.name"
              emptyMessage={`Sorry you don't have any ${
                isOrdinals ? "Ordinals" : "items"
              } to list`}
              isLoading={nftsLoading}
              collectionType="listing"
               hasJoined = {hasJoined}

            >
              <BottomBar
                nfts={createBottomNfts()}
                handleRemove={handleRemove}
                saveProgress={handleSaveProgress}
                removeAll={removeAll}
                finishLabel="Finish Listing"
              />
            </NftCollection>
            <Sidebar handleRemove={handleRemove} />
          </Flex>
        </Box>
      )}
    </>
  );
}
