import { memo, useEffect, useState } from "react";
import { Button, IconButton, useToast } from "@chakra-ui/react";
import { addBid, cancelBid } from "../../services/room.service";
import { useUA } from "../../contexts/userTracking";
import { useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import { FaTrashAlt } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { roundValue } from "../../utils";


const createInitialBidVal = (
  reservePrice: number,
  decimals: number,
  truncate: number,
  bid?: number
) => {
  if (bid) {
    return bid /  decimals;
  }
  return roundValue(reservePrice / decimals, truncate, 'up');
};

const BidCell = memo((props: any) => {
  const { uid } = useAppContext();
  const { bids, roomId, step, abbr, truncate, setModalId } = useRoom();
  const { gaSelectNft, gaSetBidPrice } = useUA();
  const toast = useToast();
  const [error, setError] = useState<string | undefined>();
  const [fetching, setFetching] = useState(false);
  const id = props.row.original.itemId;
  const bid = bids?.[id]?.bidPrice;
  const reservePrice = props.row.original.reservePrice.value;
  const decimals = 10 ** props.row.original.reservePrice.decimals;
  const hasBid = typeof bid === "number";
  const min = reservePrice / decimals;
  const [bidVal, setBidVal] = useState<number | string>(
    createInitialBidVal(reservePrice, decimals, truncate, bid)
  );

  useEffect(() => {
    if (Number(bidVal) < min) {
      setError("Bid is less than reserve price!");
    }
  }, [min, bidVal]);

  const cancel = async () => {
    try {
      if (fetching) return;
      setFetching(true);
      await cancelBid(roomId, uid!, id);
    } catch (e) {
      return toast({
        title: "Something went wrong!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setFetching(false);
    }
  };

  const handlBid = async () => {
    try {
      let submissionVal = Number(bidVal);
      submissionVal = submissionVal * decimals;
      if (fetching) return;
      setFetching(true);
      if (submissionVal < reservePrice)
        throw new Error("Bid is less than reserve price");
      await addBid(roomId, uid!, id, submissionVal);
      gaSelectNft(roomId, id);
      gaSetBidPrice(roomId, id, submissionVal);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${Number(bidVal)} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (e: any) {
      // setValue(bid ? bid / units : reserveValue);
      if (e.message) {
        return toast({
          title: "Something went wrong!",
          description: e.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } finally {
      setFetching(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e?.key === "Enter") {
      handlBid();
    }
  };

  return (
    //   <Button
    //   size="sm"
    //   onClick={() => setModalId(id)}
    //   minWidth="90px"
    //   bg={"linear-gradient(45deg, #4c31e3, #fb4bdb)"}
    //   _dark={{
    //     color: "white",
    //   }}
    // >
    //   Select
    // </Button>
    <>
      {hasBid && (
        <IconButton
          onClick={() => setModalId(id)}
          aria-label="Edit"
          icon={<EditIcon />}
        />
      )}
      {!bid && (
        <Button
          size="sm"
          onClick={() => setModalId(id)}
          minWidth="90px"
          bg={hasBid ? "#dc3545" : "linear-gradient(45deg, #4c31e3, #fb4bdb)"}
          colorScheme={hasBid ? "#dc3545" : ""}
          _dark={{
            color: "white",
          }}
        >
          {hasBid ? "Remove" : "Select"}
        </Button>
      )}
      {hasBid && (
        <IconButton
          marginLeft={2}
          className="remove-icon"
          onClick={cancel}
          aria-label="Remove"
          icon={<FaTrashAlt />}
        />
      )}
    </>
  );

  // return (
  //   <InputGroup>
  //     <NumberInput
  //       placeholder="Set Bid Price"
  //       min={min}
  //       step={step || 0.01}
  //       value={bidVal}
  //       _dark={{
  //         backgroundColor: "#051a28",
  //         opacity: 1,
  //       }}
  //       onChange={setBidVal}
  //       width="100%"
  //       onKeyDown={handleKeyDown}
  //       // keepWithinRange
  //       // clampValueOnBlur
  //       allowMouseWheel={false}
  //       lang="en"
  //     >
  //       <NumberInputField />
  //       <NumberInputStepper>
  //         <NumberIncrementStepper />
  //         <NumberDecrementStepper />
  //       </NumberInputStepper>
  //     </NumberInput>
  //     <InputRightAddon
  //       bg={hasBid ? "#dc3545" : "linear-gradient(45deg, #4c31e3, #fb4bdb)"}
  //     >
  //       <Button
  //         size="sm"
  //         onClick={hasBid ? cancel : handlBid}
  //         bg="transparent"
  //         colorScheme={hasBid ? "#dc3545" : ""}
  //         _dark={{
  //           color: "white",
  //         }}
  //         onMouseDown={(e) => e.preventDefault()}
  //       >
  //         {hasBid ? "Remove" : "Set"}
  //       </Button>
  //     </InputRightAddon>
  //   </InputGroup>
  // );
});

export default BidCell;
