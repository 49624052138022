import { Heading, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LandingFooter from "./LandingFooter";
import { slugify } from "../services/slugify";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";

export default function Faq() {
  const [data, setData] = useState<FaqData>({});

  useEffect(() => {
    import("../data/faq.json").then((data) => {
      setData(data);
    });
  }, []);

  interface FaqItem {
    question: string;
    answer: string;
  }

  interface SectionInterface {
    title: string;
    items: Array<FaqItem>;
  }

  interface FaqData {
    faq?: Array<SectionInterface>;
  }

  return (
    <div className="landing-page">
      <div className="content">
        <div className="page-header">
          <div className="logo-wrapper">
            <Link to={'/'}>
              <img src={Logo} alt="NeoSwap" className="logo" />
            </Link>
            <span className="beta-tag">beta</span>
          </div>
          <Heading as="h1" fontSize="5xl">
            FAQ
          </Heading>
          <Heading as="h4" fontSize="md" className="sub-title">
            Below you will find a series of frequently asked questions.
          </Heading>

          {!data?.faq ? (<></>) : (
            <ul className="section-pills">
              {data.faq.map((section: SectionInterface) => {
                return (
                  <li key={slugify(section.title)}>
                    <a href={'#' + slugify(section.title)}>{section.title}</a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {!data?.faq ? (
          <div className="loader">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#ed6cd8"
              size="xl"
            />
          </div>
        ) : (
          <div className="faq">
            {data.faq.map((section: SectionInterface) => {
              return (
                <div id={slugify(section.title)} key={slugify(section.title)} className="faq-section box">
                  <h1>{section.title}</h1>
                  {section.items.map((item: FaqItem) => {
                    return (
                      <div key={slugify(item.question)} className="faq-item">
                        <h2>{item.question}</h2>
                        <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}

      </div>
      <LandingFooter />
    </div>
  );
}
