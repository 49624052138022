import { VersionedTransaction } from '@solana/web3.js'
import fetch from 'cross-fetch'
import { Item } from '../types'
import { PackTransaction } from '../types'

type JupConfig = {
  slippageBps: number
  swapEndpoint: string
  quoteEndpoint: string
  delay: number
}

type Quote = {
  inputMint: string
  inAmount: string
  outputMint: string
  outAmount: string
  otherAmountThreshold: string
  swapMode: 'ExactOut' | 'ExactIn'
  slippageBps: number
  platformFee: null
  priceImpactPct: string
  routePlan: [{ swapInfo: [any]; percent: number }, { swapInfo: [any]; percent: number }]
  contextSlot: number
  timeTaken: number
}

const jupConfig: JupConfig = {
  slippageBps: 1000,
  swapEndpoint: 'https://quote-api.jup.ag/v6/swap',
  quoteEndpoint: 'https://quote-api.jup.ag/v6/quote',
  delay: 51,
}

export const getJupQuote = async (
  inputMint: string,
  outputMint: string,
  amount: number,
  mode?: 'ExactOut' | 'ExactIn'
): Promise<Quote> => {
  mode = mode || 'ExactOut'
  const url = `${jupConfig.quoteEndpoint}?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amount}&slippageBps=${jupConfig.slippageBps}&swapMode=${mode}`
  const res = await fetch(url)
  const quote = (await res.json()) as Quote

  return quote
}

export const quoteJupToTransaction = async (
  quoteResponse: any,
  userPublicKey: string
): Promise<VersionedTransaction> => {
  const { swapTransaction } = await (
    await fetch(jupConfig.swapEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quoteResponse,
        userPublicKey,
        wrapAndUnwrapSol: true,
        dynamicComputeUnitLimit: true,
        prioritizationFeeLamports: 'auto',
      }),
    })
  ).json()

  return VersionedTransaction.deserialize(Buffer.from(swapTransaction, 'base64'))
}

export const getJupPackTransaction = async (
  quoteData: {
    from: string
    to: string
    amount: number
    description: string
  }[],
  userPublicKey: string
): Promise<PackTransaction[]> => {
  const dummyItem: Item = {
    details: {
      id: '1',
      name: '',
      onChainId: '',
      sourceMarketplace: 'jup',
      type: 'token',
    },
    quantity: 1,
  }

  const txs = await Promise.all(
    quoteData.map(async (quoteData) => {
      const quote = await getJupQuote(quoteData.from, quoteData.to, quoteData.amount)
      const tx = await quoteJupToTransaction(quote, userPublicKey)
      return {
        tx: tx,
        description: quoteData.description,
        item: dummyItem,
        status: 'pending',
      } as PackTransaction
    })
  )

  return txs
}
