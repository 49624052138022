import "../css/components/homepage.scss";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ChakraLink, Box, Button, Flex, Center, Heading } from "@chakra-ui/react";
import Navbar from "../components/navbar";
import { BsTwitter, BsDiscord, BsMedium } from "react-icons/bs";
import PartnerGroup from "../components/PartnerGroup";
import SmartAuctions from "../components/SmartAuctions";
import backgroundImage from '../assets/bg-sa.png'; // Adjust the path as necessary
import DAppsCards from "../components/DAppsCards";

function isMobile() {
  return (
    "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0
  );
}

export default function Home() {
  useEffect(() => {
    document.body.classList.add("home-page");

    if (isMobile()) document.body.classList.add("is-mobile");

    const scrollElem = document.getElementById("scroll-to");

    if (scrollElem) {
      scrollElem.onclick = function () {
        document.getElementById("app-links-list")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      };
    }

    return () => {
      document.body.classList.remove("home-page");
      document.body.classList.remove("is-mobile");
    };
  }, []);

  type CustomArrowProps = {
    onClick: () => void; // assuming onClick is a function
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -right-10 top-44 z-10  bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 2L13.5 14L1.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -left-10 top-44 z-10 bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2L2.5 14L14.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const settings = {
    customPaging: function (i: number) {
      return <div className="w-2 h-2 rounded-full opacity-30 bg-white" />;
    },
    dots: true,
    dotsClass:
      "justify-center items-center gap-2.5 !flex w-full mt-6 aitradeslider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <CustomNextArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  };

  const navigate = useNavigate();
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`No element with ID ${sectionId} found`);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Tulle - Explore Apps
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="title"
          content="Tulle"
        />
        <meta
          name="description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://Tulle.ai" />
        <meta property="og:title" content="Tulle" />
        <meta
          property="og:description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="og:image" content="" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://Tulle.ai" />
        <meta property="twitter:title" content="Tulle" />
        <meta
          property="twitter:description"
          content="An AI-powered economic engine that supports smart auctions, collection swap, and starter packs."
        />
        <meta property="twitter:image" content="" />
      </Helmet>



      <Box
        id="container"
        position={"relative"}
        pt={"0px"}
      >
        {window.location.pathname == '/star-atlas-skins' ? (
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              zIndex: -1,

            }}
            className="fixed w-full h-full bg-no-repeat bg-cover bg-center"
          />
        ) : (

          <div

            className="fixed w-full h-full pattern-bg"
          />
        )}
        <div className="inner-content">
          <div id="header" className="main-menu-content">
            <Navbar />
          </div>



          <Flex
            id="howauctionswork"
            direction={"column"}
            justifyContent="center"
            alignItems={"center"}
            gap={"4"}
            px={"40px"}
          >
            <Heading as={"h2"} size={"xl"} my={"20px"}>
              Explore Apps Powered by Tulle
            </Heading>

            <DAppsCards />
          </Flex>

          <div className="homefooter">
            <div className="copyright text-earth">
              <h1>
                <span>
                  <span>Copyright &copy; </span> {new Date().getFullYear()}{" "}
                  Tulle
                </span>
                <span id="line">|</span>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <span id="line">|</span>
                <Link to="/terms-of-service">Terms of Service</Link>
              </h1>
            </div>

            <div className="footerlist">
              <button>
                {" "}
                <a
                  href="https://twitter.com/tulle_ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <BsTwitter />{" "}
                </a>{" "}
              </button>
              <button>
                {" "}
                <a
                  href="https://discord.gg/4mfqbBkGRS"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <BsDiscord />{" "}
                </a>{" "}
              </button>
              <button>
                {" "}
                <a
                  href="https://medium.com/neoswap"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <BsMedium />{" "}
                </a>{" "}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}
