import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import { getChains } from "../services/user.service";
import { useDisclosure } from "@chakra-ui/react";

enum ActionType {
  UPDATE,
  RESET,
}

interface Props {
  children?: ReactNode;
}

interface State {
  chain?: Chain;
  chains?: Chain[];
  showSwitcher: boolean;
  loading: boolean;
}

interface ChainsInterface extends State {
  getChainDetails: (chain: string, token?: string) => Chain;
  setChain: (chain: string) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

type Action =
  | { type: ActionType.UPDATE; payload: any }
  | { type: ActionType.RESET; payload: any };

export type Chain = {
  name: string;
  abbr: string;
  units: number;
  decimals: number;
  disabled: boolean;
  step: number;
  truncate: number;
  incrementToken: number;
  incrementPct: number;
  budgetMargin: number;
  downtime: boolean;
  ai: {
    maxNumDeclined: number;
  };
  tokens?: {
    [key: string]: Chain;
  };
  usdValue?: number;
};

const Chains = createContext<ChainsInterface>({} as ChainsInterface);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE:
      if (action?.payload?.chain) {
        localStorage.setItem("chain", JSON.stringify(action.payload.chain));
      }
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const getChain = (path: string, chains: Chain[]) => {
  const chain = localStorage.getItem("chain");
  if (chain) {
    return JSON.parse(chain);
  }
  const found = chains.find((chain) => path.includes(chain.name.toLowerCase()));

  return found || chains.find((chain) => chain.name === "Solana");
};

export default function ChainsProvider({ children }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const cancelRef = useRef(null);
  const isMountedRef = useRef(false);
  const [state, dispatch] = useReducer(reducer, {
    showSwitcher:
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_ENV === "development",
  } as State);
  const { chains } = state;

  const getChainsInfo = useCallback(async () => {
    const chainsData = await getChains();
    const chains = chainsData?.docs.map((doc: any) => doc?.data());
    const chain = getChain(window.location.hostname, chains);
    return { chains, chain };
  }, []);

  const getChainDetails = useCallback(
    (chain: string, token?: string) => {
      const chainDetails = chains.find(
        (c: Chain) => c.name.toLowerCase() === chain.toLowerCase()
      );

      if (!token) {
        return chainDetails;
      }
      return chainDetails.tokens[token];
    },
    [chains]
  );

  const setChain = useCallback(
    (chain: string) => {
      // if (chain === "Bitcoin" && !localStorage?.getItem("xverseDismissed")) {
      //   onOpen();
      //   localStorage.setItem("xverseDismissed", "true");
      // }
      const chainDetails = getChainDetails(chain);
      dispatch({
        type: ActionType.UPDATE,
        payload: { chain: chainDetails },
      });
    },
    [getChainDetails]
  );

  useEffect(() => {
    const initChain = async () => {
      try {
        dispatch({
          type: ActionType.UPDATE,
          payload: { loading: true },
        });
        const { chains, chain } = await getChainsInfo();
        dispatch({
          type: ActionType.UPDATE,
          payload: { chains, chain },
        });
      } catch (e: any) {
        console.log("error in initChain", e);
      } finally {
        isMountedRef.current = true;
        dispatch({
          type: ActionType.UPDATE,
          payload: { loading: false },
        });
      }
    };
    if (isMountedRef.current === true) return;
    initChain();
  }, []);
  const value = useMemo(() => ({ ...state }), [state]);

  if (!value.chain) return null;
  return (
    <Chains.Provider
      value={{ ...value, getChainDetails, setChain, isOpen, onOpen, onClose }}
    >
      {/* <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Attention:
            </AlertDialogHeader>
            <AlertDialogBody>
              Bitcoin Smart Auctions require the latest{" "}
              <strong>XVerse wallet</strong> version. More wallets coming soon!
              <br />
              <br />
              To enhance performance and ensure optimal security, please update
              your XVerse Wallet to the latest version. Once updated, attempt to
              reconnect your wallet.
              <br />
              <br />
              Thank you
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Dismiss
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
      {children}
    </Chains.Provider>
  );
}

export function useChain(): ChainsInterface {
  const ctx = useContext(Chains);
  if (ctx === undefined) {
    throw new Error("useChain requires Chains.Provider");
  }
  return ctx;
}
