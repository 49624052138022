import IconStepperArrow from "../Icons/IconStepperArrow";
import IconStepperCheck from "../Icons/IconStepperCheck";

const Step = ({
  activeStep,
  step,
  stepTitle,
}: {
  activeStep: number;
  step: number;
  stepTitle: string;
}) => {
  let bgColor = "bg-transparent";
  let textColor = "text-zinc-400";
  let border = "border border-zinc-400";
  if (activeStep > step) {
    bgColor = "bg-green-500";
    textColor = "text-green-500";
    border = "border border-green-500";
  } else if (activeStep === step) {
    bgColor = "bg-indigo-500";
    border = "border border-indigo-500";
    textColor = "text-white";
  }

  return (
    <div className="justify-start items-center gap-4 flex">
      <div
        className={`flex-none w-8 h-8 relative ${bgColor} ${border} ${textColor} text-xl font-medium leading-loose flex items-center justify-center`}
      >
        {activeStep > step ? <IconStepperCheck /> : step}
      </div>
      <div
        className={`${textColor} text-xl font-medium leading-6 ${
          step !== activeStep && "hidden"
        } md:block`}
      >
        {stepTitle}
      </div>
    </div>
  );
};

function Stepper({ activeStep }: { activeStep: number }) {
  return (
    <div className="w-full h-8 justify-center items-center gap-4 md:gap-8 inline-flex">
      <Step activeStep={activeStep} step={1} stepTitle="Connect Wallet" />
      <div className="flex-none">
        <IconStepperArrow />
      </div>
      <Step activeStep={activeStep} step={2} stepTitle="Select Pack" />
      <div className="flex-none">
        <IconStepperArrow />
      </div>
      <Step activeStep={activeStep} step={3} stepTitle="Buy Pack" />
    </div>
  );
}

export default Stepper;
