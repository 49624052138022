import { memo, useState } from "react";
import { useChain } from "../../contexts/chainsContext";
import { AiRoom, Blockchain, ChainDetails, Images, Img } from "./types";
import { roundValue } from "./utils";
import OfferPanel from "./OfferPanel";
import TimeLeft from "./TimeLeft";
import SwapModal from "../SwapModal";
import useTimer from "./useTimer";
import Stepper from "./Stepper";
import { DateTime } from "luxon";
import BuyingModal from "../Bundles/BuyingModal";


interface Props {
  room: AiRoom;
  images: Images;
  roomId: string;
  setHasSigned: () => void;
}

const ShowTimer = ({
  startTime,
  endTime,
}: {
  startTime: number;
  endTime: number;
}) => {
  const { expired, timeRemainingSec, timeLeftUnits } = useTimer({
    endTime: endTime,
    timeOffset: 0,
  });
  return (
    <div className="w-full">
      {startTime && endTime ? (
        <TimeLeft
          expired={expired}
          timeRemainingSec={timeRemainingSec}
          timeLeftUnits={timeLeftUnits}
        />
      ) : (
        ""
      )}
      <span className="text-white text-[42px] font-medium"> Buy Pack</span>
    </div>
  );
};

function Signing({ room, images, roomId, setHasSigned }: Props) {
  const { getChainDetails } = useChain();
  const chain = getChainDetails(
    room.info.blockchain as Blockchain
  ) as ChainDetails;
  const offer = room.acceptedOffer!;
  const tokenName = room.info?.token ? room.info.token : chain.abbr;
  const tokenValue = roundValue(offer?.token, chain?.tokens[tokenName.toLowerCase()].decimals, chain?.tokens[tokenName.toLowerCase()].truncate);
  const tokenAbbr = chain?.tokens[tokenName.toLowerCase()].abbr || tokenName.toUpperCase();

  const get = offer?.get?.map((id: string) => {
    const img = images[id];
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
      metadata: img,
    };
  }) as Img[];

  const give = offer?.give?.map((id: string) => {
    const img = images[id];
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
      metadata: img,
    };
  }) as Img[];

  return (
    <div className="w-full h-full px-16 py-8 bg-black bg-opacity-30 rounded-2xl backdrop-blur-[65px] flex-col justify-center items-start gap-[43px] inline-flex">
      <Stepper activeStep={3} />
      <div className="self-stretch justify-between items-center gap-12 inline-flex flex-col md:flex-row">
        <div className="flex-col justify-start items-start inline-flex">
          <ShowTimer
            startTime={room?.acceptedAt?.seconds || 0}
            endTime={DateTime.fromMillis(room.expirationTime || 0).toSeconds()}
          />
        </div>
        <SwapModal
          handleRefreshSwapState={() => {
            return new Promise((res) => res());
          }}
          aiRoomId={roomId}
          hasSwaps
          roomChain={chain.name.toLowerCase()}
          aiTrades
          setHasSigned={setHasSigned}
        />
        {/* <BuyingModal
          handleRefreshSwapState={() => {
            return new Promise((res) => res());
          }}
          hasSwaps
          roomChain={chain.name.toLowerCase()}
          setHasSigned={setHasSigned}
          offer={offer}
          get={get}
          userId={room.userId}
          tokenValue={tokenValue}
          tokenAbbr={tokenAbbr}
        /> */}
      </div>
      <OfferPanel
        showTradeFeedback={false}
        showBorder={false}
        tokenValue={tokenValue}
        chain={chain!}
        get={get}
        give={give}
        tokenName={tokenAbbr}
      />
    </div>
  );
}

export default memo(Signing);
