export default function IconPencil() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_442_7649)">
        <path
          d="M2.81326 15.4667L1.54659 20.9333C1.50289 21.1332 1.50439 21.3403 1.55097 21.5394C1.59756 21.7386 1.68805 21.9249 1.81583 22.0846C1.94362 22.2444 2.10547 22.3735 2.28957 22.4627C2.47368 22.5519 2.67537 22.5988 2.87992 22.6C2.97524 22.6096 3.07128 22.6096 3.16659 22.6L8.66659 21.3334L19.2266 10.8133L13.3333 4.93335L2.81326 15.4667Z"
          fill="#F8F8F8"
        />
        <path
          d="M22.5466 5.54661L18.6133 1.61327C18.3547 1.35598 18.0048 1.21155 17.64 1.21155C17.2752 1.21155 16.9252 1.35598 16.6666 1.61327L14.48 3.79994L20.3666 9.68661L22.5533 7.49994C22.6813 7.37133 22.7826 7.21874 22.8516 7.05092C22.9205 6.88309 22.9557 6.70332 22.955 6.52189C22.9544 6.34046 22.918 6.16093 22.848 5.99358C22.7779 5.82623 22.6755 5.67434 22.5466 5.54661Z"
          fill="#F8F8F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_442_7649">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
