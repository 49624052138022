import { Box, Center, Heading, Image, Flex, Link } from "@chakra-ui/react";
import Slider from "react-slick";
import IconLeftArrow from "../components/Icons/IconLeftArrow";
import IconRightArrow from "../components/Icons/IconRightArrow";

export default function ImageSlider({
  images,
}: {
  images: Array<{ name: string; image: string; link?: string }>;
}) {
  type CustomArrowProps = {
    onClick: () => void; // assuming onClick is a function
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        cursor={"pointer"}
        position={"absolute"}
        right={"-30px"}
        top={"56px"}
      >
        <IconRightArrow />
      </Box>
    );
  };

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        cursor={"pointer"}
        position={"absolute"}
        left={"-30px"}
        top={"56px"}
      >
        <IconLeftArrow />
      </Box>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    // nextArrow: (
    //   <CustomNextArrow
    //     onClick={function (): void {
    //       throw new Error("Function not implemented.");
    //     }}
    //   />
    // ),
    // prevArrow: (
    //   <CustomPrevArrow
    //     onClick={function (): void {
    //       throw new Error("Function not implemented.");
    //     }}
    //   />
    // ),
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 854,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 629,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 413,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      w={"full"}
      mt={"30px"}
      p={{
        base: "0 20px",
        md: "0 40px",
        lg: "0 80px",
        xl: "0px 30px",
      }}
    >
      <Box>
        <Slider {...settings}>
          {images.map((image, i) => (
            <Link
              textAlign={"center"}
              href={image?.link}
              isExternal
              cursor={"pointer"}
              key={image.link || i}
            >
              <Flex
                w={"154px"}
                h={"154px"}
                className="new-bg-transparent"
                 rounded={"28px"}
                alignItems={"center"}
                justifyContent={"center"}
                display={"inline-flex"}
                p={"30px"}
                cursor={"pointer"}
              >
                <Image src={image?.image} cursor={"pointer"} />
              </Flex>
            </Link>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}
