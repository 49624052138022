import { Button, Flex } from "@chakra-ui/react";
import PassButton from "./PassButton";
import { memo } from "react";

interface Props {
  isSeller: boolean;
  isAdmin: boolean;
  roomType: string;
  hasBid: boolean;
  handlePass: () => void;
  handleClose: () => void;
  handleBid: () => void;
  isPassing?: boolean;
}

function FooterButtons({
  isSeller,
  isAdmin,
  roomType,
  hasBid,
  handlePass,
  handleClose,
  handleBid,
  isPassing = false,
}: Props) {
  if ((!isSeller && !isAdmin) || roomType === "smartAuction") {
    return (
      <Flex justifyContent="flex-end" className="footer-buttons" gap="6px">
        <PassButton handlePass={() => handlePass()} hasBid={hasBid} />
        {!hasBid && !isPassing ? (
          <Button className="solid-blue" onClick={() => handleBid()} autoFocus>
            Set Bid
          </Button>
        ) : (
          <Button className="solid-blue" onClick={() => handleClose()}>
            Done
          </Button>
        )}
      </Flex>
    );
  }
  return (
    <Button
      className="solid-blue"
      mt="10px"
      float="right"
      onClick={() => handleClose()}
    >
      Done
    </Button>
  );
}

export default memo(FooterButtons);
