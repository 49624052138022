// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { defineStyleConfig } from '@chakra-ui/react'

// export the component theme
const tooltipTheme = defineStyleConfig({
  baseStyle: { zIndex: 9999999 },
})

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
  config,
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',

    small: '594px',
    medium: '866px',
    large: '940px',
    xLarge: '1151px',
    xxLarge: '1249px',
    xxxLarge: '1481px',
    xxxxLarge: '1754px',
  },
  components: {
    Tooltip: tooltipTheme,
  },
})

export default theme
