import { InfoIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { ChangeEvent, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAppContext } from "../../contexts/appContext";
import firebase from "../../services/firebase";
import { updateInfo } from "../../services/user.service";
import { validateEmail } from "../../utils";

export default function EmailInput() {
  const { uid } = useAppContext();
  const [data] = useDocument(
    doc(firebase.getDBApp(), `users`, uid! || "not_found", "contact", "email")
  );
  const [error, setError] = useState<string>();

  const handleBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!validateEmail(value)) return setError("Please enter a valid email");
    setError(undefined);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateInfo(uid!, "email", { value: e.target.value });
  };

  const value = data?.data()?.value as string;

  return (
    <FormControl isInvalid={!!error} isRequired>
      <Flex>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Tooltip label="The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.">
          <Icon as={InfoIcon} />
        </Tooltip>
      </Flex>
      <Input
        defaultValue={value}
        id="email"
        onBlur={handleBlur}
        onChange={handleChange}
        name="email"
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
