import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { ChangeEvent } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAppContext } from "../../contexts/appContext";
import firebase from "../../services/firebase";
import { updateInfo } from "../../services/user.service";

export default function TwitterInput() {
  const { uid } = useAppContext();
  const [data] = useDocument(
    doc(firebase.getDBApp(), `users`, uid! || "not_found", "contact", "twitter")
  );
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateInfo(uid!, "twitter", { value: e.target.value });
  };
  const value = data?.data()?.value as string;
  return (
    <FormControl>
      <FormLabel htmlFor="twitter">Twitter Handle</FormLabel>
      <InputGroup>
        <InputLeftAddon children="@" />
        <Input id="twitter" value={value || ""} onChange={handleChange} />
      </InputGroup>
    </FormControl>
  );
}
