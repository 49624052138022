export default function IconStepperArrow() {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10H25"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5147 1.51472L25 10L16.5147 18.4853"
        stroke="#ADADAD"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
