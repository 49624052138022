import { ethers } from "ethers";
// import { AbiItem } from "web3-utils";

export default [
    {
        inputs: [
            {
                internalType: "address",
                name: "_executor",
                type: "address",
            },
        ],
        name: "addNewApprovedExecutor",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "enum NeoswapPool.TokenType[]",
                name: "_tokenTypes",
                type: "uint8[]",
            },
            {
                internalType: "address[]",
                name: "_tokenContractAddresses",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_tokenIds",
                type: "uint256[]",
            },
            {
                internalType: "address[]",
                name: "_senders",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_amounts",
                type: "uint256[]",
            },
        ],
        name: "allocateTokensToPool",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "address[]",
                name: "_owners",
                type: "address[]",
            },
        ],
        name: "clearSetOfNativeAlloc",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "address[]",
                name: "_contractAddresses",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "_owners",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_tokenIds",
                type: "uint256[]",
            },
        ],
        name: "clearSetOfNftAlloc",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_poolName",
                type: "string",
            },
        ],
        name: "createNewPool",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_execId",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_exIndexStart",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_exIndexEnd",
                type: "uint256",
            },
        ],
        name: "executeTxInstructions",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "execId",
                type: "string",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "added",
                type: "bool",
            },
        ],
        name: "ExInstructionsAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "log",
                type: "string",
            },
            {
                indexed: false,
                internalType: "address",
                name: "contractAddr",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "receiver",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "FailedTransferLog",
        type: "event",
    },
    {
        inputs: [],
        name: "initialiser",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "lockForExecution",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "log",
                type: "string",
            },
        ],
        name: "Log",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_depositId",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "nativeDeposit",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "contractAddr",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "received",
                type: "bool",
            },
        ],
        name: "NFTDeposit",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "_depositId",
                type: "string",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "deposited",
                type: "bool",
            },
        ],
        name: "NativeDeposit",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "string",
                name: "poolName",
                type: "string",
            },
        ],
        name: "NewPool",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        name: "onERC1155BatchReceived",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        name: "onERC1155Received",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        name: "onERC721Received",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_executor",
                type: "address",
            },
        ],
        name: "removeApprovedExecutor",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "uint256[]",
                name: "_tokenIndexes",
                type: "uint256[]",
            },
        ],
        name: "returnMultipleAssets",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_gasAmount",
                type: "uint256",
            },
        ],
        name: "setTransferGasAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_execId",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "address[]",
                name: "_receivers",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_amounts",
                type: "uint256[]",
            },
        ],
        name: "submitNativeInstructions",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_execId",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "uint256[]",
                name: "_allocLocations",
                type: "uint256[]",
            },
            {
                internalType: "address[]",
                name: "_receivers",
                type: "address[]",
            },
        ],
        name: "submitNFTInstructions",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "allocated",
                type: "bool",
            },
        ],
        name: "TokensAllocated",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "string",
                name: "execId",
                type: "string",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "complete",
                type: "bool",
            },
        ],
        name: "TxInstrucExecuted",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "_newPoolName",
                type: "string",
            },
        ],
        name: "updatePoolName",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "string",
                name: "poolName",
                type: "string",
            },
        ],
        name: "UpdatePoolName",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "enum NeoswapPool.PoolStatus",
                name: "_poolStatus",
                type: "uint8",
            },
        ],
        name: "updatePoolStatus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "poolIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum NeoswapPool.PoolStatus",
                name: "status",
                type: "uint8",
            },
        ],
        name: "UpdatePoolStatus",
        type: "event",
    },
    {
        inputs: [],
        name: "balanceInContract",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "gasAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "getAllocationsCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "getNativeAllocation",
        outputs: [
            {
                internalType: "uint256[2]",
                name: "",
                type: "uint256[2]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_contract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_owner",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
        ],
        name: "getNFTAllocation",
        outputs: [
            {
                internalType: "uint256[2]",
                name: "",
                type: "uint256[2]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "getPoolAssetsCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "getPoolStatus",
        outputs: [
            {
                internalType: "enum NeoswapPool.PoolStatus",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_tokenIndex",
                type: "uint256",
            },
        ],
        name: "getTokenInfo",
        outputs: [
            {
                components: [
                    {
                        internalType: "enum NeoswapPool.TokenType",
                        name: "tokenType",
                        type: "uint8",
                    },
                    {
                        internalType: "address",
                        name: "tokenContractAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "allocated",
                        type: "bool",
                    },
                    {
                        internalType: "bool",
                        name: "received",
                        type: "bool",
                    },
                    {
                        internalType: "bool",
                        name: "withdrawn",
                        type: "bool",
                    },
                ],
                internalType: "struct NeoswapPool.Token",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "getTransferCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_txIndex",
                type: "uint256",
            },
        ],
        name: "getTxExecInfo",
        outputs: [
            {
                components: [
                    {
                        internalType: "enum NeoswapPool.TokenType",
                        name: "tokenType",
                        type: "uint8",
                    },
                    {
                        internalType: "address",
                        name: "tokenContractAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "tokenId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "receiver",
                        type: "address",
                    },
                ],
                internalType: "struct NeoswapPool.TxExecute",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "poolCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "pools",
        outputs: [
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
            {
                internalType: "enum NeoswapPool.PoolStatus",
                name: "status",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "allocationsCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "assetsCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "transferCount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_poolIndex",
                type: "uint256",
            },
        ],
        name: "validateAllAssetsDeposited",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
] as ethers.ContractInterface;
