

import { Helmet } from "react-helmet-async";
import { Flex, Heading } from "@chakra-ui/react";
import WaitlistProfileForm from "../components/WaitlistProfileForm";

export default function Waitlist() {
  return (
    <>
      <Helmet>
        <title>NeoSwap | Join AI Trades Waitlist</title>
      </Helmet>
      <Flex justifyContent="center" alignItems="center">
        <Heading className="wait-title" as="h2" size="lg">
          Join AI Trades Waitlist
        </Heading>
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        <p className="sub-waitlist">
          Get notified when new proposals open up. Click 'Join Waitlist' to get
          early access notifications. <br></br>Don't miss out on this
          opportunity!
        </p>
      </Flex>

      <WaitlistProfileForm showToast />
    </>
  );
}