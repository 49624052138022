import { Heading, HStack, Image, Tooltip } from "@chakra-ui/react";
import { useEffect } from "react";
import PendingImg from "../../assets/pending.jpg";
import { saveProgress } from "../../services/room.service";
// import { BiddingDataTable } from "../../components/BiddingDataTable";
import { useAppContext } from "../../contexts/appContext";
import { Nft, useRoom } from "../../contexts/roomContext";

const NFTThumb = ({ nft }: { nft: Nft }) => {
  const thumbnail = nft?.thumbnail;
  const name = nft?.name;
  return (
    <Tooltip label={name}>
      <Image
        minWidth="64px"
        boxSize="64px"
        objectFit="cover"
        src={thumbnail}
        fallbackSrc={PendingImg}
        alt={name}
      />
    </Tooltip>
  );
};

export default function ListingComplete() {
  const { uid } = useAppContext();
  const {
    roomId,
    listings,
    availableForBid,
    getAvailableForBid,
    availableToList,
    biddingDiff,
  } = useRoom();

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  useEffect(() => {
    getAvailableForBid();
  }, []);
  // const { units, abbr } = useRoom();

  // const [reservePrices] = useDocument(doc(db, "rooms", roomId!, "bids", uid!), {
  //   snapshotListenOptions: { includeMetadataChanges: true },
  // });

  const listed = Object.keys(listings || {});

  return (
    <div className="listing-page">
      <Heading className="section-title" size="md">
        Listed Items ({listed?.length})
        <button onClick={goBack} className="small-link">
          Edit Listings
        </button>
      </Heading>
      <HStack className="listing-section box">
        {listed?.map((id) => {
          const nft = availableToList?.find((ele) => ele.itemId === id)!;
          return <NFTThumb key={id} nft={nft} />;
        })}
      </HStack>
      <Heading
        className="section-title"
        size="md"
        marginTop={7}
        justifyContent={"space-between"}
      >
        Listed By Others
        {biddingDiff && (
          <button onClick={getAvailableForBid} className="small-link">
            Get Latest
          </button>
        )}
      </Heading>
      {/* <div className="listing-section box">
        {availableForBid?.length > 0 && <BiddingDataTable />}
      </div> */}
    </div>
  );
}
