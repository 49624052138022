import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { addFeedback } from "../services/room.service";

interface AcceptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  roomId: string;
  uid: string;
  handleInitialize: () => void;
}

const AcceptModal: React.FC<AcceptModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  roomId,
  uid,
  handleInitialize,
}) => {
  interface ReasonState {
    acceptedReasons?: string[];
    acceptedReasonOther?: string;
  }
  interface AcceptReason {
    label: string;
    slug: string;
  }

  const [reason, setReason] = React.useState<ReasonState>({
    acceptedReasons: [],
    acceptedReasonOther: "",
  });
  const [isSent, setIsSent] = React.useState(false);
  const [userAcceptReasons, setUserAcceptReasons] = React.useState<number[]>(
    []
  );

  const handleSubmit = async () => {
    await addFeedback(roomId, uid, reason);
    handleInitialize();
    setReason({});
    //onClose();
    setIsSent(true);
  };

  const handleSkip = async () => {
    handleInitialize();
    setReason({});
    //onClose();
    setIsSent(true);
  };

  const closeModal = () => {
    setIsSent(false);
    onClose();
  };

  const handleReasonChange = (itemIndex: number) => {
    const isReasonExists = userAcceptReasons.includes(itemIndex);
    let updatedReasons: number[] = [];
    if (isReasonExists) {
      // Remove the itemIndex from userAcceptReasons
      updatedReasons = userAcceptReasons.filter(
        (reason) => reason !== itemIndex
      );
      setUserAcceptReasons(updatedReasons);
    } else {
      // Add the itemIndex to userAcceptReasons
      updatedReasons = [...userAcceptReasons, itemIndex];
      setUserAcceptReasons(updatedReasons);
    }

    // Update the reason state based on selected reasons
    const selectedReasons: string[] = updatedReasons.map(
      (itemIndex: number) => acceptReasons[itemIndex].slug
    );
    setReason({ acceptedReasons: selectedReasons });
  };

  const acceptReasons: AcceptReason[] = [
    {
      label: "Wanted to get rid of Item",
      slug: "give-nft",
    },
    {
      label: "Good value",
      slug: "value",
    },
    {
      label: "Wanted offered collection",
      slug: "get-collection",
    },
    {
      label: "Wanted offered Item",
      slug: "get-nft",
    },
    {
      label: "Other (please specify)",
      slug: "other",
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={closeModal} trapFocus={false}>
      <ModalOverlay />
      <ModalContent bg="#041C28">
        <ModalHeader>
          {!isSent ? (
            <h1>
              Tell us what you did like about the offer
              <br />
              (Select all that apply)
            </h1>
          ) : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isSent ? (
            <div className="accepted-div">
              <h1>You have accepted trade</h1>
              <p>
                Thank you for your feedback on this trade. Our AI is always
                learning so hopefully we can offer you a better trade in the
                future!
              </p>
              <hr />
            </div>
          ) : (
            <div>
              <Text fontSize={"15px"} mb={"10px"}>
                This will help us propose better trades to you in the future.
              </Text>

              <Flex direction={"column"} gap={"12px"}>
                {acceptReasons.map((reason, index) => (
                  <Box
                    key={index}
                    textAlign={"center"}
                    background={
                      userAcceptReasons.includes(index) ? "#6E60FE" : "#072333"
                    }
                    border={`3.85px solid ${userAcceptReasons.includes(index) ? "#6E60FE" : "#397293"
                      }`}
                    borderRadius={"11.5368px"}
                    py={2}
                    cursor={"pointer"}
                    onClick={() => handleReasonChange(index)}
                  >
                    {reason.label}
                  </Box>
                ))}
              </Flex>

              <Textarea
                className="accept-input"
                height="20"
                mt="3"
                value={reason.acceptedReasonOther}
                onChange={(e) =>
                  setReason({
                    acceptedReasons: reason.acceptedReasons,
                    acceptedReasonOther: e.target.value,
                  })
                }
                placeholder="write here..."
                bg="#072638"
                border="2px solid"
                borderColor="grey"
                marginBottom="4"
                isDisabled={
                  !userAcceptReasons.includes(acceptReasons.length - 1)
                }
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {isSent ? (
            <div>
              <Button bg="#6565FF" color="white" onClick={onClose}>
                Done
              </Button>
            </div>
          ) : (
            <Flex w={"full"}>
              <Button
                border="1px solid"
                borderColor="grey"
                bg="#041C28"
                color="grey"
                mr={"auto"}
                onClick={handleSkip}
              >
                Skip
              </Button>
              <Button
                border="1px solid"
                borderColor="grey"
                bg="#041C28"
                color="grey"
                mr={3}
                onClick={onClose}
              >
                Go back
              </Button>

              <Button
                // bg={reason ? "#072638" : "grey"}
                // color={reason ? "grey" : "white"}
                bg="#6C60FF"
                color="white"
                onClick={handleSubmit}
                isDisabled={!reason}
                _disabled={{ opacity: 1, cursor: "not-allowed" }}
              >
                Send
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AcceptModal;
