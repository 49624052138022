import {
  AppConfig,
  PsbtData,
  UserSession,
  showConnect,
  openPsbtRequestPopup,
} from "@stacks/connect";
import { signTransaction, SignTransactionOptions } from "sats-connect";
// import { StacksTestnet, StacksMainnet } from "@stacks/network";
import { useAppContext } from "../../contexts/appContext";
import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useChain } from "../../contexts/chainsContext";
import { useBtcContext } from "../../contexts/btcContext";
// import { StacksMainnet } from "@stacks/network";
import * as btc from "@scure/btc-signer";
import { useUA } from "../../contexts/userTracking";
const { hex, base64 } = require("@scure/base");

const appConfig = new AppConfig();
const userSession = new UserSession({ appConfig });

interface SignPsbtRequestParams {
  publicKey: string;
  hex: string;
  allowedSighash?: btc.SignatureHash[];
  signAtIndex?: number | number[];
  network?: typeof btc.NETWORK; // default is user's current network
  account?: number; // default is user's current account
}

export default function useBitcoin() {
  const {
    connect: btcConnect,
    isConnecting,
    addresses,
    wallet,
  } = useBtcContext();
  const { onOpen } = useChain();
  const toast = useToast();
  const {
    getEncodedNonce,
    getToken,
    signInWithToken,
    signingIn,
    signOut,
    updateState,
    btcInfo,
  } = useAppContext();
  const { gaSignWallet } = useUA();

  const [connected, setConnected] = useState(false);

  // useEffect(() => {
  //   setConnected(userSession.isUserSignedIn());
  // }, []);

  const connect = () => {
    if (wallet) {
      setConnected(true);
    } else if (!userSession.isUserSignedIn()) {
      showConnect({
        userSession,
        // network: StacksMainnet,
        appDetails: {
          name: "NeoSwap",
          icon: "https://neoswap.party/favicon.ico",
        },
        onFinish: async () => {
          setConnected(true);
        },
        onCancel: () => {
          // handle if user closed connection prompt
        },
      });
    } else {
      setConnected(true);
    }
  };

  useEffect(() => {
    if (!addresses) return;
    updateState({
      btcInfo: addresses,
    });
    const address = addresses.ordinals.address;
    if (!address) return;
    handleSign(address);
  }, [addresses]);

  const handleSign = async (address: string) => {
    // const window = window as any;
    // console.log(window.btc);
    updateState({ signingIn: true });
    const nonce = await getEncodedNonce(address, "bitcoin");
    if (!nonce) throw new Error("No nonce");
    let signature = "nosign";
    // switch (btcWallet) {
    //   case BtcWallets.HIRO:
    //     signature = await window?.btc?.request("signMessage", {
    //       message: nonce,
    //     });
    //   case BtcWallets.XVERSE:
    //     signature = "nosign";
    //   // await signMessage({
    //   //   payload: {
    //   //     address,
    //   //     message: nonce as string,
    //   //     network: { type: "Mainnet" },
    //   //   },
    //   //   onFinish: (response: any) => {
    //   //     signature = response;
    //   //   },
    //   //   onCancel: () => {
    //   //     console.log("CANCELLED");
    //   //   },
    //   // });
    // }
    if (!signature) throw new Error("No signature");
    const token = await getToken(address, "bitcoin", signature);
    if (!token) throw new Error("No token");
    await signInWithToken(token);
    gaSignWallet(wallet);
    updateState({ signingIn: false });
  };

  const signIn = async () => {
    try {
      if (!localStorage.getItem("xverseDismissed")) {
        onOpen();
        return localStorage.setItem("xverseDismissed", "true");
      }

      updateState({ signingIn: true });
      const btcAddresses = await btcConnect();
      console.log("btcAddresses", btcAddresses);
    } catch (e) {
      console.log(e);
      onOpen();
    } finally {
      updateState({ signingIn: false });
    }
  };

  const deposit = async (config: any) => {
    try {
      let signedPsbt;

      console.log("DEPOSITING", config);
      if (!btcInfo.ordinals.address) {
        throw new Error("No ordinals address found");
      }

      if (!btcInfo.wallet || btcInfo.wallet === "xverse") {
        // Xverse Sign
        console.log("Signing with Xverse", window.BitcoinProvider);
        const signPsbtOptions = {
          payload: {
            network: {
              type: "Mainnet",
            },
            broadcast: false,
            psbtBase64: config.psbtBase64,
            message: config.message,
            inputsToSign: config.inputsToSign,
          },
          onFinish: (response: any) => {
            signedPsbt = response.psbtBase64;
          },
          onCancel: (e: any) => {
            console.log("Error signing", e);
            toast({
              title: "Error Signing PSBT",
              description: e?.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          },
        };

        console.log("SIGN PSBT OPTIONS", signPsbtOptions);
        await signTransaction(signPsbtOptions as SignTransactionOptions);
      }

      if (btcInfo.wallet === "hiro") {
        console.log("Config object",config)
        const [{ address: userAddress, signingIndexes, sigHash }] = config.inputsToSign;
        const userPubKey =
          btcInfo.ordinals.address == userAddress
            ? btcInfo.ordinals.publicKey
            : btcInfo.payment.publicKey;
        const psbtHex = hex.encode(base64.decode(config.psbtBase64));
        const sigHashToUse = sigHash === 0 ? [btc.SignatureHash.ALL] : [btc.SignatureHash.SINGLE , btc.SignatureHash.ANYONECANPAY]; 
        const requestParams: SignPsbtRequestParams = {
          publicKey: userPubKey!,
          hex: psbtHex,
          signAtIndex: signingIndexes,
          allowedSighash: sigHashToUse,
        };

        const signedResponse = await window.btc.request(
          "signPsbt",
          requestParams
        );

        console.log("SIGNED RESPONSE", signedResponse);

        signedPsbt = base64.encode(hex.decode(signedResponse.result.hex));
      }

      if (btcInfo.wallet === "unisat") {
        const unisat = window.unisat;
        const result = await unisat?.requestAccounts();
        const address = result[0];

        const requiredAddress = config.inputsToSign[0].address;
        if (address !== requiredAddress) {
          let first6Char = requiredAddress.substring(0, 6);
          let last5Char = requiredAddress.substring(requiredAddress.length - 5);
          const truncatedAddress = first6Char + "..." + last5Char;
          return toast({
            title: "Error Signing PSBT",
            description: `Wallet address doesn't match the required signing address. Please switch to your wallet with the address ${truncatedAddress} in Unisat and try again.`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
        const psbtHex = hex.encode(base64.decode(config.psbtBase64));
        const psbtResult = await (window as any).unisat.signPsbt(psbtHex, {
          autoFinalized: false,
        });
        signedPsbt = base64.encode(hex.decode(psbtResult));
      }
      return { signedPsbt };
    } catch (e: any) {
      console.log(e);
      toast({
        title: "Error",
        description:
          e?.message || "Please report a bug through the feedback button.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      throw e;
    }
  };

  const connecting = signingIn || isConnecting;
  // console.log(">>>>>>>>>>>>>>", connected, userSession);
  return {
    connect,
    signIn,
    signOut,
    deposit,
    cancel: () => {},
    connected: true,
    connecting,
    getStatementUrl: () => {},
    wallet,
  };
}

// var resolve = function(cardinalAddress, ordinalAddress) {
//   // use addresses
// };

// const signIn = async (addr?: string) => {
//   const address = addr || stxAddress;
//   try {
//     updateState({ signingIn: true });
//     if (!address) return await handleConnect();
//     if (!address) throw new Error("No public key");
//     const nonce = await getEncodedNonce(address, "stacks");
//     if (!nonce) throw new Error("No nonce");
//     const signature = await openSignMessage({
//       message: nonce as string,
//     });
//     if (!signature?.signature) throw new Error("No signature");
//     const token = await getToken(
//       address,
//       "stacks",
//       signature?.signature,
//       signature?.publicKey
//     );
//     if (!token) throw new Error("No token");
//     await signInWithToken(token);
//     gaSignWallet();
//   } catch (e: any) {
//     console.log(e);
//     toast({
//       title: "Error",
//       description: e.message,
//       status: "error",
//       isClosable: true,
//     });
//   } finally {
//     updateState({ signingIn: false });
//   }
// };
