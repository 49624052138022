import { useRef } from "react";
import { Thead, Tbody, Tr, Th, chakra, Box } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import NeoTable from "../Table/NeoTable";
import { Row } from "../Table/Row";
import { BiddableNft, Nft, useRoom } from "../../contexts/roomContext";
import { createFloor } from "../../utils";

export type DataTableProps = {
  nftIds: string[];
  listings: { [key: string]: { reservePrice?: number } };
  maxReservePrice: number;
  canList: boolean;
  units: number;
  abbr: string;
  uid: string;
  roomId: string;
  prevRows?: ListableNft[];
};

export type ListableNft = {
  id: string;
  uid: string;
  roomId: string;
  reservePrice?: number;
  listingPrice?: number;
  isListed: boolean;
  canList: boolean;
  maxReservePrice: number;
  abbr: string;
  units: number;
  collectionFloorPrice?: number | string | undefined;
  collectionName?: string;
};

interface Props {
  data: Nft[] | BiddableNft[];
  columns: any;
}

export function CollectionTable({ data, columns }: Props) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <Box
      className="collection-table"
      ref={tableContainerRef}
      width="100%"
      overflow="auto"
      px={4}
    >
      <NeoTable>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {header.column.getIsSorted() ? (
                      <chakra.span pl="4">
                        {header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {rows.map((row) => {
            const cells = row.getVisibleCells() as any;
            return <Row key={row.id} row={row} cells={cells} />;
          })}
        </Tbody>
      </NeoTable>
    </Box>
  );
}
