import { Skeleton } from "@chakra-ui/react";

interface Props {
  count?: number;
  minH?: string;
}

export default function TileLoader({ count = 8, minH }: Props) {
  return (
    <>
      {Array.from(Array(count).keys()).map((ele) => {
        return <Skeleton key={ele} minH={minH} />;
      })}
    </>
  );
}
