import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const NsModal = ({
  isOpen,
  onClose,
  children,
  header = "Default Header",
  headerClasses = "",
  showCloseButton = true,
  size = "md",
  footer = true,
  bodyClass = "",
  headerAlign = "center",
  footerButtons = [
    { label: "Close", onClick: () => { }, variant: "grey", mr: 3 },
    { label: "Secondary Action", onClick: () => { }, variant: "grey", closeOnClick: false }
  ],
}: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size={size} trapFocus={false}>
    <ModalOverlay />
    <ModalContent bg="#1D1D24" borderRadius={0} className="bg-opacity-20 !mt-0 md:!mt-10">
      <ModalHeader textAlign={headerAlign} className={`text-xl rogan-bold uppercase ${headerClasses}`}>{header}</ModalHeader>
      {showCloseButton && <ModalCloseButton />}
      <ModalBody className={bodyClass}>{children}</ModalBody>
      {footer && <ModalFooter>
        <div className='w-full flex justify-between'>
          {footerButtons.map((btn: any, index: any) => (
            <button key={index} {...btn} onClick={() => {
              btn.onClick();
              if (btn.closeOnClick !== false) {
                onClose();
              }
            }} className={`
              px-10 py-3 justify-center items-center inline-flex text-center  rogan-regular uppercase  bg-white bg-opacity-10
              ${btn.variant === "grey" ? "text-white" :
                btn.variant === "action" ? "text-amber-300 rogan-bold" :
                  "text-white"}
              `}>
              {btn.label}
            </button>
          ))}
        </div>
      </ModalFooter>}
    </ModalContent>
  </Modal>
);

export default NsModal;
