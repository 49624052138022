import useStacks from "./useStacks";
import useSolana from "./useSolana";
import usePolygon from "./usePolygon";
import useEthereum from "./useEthereum";
import useBitcoin from "./useBitcoin";

const chainInterface: ChainInterface = {
  stacks: useStacks,
  solana: useSolana,
  ethereum: useEthereum,
  polygon: usePolygon,
  notFound: () => ({
    signOut: () => ({}),
    signIn: () => ({}),
    connect: () => ({}),
    deposit: () => ({}),
    connected: false,
    connecting: false,
    getStatementUrl: () => {},
  }),
};

export type ChainInterface = {
  [key: string]: (chainName?: string) => {
    signOut: any;
    signIn: any;
    connect: any;
    deposit: any;
    connected: boolean;
    connecting: boolean;
    address?: string;
    getStatementUrl: any;
    deprecatedDeposit?: any;
  };
};

export default function useNeoChain() {
  // return chainInterface?.[chainName as keyof ChainInterface]?.(chainName);
  return {
    stacks: useStacks(),
    solana: useSolana(),
    // ethereum: useEthereum(),
    // polygon: usePolygon(),
    bitcoin: useBitcoin(),
    notFound: {
      signOut: () => ({}),
      signIn: () => ({}),
      connect: () => ({}),
      deposit: () => ({}),
      connected: false,
      connecting: false,
      getStatementUrl: () => ({}),
    },
  };
}
