import { ReactNode, useState, createContext, useMemo } from "react";
import { ClientProvider } from "@micro-stacks/react";
import { STACKS_NETWORK } from "../services/stacks";
import { useChain } from "../contexts/chainsContext";
import BtcContextProvider from "./btcContext";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import {
  // BraveWalletAdapter,
  CoinbaseWalletAdapter,
  // SolflareWalletAdapter,
  // LedgerWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

require("@solana/wallet-adapter-react-ui/styles.css");

const network = process.env.REACT_APP_SOLANA_NETWORK;
if (!network) throw new Error("Solana network must be set!");

// https://github.com/GtpsFinance/gtpsfinance-dex-interface/blob/c6a45f378b39e3a86d061ad0da1698783c242a30/src/components/Web3Provider/index.tsx

const supportedWallets = (chainName?: string) => {
  // console.log("chainName", chainName);
  switch (chainName) {
    case "Solana":
      return ["phantom", "solflare", "coinbasesolana", "bravesol"];
    case "Ethereum":
      return ["metamask", "coinbase", "walletconnect", "braveevm"];
    default:
      return [];
  }
};

type ConnectionState =
  | "notConnected"
  | "connecting"
  | "connected"
  | "aborted"
  | "initiateAuth"
  | "authInProgress";

interface WalletContextData {
  isConnected: boolean;
  setIsConnected: (open: boolean) => void;
  isAuthenticating: boolean;
  setIsAuthenticating: (open: boolean) => void;
  connectionState: ConnectionState;
  setConnectionState: React.Dispatch<React.SetStateAction<ConnectionState>>;
}

const defaultWalletContextData: WalletContextData = {
  isConnected: false,
  setIsConnected: () => { }, // Provide a default no-op function
  isAuthenticating: false,
  setIsAuthenticating: () => { }, // Provide a default no-op function
  connectionState: "notConnected",
  setConnectionState: () => { }, // Provide a default no-op function
};

export const WalletContext = createContext<WalletContextData>(
  defaultWalletContextData
);

interface Props {
  children: ReactNode;
}

export default function NeoSwapWalletProvider({ children }: Props) {
  // const { chain } = useChain();
  // const [isConnected, setIsConnected] = useState(false);
  // const [isAuthenticating, setIsAuthenticating] = useState(false);
  // const [connectionState, setConnectionState] =
  //   useState<ConnectionState>("notConnected");

  // const changeConnectionState = async (connectionStatus: ConnectionState) => {
  //   setConnectionState(connectionStatus);
  // };

  const network = WalletAdapterNetwork.Mainnet;
  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      // new SolflareWalletAdapter(),
      // new BraveWalletAdapter(),
      new CoinbaseWalletAdapter(),
      // new LedgerWalletAdapter(),
    ],
    [network]
  );

  return (
    <BtcContextProvider>
      {/* <WalletContext.Provider
        value={{
          isConnected,
          setIsConnected,
          isAuthenticating,
          setIsAuthenticating,
          connectionState,
          setConnectionState,
        }}
      > */}
      <ClientProvider
        appName="NeoSwap"
        appIconUrl="https://neoswap.party/favicon.ico"
        network={STACKS_NETWORK}
      >
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
            <WalletModalProvider>
              {/* <DynamicContextProvider
            settings={{
              environmentId: process.env
                .REACT_APP_DYNAMIC_APP_ENVIRONMENT_ID as string,
              initialAuthenticationMode: "connect-only",
              walletsFilter: FilterWallets(supportedWallets(chain?.name)),
              enableVisitTrackingOnConnectOnly: true,
              logLevel: 'DEBUG',
              evmNetworks,
              eventsCallbacks: {
                onAuthFlowOpen: () => {
                  changeConnectionState("connecting");
                },
                onAuthFlowClose: () => {
                  changeConnectionState("initiateAuth");
                },
                onLogout: (args) => {
                  console.log("onLogout was called", args);
                },
              },
            }}
          > */}
              {children}
              {/* </DynamicContextProvider> */}
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ClientProvider>
      {/* </WalletContext.Provider> */}
    </BtcContextProvider>
  );
}
