import { useCallback, useEffect, useMemo, useState } from 'react'
import { useWalletPassThrough } from '../contexts/WalletPassthroughContext'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'

import { Connection } from '@solana/web3.js'

import { SolanaClient } from '../clients/solanaClient'
import { PackTransaction } from '../types/transactions'

const NO_WALLET_ERR = 'noWalletErr'

const useSolana = () => {
  const wallet = useWalletPassThrough()
  const walletModal = useWalletModal()
  const [autoConnect, setautoConnect] = useState(false)

  useEffect(() => {
    if (autoConnect && wallet.wallet) {
      connect()
    }
  }, [wallet.wallet, autoConnect])

  const connect = async () => {
    try {
      if (!wallet.connected && !wallet.wallet) {
        walletModal.setVisible(true)

        throw new Error(NO_WALLET_ERR)
      }
      await wallet.connect()
    } catch (e: any) {
      if (e.message === NO_WALLET_ERR) {
        setautoConnect(true)
      }
    }
  }

  const solanaClient = useMemo(() => {
    const alternativeConnections = process.env.REACT_APP_SOLANA_ALTERNATIVE_NETWORKS?.split(
      ','
    ).map((url) => {
      return new Connection(url, 'confirmed')
    })
    return new SolanaClient({
      wallet: wallet,
      connection: new Connection(process.env.REACT_APP_SOLANA_NETWORK!, 'confirmed'),
      alternativeConnections: alternativeConnections,
      sendDelay: 50, // set the desired send delay
    })
  }, [wallet.connected])

  const signAndSendTransactions = useCallback(
    async (pTxs: PackTransaction[]) => {
      const sendOptions = { skipPreflight: false }

      return await solanaClient.signAndSendTransactions(pTxs, sendOptions, true)
    },
    [wallet]
  )

  const checkTransactions = useCallback(async (pTxs: PackTransaction[], rebroadcast?: boolean) => {
    const sendOptions = { skipPreflight: true }
    return await solanaClient.checkTransactions(pTxs, rebroadcast, sendOptions)
  }, [])

  return {
    connect: connect,
    disconnect: wallet.disconnect,
    connected: wallet.connected,
    walletAddress: wallet.publicKey?.toBase58(),

    signAndSendTransactions,
    checkTransactions,
  }
}

export default useSolana
