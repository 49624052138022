import React from 'react';

const PartnerLogo: React.FC<{
  logoImageAlt: string;
  logoSubtitle: string;
}> = ({
  logoImageAlt = '',
  logoSubtitle = ''
}) => {
    return (
      <div className="partner-logo">
        <div className="partner-logo-img" role="img" aria-label={logoImageAlt}></div>
        <h2 className="partner-logo-subtitle">{logoSubtitle}</h2>
      </div>
    );
  }

export default PartnerLogo;
