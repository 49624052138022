import React, { forwardRef, useState, useEffect, useRef } from "react";
import { Box, Button, Text, BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
  noOfLines: number;
}

export const ExpandableText = forwardRef<HTMLDivElement, Props>(
  ({ children, noOfLines, ...rest }, ref) => {
    const [expandedCount, setExpandedCount] = useState<number | undefined>(noOfLines);
    const [isClicked, setIsClicked] = useState(false);
    const [isTextClamped, setIsTextClamped] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (contentRef.current) {
        setIsTextClamped(
          contentRef.current.scrollHeight > contentRef.current.clientHeight || isClicked
        );
      }
    }, [isClicked, expandedCount]);

    const handleToggle = () => {
      setIsClicked(!isClicked);
      setExpandedCount(expandedCount ? undefined : noOfLines);
    };

    return (
      <Box ref={ref} {...rest}>
        <Box ref={contentRef} noOfLines={expandedCount}>
          {children}
        </Box>
        {isTextClamped && (
          <Button
            float="right"
            color="#FF4ADA"
            size="sm"
            variant="link"
            onClick={handleToggle}
          >
            <Text>{expandedCount ? 'Read more' : 'Show less'}</Text>
          </Button>
        )}
      </Box>
    );
  }
);

ExpandableText.displayName = 'ExpandableText';
