export default function IconWaiting() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M24.53,3.97V0.18H5.41v3.79c0,4.48,4.61,5.9,7.25,9.28c0.59,0.76,0.59,3.1,0,3.86 c-2.64,3.38-7.25,4.8-7.25,9.28v3.79h19.12v-3.79c0-4.48-4.61-5.9-7.25-9.28c-0.59-0.76-0.59-3.1,0-3.86 C19.92,9.87,24.53,8.46,24.53,3.97z" fill="currentColor"/>
    </svg>
  );
}
