import { Helmet } from "react-helmet-async";
import { Heading } from "@chakra-ui/react";
import ProfileForm from "../components/ProfileForm";
import SwapModal from "../components/SwapModal";

export default function Profile() {
  return (
    <>
      <Helmet>
        <title>NeoSwap | Profile</title>
      </Helmet>
      <Heading className="page-title" as="h2" size="lg">
        Profile
      </Heading>
      <ProfileForm />
    </>
  );
}
