import { useState, useEffect } from "react";
import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import {
  doc,
  query,
  collection,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useChain } from "../contexts/chainsContext";
import EmailInput from "../components/Inputs/EmailInput";
import { useUA } from "../contexts/userTracking";
import { useAppContext } from "../contexts/appContext";
import Firebase from "../services/firebase";
import { generateBuyDown } from "../services/room.service";
import { joinAiWaitlist } from "../services/user.service";
import { createFloor, hasError, validateEmail } from "../utils";

import { useQuery } from "@tanstack/react-query";
import { getNftsMetaData } from "./AiOffers";
import { ChainDetails } from "../components/AiTradesV2/types";
import TradesForDayComplete from "../components/AiTradesV2/TradesForDayComplete";

import MagicEden from "../assets/supportedpaypartners/magiceden.png";
import Tensor from "../assets/supportedpaypartners/tensor.png";
import HowItWorks from "../components/HowItWorks";
import LinkModal from "../components/LinkModal";

const ids = [
  "solana-3nunXeT57fywysST8oLPsinVLHcCY6kByW136AW2hB6v",
  "solana-SiRx4pyycfm1RTaoffHbLNsAZrwoDocZgxj6G3gUi26",
  "solana-9o9RGJHhWebAjusA1btZdnEZGvyXZDUJHyetsL969LU8",
  "solana-GhAcswniqHAFayDR1auAtjJFX2ygSJYb9D9eevNYaffZ",
  "solana-F8tUzEP2uTq4FRuftT5ZPH7LAQktpeZL5h91t62eZD6v",
  "solana-3nxmRW2nWMMYTNc17t1VfNASJWvgGHUNJVn2PoxsK4wT",
  "solana-7DayWXfdfNkhfx6bh5G9dsUdx83B3BihfRdxAuVxE1ck",
  "solana-J1eJ6zwLYrBM9BWYjV6aUk1Q8McKHVaXuVnvyXFtEVpL",
  "solana-B7U2HccZxqN5d38KyNyYrzZcVNvkSkg6Pm6mbyVAJwLA",
  "solana-2tT6BjdHWB4bhgaNEEVTfGnP9N5NcXqiF2fS6KBVd1xD",
  "solana-3XWXfzRav7ku3kzCLhs2vvwbduNUrhFTBawXs1jMD5Lp",
];

const LeftSlider = ({ settings }: { settings: any }) => {
  const { getChainDetails } = useChain();
  const chain = getChainDetails("solana") as ChainDetails;

  const { isFetching, data } = useQuery({
    queryKey: ["aiTradesSlider"],
    queryFn: () => getNftsMetaData({ ids }),
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="w-[400px] max-w-full pt-px flex-col justify-start items-center gap-8 inline-flex">
      <div className="w-96 text-center text-indigo-500 text-xl font-medium leading-loose">
        Recently Purchased With NeoSwap AI
      </div>
      <div className="w-full">
        <div className="justify-start items-center inline-flex w-full relative">
          <Slider {...settings} className="w-full relative">
            {isFetching && (
              <div>
                <div className="rounded-2xl w-full overflow-hidden">
                  <Skeleton height="500px" width="100%" />
                </div>
              </div>
            )}
            {data?.map((img) => {
              const name = img.name;
              const src = img.image || img.thumbnail || "";
              const collectionFloorPrice = createFloor(img, chain.truncate);
              const collectionName = img.collection.name;
              return (
                <div key={img.itemId}>
                  <div className="rounded-2xl w-full overflow-hidden">
                    <img src={src} alt={name} />
                  </div>
                  <div className="p-4 bg-black bg-opacity-30 rounded-2xl backdrop-blur-3xl flex-col justify-start items-start gap-2 flex w-full mt-4">
                    <div className="text-white text-xl font-medium leading-loose">
                      {img.name}
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="text-white text-base font-medium leading-tight">
                        {collectionName}
                      </div>
                      <div className="self-stretch text-right text-white text-base font-medium leading-tight">
                        {collectionFloorPrice}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default function PayWithNS() {
  const { gaGenerateSwap } = useUA();
  const toast = useToast();
  const [error, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [joining, setJoining] = useState(false);
  const [sequenceMessage, setSequenceMessage] = useState("");
  const [results, setResults] = useState<any>([]);
  const { uid } = useAppContext();
  const navigate = useNavigate();
  const theChain = uid!.split("-")[0];

  const [nftUrl, setNftUrl] = useState("");

  const { chain } = useChain();

  const [emailData] = useDocument(
    doc(Firebase.getDBApp(), `users`, uid!, "contact", "email")
  );

  const email = emailData?.data();

  const [chainAvailableTradeData, chainLoading] = useDocument(
    doc(Firebase.getDBApp(), "blockchain", theChain || "")
  );
  const [userAvailableTradeData, userLoading] = useDocument(
    doc(Firebase.getDBApp(), "users", uid!, "private", "ai")
  );

  const [waitlistData] = useDocument(
    doc(Firebase.getDBApp(), "aiWaitlist", uid!)
  );

  const waitlist = waitlistData?.data();
  const chainAvailableTrade = chainAvailableTradeData?.data();
  const ai = chainAvailableTrade?.["ns-pay"];
  const userAvailableTrade = userAvailableTradeData?.data()?.availableAiTrades;
  const allowNsPay = userAvailableTradeData?.data()?.allowNsPay;

  const getMyAiTrades = async () => {
    // setCurrentFilter("ai");
    const db = Firebase.getDBApp();
    const q = query(
      collection(db, "rooms"),
      where("info.roomType", "==", "ai"),
      where("users", "array-contains", uid),
      orderBy("info.startAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const q2 = query(
      collection(db, "aiRooms"),
      where("userId", "==", uid),
      orderBy("info.createdAt", "desc")
    );
    const querySnapshot2 = await getDocs(q2);
    setResults([...querySnapshot2.docs, ...querySnapshot.docs]);
  };

  useEffect(() => {
    getMyAiTrades();
  }, []);

  const handleGenerateTrade = async () => {
    try {
      setLoading(true);
      loadingSequence(0);
      const res = await generateBuyDown({ userId: uid!, nftUrl });
      if (!res.success) {
        throw new Error("Failed to fetch data");
      }
      gaGenerateSwap();
      navigate(`/pay-with-neoswap/${res.aiRoomId}`, { replace: true });
    } catch (e) {
      setHasError(true);
      setLoading(false);
      setSequenceMessage("");
      // const isNoNftError =
      //   (e as Error).message.replace(/^FirebaseError:\s*/, "") ===
      //   "No offers found";

      // toast({
      //   title: isNoNftError ? "No trade found" : "Error",
      //   description: isNoNftError
      //     ? `No offers found. We recommend adding more ${chain?.abbr}s and Items to your wallet, and then try again to increase your likelihood of success.`
      //     : (e as Error).message.replace(/^FirebaseError:\s*/, ""),
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   styleConfig: {
      //     zIndex: 999999,
      //   },
      // });
    }
  };

  const sequenceSteps = [
    { message: "Reading your Items", delay: 4000 },
    { message: "Finding trades", delay: 8000 },
    { message: "Solving" }, // No delay for the last step
  ];

  const loadingSequence = (index: number) => {
    if (index >= sequenceSteps.length) {
      // Exit the recursion when all messages have been shown
      return;
    }

    const { message, delay } = sequenceSteps[index];

    // Update the state with the current message
    setSequenceMessage(message);

    setTimeout(() => {
      // Call loadingSequence again with the next index after the specified delay
      loadingSequence(index + 1);
    }, delay || 0); // Use 0 as the default delay if not specified
  };

  const handleJoinWaitlist = async () => {
    try {
      setJoining(true);
      if (!validateEmail(email?.value)) {
        throw new Error("Invalid email");
      }
      await joinAiWaitlist(uid!, email?.value);
      toast({
        title: "Congratulations!",
        description: "You were added to the waitlist.",
        status: "success",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } catch (e: any) {
      toast({
        title: "Something went wrong adding you to the waitlist",
        description: e?.message || "",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      setJoining(false);
    }
  };

  if (userLoading || chainLoading) {
    return (
      <div className="nft-loader">
        <Spinner color="#ed6cd8" />
        <p> Loading, please wait... </p>
      </div>
    );
  }

  if ( (!ai || ai?.availableAiTrade < 1 || !ai?.active) && !allowNsPay) {
    const hasJoined = !!waitlist;

    if (hasJoined) {
      return (
        <>
          <Helmet>
            <title>NeoSwap | AI Trades Waitlist</title>
          </Helmet>
          <Center>
            <Container maxW="xl" textAlign="center" gap={8}>
              <Heading className="wait-title" as="h2" size="lg">
                You're on the list
              </Heading>
              <p className="sub-waitlist">
                We'll let you know when new proposals open up.
              </p>
              <p>
                {" "}
                In the meantime, you can switch your wallet to Solana or Stacks
                to try AI Trades.{" "}
              </p>
            </Container>
          </Center>
        </>
      );
    }

    return (
      <>
        <Helmet>
          <title>NeoSwap | AI Trades Waitlist</title>
        </Helmet>
        <Center>
          <Container maxW="xl" textAlign="center" gap={8}>
            <Heading className="wait-title" as="h2" size="lg">
              Join AI Trades Waitlist
            </Heading>
            <p className="sub-waitlist">
              {/* We are currently live on Stacks and Solana, with one trade
              available per user each day. */}
              We are pausing AI trades to make major improvements. We'll be back.
            </p>
            <p className="sub-waitlist">
              Get notified when AI trades are live.
            </p>
            <p className="sub-waitlist">
              Click 'Join Waitlist' to get early access notifications.
            </p>
            <Container maxW="xl">
              <EmailInput />
              <Button
                width="100%"
                className="grad-btn"
                mt="30px"
                onClick={handleJoinWaitlist}
                isDisabled={!!waitlist}
                isLoading={joining}
              >
                Join Waitlist
              </Button>
            </Container>
          </Container>
        </Center>
      </>
    );
  }

  if (userAvailableTrade < 1) {
    return (
      <>
        <Helmet>
          <title>NeoSwap | AI Trades Complete</title>
        </Helmet>
        <TradesForDayComplete />
      </>
    );
  }

  type CustomArrowProps = {
    onClick: () => void; // assuming onClick is a function
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -right-10 top-44 z-10 bg-black bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 2L13.5 14L1.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -left-10 top-44 z-10 bg-black bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2L2.5 14L14.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };
  const settings = {
    customPaging: function (i: number) {
      return <div className="w-2 h-2 rounded-full opacity-30 bg-white" />;
    },
    dots: true,
    dotsClass:
      "justify-center items-center gap-2.5 !flex w-full mt-4 aitradeslider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <CustomNextArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  };

  const RightContentSection = () => (
    <div className="flex-col w-[490px] max-w-full justify-center items-center gap-12 inline-flex">
      <div className="self-stretch flex-col justify-center items-center gap-12 flex">
        <div className="self-stretchflex-col justify-center items-center gap-12 flex">
          <h1 className="text-center text-5xl sm:text-6xl font-medium gradient-text">
            Pay with NeoSwap
          </h1>
        </div>
        <div className="text-center">
          <span className="text-gray-200 text-xl font-medium leading-snug">
            Automatically generate offers to buy an Item using Item you already
            have.
          </span>
        </div>
      </div>
      <div className="self-stretchpy-2.5 flex-col justify-center items-center gap-2.5 flex w-full">
        {sequenceMessage && loading ? (
          <Flex
            p={10}
            alignItems={"center"}
            justifyContent={"center"}
            gap={4}
            flexDir="column"
          >
            <Spinner color="#ed6cd8" size={"xl"} emptyColor="#666666" />{" "}
            {sequenceMessage}
          </Flex>
        ) : (
          <div className="flex flex-col w-full gap-12 items-center">
            <div className="flex flex-col items-end w-full flex-1">
              <HowItWorks />
              <input
                type="text"
                className={`pl-3 pr-2.5 py-2 bg-slate-800 rounded-md border ${
                  error ? "border-red-500" : "border-indigo-500"
                } justify-center items-center gap-72 inline-flex w-full`}
                placeholder="Paste Link to Item"
                value={nftUrl}
                onChange={(e) => {
                  if (error) setHasError(false);
                  setNftUrl(e.target.value);
                }}
              />
              {error && (
                <span className="w-full text-xs font-normal text-red-500 mt-3 text-center">
                  Oops! It looks like the link you've entered is not correctly
                  formatted. <br />
                  Please click <LinkModal /> to learn how to get the right link.
                </span>
              )}
            </div>

            <div
              role="button"
              className="px-8 py-4 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex cursor-pointer text-center text-white text-base font-medium capitalize w-fit"
              onClick={loading || error ? () => null : handleGenerateTrade}
              style={
                error
                  ? {
                      pointerEvents: "none",
                      opacity: 0.7,
                    }
                  : {}
              }
            >
              {loading ? "Processing" : "Pay with NeoSwap"}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-12">
        <h3 className="text-center">Supported Marketplaces</h3>
        <div className="flex items-center justify-center gap-12">
          <a href="https://magiceden.io/" target="_blank">
            <img src={MagicEden} alt="Magic Eden" />
          </a>
          <a href="https://www.tensor.trade/" target="_blank">
            <img src={Tensor} alt="Tensor" />
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>NeoSwap | Pay with NeoSwap</title>
      </Helmet>
      <Center>
        <Container maxW="5xl">
          <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-20 lg:gap-0 lg:justify-between pt-10">
            <LeftSlider settings={settings} />
            <RightContentSection />
          </div>
        </Container>
      </Center>
    </>
  );
}
