import { Heading } from "@chakra-ui/react";
import Logo from "../assets/logo.svg";
import LandingFooter from "./LandingFooter";
import SmartAuctions from "../components/SmartAuctions";
import { Link } from "react-router-dom";

export default function SmartAuctionPage() {
  return (
    <div className="landing-page">
      <div className="content">
        <div className="page-header">
          <div className="logo-wrapper">
            <Link to={'/'}>
              <img src={Logo} alt="NeoSwap" className="logo" />
            </Link>
            <span className="beta-tag">beta</span>
          </div>
          <Heading as="h1" fontSize="5xl">
            Events
          </Heading>
          <Heading as="h4" fontSize="md" className="sub-title">
            Below you will find the latest events you can join.
          </Heading>
        </div>
        <SmartAuctions onlyLive={false} />
      </div>
      <LandingFooter />
    </div>
  );
}
