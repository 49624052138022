import { memo } from "react";
import ImageWrapper from "../ImageWrapper";
import ListButton from "../ListingDataTable/ListButton";
import { createFloor, getReserveLabel } from "../../utils";
import { FaTrashAlt, FaLock } from "react-icons/fa";
import { IconButton, Button, useDisclosure, Box } from "@chakra-ui/react";
import { removeListing } from "../../services/room.service";
import { useUA } from "../../contexts/userTracking";
import { Nft, useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import { LockIcon } from "@chakra-ui/icons";
import LockedOverlay from "./LockedOverlay";
import IconExpand from "../Icons/IconExpand";
import TxtBlock from "../TxtBlock";
import { checkImage } from "../../utils/index";
import HTMLBlock from "../HTMLBlock";

interface Props {
  nft: Nft;
  listing?: {
    reservePrice: number;
    listingPrice: number;
  };
}

const createImage = (nft: Nft, isUserLocked: boolean, isLocked: boolean) => {
  const img = nft.thumbnail || nft.image;
  if (nft?.assetType?.includes("text/plain")) {
    return <TxtBlock nft={nft} width="100%" fontSize="12px" minH="200px" />;
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ minHeight: "200px" }} />;
  }
  return (
    <ImageWrapper url={checkImage(img)} isLocked={isUserLocked || isLocked} />
  );
};

const GridItem = memo(({ nft }: Props) => {
  const { uid } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { abbr, roomId, decimals, truncate, setModalId, listings } = useRoom();
  const listing = listings?.[nft?.itemId || ""];
  const reservePrice = listing?.listingPrice;
  const isListed = typeof reservePrice === "number";
  const { gaRemoveNft } = useUA();

  const lockedRoomId = nft.locks.length
    ? nft.locks?.[0]?.lockedRoom_id
    : undefined;
  const isLocked = !!lockedRoomId;

  const isUserLocked = nft?.locks?.length > 0;

  const handleRemove = () => {
    removeListing(roomId, uid!, nft.itemId);
    return gaRemoveNft(roomId, nft.itemId);
  };

  return (
    <div className={`grid-item ${isListed ? "selected" : ""}`}>
      <div className="contents">
        <div className="media-holder">
          {(isUserLocked || isLocked) && (
            <Box className="lock-icon-div">
              <FaLock className="lock-icon" color="white" />
            </Box>
          )}
          {createImage(nft, isUserLocked, isLocked)}
        </div>
        <div className="nft-details">
          {nft.collection.name && (
            <h4 title={nft.collection.name}>{nft.collection.name}</h4>
          )}
          <h3 title={nft.name}>{nft.name}</h3>
        </div>
        <div className="price-info">
          <div className="price">
            <span className="label">Floor</span> {createFloor(nft, truncate)}
          </div>
          {isListed && (
            <div className="price">
              <span className="label">Your Listing</span>{" "}
              {getReserveLabel(reservePrice, decimals, abbr, truncate)}
            </div>
          )}
        </div>
        <div className="grid-item-footer">
          {isListed && (
            <IconButton
              className="icon-button"
              onClick={() => setModalId(nft.itemId)}
              aria-label="Edit"
              icon={<IconExpand />}
            />
          )}
          {!isUserLocked && !isLocked ? (
            <ListButton nft={nft} listing={listing} />
          ) : (
            <Button
              size="sm"
              className="listing-button"
              onClick={onOpen}
              minWidth="90px"
              bg={"linear-gradient(45deg, #4c31e3, #fb4bdb)"}
              _dark={{
                color: "white",
              }}
            >
              List
            </Button>
          )}
          {isListed && (
            <IconButton
              className="remove-icon icon-button"
              onClick={handleRemove}
              aria-label="Remove"
              icon={<FaTrashAlt />}
            />
          )}
        </div>
      </div>
      {isLocked && (
        <LockedOverlay
          roomId={lockedRoomId}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
    </div>
  );
});

export default GridItem;
