interface IconZoomProps {
  width?: number;
}

export default function IconZoom({ width } : IconZoomProps) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      width={width}
    >
      <path d="M29.29,25.66l-6.9-6.9c1.17-1.87,1.85-4.07,1.85-6.44c0-6.7-5.43-12.12-12.12-12.12S0,5.63,0,12.32 c0,6.7,5.43,12.12,12.12,12.12c2.58,0,4.96-0.81,6.92-2.17l6.82,6.82c0.47,0.47,1.09,0.71,1.71,0.71c0.62,0,1.24-0.24,1.72-0.71 C30.24,28.14,30.24,26.61,29.29,25.66z M12.12,20.52c-4.53,0-8.19-3.67-8.19-8.19s3.67-8.19,8.19-8.19c4.53,0,8.19,3.67,8.19,8.19 S16.65,20.52,12.12,20.52z" fill="currentColor" />
    </svg>
  );
}
