import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { addFeedback } from "../services/room.service";

interface DeclineModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  roomId: string;
  uid: string;
  canRetry: boolean;
}

const DeclineModal: React.FC<DeclineModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  roomId,
  uid,
  canRetry,
}) => {
  interface ReasonState {
    declinedReasons?: string[];
    declinedReasonOther?: string;
  }

  interface RejectReason {
    label: string;
    slug: string;
  }

  const [reason, setReason] = React.useState<ReasonState>({
    declinedReasons: [],
    declinedReasonOther: "",
  });
  const [isSent, setIsSent] = React.useState(false);
  const [userRejectReasons, setUserRejectReasons] = React.useState<number[]>(
    []
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await addFeedback(roomId, uid, reason);
    setReason({});
    //onClose();
    setIsSent(true);
    navigate(`/ai-trades`);
  };

  const closeModal = () => {
    setIsSent(false);
    onClose();
  };

  const handleReasonChange = (itemIndex: number) => {
    const isReasonExists = userRejectReasons.includes(itemIndex);
    let updatedReasons: number[] = [];
    if (isReasonExists) {
      // Remove the itemIndex from userRejectReasons
      updatedReasons = userRejectReasons.filter(
        (reason) => reason !== itemIndex
      );
      setUserRejectReasons(updatedReasons);
    } else {
      // Add the itemIndex to userRejectReasons
      updatedReasons = [...userRejectReasons, itemIndex];
      setUserRejectReasons(updatedReasons);
    }

    // Update the reason state based on selected reasons
    const selectedReasons: string[] = updatedReasons.map(
      (itemIndex: number) => rejectReasons[itemIndex].slug
    );
    setReason({ declinedReasons: selectedReasons });
  };

  const rejectReasons: RejectReason[] = [
    {
      label: "Want to Keep Item",
      slug: "give-nft",
    },
    {
      label: "Poor value",
      slug: "value",
    },
    {
      label: "Don't want offered collection",
      slug: "get-collection",
    },
    {
      label: "Don't want offered Item",
      slug: "get-nft",
    },
    {
      label: "Other (please specify)",
      slug: "other",
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={closeModal} trapFocus={false}>
      <ModalOverlay />
      <ModalContent bg="#041C28">
        <ModalHeader>
          {!isSent ? (
            <h1>
              Why did you decline the trade?
              <br />
              (Select all that apply)
            </h1>
          ) : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isSent ? (
            <div className="declined-div">
              <h1>You have declined trade</h1>
              <p>
                Thank you for your feedback on this trade. Our AI is always
                learning so hopefully we can offer you a better trade in the
                future!
              </p>
              <hr />
            </div>
          ) : (
            <div>
              <Text fontSize={"15px"} mb={"10px"}>
                This will help us propose better trades to you in the future.
              </Text>

              <Flex direction={"column"} gap={"12px"}>
                {rejectReasons.map((reason, index) => (
                  <Box
                    key={index}
                    textAlign={"center"}
                    background={
                      userRejectReasons.includes(index) ? "#6E60FE" : "#072333"
                    }
                    border={`3.85px solid ${userRejectReasons.includes(index) ? "#6E60FE" : "#397293"
                      }`}
                    borderRadius={"11.5368px"}
                    py={2}
                    cursor={"pointer"}
                    onClick={() => handleReasonChange(index)}
                  >
                    {reason.label}
                  </Box>
                ))}
              </Flex>

              <Textarea
                className="decline-input"
                height="20"
                mt="3"
                value={reason.declinedReasonOther}
                onChange={(e) =>
                  setReason({
                    declinedReasons: reason.declinedReasons,
                    declinedReasonOther: e.target.value,
                  })
                }
                placeholder="write here..."
                bg="#072638"
                border="2px solid"
                borderColor="grey"
                marginBottom="4"
                isDisabled={
                  !userRejectReasons.includes(rejectReasons.length - 1)
                }
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {isSent ? (
            <div>
              <Button bg="#6565FF" color="white" onClick={onClose}>
                Done
              </Button>
            </div>
          ) : (
            <div>
              <Button
                border="1px solid"
                borderColor="grey"
                bg="#041C28"
                color="grey"
                mr={3}
                onClick={onClose}
              >
                Go back
              </Button>

              <Button
                // bg={reason ? "#072638" : "grey"}
                // color={reason ? "grey" : "white"}
                bg="#6C60FF"
                color="white"
                onClick={handleSubmit}
                isDisabled={!reason}
                _disabled={{ opacity: 1, cursor: "not-allowed" }}
              >
                Send
              </Button>
            </div>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeclineModal;
