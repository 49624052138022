import { useState, useEffect } from "react";
import { DateTime } from "luxon";

export const useTimeLeft = (time: number) => {
  // Convert the deadline to UTC
  const deadline = DateTime.fromSeconds(time, { zone: "UTC" });

  const calculateTimeLeft = () => {
    if (DateTime.now().setZone("UTC") >= deadline) {
      // If the deadline has passed, return an object representing zero time left
      return { years: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    } else {
      // Otherwise, calculate the time left until the deadline
      return deadline
        .diff(DateTime.now().setZone("UTC"), [
          "years",
          "months",
          "days",
          "hours",
          "minutes",
          "seconds",
        ])
        .toObject();
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (DateTime.now().setZone("UTC") < deadline) {
      // Only set up the interval if the deadline has not passed
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft, deadline]);

  return timeLeft;
};

export default function HandleTime({ time }: { time: number }) {
  const timeLeft = useTimeLeft(time);

  const value = (val: number | undefined, label: string) => {
    return Math.max(0, Math.round(val || 0)).toString().padStart(2, '0') + label;
  };

  return (
    <div className="timer flex space-x-2">
      <span className="timer-item">
        <span className="val">{value(timeLeft.days, "d")}</span>
      </span>
      <span className="timer-item">
        <span className="val">:</span>
      </span>
      <span className="timer-item">
        <span className="val">{value(timeLeft.hours, "h")}</span>
      </span>
      <span className="timer-item">
        <span className="val">:</span>
      </span>
      <span className="timer-item">
        <span className="val">{value(timeLeft.minutes, "m")}</span>
      </span>
      <span className="timer-item">
        <span className="val">:</span>
      </span>
      <span className="timer-item">
        <span className="val">{value(timeLeft.seconds, "s")}</span>
      </span>
    </div>
  );
}
