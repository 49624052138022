import React, { useState } from "react";
import { Container, Flex, Text, Box, Button } from "@chakra-ui/react";
import { useChain } from "../contexts/chainsContext";
import IconSolana from "../components/Icons/IconSolana";
import IconStacks from "../components/Icons/IconStacks";
import useNeoChain from "../hooks/useNeoChain";
import { useAppContext } from "../contexts/appContext";

type Props = {};

const chains = [
  {
    name: "Solana",
    icon: (
      <IconSolana
        w={{ lg: "47px", base: "30px" }}
        h={{ lg: "47px", base: "30px" }}
      />
    ),
  },
  {
    name: "Stacks",
    icon: (
      <IconStacks
        w={{ lg: "47px", base: "30px" }}
        h={{ lg: "47px", base: "30px" }}
      />
    ),
  },
];

const ConnectExtension = (props: Props) => {
  const [isConnected, setIsConnected] = useState(false);
  const { uid } = useAppContext();
  const { chain, setChain } = useChain();
  const chainFns = useNeoChain() as any;
  const { signIn, signOut } =
    chainFns?.[chain?.name?.toLowerCase() || "not_found"];

  const isChrome = !!(window as any).chrome;

  const handleChainChange = (chain: string) => {
    setChain(chain);
  };

  const handleConnect = async () => {
    await signOut();
    await signIn();

    setIsConnected(true);
  };

  return (
    <Container
      height={"full"}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={"center"}
    >
      <Box
        width="550px"
        background={"#07324A"}
        py={16}
        px={12}
        borderRadius={8}
      >
        {!isChrome ? (
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={8}
          >
            <Text fontSize={"lg"} fontWeight={"bold"}>
              We only support Google Chrome for now
            </Text>
          </Flex>
        ) : isConnected && uid ? (
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={8}
          >
            <Text fontSize={"lg"} fontWeight={"bold"}>
              Connected
            </Text>
          </Flex>
        ) : (
          <>
            <Flex justifyContent={"center"} alignItems={"center"} gap={8}>
              {chains.map((item) => (
                <Flex flexDirection={"column"} justifyContent={"center"}>
                  <Flex
                    as={"button"}
                    paddingX={12}
                    paddingY={12}
                    onClick={() => handleChainChange(item.name)}
                    background="rgba(255,255,255,.1)"
                    _groupHover={{ background: "rgba(255,255,255,.2)" }}
                    rounded="xl"
                    border={
                      chain?.name === item.name
                        ? "3px solid #6C60FF"
                        : "3px solid transparent"
                    }
                  >
                    {item.icon}
                  </Flex>

                  <Text textAlign={"center"} marginTop={2}>
                    {item.name}
                  </Text>
                </Flex>
              ))}
            </Flex>

            <Flex width={"full"} marginTop={8}>
              <Button
                background={"#6C60FF"}
                width={"full"}
                onClick={handleConnect}
              >
                Connect
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ConnectExtension;
