import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Tooltip,
  Icon,
  Button,
  HStack,
  Checkbox,
  Link,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../contexts/appContext";
import { useUA } from "../contexts/userTracking";
import {
  // addToVerified,
  updateInfo,
  updateTerms,
} from "../services/user.service";
import { hasError } from "../utils";
import { addCollectionSwapOffer } from "../services/room.service";

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter a display name"),
  email: Yup.string().email("Please enter a valid email").required("Please enter an email"),
  telegram: Yup.string(),
  discord: Yup.string(),
  tacChecked: Yup.boolean().oneOf(
    [true],
    "Please accept the terms and conditions"
  ),
});

export default function SignupForm({
  handleDone,
  csOfferData,
  setStep,
  settings,
  showToast,
  onClose,
}: {
  handleDone?: () => void;
  csOfferData?: any;
  setStep?: (step: number) => void;
  settings?: any;
  showToast?: boolean;
  onClose?: () => void;
}) {
  const toast = useToast();
  const {
    uid,
    email,
    telegram,
    discord,
    user,
    displayName,
    agreements,
    // updateEmail,
    updateUserProfile,
    sendEmailVerification,
    updateState,
  } = useAppContext();

  const { gaCreateProfile, gaVerifyEmail } = useUA();
  const { gaSignedSwap } = useUA();

  const initialValues = {
    email: user?.email || email?.value || "",
    telegram: telegram?.value || "",
    discord: discord?.value || "",
    displayName: user?.displayName || displayName || "",
    tacChecked: agreements?.consentTosAndPp || false,
    notificationsChecked: agreements?.consentNotifications || false,
  };

  const handleError = (e: any) => {
    let message = "Please Try Again!";

    if (e.code === "auth/requires-recent-login") {
      message = "Please re-login to update your email!";
    }

    if (e.code === "auth/email-already-in-use") {
      message = "Email already in use!";
    }

    if (e.code === "auth/too-many-requests") {
      message = "Too many requests, please try again later!";
    }

    toast({
      title: "Error updating your email!",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  // const handleVerify = async (email: string) => {
  //   await sendEmailVerification(email);
  //   toast({
  //     title: "Email Verification Sent",
  //     description: "Please check your inbox or spam folder",
  //     status: "success",
  //     duration: 9000,
  //     isClosable: true,
  //   });
  // };

  const handleRetry = async (values: typeof initialValues) => {
    try {
      console.log("updateInfo");
      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });
      //if email already in use the
      console.log("updateEmail");
      await updateInfo(uid!, "email", {
        value: values.email,
        isVerified: true,
        verificationSent: true,
      });
      updateState({
        displayName: values.displayName,
        email: {
          value: values.email,
          isVerified: true,
          verificationSent: true,
        },
        agreements: {
          consentTosAndPp: values.tacChecked,
          consentNotifications: values.notificationsChecked,
        },
      });
      gaCreateProfile();
    } catch (e: any) {
      handleError(e);
    }
  };

  const handleSubmit = async (values: typeof initialValues, props: any) => {
    try {
      gaVerifyEmail();

      gaSignedSwap()
      await updateUserProfile({ displayName: values.displayName });
      console.log("updateUserProfile");
      console.log("updateInfo");
      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });
      if (values.email) {
        // await updateEmail(values.email);
        // console.log("updateEmail");
        // await handleVerify(values.email);
        // console.log("handleVerify");
        await updateInfo(uid!, "email", {
          value: values.email,
          verificationSent: true,
        });
        updateState({
          email: {
            value: values.email,
            // verificationSent: true,
          },
        });
      }
      if (values.telegram) {
        await updateInfo(uid!, "telegram", {
          value: values.telegram,
        });
        updateState({
          telegram: {
            value: values.telegram,
          },
        });
      }
      if (values.discord) {
        await updateInfo(uid!, "discord", {
          value: values.discord,
        });
        updateState({
          discord: {
            value: values.discord,
          },
        });
      }
      if (csOfferData) {
        addCollectionSwapOffer(csOfferData);
      }
      updateState({
        displayName: values.displayName,
        agreements: {
          consentTosAndPp: values.tacChecked,
          consentNotifications: values.notificationsChecked,
        },
      });
      gaCreateProfile();
      if (showToast) {
        toast({
          title: "Offer saved!",
          description: "Saved",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      if (setStep) {
        setStep(2)
      }
      if (handleDone) {
        handleDone();
      }
    } catch (e: any) {
      if (e.code === "auth/email-already-in-use") {
        return handleRetry(values);
      }
      console.log("ERROR IN HANDLE SUBMIT", e);
      handleError(e);
    } finally {
      props.setSubmitting(false);
    }
  };

  return (
    <Box width="100%">
      <Center>
        <Box width="100%" padding={settings?.boxPadding ? settings.boxPadding : 10}>
          {settings?.heading ? (
            <Heading textAlign="center" size="sm" mb={2} mt={6}>
              {settings.heading}
            </Heading>
          ) : (
            <Heading textAlign="center" size="xl" mb={3} mt={3}>
              What should we call you?
            </Heading>
          )}
          {settings?.subheadingWarning && <Heading textAlign="center" size="sm" mb={2} color="red">
            {settings.subheadingWarning}
          </Heading>}
          {!settings?.hideChangeLater && <Heading textAlign="center" size="md" mb={2}>
            You can always change this later
          </Heading>}
          <Container width={{ base: "100%" }} p={0}>
            <div className="rounded-md p-2">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ errors, touched, values, handleChange, ...props }) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <Flex
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="6"
                      >
                        <FormControl
                          isRequired
                          isInvalid={hasError(errors, touched, "displayName")}
                        >
                          <FormLabel htmlFor="displayName">
                            Display Name
                          </FormLabel>
                          <Input
                            id="displayName"
                            name="displayName"
                            onChange={handleChange}
                            value={values.displayName}
                          />
                          <FormErrorMessage>
                            {errors.displayName}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={hasError(errors, touched, "email")}
                          isRequired
                        >
                          <Flex>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Tooltip label="The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.">
                              <Icon as={InfoIcon} />
                            </Tooltip>
                          </Flex>
                          <Input
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={hasError(errors, touched, "telegram")}
                        >
                          <Flex>
                            <FormLabel htmlFor="telegram">Telegram</FormLabel>
                            {/* <Tooltip label="The telegram is needed so we can reach out if needed">
                              <Icon as={InfoIcon} />
                            </Tooltip> */}
                          </Flex>
                          <Input
                            id="telegram"
                            name="telegram"
                            onChange={handleChange}
                            value={values.telegram}
                          />
                          <FormErrorMessage>{errors.telegram}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={hasError(errors, touched, "discord")}
                        >
                          <Flex>
                            <FormLabel htmlFor="discord">Discord</FormLabel>
                            {/* <Tooltip label="The discord is needed so we can reach out if needed">
                              <Icon as={InfoIcon} />
                            </Tooltip> */}
                          </Flex>
                          <Input
                            id="discord"
                            name="discord"
                            onChange={handleChange}
                            value={values.discord}
                          />
                          <FormErrorMessage>{errors.discord}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired>
                          <HStack className="flex items-center">
                            <Checkbox
                              id="tacChecked"
                              name="tacChecked"
                              isChecked={values.tacChecked}
                              onChange={handleChange}
                            />
                            <FormLabel fontSize="9px" mb="0">
                              I have read and agree to the{" "}
                              <Link
                                href="https://neoswap.cc/terms-and-conditions"
                                target="_blank"
                              >
                                terms and conditions
                              </Link>
                            </FormLabel>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Checkbox
                              id="notificationsChecked"
                              name="notificationsChecked"
                              isChecked={values.notificationsChecked}
                              onChange={handleChange}
                            />
                            <FormLabel fontSize="9px" mb={0}>
                              Please add me to the email list to receive the
                              NeoSwap news & updates and partners.
                            </FormLabel>
                          </HStack>
                        </FormControl>
                        <div className="flex gap-2 w-full">
                          {settings?.canClose && <Button
                            type="button"
                            width="100%"
                            className="flex-1"
                            onClick={() => {
                              if (onClose) {
                                onClose()
                              } else if (handleDone) {
                                handleDone()
                              }
                            }}
                          >
                            Close
                          </Button>}
                          <Button
                            type="submit"
                            width="100%"
                            className="flex-1"
                            isLoading={props.isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </Flex>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Container>
        </Box>
      </Center >
    </Box >
  );
}
