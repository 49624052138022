import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Image,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";

import SmallLogo from "../assets/smallLogo.svg";

import metab from '../assets/metab.png';
import tensortab from '../assets/tensortab.png';
import { RiFacebookBoxFill } from "react-icons/ri";

export default function LinkModal({ className = "" }: { className?: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState('me');
  return (
    <>
      <span
        className={`text-fuchsia-500 text-xs font-normal underline cursor-pointer ${className}`}
        role="button"
        onClick={onOpen}
      >
        here
      </span>
      <Modal isOpen={isOpen} onClose={onClose} isCentered trapFocus={false}>
        <ModalOverlay zIndex={9999999} />
        <ModalContent background="#000" className="border" maxW={"1000px"}>
          <ModalHeader
            height="60px"
            display="flex"
            justifyContent="center"
            borderBottom="1px solid #1B1B1C"
          >
            <img src={SmallLogo} alt="neoswap" />
          </ModalHeader>
          <ModalBody
            pl="40px"
            pr="40px"
            pt="20px"
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
          >
            <div>
              <div className="flex">
                <div onClick={() => setActiveTab("me")} className={`flex items-center cursor-pointer border px-6 py-3 rounded-tl-lg ${activeTab === "me" ? "bg-gray-100" : null}`}>
                  <img src={metab} />
                </div>
                <div onClick={() => setActiveTab("tensor")} className={`flex items-center cursor-pointer border px-6 py-3 ${activeTab === "tensor" ? "bg-gray-100" : null}`}>
                  <img src={tensortab} />
                </div>
              </div>
              <div className={activeTab === "me" ? "" : "hidden"}>
                <Image
                  maxW={{ base: "100%" }}
                  src="https://prepared-bouquet-933cbcd07a.media.strapiapp.com/me_0b9f12d80a.gif"
                />
              </div>

              <div className={activeTab === "tensor" ? "" : "hidden"}>
                <Image
                  maxW={{ base: "100%" }}
                  src="https://prepared-bouquet-933cbcd07a.media.strapiapp.com/t_e73839a31e.gif"
                />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
        <ModalFooter />
      </Modal>
    </>
  );
}
