import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { BiddableNft } from "../../contexts/roomContext";
import IconLink from "../Icons/IconLink";
import { createFloor, getVolumeLabel } from "../../utils";
import { memo } from "react";
import { ExpandableText } from "../ExpandableText";

interface Props {
  nft: BiddableNft;
  abbr: string;
  truncate: number;
  isOrdinals: boolean;
  suggestedPrice: string;
}

function DetailsTab({ nft, abbr, truncate, isOrdinals, suggestedPrice}: Props) {
  const modifiedAttributes = nft?.attributes.map((attr) => {
    const modifiedValue = attr.value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { ...attr, value: modifiedValue };
  });

  return (
    <div className="listing-modal-details">
      <div className="nft-details-tab">
        {nft?.collection.name || nft?.collection.twitter ? (
          <>
            {nft.collection.twitter ? (
              <h4>
                <a target="_blank" href={nft.collection.twitter}>
                  {nft.collection.twitter}
                </a>
              </h4>
            ) : (
              <h4>{nft.collection.name}</h4>
            )}
          </>
        ) : null}
        <h3>{nft?.name}</h3>

        <div className="the-nft-details">
          {!isOrdinals && 
          <div className="nft-details-item">
            <p>{suggestedPrice}</p>
            <span>{createFloor(nft)}</span>
          </div>
          }

          <div className="nft-details-item">
            <p>Volume</p>
            <span>
              {nft &&
                getVolumeLabel(
                  nft.collection.volume,
                  nft.reservePrice?.decimals || 0,
                  abbr,
                  truncate
                )}
            </span>
          </div>

          <div className="nft-details-item">
            <p>Owners</p>
            <span>{nft?.collection.owners}</span>
          </div>

          <div className="nft-details-item">
            <p>Total</p>
            <span>{nft?.collection.totalItems}</span>
          </div>

          {nft?.marketplace.url ? (
            <div className="last-link">
              <span>
                <a href={nft?.marketplace.url} target="_blank" rel="noreferrer">
                See on Marketplace
                <div>
                  <IconLink />
                </div>
              </a>
              </span>
            </div>
          ) : null}
        </div>

        <Box className="" style={{ maxHeight: 140, overflowY: "scroll" }}>
          <h1 className="titleAtributes">Attributes</h1>
          {nft?.attributes ? (
            <Table>
              <Thead>
                <Tr>
                  <Th pl={"0"}>Type</Th>
                  <Th>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {modifiedAttributes?.map((attr) => (
                  <Tr key={attr.trait_type}>
                    <Td pl={"0"}>{attr.trait_type}</Td>
                    <Td>{attr.value}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <h1>--</h1>
          )}
        </Box>

        {nft?.userDescription ? (
          <>
            <h5>Additional Info Provided by Seller</h5>
            <p>{nft.userDescription}</p>
          </>
        ) : null}

        <h5>Details</h5>
        <p>
          <ExpandableText noOfLines={1}>
              {nft?.description ? nft?.description : "This Item has no description."}
          </ExpandableText>
          
        </p>
      </div>
    </div>
  );
}


export default memo(DetailsTab)