interface Props {
  className?: string;
}

export default function IconCurveArrow({ className }: Props) {
  return (
    <svg
      version="1.1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M1.3,22.7L6.7,28c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9l-3.1-3.1H9c11.1,0,20.1-9,20.2-20.2 c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3c0,9.6-7.8,17.5-17.5,17.5H5.5l3.1-3.1c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0 l-5.4,5.4C0.8,21.3,0.8,22.1,1.3,22.7L1.3,22.7z" fill="currentColor"/>
    </svg>
  );
}
