import { memo } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";

// import ListModal from "./ListModal";
import { removeListing } from "../../services/room.service";
import { useUA } from "../../contexts/userTracking";
import { Nft, useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";

interface Props {
  nft: Nft;
  listing?: {
    reservePrice: number;
    listingPrice: number;
  };
}

const ListButton = memo(({ nft, listing }: Props) => {
  const { uid } = useAppContext();
  const { roomId, setModalId, canList } = useRoom();
  const { gaRemoveNft } = useUA();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const reservePrice = listing?.listingPrice;
  const isListed = typeof reservePrice === "number";

  const toggleListing = () => {
    if (isListed) {
      removeListing(roomId, uid!, nft.itemId);
      return gaRemoveNft(roomId, nft.itemId);
    }
    setModalId(nft.itemId);
  };

  const isLocked = nft?.locks?.length > 0;
  return (
    <div className="list-controls">
      {/* <ListModal
        isOpen={isOpen}
        onClose={onClose}
        nft={nft}
        listing={listing}
      /> */}
      <Button
        size="sm"
        className="listing-button"
        onClick={toggleListing}
        minWidth="90px"
        bg={isListed ? "#dc3545" : "linear-gradient(45deg, #4c31e3, #fb4bdb)"}
        colorScheme={isListed ? "#dc3545" : ""}
        _dark={{
          color: "white",
        }}
        disabled={(!isListed && !canList) || isLocked}
      >
        {isListed ? "Remove" : "List"}
      </Button>
    </div>
  );
});

export default ListButton;
