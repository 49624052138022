import { AiRoom } from "./types";

export const roundValue = (
  value?: number,
  decimals?: number,
  trunc: number = 3
) => {
  if (!value || !decimals) return;
  const val = value / 10 ** decimals;
  if (value === 0 || !isFinite(val)) return 0;
  const units = 10 ** trunc;
  return Math.ceil(val * units) / units;
};


export const createIdsFromOffer = (room?: AiRoom, offerId?: string) => {
  if (!room || !offerId) return [];
  const offer = room.offers?.[offerId];
  if (!offer) return [];
  const { give, get } = offer;
  const ids: string[] = [...(give || []), ...(get || [])];
  return [...new Set(ids)];
};