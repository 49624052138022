import { Link, useNavigate } from "react-router-dom";
import IconBrainWires from "../Icons/IconBrainWires";
import useTimer from "./useTimer";

export default function TradesForDayComplete({
  nextTradeAt,
}: {
  nextTradeAt?: number;
}) {
  const navigate = useNavigate();

  const { timeLeftUnits } = useTimer({
    endTime: nextTradeAt || 0,
    timeOffset: 0,
  });

  return (
    <div className="flex flex-col items-center gap-8 mt-32">
      <div>
        <IconBrainWires />
      </div>
      <div className="text-center text-[31px] font-medium">
        Get AI-Proposed Item Trades
      </div>
      <div className="text-center text-[20px] font-medium">
        Next Available In{" "}
        <span className={`text-green-400 text-[42px]`}>
          {timeLeftUnits?.hours}h:{timeLeftUnits?.minutes}m:
          {timeLeftUnits?.seconds}s
        </span>
      </div>
      <div className="text-[21px]">
        In the meantime, you can{" "}
        <Link
          className="text-[#3DFF99] underline decoration-solid"
          to="/ai-history"
        >
          view your AI Trades
        </Link>
      </div>
      <button
        className="w-64 h-16 px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
        onClick={() => navigate(`/`)}
      >
        Back to Homepage
      </button>
    </div>
  );
}
