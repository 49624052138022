import { InfoIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useAppContext } from "../contexts/appContext";
import * as Yup from "yup";

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter a display name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required!"), // pass your error message string
});

interface Props {
  label?: string;
  showToast?: boolean;
}

const hasError = (props: any, name: string) => {
  return !!props.errors[name] && props.touched[name];
};

export default function WaitlistProfileForm({ label = "Join Waitlist", showToast }: Props) {
  const toast = useToast();
  const { avatar, displayName, email, updateUser, updateEmail } =
    useAppContext();

  const initialValues = {
    email: email?.value || "",
    displayName: displayName || "",
  };

  const handleSubmit = async (e: typeof initialValues) => {
    try {
      const req: any = [];

      //NEED HERE TO ADD [ADDRESS-EMAIL WAITLISTED FOR AI TRADES]
      // if (e.displayName) req.push(updateUser({ displayName: e.displayName }));
      // if (e.email) req.push(updateEmail({ value: e.email, prompt: false }));
      await Promise.all(req as any);
      if (showToast) {
        toast({
          title: "You have been waitlisted successfully",
          status: "success",
          isClosable: true,
          duration: 5000,
          styleConfig: {
            zIndex: 999999,
          },
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  };
  return (
    <Container width={{ base: "100%", md: "sm" }}>
      <div className="box">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="4"
                >
 
                  <FormControl   isInvalid={hasError(props, "email")}>
                    <Flex>
                      <FormLabel htmlFor="email">Your Email</FormLabel>
                     
                    </Flex>

                    <Input
                      id="email"
                      name="email"
                      type="email"
                      disabled={true}
                      onChange={props.handleChange}
                      value={props.values.email}
                    />
                    <FormErrorMessage>{props.errors.email}</FormErrorMessage>
                  </FormControl>
                  <Button
                    type="submit"
                    width="100%"
                    isLoading={props.isSubmitting}
                    className="grad-btn"
                  >
                    {label}
                  </Button>
                </Flex>
              </form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
}