import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  Flex,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useAppContext } from "../contexts/appContext";
import { Nft } from "../contexts/roomContext";
import { updateUser } from "../services/user.service";
import PendingImg from "../assets/pending.jpg";
import MYNFTModal from "./MYNFTmodal";
import { useEffect, useState } from "react";
import { NFTModal } from "./NFTModal";
import { BiLockAlt } from "react-icons/bi";
import {
  updateLockedItem,
  unlockLockedItem,
  getLockStatus,
} from "../services/room.service";

interface Props {
  nft: Nft;
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  itemId: string;
}

export function LockNftModal({ nft, isOpen, onClose, userId }: Props) {
  const { name, thumbnail, itemId } = nft;
  const { uid } = useAppContext();
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const handleLockNft = async () => {
    try {
      await updateLockedItem(userId, itemId, 1, "user");
      onClose();
    } catch (error) {
      console.log("Error locking Item:", error);
    }
  };

  const handleUnlock = async () => {
    try {
      await unlockLockedItem(userId, itemId);
      onClose();
    } catch (error) {
      console.log("Error deleting locked item:", error);
    }
  };

  useEffect(() => {
    const checkLockStatus = async () => {
      const lockStatus = await getLockStatus(uid!, itemId);
      if (lockStatus) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    };
    checkLockStatus();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent bg="#072539">
        <ModalCloseButton />
        {isLocked ? (
          <Text textAlign="center" mt="2rem" fontWeight="bold" fontSize="lg">
            Unlock Item{" "}
          </Text>
        ) : (
          <Text textAlign="center" mt="2rem" fontWeight="bold" fontSize="lg">
            Lock Item{" "}
          </Text>
        )}
        <ModalBody>
          {isLocked ? (
            <Text textAlign="center" fontSize="sm">
              Are you sure you want to unlock this Item?{" "}
            </Text>
          ) : (
            <Text textAlign="center" fontSize="sm">
              Are you sure you want to lock this Item?{" "}
            </Text>
          )}
          {isLocked ? (
            <h1> </h1>
          ) : (
            <Text textAlign="center" fontSize="sm">
              When an Item is locked, it can't be listed nor included on Ai
              proposed trades
            </Text>
          )}
          <Center>
            <Box>
              <Image
                src={thumbnail}
                alt={name}
                draggable="false"
                fallbackSrc={PendingImg}
                borderRadius={useBreakpointValue({ base: "md", md: "xl" })}
                cursor="pointer"
                maxH="15rem"
                mt="1rem"
                width="fit-content"
                marginLeft="auto"
                marginRight="auto"
              />
              <Text textAlign="center" fontWeight="medium" mt="0.8rem">
                {name || "Name not found"}
              </Text>
            </Box>
          </Center>

          <Center>
            <Box m="1rem">
              <Button size="sm" bg="#052a42" onClick={onClose}>
                Close
              </Button>
              {isLocked ? (
                <Button size="sm" ml="4" bg="#FB4BDB" onClick={handleUnlock}>
                  Unlock
                </Button>
              ) : (
                <Button size="sm" ml="4" bg="#FB4BDB" onClick={handleLockNft}>
                  Lock
                </Button>
              )}
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
