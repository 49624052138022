import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  SimpleGrid,
  Box,
  ModalBody,
  Flex,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  AlertDialog,
  AlertDialogContent,
  AlertDialogBody,
  Button,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { cloneElement, ReactElement } from "react";
import { createFloor, getVolumeLabel } from "../utils";
import { Nft, useRoom, BiddableNft } from "../contexts/roomContext";
import { useChain } from "../contexts/chainsContext";
import PendingImg from "../assets/pending.jpg";
import IconLink from "./Icons/IconLink";
import HTMLBlock from "./HTMLBlock";
import TxtBlock from "./TxtBlock";

interface NFTModalProps {
  children: ReactElement;
  nft: Nft;
}

const createImage = (nft?: BiddableNft) => {
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }
  return (
    <Image
      objectFit="cover"
      src={nft?.image || nft?.thumbnail}
      fallbackSrc={PendingImg}
    />
  );
};

export const NFTModal: React.FC<NFTModalProps> = ({ nft, children }) => {
  const cancelRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getChainDetails } = useChain();
  const { decimals, abbr, truncate, handlePrev, handleNext } = useRoom();
  const [currentTab, setCurrentTab] = useState("details");
  const units = 10 ** decimals;
  // const formatCompactNumber = (number: number): string => {
  //   if (number < 1000) {
  //     return number.toString();
  //   } else if (number >= 1000 && number < 1_000_000) {
  //     return (number / 1000).toFixed(1) + "K";
  //   } else if (number >= 1_000_000 && number < 1_000_000_000) {
  //     return (number / 1_000_000).toFixed(1) + "M";
  //   } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
  //     return (number / 1_000_000_000).toFixed(1) + "B";
  //   } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
  //     return (number / 1_000_000_000_000).toFixed(1) + "T";
  //   }
  //   return number.toString();
  // };

  const vol =
    nft?.collection?.volume / 10 ** getChainDetails(nft.blockchain)?.decimals;

  const formatCompactNumber = (number: number): string => {
    const units = ["", "K", "M", "B", "T"];
    let magnitude = 0;

    while (number >= 1000 && magnitude < units.length - 1) {
      number /= 1000;
      magnitude++;
    }

    return number.toFixed(1) + units[magnitude];
  };

  const formattedVolume = formatCompactNumber(vol);

  const modifiedAttributes = nft.attributes.map((attr) => {
    const modifiedValue = attr.value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { ...attr, value: modifiedValue };
  });

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      <AlertDialog
        id="listing-modal"
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxWidth="1050px" className="ns-dialog-content">
          <AlertDialogHeader>
            <div className="listing-modal-header">
              <h3>Details</h3>
              {/* <p>Place your bid here</p> */}
            </div>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <div className="listing-modal-body">
              <Flex direction="column" className="image-arrows">
                <div className="asset-container">{createImage(nft)}</div>

                {/* <Flex
                  gap="4"
                  justifyContent="space-between"
                  alignItems="center"
                  className="nft-items-nav"
                >
                  <Button
                    className="nav-button"
                    onClick={() => handlePrev(nfts)}
                    isDisabled={nfts.length < 2}
                  >
                    <FaChevronLeft className="btn-icon" />
                    <span className="btn-text">Prev</span>
                  </Button>
                  <p>
                    Viewing [{itemIndex}/{availableForBid?.length}]
                  </p>
                  <Button
                    className="nav-button"
                    onClick={() => handleNext(nfts)}
                    isDisabled={nfts.length < 2}
                  >
                    <span className="btn-text">Next</span>
                    <FaChevronRight className="btn-icon" />
                  </Button>
                </Flex> */}
              </Flex>

              <div className="tabs-wrapper bidding-modal">
                <div className="tabs-buttons">
                  <div className={"tab active"}>Details</div>
                </div>
                {currentTab === "details" && (
                  <div className="listing-modal-details">
                    <div className="nft-details-tab">
                      {nft?.collection.name || nft?.collection.twitter ? (
                        <>
                          {nft.collection.twitter ? (
                            <h4>
                              <a target="_blank" href={nft.collection.twitter}>
                                {nft.collection.twitter}
                              </a>
                            </h4>
                          ) : (
                            <h4>{nft.collection.name}</h4>
                          )}
                        </>
                      ) : null}
                      <h3>{nft?.name}</h3>

                      <div className="the-nft-details">
                        <div className="nft-details-item">
                          <p>Floor</p>
                          <span>{createFloor(nft)}</span>
                        </div>

                        <div className="nft-details-item">
                          <p>Volume</p>
                          <span>
                            {nft &&
                              getVolumeLabel(
                                nft.collection.volume,
                                nft.collection?.floorPrice?.floorPrice
                                  ?.decimals || 0,
                                abbr,
                                truncate
                              )}
                          </span>
                        </div>

                        <div className="nft-details-item">
                          <p>Owners</p>
                          <span>{nft?.collection.owners}</span>
                        </div>

                        <div className="nft-details-item">
                          <p>Total</p>
                          <span>{nft?.collection.totalItems}</span>
                        </div>

                        {nft?.marketplace.url ? (
                          <div className="last-link">
                           <span>
                            <a href={nft?.marketplace.url} target="_blank" rel="noreferrer">
                              See on Marketplace
                              <div>
                                <IconLink />
                              </div>
                            </a>
                            </span>
                          </div>
                        ) : null}
                      </div>

                      <Box
                        className=""
                        style={{ maxHeight: "410px", overflowY: "scroll" }}
                      >
                        <h1 className="titleAtributes">Attributes</h1>
                        {nft?.attributes ? (
                          <Table>
                            <Thead>
                              <Tr>
                                <Th pl={"0"}>Type</Th>
                                <Th>Value</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {modifiedAttributes?.map((attr) => (
                                <Tr key={attr.trait_type}>
                                  <Td pl={"0"}>{attr.trait_type}</Td>
                                  <Td>{attr.value}</Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        ) : (
                          <h1>--</h1>
                        )}
                      </Box>

                      <h5>Details</h5>
                      <p>
                        {nft?.description
                          ? nft?.description
                          : "This Item has no description."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AlertDialogBody>
          <AlertDialogFooter className="modal-footer" />
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

//${attr.name}
