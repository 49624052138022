import { memo } from "react";
import {
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import PendingImg from "../../assets/pending.jpg";

const ImageCell = memo((props: any) => {
  const name = props.row.original.name;
  const thumbnail = props.row.original.thumbnail;

  return (
    <HStack>
      <Image
        height="64px"
        objectFit="cover"
        src={thumbnail || PendingImg}
        fallbackSrc={PendingImg}
        loading="lazy"
      />
      <VStack alignItems="baseline">
        <Text>{name}</Text>
      </VStack>
    </HStack>
  );
});

export default ImageCell;
