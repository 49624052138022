import {
  Button,
  Stack,
  useDisclosure,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import useNeoChain from "../hooks/useNeoChain";
import { addTxId, getSwaps, updateSwapField, generateAiRoom, getAiRoom } from "../services/room.service";
import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { useUA } from "../contexts/userTracking";
import AiTradesButton from "./AiTradesV2/Button";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AiRoom } from "../components/AiTradesV2/types";
import { acceptAiOffer } from "../pages/AiOffers";

export type UserSwap = {
  blockchain: "solana" | "ethereum" | "stacks";
  type: string;
  order: number;
  description: string;
  config: any;
  txId?: string;
  swapId?: string;
};

const DepositItem = ({
  swap,
  fetchSwaps,
  canSign,
  deposit,
  roomChain,
  setShowGenerateAgain,
  onSigned,
  alreadySigned,
}: {
  swap: UserSwap;
  fetchSwaps: () => void;
  canSign: boolean;
  deposit: (args: any) => Promise<any>;
  roomChain: string;
  setShowGenerateAgain?: (value: boolean) => void;
  onSigned?: () => void;
  alreadySigned?: boolean;
}) => {
  const toast = useToast();
  const { gaSignSwap } = useUA();
  const [depositing, setDepositing] = useState(false);
  const { getTokenBalance, uid } = useAppContext();
  const chainFns = useNeoChain() as any;
  const chain = chainFns[roomChain || "not_found"];

  const errorThatShoudGenerateAgain = chain?.errorThatShoudGenerateAgain;

  const handleDeposit = async () => {
    try {
      setDepositing(true);
      console.log(swap.config);
      const res = await deposit(swap.config);
      console.log("RES", res);
      const { hashes: txIds, programId, signedPsbt } = res;
      if (signedPsbt) {
        console.log("WE RECEIVED A SIGNED PSBT", signedPsbt);
        await updateSwapField(
          swap.config.path,
          swap.config.propName,
          signedPsbt
        );
        await getTokenBalance(uid!);
      } else {
        if (!txIds) return setDepositing(false);
        if (txIds) {
          await Promise.all(
            txIds.map(async (txId: string) => {
              const data = {
                ...swap,
                txId,
                status: "pending",
              } as any;
              if (programId) {
                data.programId = programId;
              }
              gaSignSwap(txId);
              console.log("DATA", data);
              return await addTxId(roomChain, txId, data);
            })
          );
        }
        await getTokenBalance(uid!);
        setDepositing(false);
        if (onSigned) {
          onSigned();
        }
      }
    } catch (e: any) {
      setDepositing(false);
      if (errorThatShoudGenerateAgain(e)) {
        if (setShowGenerateAgain) {
          setShowGenerateAgain(true);
        }
      }
      toast({
        title: "Error Purchasing!",
        description:
          e?.message || "Please report a bug through the feedback button.",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      fetchSwaps();
    }
  };

  const btnLabel = alreadySigned ? "Signed" : "Sign";

  return (
    <div className="action-box">
      <div dangerouslySetInnerHTML={{ __html: swap.description }} />
      <div className="controls">
        <Button
          flexShrink="0"
          className="button action"
          onClick={handleDeposit}
          isLoading={depositing}
          loadingText="...Purchasing"
          isDisabled={!canSign}
        >
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

const TextElement = (props: any) => {
  const [value, setValue] = useState("");

  return (
    <form
      className="form"
      onSubmit={async (e: any) => {
        e.preventDefault();
        await updateSwapField(props.path, props.propName, value);
        props.fetchSwaps();
      }}
    >
      <div>
        <p dangerouslySetInnerHTML={{ __html: props.label }} />
        <Input
          placeholder={props.placeholder}
          onChange={(e: any) => setValue(e.target.value)}
          required
        />
      </div>
      <div className="controls">
        <Button type="submit" className="button action">
          Save
        </Button>
      </div>
    </form>
  );
};

const SwapForm = ({
  swap,
  fetchSwaps,
}: {
  swap: UserSwap;
  fetchSwaps: () => void;
}) => {
  const toast = useToast();
  const isMountedRef = useRef(false);
  console.log("SwapForm", swap);

  const handleCopy = async (event: any) => {
    const txt = typeof event === "object" ? event?.target?.innerText : event;
    if (!txt) return;

    await navigator.clipboard.writeText(txt);
    toast({
      title: "Copied to clipboard!",
      status: "success",
      duration: 9000,
      isClosable: true,
      styleConfig: {
        zIndex: 999999,
      },
    });
  };

  // function UpdateListOnScreen(NewListItem) {
  //   var grabList = document.getElementById('requestList');

  //   var text = "" + GetCalendarName(NewListItem.calChoice) + " For " + GetLessonSlot(NewListItem.lessonChoice) + " On " + GetDateInTextForm(NewListItem.date) + "";
  //   var entry = document.createElement('li');
  //   entry.id = list.length - 1;
  //   entry.className = "ItemNotChecked";
  //   entry.appendChild(document.createTextNode(text));

  //   /*Add a button to each LI */
  //   var button = document.createElement('button');
  //   button.innerText = 'Click me!';
  //   entry.appendChild(button);

  //   grabList.appendChild(entry);
  // }

  useEffect(() => {
    if (isMountedRef.current) return;
    const ordinal = document.getElementById(`${swap.swapId}-ordinal`);
    const utxo = document.getElementById(`${swap.swapId}-utxo`);
    const btcAddr = document.getElementById(`${swap.swapId}-btc`);
    if (ordinal) {
      ordinal.style.cursor = "pointer";
      const btn = document.createElement("button");
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        const txt = ordinal.getElementsByTagName("a")[0].innerHTML;
        handleCopy(txt);
        console.log("click btn", ordinal);
      });
      btn.classList.add("copy-btn");
      btn.id = `copy-${swap.swapId}-ordinal`;
      btn.innerText = "Copy";
      ordinal.appendChild(btn);
    }

    console.log("ordinal", ordinal);
    // ordinal?.addEventListener("click", (event: any) => {
    //   console.log(`${swap.swapId}-ordinal`, event);
    //   // do something
    //   handleCopy(event);
    // });

    if (utxo) {
      utxo.style.cursor = "pointer";
    }
    utxo?.addEventListener("click", (event: any) => {
      handleCopy(event);
    });

    if (btcAddr) {
      btcAddr.style.cursor = "pointer";
    }
    btcAddr?.addEventListener("click", (event: any) => {
      console.log(`${swap.swapId}-btc`, event);
      // do something
      handleCopy(event);
    });
    isMountedRef.current = true;
  }, []);

  return swap?.config?.map((ele: any, index: number) => {
    if (ele.type === "text") {
      return <TextElement key={index} fetchSwaps={fetchSwaps} {...ele} />;
    }
    return null;
  });
};

export default function SwapModal({
  roomId,
  aiRoomId,
  hasSwaps,
  handleRefreshSwapState,
  roomChain,
  aiTrades,
  setHasSigned,
}: {
  roomId?: string;
  aiRoomId?: string;
  hasSwaps: boolean;
  handleRefreshSwapState: () => Promise<void>;
  roomChain: string;
  aiTrades?: boolean;
  setHasSigned?: () => void;
}) {
  const { uid } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [swaps, setSwaps] = useState<UserSwap[]>([]);
  const [signedSwaps, setSignedSwaps] = useState<{ [key: string]: boolean }>({});

  const handleItemSigned = (order: number) => {
    setSignedSwaps(prev => ({ ...prev, [order]: true }));

    // Check if all items are signed
    if (swaps) {
      const allSigned = swaps.every(swap => signedSwaps[swap.order] || swap.order === order);
      if (allSigned && setHasSigned) {
        setHasSigned();
      }
    }
  };

  const chainFns = useNeoChain() as any;
  const { deposit, connected, connect } = chainFns[roomChain || "not_found"];
  const [showGenerateAgain, setShowGenerateAgain] = useState(false);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const fetchSwaps = async () => {
    try {
      const [{ data }] = await Promise.all([
        (await getSwaps({ roomId, aiRoomId, userId: uid })) as any,
        handleRefreshSwapState(),
      ]);
      // const { data } = (await getSwaps(roomId, uid!)) as any;
      const results: UserSwap[] = JSON.parse(data) || [];
      setSwaps(results.sort((a: UserSwap, b: UserSwap) => a.order - b.order));
    } finally {
      console.log("done fetching swaps");
    }
  };

  const handleGenerateAgain = async () => {
    setGenerating(true);
    const roomDoc = await getAiRoom(aiRoomId!);
    const room = roomDoc.data() as AiRoom;
    const acceptedOffer = room.acceptedOffer;

    const res = await generateAiRoom({ userId: uid!, campaign: room.campaign, token: room.info.token });
    if (!res.success) {
      throw new Error("Failed to fetch data");
    }

    const newRoomDoc = await getAiRoom(res.aiRoomId);
    const newRoom = newRoomDoc.data() as AiRoom;

    const newOfferId = Object.keys(newRoom.offers).find((key) => {
      const offer = newRoom.offers[key];
      return offer.name === acceptedOffer?.name && offer.subname === acceptedOffer?.subname;
    });

    await acceptAiOffer({ walletId: uid!, aiRoomId: res.aiRoomId, offerId: newOfferId! })

    navigate(`/ai-trades/${res.aiRoomId}`, { replace: true });

    setGenerating(false);
  };

  const { isFetching, isRefetching, data } = useQuery({
    queryKey: ["nfts", roomId, aiRoomId, uid],
    queryFn: async () => {
      const { data } = await getSwaps({ roomId, aiRoomId, userId: uid });
      return data;
    },
    staleTime: 5000,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
    onSuccess: (data: string) => {
      const results: UserSwap[] = JSON.parse(data) || [];
      setSwaps(results.sort((a: UserSwap, b: UserSwap) => a.order - b.order));
      handleRefreshSwapState();
    },
  });

  useEffect(() => {
    if (!connected) {
      connect();
    }
  }, []);

  return (
    <>
      {aiTrades && connected && (
        <AiTradesButton
          handleClick={() => {
            onOpen();
            fetchSwaps();
          }}
          isDisabled={!hasSwaps}
          className="w-full md:w-52 flex items-center justify-center px-10 md:px-4 py-6 mx:py-4 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md text-base font-bold text-center text-white capitalize inter-bold"
        >
          Buy Pack
        </AiTradesButton>
      )}
      {!connected && (
        <Button
          className="bar-button"
          backgroundColor="#6C60FF"
          onClick={connect}
          loadingText={"Purchasing..."}
          isDisabled={!hasSwaps}
          size={{
            base: "sm",
            md: "lg",
          }}
        >
          Connect Wallet
        </Button>
      )}
      {!aiTrades && connected && (
        <Button
          className={"bar-button"}
          backgroundColor="#6C60FF"
          onClick={() => {
            onOpen();
            fetchSwaps();
          }}
          loadingText={"Depositing..."}
          isDisabled={!hasSwaps}
          size={{
            base: "sm",
            md: "lg",
          }}
        >
          Sign
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        id="swap-modal"
        isCentered={true}
        scrollBehavior="inside"
        trapFocus={false}
      >
        <ModalOverlay bg="#091824" />
        <ModalContent className="!bg-[#091824]">
          <ModalHeader fontSize="lg" fontWeight="bold">
            Your Transactions
            <Alert
              status="info"
              className="info-alert"
              background={"linear-gradient(90deg, rgb(255 0 0 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
              }
              borderRadius={"4px"}
              marginTop={"10px"}
            >
              <AlertIcon color={"#6C60FF"} />
              <AlertDescription>
                Warning: Do not refresh the page while the transaction is executing.  This transaction is complex and may require multiple attempts to complete.
              </AlertDescription>
            </Alert>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="swap-content">
              {isFetching && !isRefetching && (
                <div className="swap-loader">
                  <h2>Loading, please wait...</h2>
                  <p>We are fetching on-chain data, this may take a moment.</p>
                </div>
              )}
              <Skeleton isLoaded={!!data} minH={200} startColor="#091824" endColor="#0d263b">
                <Stack spacing="4">
                  {swaps?.map((swap, idx) => {
                    const isFirstSwap = idx === 0;
                    const prevSwap = swaps?.[idx - 1];
                    const isPrevSwapErrorOrMessage = prevSwap && (prevSwap.type === "error" || prevSwap.type === "message");
                    const isPrevSwapSigned = idx > 0 && signedSwaps[swaps[idx - 1].order];
                    const isCurrentSwapSigned = signedSwaps[swap.order];

                    let canSign = false;
                    if (isFirstSwap) {
                      // First transaction can be signed if it's not already signed
                      canSign = !isCurrentSwapSigned;
                    } else if (isPrevSwapErrorOrMessage || isPrevSwapSigned) {
                      // Subsequent transactions can be signed if the previous one is signed or an error/message,
                      // and the current one isn't signed yet
                      canSign = !isCurrentSwapSigned;
                    }

                    if (
                      swap.type === "deposit" ||
                      swap.type === "tx" ||
                      swap.type === "signature"
                    ) {
                      return (
                        <div className="swap-item !bg-black/40" key={idx}>
                          <h2>Transaction {idx + 1}</h2>
                          <DepositItem
                            key={idx}
                            swap={swap}
                            fetchSwaps={fetchSwaps}
                            canSign={canSign}
                            deposit={deposit}
                            roomChain={roomChain}
                            setShowGenerateAgain={setShowGenerateAgain}
                            onSigned={() => handleItemSigned(swap.order)}
                            alreadySigned={isCurrentSwapSigned}
                          />
                        </div>
                      );
                    }
                    if (swap.type === "input") {
                      return (
                        <div className="swap-item !bg-black/40" key={idx}>
                          <h2>Transaction {idx + 1}</h2>
                          <SwapForm
                            key={idx}
                            swap={swap}
                            fetchSwaps={fetchSwaps}
                          />
                        </div>
                      );
                    }
                    if (swap.type === "error" || swap.type === "message")
                      return (
                        <div className="swap-item !bg-black/40" key={idx}>
                          <h2>Transaction {idx + 1}</h2>
                          <div className="message">
                            {swap.type === "error" ? (
                              <WarningIcon />
                            ) : (
                              <InfoIcon />
                            )}
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: swap.description,
                              }}
                            />
                          </div>
                        </div>
                      );
                    return "Swap Type unhandled";
                  })}
                </Stack>
                {showGenerateAgain && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <Button
                      className={"bar-button"}
                      backgroundColor="#6C60FF"
                      onClick={() => {
                        handleGenerateAgain()
                      }}
                      isLoading={generating}
                      loadingText={"Generating..."}
                      isDisabled={generating}
                    >
                      Generate Again
                    </Button>
                  </div>)}
              </Skeleton>

            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
