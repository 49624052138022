interface Props {
  className?: string;
}

export default function IconRefresh({ className }: Props) {
  return (
    <svg
      version="1.1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M29.1,2.1c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.4L25,4.5c-1.3-1.3-2.9-2.3-4.6-3 c-1.7-0.7-3.5-1-5.4-1c-3.5,0-6.5,1-9.1,3.1c-2.6,2.1-4.3,4.8-5.1,8.2V12c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2h3.8 c0.3,0,0.5-0.1,0.6-0.4c0.5-1.3,0.9-2,1-2.2c0.9-1.4,2.1-2.6,3.5-3.4c1.5-0.8,3-1.2,4.7-1.2c2.5,0,4.7,0.9,6.6,2.6L19,10.5 c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.4,0.9,0.4h8.5c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.5,0.4-0.8V2.9 C29.5,2.6,29.4,2.3,29.1,2.1z" fill="currentColor" />
      <path d="M28.4,17.4h-3.6c-0.3,0-0.5,0.1-0.6,0.4c-0.5,1.3-0.9,2-1,2.2c-0.9,1.4-2.1,2.6-3.5,3.4c-1.5,0.8-3,1.2-4.7,1.2 c-1.2,0-2.4-0.2-3.5-0.7c-1.1-0.5-2.1-1.1-3-1.9l2.6-2.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.4-0.8-0.4 H1.7c-0.3,0-0.6,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.8v8.5c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.8-0.4	L5,25.5c1.3,1.3,2.9,2.3,4.6,3c1.7,0.7,3.5,1,5.3,1c3.5,0,6.5-1,9-3.1c2.6-2.1,4.3-4.8,5.1-8.2c0,0,0-0.1,0-0.1	c0-0.2-0.1-0.3-0.2-0.4C28.7,17.5,28.6,17.4,28.4,17.4z" fill="currentColor" />
    </svg>
  );
}
