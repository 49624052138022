import Fuse from "fuse.js";
import { sortBy, groupBy as lGroupBy } from "lodash";

type SortDirection = "asc" | "desc";

export type SortOptions = {
  key: string;
  direction: SortDirection;
};

type FilterAndSortRes = {
  results: any[];
  groups: any[];
  query: string;
  sortOptions?: SortOptions;
};

export const filterAndSort = (
  data: any,
  filterKeys: string[],
  query: string,
  sortOptions?: SortOptions,
  groupBy?: string
): FilterAndSortRes => {
  let results: any[] = data;
  let groups: string[] = [];

  const fuse = new Fuse(data, {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    keys: filterKeys,
  });

  const search = fuse.search(query);
  if (search.length) {
    results = search.map((item) => {
      return item.item;
    });
  }

  if (sortOptions) {
    results = sortBy(results, [sortOptions.key]);
    if (sortOptions.direction === "desc") {
      results = results.reverse();
    }
  }

  if (groupBy) {
    groups = Object.keys(lGroupBy(results, groupBy));
  }

  return {
    results,
    groups,
    query,
    sortOptions,
  };
};
