import { Box, Flex, BoxProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Nft } from "../contexts/roomContext";
import { NftMetadata } from "ns-types";

interface Props extends BoxProps {
  nft: Nft | NftMetadata;
}

export default function TxtBlock({ nft, ...props }: Props) {
  const [txt, setTxt] = useState<string | undefined>();
  useEffect(() => {
    const fetchImg = async () => {
      try {
        if (!nft || !nft?.image) return;
        let res = await fetch(nft.image);
        const blob = await res.text();
        setTxt(blob);
        console.log(res, blob);
      } catch (e) {
        console.log(e);
      }
    };
    fetchImg();
  }, []);

  return (
    <Flex
      whiteSpace="pre-line"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      padding="4px"
      {...props}
    >
      <Box display="block">
        <span
          style={{
            overflowWrap: "normal",
            wordBreak: "normal",
            boxSizing: "border-box",
          }}
        >
          {txt}
        </span>
      </Box>
    </Flex>
  );
}
