import { Spinner } from "@chakra-ui/react";
import { ReactElement } from "react";
import { createSearchParams, Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../contexts/appContext";

interface Props {
  children: ReactElement;
}

export const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();
  const { uid, signingIn, email, initialized } = useAppContext();

  if (signingIn) return null;

  let pathname;

  if (!uid) pathname = "/connect";

  // if (uid && location.pathname !== "/profile")
  //   pathname = "/verify-email";

  if (pathname && location.pathname !== pathname) {
    return (
      <Navigate
        to={{
          pathname,
          search: createSearchParams({
            returnUrl: location.pathname,
          }).toString(),
        }}
      />
    );
  }
  return children;
};
