import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Heading } from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import { loadNfts } from "../services/nfts.service";
import NftCollection from "../components/NftCollection";
import { Nft } from "../contexts/roomContext";
import MyNftCard from "../components/NftCollection/MyNftCard";
import ImageCell from "../components/Table/ImageCell";
import { createColumnHelper } from "@tanstack/react-table";
import { createFloor } from "../utils";
import { Spinner } from "@chakra-ui/react";

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor("itemId", {
    cell: ImageCell,
    header: "",
  }),
  columnHelper.accessor("collectionName", {
    cell: (info) => {
      return info.row.original.collection.name;
    },
    header: "Collection Name",
  }),
  columnHelper.accessor("collectionFloorPrice", {
    cell: (info) => {
      return createFloor(info.row.original);
    },
    header: "Collection Floor Price",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("collection.verified.spamScore", {
    header: "Collection Spam Score",
    meta: {
      isNumeric: true,
    },
  }),
];

export default function MyItems() {
  const { uid } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNfts] = useState<Nft[]>([]);
  useEffect(() => {
    const getNfts = async () => {
      setIsLoading(true);
      const { data } = (await loadNfts(uid!)) as { data: Nft[] };
      setNfts(data);
      setIsLoading(false);
    };
    getNfts();
  }, [uid]);

  return (
    <>
      <Helmet>
        <title>{`NeoSwap | My Items`}</title>
      </Helmet>
      <Heading className="page-title" as="h2" size="lg">
        My Items
      </Heading>
      <div className="box">
        {isLoading ? (
          <div className="nft-loader">
            <Spinner color="#ed6cd8" />
            <p className="loadingtext"> Loading Items, please wait... </p>
          </div>
        ) : (
          <NftCollection
            GridComponent={({ nft }: { nft: Nft }) => <MyNftCard nft={nft} />}
            data={nfts}
            title=""
            columns={columns}
            indexOptions={["name", "collection.name"]}
            groupBy="collection.name"
            emptyMessage="No Items found"
            breakPoints={{
              base: 1,
              md: 2,
              lg: 4,
              xl: 5,
              xxxLarge: 5,
              xxxxLarge: 5,
            }}
            isLoading={isLoading}
            collectionType="myNfts"
          />
        )}
      </div>
    </>
  );
}
