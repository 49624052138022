import type { WalletContextState } from '@solana/wallet-adapter-react'
import type { IWalletPassThrough } from '../contexts/WalletPassthroughContext'
import type { Connection, SendOptions } from '@solana/web3.js'
import type { PackTransaction } from '../types/transactions'
import { sleep } from '../../../utils'
import { cloneDeep } from 'lodash'
import bs58 from 'bs58'

export class SolanaClient {
  private connection: Connection
  private alternativeConnections: Connection[] = []
  private wallet: IWalletPassThrough
  private sendDelay: number = 51

  constructor({
    wallet,
    connection,
    alternativeConnections,
    sendDelay,
  }: {
    wallet: IWalletPassThrough
    connection: Connection
    alternativeConnections?: Connection[]
    sendDelay: number
  }) {
    this.wallet = wallet
    this.connection = connection
    if (!!alternativeConnections) this.alternativeConnections = alternativeConnections
    this.sendDelay = sendDelay
  }

  /**
   * Sign and send the given transactions.
   * @param {PackTransaction[]} pTxs - pack transactions to sign and send.
   * @param {SendOptions} [sendOptions] - (optional) The options for sending the transaction.
   * @param {boolean} [useAlternativeConnections] - (optional) Whether to use alternative connections to send transactions.
   * @param {number} [sendDelay] - (optional) The delay between sending transactions.
   * @returns {Promise<PackTransaction[]>} - The signed and sent transactions.
   */
  public async signAndSendTransactions(
    pTxs: PackTransaction[],
    sendOptions?: SendOptions,
    useAlternativeConnections?: boolean,
    sendDelay?: number
  ): Promise<PackTransaction[]> {
    if (!this.wallet.connected) await this.wallet.connect()

    pTxs = cloneDeep(pTxs)
    if (!!sendDelay) this.sendDelay = sendDelay

    //refresh blockhash
    const { pTxs: refreshedPacks, blockheight } = await this.refreshBlockhash(pTxs)

    //sign all transactions
    ;(await this.wallet.signAllTransactions!(refreshedPacks.map((pt) => pt.tx))).map(
      (signedTx, index) => {
        refreshedPacks[index].tx = signedTx
        refreshedPacks[index].blockheight = blockheight
      }
    )

    return await Promise.all(
      refreshedPacks.map(async (pt, i) => {
        await sleep(i * this.sendDelay)
        await this.sendTransaction(pt, sendOptions, useAlternativeConnections)
        return pt
      })
    )
  }

  private async sendTransaction(
    pt: PackTransaction,
    sendOptions?: SendOptions,
    useAlternativeConnections?: boolean
  ): Promise<void> {
    let connections = [this.connection]
    if (useAlternativeConnections) connections = this.alternativeConnections
    const serializedTx = pt.tx.serialize()

    const sendPromises = connections.map(async (conn) => {
      try {
        const hash = await conn.sendRawTransaction(serializedTx, sendOptions)
        return { success: true, hash, conn }
      } catch (e) {
        console.error(`Error sending transaction with connection ${conn.rpcEndpoint}`, e)
        return { success: false, error: e, conn }
      }
    })

    const results = await Promise.all(sendPromises)
    const successfulResult = results.find((result) => result.success)

    if (successfulResult) {
      pt.hash = successfulResult.hash
      pt.status = 'broadcast'
      console.log('success hash', bs58.encode(pt.tx.signatures[0]), pt.hash)
    } else {
      const firstResult = results[0]
      pt.hash = bs58.encode(pt.tx.signatures[0])
      pt.status = 'failed'
      pt.failedReason =
        'Sending failed: ' +
        (firstResult.error instanceof Error ? firstResult.error.toString() : 'Unknown error')
      console.error('All connections failed to send the transaction')
    }
  }

  /**
   * Check the transaction status of the given transactions.
   * @param {PackTransaction[]} pTxs - pack transactions to check.
   * @returns {Promise<PackTransaction[]>} - The transactions with updated status.
   */
  public async checkTransactions(
    pTxs: PackTransaction[],
    rebroadcast?: boolean,
    sendOptions?: SendOptions
  ): Promise<PackTransaction[]> {
    pTxs = cloneDeep(pTxs)

    //filter transactions to check
    let txsToCheck = pTxs.filter((pt) => pt.status == 'broadcast')
    if (txsToCheck.length == 0) return pTxs

    //get transactions data
    let txsData = await this.connection.getTransactions(
      txsToCheck.map((pt) => pt.hash!),
      {
        maxSupportedTransactionVersion: 0,
      }
    )

    txsData.forEach((txData) => {
      let hash = txData?.transaction.signatures[0]
      if (!hash) return

      //find tx in pTxs with same hash
      let txIndex = pTxs.findIndex((pt) => pt.hash === hash)!

      if (txIndex > -1) {
        if (!txData?.meta?.err) {
          pTxs[txIndex].status = 'success'
        } else {
          pTxs[txIndex].status = 'failed'
          pTxs[txIndex].failedReason = txData.meta.err.toString()

          //logs error for debugging
          console.log(' error checking Tx :')
          txData.meta.logMessages?.forEach(console.log)
        }
      }
    })

    txsToCheck = pTxs.filter((pt) => pt.status == 'broadcast')
    if (txsToCheck.length == 0) return pTxs

    let lastValidBlockHeight = (await this.connection.getLatestBlockhash()).lastValidBlockHeight
    txsToCheck.forEach((pt) => {
      if (lastValidBlockHeight > pt.blockheight! + 151) {
        pt.status = 'timeout'
      }
    })

    if (rebroadcast) {
      let txsToRebroadcast = pTxs.filter((pt) => pt.status == 'broadcast')

      await Promise.all(
        txsToRebroadcast.map(async (pt, i) => {
          await sleep(i * this.sendDelay)
          //serialize
          let serializedTx = pt.tx.serialize()
          //send transaction
          await this.connection.sendRawTransaction(serializedTx, sendOptions).catch((e) => {
            console.error('Error rebroadcasting transaction', e)
          })
          return
        })
      )
    }

    return pTxs
  }

  /**
   * Refresh the blockhash of the given transactions.
   * @param {PackTransaction[]} pTxs - pack transactions to refresh blockhash.
   * @returns {Promise<{ pTxs: PackTransaction[]; blockheight: number }>} - The transactions with updated blockhash linked with the blockheight at which these transactions were updated.
   */
  private async refreshBlockhash(
    pTxs: PackTransaction[]
  ): Promise<{ pTxs: PackTransaction[]; blockheight: number }> {
    const blockhash = await this.connection.getLatestBlockhash()
    return {
      pTxs: pTxs.map((pt) => {
        pt.tx.message.recentBlockhash = blockhash.blockhash
        return pt
      }),
      blockheight: blockhash.lastValidBlockHeight,
    }
  }
}
