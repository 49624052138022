export default function IconPaperX() {
  return (
    <svg
      width="154"
      height="190"
      viewBox="0 0 154 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_57_5990)">
        <path
          d="M147.6 183.787H6V31.1733C12.6741 31.1658 19.0727 28.5113 23.792 23.792C28.5112 19.0727 31.1658 12.6741 31.1733 6H122.427C122.42 9.30618 123.068 12.5809 124.335 15.6347C125.602 18.6886 127.462 21.4609 129.807 23.7912C132.137 26.1372 134.91 27.9977 137.964 29.2649C141.018 30.532 144.293 31.1807 147.6 31.1733V183.787Z"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M147.6 186.787H150.6V183.787V31.1733V28.1667L147.593 28.1733C144.684 28.1798 141.801 27.609 139.114 26.4939C136.426 25.3788 133.986 23.7416 131.936 21.6771L131.929 21.6701L131.922 21.6631C129.858 19.6124 128.221 17.1727 127.106 14.4852C125.991 11.7977 125.42 8.91592 125.427 6.00641L125.433 3H122.427H31.1733H28.1767L28.1733 5.99663C28.1667 11.8762 25.8281 17.5132 21.6706 21.6707C17.5131 25.8282 11.8762 28.1667 5.99663 28.1733L3 28.1767V31.1733V183.787V186.787H6H147.6Z"
          stroke="url(#paint0_linear_57_5990)"
          strokeWidth="6"
        />
      </g>
      <path
        d="M76.7991 108.267C97.6533 108.267 114.559 91.3609 114.559 70.5066C114.559 49.6523 97.6533 32.7466 76.7991 32.7466C55.9448 32.7466 39.0391 49.6523 39.0391 70.5066C39.0391 91.3609 55.9448 108.267 76.7991 108.267Z"
        fill="url(#paint1_linear_57_5990)"
      />
      <path
        d="M92.374 86.0821C91.1452 87.3109 89.1528 87.3109 87.924 86.0821L76.7988 74.9569L65.6737 86.0821C64.4448 87.3109 62.4525 87.3109 61.2236 86.0821V86.0821C59.9948 84.8532 59.9948 82.8609 61.2236 81.632L72.3488 70.5069L61.2236 59.3817C59.9948 58.1529 59.9948 56.1605 61.2236 54.9317V54.9317C62.4525 53.7028 64.4448 53.7028 65.6737 54.9317L76.7988 66.0568L87.924 54.9317C89.1528 53.7028 91.1452 53.7028 92.374 54.9317V54.9317C93.6029 56.1605 93.6029 58.1529 92.374 59.3817L81.2489 70.5069L92.374 81.632C93.6029 82.8609 93.6029 84.8532 92.374 86.0821V86.0821Z"
        fill="black"
      />
      <path
        d="M97.2517 117.707H56.345C53.7382 117.707 51.625 119.82 51.625 122.427C51.625 125.033 53.7382 127.147 56.345 127.147H97.2517C99.8584 127.147 101.972 125.033 101.972 122.427C101.972 119.82 99.8584 117.707 97.2517 117.707Z"
        fill="url(#paint2_linear_57_5990)"
      />
      <path
        d="M111.412 136.587H42.1848C39.5781 136.587 37.4648 138.7 37.4648 141.307C37.4648 143.913 39.5781 146.027 42.1848 146.027H111.412C114.018 146.027 116.132 143.913 116.132 141.307C116.132 138.7 114.018 136.587 111.412 136.587Z"
        fill="url(#paint3_linear_57_5990)"
      />
      <defs>
        <filter
          id="filter0_b_57_5990"
          x="-65"
          y="-65"
          width="283.6"
          height="319.787"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_57_5990"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_57_5990"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_57_5990"
          x1="101.73"
          y1="-42.7679"
          x2="-48.5845"
          y2="136.933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_57_5990"
          x1="90.095"
          y1="12.031"
          x2="30.2297"
          y2="101.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_57_5990"
          x1="85.6623"
          y1="115.117"
          x2="83.6676"
          y2="131.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_57_5990"
          x1="90.6482"
          y1="133.997"
          x2="89.3598"
          y2="150.112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
