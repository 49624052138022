import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import SmallLogo from "../assets/smallLogo.svg";
import LinkModal from "./LinkModal";

export default function HowItWorks() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <span>
      <span
        className="text-fuchsia-500 text-sm font-normal underline capitalize cursor-pointer"
        onClick={onOpen}
      >
        How does it work?
      </span>
      <IconButton
        onClick={onOpen}
        variant="ghost"
        aria-label="how does it work"
        isRound
      >
        <QuestionIcon color={"#FF4ADA"} />
      </IconButton>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm" trapFocus={false}>
        <ModalOverlay />
        <ModalContent background="#000">
          <ModalHeader
            height="60px"
            display="flex"
            justifyContent="center"
            borderBottom="1px solid #1B1B1C"
          >
            <img src={SmallLogo} alt="neoswap" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pl="40px" pr="40px" pt="20px">
            <div className="w-full text-center mb-[20px]">
              <span className="text-white text-[21.93px] font-normal">
                How It{" "}
              </span>
              {/* class="text-transparent text-8xl bg-clip-text bg-gradient-to-b from-[#00FFA3] to-[#DC1FFF]" */}

              <span
                style={{
                  background:
                    "linear-gradient(214deg, #00FFA3 -3.51%, #DC1FFF 99.6%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                }}
                className="text-[21.93px] bg-clip-text font-normal text-transparent"
              >
                Works
              </span>
            </div>
            <div className="w-full px-[27.42px] py-[13.71px] bg-neutral-800 bg-opacity-90 rounded-[13.71px] shadow justify-center items-center gap-[11.42px] inline-flex relative">
              <div className="absolute w-[3px] bg-slate-400 h-[153px] top-[21px] left-[31px] -z-10" />
              <div className="grow shrink basis-0 flex-col justify-center items-center gap-[10.28px] inline-flex">
                <div className="w-full justify-center items-center gap-[13.71px] flex">
                  <div className="w-3 h-3 bg-white rounded-full shadow" />
                  <div className="grow shrink basis-0 text-slate-300 text-sm font-medium">
                    Paste link from marketplace
                  </div>
                </div>
                <div className="w-full justify-center items-start gap-[13.71px] inline-flex">
                  <div className="w-3 h-3 bg-white rounded-full shadow" />
                  <div className="grow shrink basis-0 flex-col justify-center gap-[1.14px] inline-flex">
                    <div className="text-slate-300 text-sm font-medium leading-[20px] -mt-1">
                      Click Pay with{" "}
                      <img
                        style={{ width: "80px", height: "10px" }}
                        src={SmallLogo}
                        alt="neoswap"
                      />
                    </div>
                    <div className="text-gray-500 text-xs font-normal">
                      Please click <LinkModal /> to learn how to get the right
                      link.
                    </div>
                  </div>
                </div>

                <div className="w-full justify-center items-start gap-[13.71px] inline-flex">
                  <div className="w-3 h-3 bg-white rounded-full shadow" />
                  <div className="grow shrink basis-0 flex-col justify-center gap-[1.14px] inline-flex">
                    <div className="text-slate-300 text-sm font-medium leading-[20px] -mt-1">
                      Select a trade
                    </div>
                    <div className="text-gray-500 text-xs font-normal">
                      We scan your wallet and our AI finds offers for you.
                    </div>
                  </div>
                </div>
                <div className="w-full justify-center items-center gap-[13.71px] inline-flex">
                  <div className="w-3 h-3 bg-white rounded-full shadow" />
                  <div className="grow shrink basis-0 flex-col justify-center gap-[1.14px] inline-flex">
                    <div className="text-slate-300 text-sm font-medium">
                      Sign your trade
                    </div>
                  </div>
                </div>
                <div className="w-full justify-center items-start gap-[13.71px] inline-flex">
                  <div className="w-3 h-3 bg-white rounded-full shadow" />
                  <div className="grow shrink basis-0 flex-col justify-center gap-[1.14px] inline-flex">
                    <div className="text-slate-300 text-sm font-medium leading-[20px] -mt-1">
                      Wait
                    </div>
                    <div className="text-gray-500 text-xs font-normal">
                      The trades will be human-approved. Please be patient, it
                      may take some time.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter padding="20px 40px">
            <button
              onClick={onClose}
              role="button"
              className="w-full px-[102.82px] py-[20.56px] bg-gradient-to-l from-fuchsia-500 via-fuchsia-500 to-indigo-500 rounded-[13.71px] justify-center items-center gap-[13.71px] inline-flex"
            >
              <div
                className="text-center text-white text-sm font-semibold font-['Inter'] uppercase"
                onClick={onClose}
              >
                Got It
              </div>
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </span>
  );
}
