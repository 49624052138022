import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { LegacyRef, SetStateAction, useEffect, useRef, useState } from "react";
import { serverTimestamp } from "firebase/firestore";
import {
  initializeSwap,
  solveTrade,
  updateRoom,
  cancelTrades,
} from "../services/room.service";
import ReactDOM from "react-dom";
import usePolygon from "../hooks/usePolygon";
import { useUA } from "../contexts/userTracking";
import { useRoom } from "../contexts/roomContext";

interface Props {
  status: string;
  roomId: string;
  swapId?: string;
  tradeFound: boolean;
  chain: string;
}

interface SolanaInitializeProps {
  roomId: string;
  swapId?: string;
  tradeFound: boolean;
}


export default function AdminBar({
  status,
  roomId,
  swapId,
  tradeFound,
  chain,
}: Props) {
  const { gaArchiveParty } = useUA();
  const toast = useToast();
  const topBar = document.getElementById("top-bar");
  const [archiving, setArchiving] = useState(false);
  const [solving, setSolving] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isCanceling, setIsCanceling] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const hasSubscribedToBuyDown = localStorage.getItem("buydown-waitlist-subscribed");
  
  useEffect(() => {
    if (!hasSubscribedToBuyDown) {
      document.body.classList.add("bodywithadmin");
    }
    document.body.classList.add("admin-active");

    return () => {
      document.body.classList.remove("admin-active");
    };
  });

  const { room } = useRoom();

  // const handleNext = async () => {
  //   let newStatus;

  //   if (status === "listing") newStatus = "bidding";
  //   if (status === "bidding") newStatus = "finalize";
  //   if (status === "finalize") return;
  //   try {
  //     setProgressing(true);
  //     await updateRoom(roomId, { status: newStatus });
  //   } catch (e) {
  //     console.log(e);
  //     toast({
  //       title: "Error Changing the room status",
  //       description: "Please Try Again!",
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //       styleConfig: {
  //         zIndex: 999999,
  //       },
  //     });
  //   } finally {
  //     setProgressing(false);
  //   }
  // };

  const handleSolve = async () => {
    try {
      onClose();
      setSolving(true);
      await updateRoom(roomId, { status: "solving" });
      const res = (await solveTrade(roomId)) as any;
      if (res?.data?.result === "error") {
        throw new Error(res?.data?.detail);
      }
      onClose();
    } catch (e: any) {
      toast({
        title: "Error Calling the Solver",
        description: e.message || "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      setSolving(false);
    }
  };

  const handleArchive = async () => {
    try {
      setArchiving(true);
      await updateRoom(roomId, {
        status: status === "archived" ? "listing" : "archived",
        archivedAt: status === "archived" ? null : serverTimestamp(),
      });
      if (status !== "archived") {
        gaArchiveParty(roomId);
      }
    } catch (e) {
      toast({
        title: "Error Archiving the Room",
        description: "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      setArchiving(false);
    }
  };

  const handleCancel = async () => {
    try {
      setIsCanceling(true);
      onClose();
      // setSolving(true);
      // await updateRoom(roomId, { status: "finalize" });
      const res = (await cancelTrades(roomId)) as any;
      if (res?.data?.result === "error") {
        throw new Error(res?.data?.detail);
      }
      onClose();
    } catch (e: any) {
      toast({
        title: "Error Canceling the Trades",
        description: e.message || "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      setSolving(false);
      setIsCanceling(false);
    }
  };

  const handleCancelAndSolve = (action: SetStateAction<string>) => {
    setModalType(action);
    onOpen();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure you want to proceed?
            </AlertDialogHeader>
            <AlertDialogBody>
              You are about to{" "}
              {modalType && modalType === "cancel"
                ? "cancel the active swaps"
                : "find swaps and proceed to signing stage"}
              . This action can't be undone. Are you sure you want to proceed?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button
                colorScheme="red"
                onClick={
                  modalType && modalType === "cancel"
                    ? handleCancel
                    : handleSolve
                }
                ml={3}
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {ReactDOM.createPortal(
        <div id="admin-controls">
          <h2>Host Controls:</h2>
          {/* <div className="button-wrap"> */}
          <Flex gap={8} width="auto" p={4}>
            {/* <Button
              width={"100%"}
              bgColor="#6c60ff"
              color="white"
              onClick={handleNext}
              isLoading={progressing}
              loadingText={"Moving Forward..."}
              isDisabled={status === "finalize"}
              size="sm"
              rightIcon={<ChevronRightIcon />}
            >
              Next Step
            </Button> */}
            <Button
              width={"100%"}
              bgColor="green"
              color="white"
              onClick={() => handleCancelAndSolve("solve")}
              isLoading={solving}
              isDisabled={tradeFound}
              loadingText={"Solving..."}
              size="sm"
              rightIcon={<CheckIcon />}
            >
              Solve Trade
            </Button>
            {/* {chain === "solana" && (
              <SolanaInitialize
                roomId={roomId}
                swapId={swapId}
                tradeFound={tradeFound}
              />
            )}
            {(chain === "polygon" || chain === "ethereum") && (
              <PolygonInitialize
                roomId={roomId}
                swapId={swapId}
                tradeFound={tradeFound}
              />
            )}
            {chain === "stacks" && <InitializeSwap />} */}
            <Button
              width={"100%"}
              colorScheme={status === "archived" ? "green" : "orange"}
              onClick={handleArchive}
              isLoading={archiving}
              isDisabled={archiving}
              loadingText={"Loading..."}
              size="sm"
            >
              {status === "archived" ? "Activate Room" : "Archive Room"}
            </Button>

            <Button
              width={"100%"}
              colorScheme={"red"}
              onClick={() => handleCancelAndSolve("cancel")}
              isLoading={isCanceling}
              isDisabled={isCanceling}
              loadingText={"Canceling..."}
              size="sm"
            >
              Cancel Swaps
            </Button>
          </Flex>
        </div>,
        topBar!
      )}
    </>
  );
}
