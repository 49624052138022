import partyIcon from "../assets/party.svg";
import { useState } from "react";
import IconClose from "./Icons/IconClose";

interface Props {
  userRevenue: string;
}

export default function UserRevenue({ userRevenue }: Props) {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div
      className="revenue-share-message box"
      style={{ display: isVisible ? "flex" : "none" }}
    >
      <div className="close-icon" onClick={() => setIsVisible(false)}>
        <IconClose />
      </div>
      <img src={partyIcon} alt="Congrats!" />
      <div>
        <h2>
          Congrats! You made <span>{userRevenue}</span> by hosting this event!
        </h2>
        <p>
          The SOL will be added to the amount of SOL you receive or reflected on
          the SOL you have to pay.
        </p>
      </div>
    </div>
  );
}
