export default function IconEyeSlash() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29596 0.451292C2.15378 0.318812 1.96574 0.246689 1.77144 0.250117C1.57714 0.253545 1.39175 0.332257 1.25434 0.46967C1.11693 0.607083 1.03821 0.792468 1.03478 0.986769C1.03136 1.18107 1.10348 1.36912 1.23596 1.51129L19.236 19.5113C19.3046 19.585 19.3874 19.6441 19.4794 19.6851C19.5714 19.7261 19.6707 19.7481 19.7714 19.7499C19.8721 19.7517 19.9722 19.7331 20.0656 19.6954C20.1589 19.6577 20.2438 19.6015 20.315 19.5303C20.3862 19.4591 20.4424 19.3743 20.4801 19.2809C20.5178 19.1875 20.5363 19.0875 20.5346 18.9868C20.5328 18.8861 20.5107 18.7868 20.4697 18.6948C20.4287 18.6028 20.3696 18.52 20.296 18.4513L2.29596 0.451292ZM21.442 10.5343C20.9029 12.1518 20.0032 13.6256 18.811 14.8443L15.712 11.7453C16.0463 10.8092 16.108 9.79745 15.89 8.82767C15.6719 7.85789 15.183 6.96994 14.4802 6.26708C13.7773 5.56422 12.8894 5.07533 11.9196 4.85727C10.9498 4.63922 9.93804 4.70095 9.00196 5.03529L6.52496 2.55829C7.87198 2.01025 9.31272 1.72937 10.767 1.73129C15.737 1.73129 19.952 4.95429 21.442 9.42129C21.562 9.78329 21.562 10.1733 21.442 10.5343Z"
        fill="#081923"
      />
      <path
        d="M14.5159 9.98125C14.5159 10.1613 14.5029 10.3383 14.4789 10.5113L10.2349 6.26825C10.7669 6.19216 11.309 6.23129 11.8245 6.383C12.3401 6.53471 12.817 6.79545 13.2229 7.14755C13.6289 7.49966 13.9545 7.93491 14.1775 8.42381C14.4006 8.91272 14.516 9.44386 14.5159 9.98125ZM11.2959 13.6943L7.05289 9.45025C6.97048 10.0266 7.02342 10.6143 7.20751 11.1667C7.3916 11.719 7.70178 12.2209 8.11349 12.6327C8.52519 13.0444 9.02711 13.3545 9.57948 13.5386C10.1319 13.7227 10.7195 13.7757 11.2959 13.6933V13.6943Z"
        fill="#081923"
      />
      <path
        d="M5.516 9.98119C5.516 9.36219 5.623 8.76819 5.82 8.21719L2.72 5.11719C1.5281 6.33603 0.628787 7.80981 0.09 9.42719C-0.03 9.78919 -0.03 10.1792 0.09 10.5412C1.579 15.0082 5.794 18.2312 10.765 18.2312C12.265 18.2312 13.698 17.9372 15.007 17.4042L12.53 14.9272C11.7377 15.2098 10.889 15.298 10.0555 15.1841C9.22194 15.0703 8.42799 14.7578 7.74048 14.273C7.05296 13.7881 6.49202 13.1452 6.1049 12.3983C5.71777 11.6514 5.5158 10.8224 5.516 9.98119Z"
        fill="#081923"
      />
    </svg>
  );
}
