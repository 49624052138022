import { useEffect, useState } from 'react';
import { callTwitterConnectCallback } from "../services/user.service";
import { useAppContext } from "../contexts/appContext";

const TwitterOAuthCallback = () => {
  const [pageContent, setPageContent] = useState<string>('Processing your Twitter login...\nDo not close this tab');
  const { setTwitterConnected, reloadUser } = useAppContext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const oauth_token = params.get('oauth_token');
    const oauth_verifier = params.get('oauth_verifier');

    if (oauth_token && oauth_verifier) {
      window.history.replaceState({}, document.title, window.location.pathname);
      callTwitterConnectCallback(oauth_token, oauth_verifier)
        .then(() => {
          console.log('OAuth process completed successfully.');
          setPageContent('Twitter login successful! You can close this tab now.');
          setTwitterConnected(true);
          localStorage.setItem('twitterConnected', 'true');
          reloadUser();
        })
        .catch(error => {
          console.error('OAuth process failed:', error);
          setPageContent('Twitter login failed. Please start the process again.');
        });
    } else {
      setPageContent('Invalid parameters. Please start the process again.');
    }
  }, []);

  return (
    <div>
      {pageContent}
    </div>
  );
};

export default TwitterOAuthCallback;
