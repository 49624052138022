// @ts-nocheck
import React, { useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormErrorMessage,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, ErrorMessage } from "formik";
import { useAppContext } from "../../contexts/appContext";
import { updateInfo } from "../../services/user.service";
import * as Yup from "yup";

import { hasError } from "../../utils";

Yup.addMethod(Yup.object, "atLeastOneOf", function (list) {
  return this.test({
    name: "atLeastOneOf",
    message: "${path} must have at least one of these keys: ${keys}",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) =>
      value == null || list.some((f: string | number) => value[f] != null),
  });
});

function ContactMethodModal({
  open = false,
  handleClose,
}: {
  open: boolean;
  handleClose: any;
}) {
  const cancelRef = useRef<HTMLDivElement>(null);
  const { uid, updateEmail } = useAppContext();
  const list = ["email", "discord", "twitter"];
  const schema = Yup.object()
    .shape({
      email: Yup.string().email("Please enter a valid email: name@domain.com"),
      discord: Yup.string(),
      twitter: Yup.string(),
    })
    .test({
      name: "atLeastOneOf",
      message: "${path} must have at least one of these keys: ${keys}",
      exclusive: true,
      params: { keys: list.join(", ") },
      test: (value: any, ctx: any) => {
        const isValid =
          value == null || list.some((f: any) => value[f] != null);
        if (isValid) return true;
        return ctx.createError({
          path: "allrequired",
          message: "One field must be set",
        });
      },
    });

  const handleSubmit = async (values: any, { setSubmitting, ...rest }: any) => {
    try {
      await updateInfo(uid!, "email", { value: values.email });
      await updateInfo(uid!, "twitter", { value: values.twitter });
      await updateInfo(uid!, "discord", { value: values.discord });

      toast({
        title: "Notification settings saved successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      handleClose();
    } catch (e) {
      rest.handleError(e);
    } finally {
      rest.setSubmitting(false);
    }
  };

  const initialValues = {
    email: "",
    discord: "",
    twitter: "",
  };

  const toast = useToast();

  return (
    <AlertDialog
      id="notification-info-modal"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      isOpen={open}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxWidth="500px" className="ns-dialog-content">
        <AlertDialogHeader>
          <Box>
            <h3>Notification Settings</h3>
            <Text fontSize="16px">
              To proceed, please provide the following contact details. Your
              email address is required, while the rest of the information is
              optional. We will notify you when the auction ends.
            </Text>
          </Box>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Box>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              initialValues={initialValues}
            >
              {({ errors, touched, values, handleChange, ...props }) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Flex direction="column" gap="20px">
                      <FormControl
                        isInvalid={hasError(errors, touched, "email")}
                        isRequired
                      >
                        <Flex>
                          <FormLabel htmlFor="email">Email</FormLabel>
                        </Flex>
                        <Input
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          placeholder="mail@neoswap.com"
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={hasError(errors, touched, "discord")}
                      >
                        <Flex>
                          <FormLabel htmlFor="email">Discord</FormLabel>
                        </Flex>
                        <Input
                          id="discord"
                          name="discord"
                          onChange={handleChange}
                          value={values.discord}
                          placeholder="discordUsername"
                        />
                        <FormErrorMessage>{errors.discord}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={hasError(errors, touched, "twitter")}
                      >
                        <Flex>
                          <FormLabel htmlFor="twitter">Twitter</FormLabel>
                        </Flex>
                        <Input
                          id="twitter"
                          name="twitter"
                          onChange={handleChange}
                          value={values.twitter}
                          placeholder="@neoswap_ai"
                        />
                        <FormErrorMessage>{errors.twitter}</FormErrorMessage>
                      </FormControl>

                      {errors.allrequired && (
                        <Text color="red.500" fontSize="sm">
                          {errors.allrequired}
                        </Text>
                      )}
                    </Flex>
                    <Button
                      type="submit"
                      width="100%"
                      isLoading={props.isSubmitting}
                      mt={4}
                      mb={4}
                    >
                      Submit
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ContactMethodModal;
