export const config = {
  frameRate: 60,
  width: 512,
  height: 512,
  curvePrecision: 0.009,
  profileLinkPrefix: "/profile/",
  speed: 0.0035,
  speedMultiplier: 0.7,
  showAmounts: false,
  showUsernames: true,
  backgroundColor: "#1B1A19",
  showNodes: false,
  randomSeed: 9,
  linesThickness: 2,
  pauseOnHover: false,
  showArrows: false,
  exportGif: true,
  gifLength: 6.8,
  showLogo: true,
  onNodeClick: (node:string) => { console.log("Clicked on node: ", node) },
  onNodeHover: (node:string) => { console.log("Hovered on node: ", node) }
};

export const colorMap = ["#A7B600"];
// export const colorMap = [
//   "#9e72ff",
//   "#54ffb1",
//   "#f923dd",
//   "#01d691",
//   "#cd50ff",
//   "#44c474",
//   "#fe00a7",
//   "#00f0ba",
//   "#e236c3",
//   "#6ee695",
//   "#c057d3",
//   "#7bffca",
//   "#d84da6",
//   "#03e0be",
//   "#fc5a99",
//   "#42ae69",
//   "#ff7de6",
//   "#2a9956",
//   "#f38dff",
//   "#03c5b3",
//   "#ff6fc7",
//   "#2cf6ff",
//   "#cb57b5",
//   "#aaffdc",
//   "#4f7fec",
//   "#8ecd9e",
//   "#8974d7",
//   "#009c8b",
//   "#ffa3d7",
//   "#13e8ff",
//   "#cf7995",
//   "#83f1ff",
//   "#ad6da9",
//   "#a8fffe",
//   "#9a73c2",
//   "#95e4d6",
//   "#5984c8",
//   "#209196",
//   "#e8cdfe",
//   "#00c0e5",
//   "#bcbdff",
//   "#03b2e0",
//   "#98b6ff",
//   "#018dc2",
//   "#84d7ff",
//   "#47aaff",
//   "#6e90be",
//   "#01a9e3",
//   "#8bb6e3",
//   "#6bb5ff",
// ];
