import { useAppContext } from "../contexts/appContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  AiRoom,
  Blockchain,
  ChainDetails,
  Img,
} from "../components/AiTradesV2/types";
import firebase from "../services/firebase";
import { useQuery } from "@tanstack/react-query";
import { useChain } from "../contexts/chainsContext";
import { Disclosure } from "@headlessui/react";
import { DateTime } from "luxon";
import OfferPanel from "../components/AiTradesV2/OfferPanel";
import { createImages, getNftsMetaData } from "./AiOffers";
import { createIdsFromOffer, roundValue } from "../components/AiTradesV2/utils";
import { ReactElement, useState } from "react";
import NeoImage from "../components/AiTradesV2/Img";
import { Select } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

function PanelHeader({
  roomId,
  get,
  give,
  tokenValue,
  chain,
  open,
  title,
  children,
}: {
  roomId: string;
  get: Img[];
  give: Img[];
  tokenValue: number;
  chain: ChainDetails;
  open: boolean;
  title: ReactElement;
  children: ReactElement;
}) {
  return (
    <div className="w-full px-8 py-4 bg-black bg-opacity-30 rounded-2xl backdrop-blur-[65px] justify-center items-center gap-2.5 inline-flex flex-col">
      <div className="flex w-full items-center">
        <div className="grow shrink basis-0 h-fit lg:h-16 justify-start items-center gap-4 flex flex-col lg:flex-row">
          {/* <div className="justify-start items-center gap-4 flex">
          <div role="button" className="w-12 h-12 relative">
            {checked && (
              <div className="w-8 h-8 left-[8px] top-[8px] absolute bg-green-500 rounded-full" />
            )}
            <div className="w-12 h-12 left-0 top-0 absolute rounded-full border-2 border-white" />
          </div>
          <div className="text-white text-[21px] font-medium leading-loose">
            Trade {getLetterAtIndex(idx)}
          </div>
        </div> */}
          {title}
          {!open && (
            <>
              <div className="justify-start items-center gap-4 flex">
                {tokenValue && tokenValue > 0 && (
                  <>
                    <div className="justify-center items-center gap-4 flex">
                      <div className="text-center text-white text-[21px] font-medium leading-loose">
                        {Math.abs(tokenValue)} {chain.abbr}{" "}
                      </div>
                      {/* <div className="w-16 h-16 relative">
                <div className="w-8 h-8 left-0 top-0 absolute bg-black rounded-full">
                  <Icon />
                </div>
              </div> */}
                    </div>
                    {get.length > 0 && (
                      <div className="text-white text-[21px] font-medium leading-loose">
                        &
                      </div>
                    )}
                  </>
                )}
                <div className="justify-start items-start gap-1.5 flex">
                  {get?.map(({ id, src, alt }) => {
                    return (
                      <div
                        key={`get_${id}}`}
                        className="w-16 h-16 bg-white rounded-md border-b-2 border-green-500 justify-center items-center flex overflow-hidden"
                      >
                        <NeoImage src={src} alt={alt} className="w-16 h-16" />
                      </div>
                    );
                  })}
                </div>
              </div>
              {(give?.length > 0 || get?.length > 0) && (
                <div className="w-[25.97px] h-[16.97px] relative">
                  <svg
                    width="30"
                    height="20"
                    viewBox="0 0 30 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.96875 10.3169H27.9688"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19.4835 1.83158L27.9688 10.3169L19.4835 18.8021"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10.4853 1.83164L2 10.3169L10.4853 18.8022"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              )}
              <div className="justify-start items-center gap-[5.87px] flex">
                {tokenValue && tokenValue < 0 && (
                  <>
                    <div className="justify-center items-center gap-4 flex">
                      <div className="text-center text-white text-[21px] font-medium leading-loose">
                        {Math.abs(tokenValue)} {chain.abbr}{" "}
                      </div>
                      {/* <div className="w-16 h-16 relative">
                <div className="w-8 h-8 left-0 top-0 absolute bg-black rounded-full">
                  <Icon />
                </div>
              </div> */}
                    </div>
                    {give.length > 0 && (
                      <div className="text-white text-[21px] font-medium leading-loose">
                        &
                      </div>
                    )}
                  </>
                )}
                {give?.map(({ id, src, alt }) => {
                  return (
                    <div
                      key={`give_${id}`}
                      className="w-16 h-16 bg-white rounded-md border-b-2 border-red-600 justify-center items-center flex overflow-hidden"
                    >
                      <NeoImage src={src} alt={alt} className="w-16 h-16" />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <Link
          className="text-[#3DFF99] underline decoration-solid"
          to={`/ai-trades/${roomId}`}
        >
          View
        </Link>
        {/* <div className="relative">
          <Disclosure.Button>
            <div className={!open ? "rotate-180 transition-all" : "rotate-0"}>
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.178 14.569C9.26974 14.7022 9.39249 14.8112 9.53569 14.8864C9.67888 14.9617 9.83823 15.001 10 15.001C10.1618 15.001 10.3211 14.9617 10.4643 14.8864C10.6075 14.8112 10.7303 14.7022 10.822 14.569L19.822 1.569C19.9262 1.41906 19.9873 1.24343 19.9986 1.06121C20.01 0.87898 19.9712 0.697123 19.8865 0.535394C19.8018 0.373665 19.6743 0.238248 19.518 0.143858C19.3617 0.049468 19.1826 -0.000286344 19 1.23965e-06H0.999998C0.817841 0.000753649 0.639336 0.0511479 0.483678 0.145764C0.328021 0.24038 0.201101 0.375639 0.116566 0.536994C0.0320324 0.698349 -0.00691693 0.879696 0.00390703 1.06153C0.014731 1.24337 0.0749187 1.41881 0.177998 1.569L9.178 14.569Z"
                  fill="#F8F8F8"
                />
              </svg>
            </div>
          </Disclosure.Button>
        </div> */}
      </div>

      {children}
    </div>
  );
}

const RoomPanel = ({ room, chain }: { room: AiRoom; chain: ChainDetails }) => {
  const offer = room.acceptedOffer!;
    console.log(room.info?.token)

  const tokenValue = roundValue(offer?.token, chain?.decimals, chain?.truncate);
  const tokenName = room.info?.token ?  room.info.token : chain.abbr;

  const ids = createIdsFromOffer(room, room.acceptedOfferId);

  const { data: images, isLoading } = useQuery({
    queryKey: ["nfts", room.id],
    queryFn: () => getNftsMetaData({ ids }),
    enabled: !!room && ids.length > 0,
    staleTime: 5000,
    select: (data) => createImages(data),
  });

  const get = room?.acceptedOffer?.get?.map((id: string) => {
    const img = images?.[id];
    if (!img) return;
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
    };
  }) as Img[];

  const give = room?.acceptedOffer?.give?.map((id: string) => {
    const img = images?.[id];
    if (!img) return;
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
    };
  }) as Img[];

  if (isLoading) return null;

  const createPanel = () => {
    if (room.state === "accepted")
      return (
        <OfferPanel
          showTradeFeedback={false}
          showBorder={false}
          tokenValue={tokenValue}
          chain={chain!}
          get={get}
          give={give}
          tokenName={tokenName.toUpperCase()}
        />
      );
    return null;
  };

  const createTitle = () => {
    if (room.state === "accepted") {
      const seconds = room?.acceptedAt?.seconds || new Date().getSeconds();
      return <>Accepted At: {DateTime.fromSeconds(seconds).toRelative()}</>;
    }
    if (room.state === "declined") {
      const seconds = room.declinedAt?.seconds || new Date().getSeconds();
      return <>Declined At: {DateTime.fromSeconds(seconds).toRelative()}</>;
    }

    if (room.state === "active") {
      const seconds = room?.info?.createdAt?.seconds || new Date().getSeconds();
      return <>Created At: {DateTime.fromSeconds(seconds).toRelative()}</>;
    }

    return <></>;
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <PanelHeader
            open={open}
            give={give}
            get={get}
            tokenValue={tokenValue!}
            chain={chain!}
            title={createTitle()}
            roomId={room.id!}
          >
            <Disclosure.Panel className={"w-full"}>
              {createPanel()}
            </Disclosure.Panel>
          </PanelHeader>
        </>
      )}
    </Disclosure>
  );
};

// All statuses
// Pending
// Completed

export const getHistory = async (userId: string, filter: string) => {
  const rooms: AiRoom[] = [];

  const db = firebase.getDBApp();

  let q = query(collection(db, "aiRooms"), where("userId", "==", userId));

  if (filter === "Pending") {
    q = query(q, where("state", "==", "active"));
  }

  if (filter === "Completed") {
    q = query(q, where("state", "==", "accepted"));
  }

  const res = await getDocs(q);

  res.forEach((doc) => {
    rooms.push({
      id: doc.id,
      ...(doc.data() as AiRoom),
    });
  });

  return rooms;
};

const options = ["All", "Pending", "Completed"];

export default function AiHistory() {
  const { uid } = useAppContext();
  const { getChainDetails } = useChain();
  const [filter, setFiltered] = useState("All");
  const { data } = useQuery({
    queryKey: ["history", uid, filter],
    queryFn: () => getHistory(uid!, filter),
    enabled: !!uid,
    refetchOnWindowFocus: false,
  });

  console.log(data)
  return (
    <div className="w-full">
      <div className="flex flex-col w-full rounded-2xl p-2 gap-4">
        <Select value={filter} onChange={(e) => setFiltered(e.target.value)}>
          {options.map((opt) => {
            return <option value={opt}>{opt}</option>;
          })}
        </Select>
        {data?.map((room: AiRoom) => {
          const chain = getChainDetails(
            room.info.blockchain as Blockchain
          ) as ChainDetails;
          return <RoomPanel key={room.id} room={room} chain={chain!} />;
        })}
      </div>
    </div>
  );
}
