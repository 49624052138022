import { assign, setup, fromPromise } from 'xstate'
import { SharedData, Pack, PackTransaction, TokenAmount } from './types'
import { PacksClient } from '../../clients/packsClient'
import { assertTransactionsSuccess, handleShare } from '../../utils'
import { userAnalyticsClient } from '../../clients/userAnalyticsClient'

const modalMachine = setup({
  types: {
    input: {} as {
      partnerToken: string
      partner?: string
      packId?: string
      packDetails?: Pack
      twitterMessageUrl?: string
      twitterMessageText?: string
    },
  },
  actions: {
    invokeHandleShare: ({ context }: { context: SharedData }) => {
      handleShare(context.twitterMessageUrl, context.twitterMessageText)
    },
  },
  actors: {
    fetchPackDetails: fromPromise<Pack, SharedData>(async ({ input }) => {
      return input.packClient.fetchPackDetails({ pack: input.packDetails, packId: input.packId })
    }),
    fetchTxs: fromPromise<PackTransaction[], SharedData>(async ({ input }) => {
      return input.packClient.fetchTransactions(input.packDetails!, input.walletAddress!)
    }),
    retryTxs: fromPromise<PackTransaction[], SharedData>(async ({ input }) => {
      return input.packClient.retryTransactions(input.txs!, input.walletAddress!)
    }),
    fetchBalances: fromPromise<TokenAmount[], SharedData>(async ({ input }) => {
      return input.packClient.fetchBalances(input.walletAddress!)
    }),
  },
}).createMachine({
  id: 'buyingModal',
  initial: 'init',
  context: ({ input }) => ({
    partner: input.partnerToken.slice(-5),
    packId: input.packId,
    packDetails: input.packDetails,
    txs: [],
    previousTxs: [],
    walletAddress: undefined,
    balances: [],
    error: undefined,
    message: undefined,
    packClient: new PacksClient({ jwtToken: input.partnerToken }),
    twitterMessageUrl: input.twitterMessageUrl ?? '',
    twitterMessageText: input.twitterMessageText ?? '',
  }),
  states: {
    // Initial state, load data
    init: {
      entry: [
        ({ context }: { context: SharedData }) => {
          userAnalyticsClient.packsSelectPack(context.partner, context.packId || 'unknown')
        },
      ],
      invoke: {
        src: 'fetchPackDetails',
        input: ({ context }) => context,
        onDone: {
          target: 'connect',
          actions: assign(({ event }) => ({ packDetails: event.output })),
        },
        onError: {
          target: 'errorInit',
          actions: assign(({ event }) => ({ error: event.error })),
        },
      },
    },

    connect: {
      on: {
        CONNECT: {
          target: 'loadBalances',
          actions: assign(({ event }) => ({ walletAddress: event.walletAddress })),
        },
        NO_CONNECT: {
          target: 'notConnected',
          actions: assign({ message: 'Please connect your wallet' }),
        },
      },
    },

    notConnected: { on: { NEXT: { target: 'connect' } } },

    loadBalances: {
      invoke: {
        src: 'fetchBalances',
        input: ({ context }) => context,
        onDone: {
          target: 'accept',
          actions: assign(({ event }) => ({ balances: event.output })),
        },
        onError: {
          target: 'errorBalances',
          actions: assign(({ event }) => ({ error: event.error })),
        },
      },
    },

    accept: {
      on: {
        NEXT: { target: 'fetchTxs' },
        UPDATE_BALANCES: { target: 'loadBalances' },
      },
    },

    fetchTxs: {
      entry: [
        ({ context }: { context: SharedData }) => {
          userAnalyticsClient.packsBuyPackInitialized(context.partner, context.packId || 'unknown')
        },
      ],
      invoke: {
        src: 'fetchTxs',
        input: ({ context }) => context,
        onDone: {
          target: 'signTxs',
          actions: assign(({ event }) => ({ txs: event.output })),
        },
        onError: {
          target: 'errorFetchTxs',
          actions: assign(({ event }) => ({ error: event.error })),
        },
      },
    },

    retryTxs: {
      entry: [
        ({ context }: { context: SharedData }) => {
          userAnalyticsClient.packsBuyPackRetry(context.partner, context.packId || 'unknown')
        },
      ],
      invoke: {
        src: 'retryTxs',
        input: ({ context }) => context,
        onDone: {
          target: 'signTxs',
          actions: assign(({ event, context }) => ({
            txs: event.output,
            previousTxs: context.txs.filter((tx) => !!tx.hash).concat(context.previousTxs),
          })),
        },
        onError: {
          target: 'errorFetchTxs',
          actions: assign(({ event }) => ({ error: event.error })),
        },
      },
    },

    signTxs: {
      on: {
        SIGN: {
          target: 'checkTxs',
          actions: assign(({ event }) => ({ txs: event.txs })),
        },
        REJECT: {
          target: 'errorSignReject',
          actions: assign({ message: 'Sign transactions to buy the pack' }),
        },
        ERROR: {
          target: 'errorSign',
          actions: assign(({ event }) => ({ error: event.error })),
        },
      },
    },

    checkTxs: {
      on: {
        UPDATE: [
          {
            guard: ({ event }) => assertTransactionsSuccess(event.txs) === 'success',
            target: 'success',
            actions: assign(({ event }) => ({ txs: event.txs })),
          },
          {
            guard: ({ event }) => assertTransactionsSuccess(event.txs) === 'failed',
            target: 'errorBlockchainTxs',
            actions: assign(({ event }) => (event.txs ? { txs: event.txs } : {})),
          },
          {
            target: 'checkTxs',
            actions: assign(({ event }) => ({ txs: event.txs })),
          },
        ],
        ERROR: {
          target: 'errorBlockchainTxs',
          actions: assign(({ event }) => ({ error: event.error, txs: event.txs })),
        },
      },
    },

    success: {
      entry: [
        ({ context }: { context: SharedData }) => {
          userAnalyticsClient.packsBuyPackSuccess(context.partner, context.packId || 'unknown')
        },
      ],
      on: {
        SHARE: {
          actions: [{ type: 'invokeHandleShare' }],
          target: 'shared',
        },
      },
    },

    shared: {
      type: 'final',
    },

    errorInit: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Error fetching pack details'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'init' },
    },
    errorBalances: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Error fetching balances'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'loadBalances' },
    },
    errorFetchTxs: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Error fetching transactions'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'fetchTxs' },
    },
    errorSignReject: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Rejected signing'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'signTxs' },
    },
    errorSign: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Error Signing'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'retryTxs' },
    },
    errorBlockchainTxs: {
      entry: [
        ({ context }: { context: SharedData }) => {
          let message = 'Transaction failed'
          if (context.error) {
            const error = context.error as Error
            message = error.message
          }
          userAnalyticsClient.packsBuyPackError(
            context.partner,
            context.packId || 'unknown',
            message
          )
        },
      ],
      on: { RETRY: 'retryTxs' },
    },
  },
})

export default modalMachine
