import { useLocation, useNavigate } from "react-router-dom";
import IconsTradesError from "../Icons/IconsTradesError";
import { Blockchain } from "ns-types";

interface Props {
  roomChain?: Blockchain;
  errors: string[];
}

export default function RoomErrors({ roomChain, errors }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const createErrorMsg = (err: string) => {
    console.log(err);
    let txt = "";

    switch (err) {
      case "not_enough_funds":
        txt =
          "You don't have enough funds to sign the trade. Please add more funds and try again";
        break;
      case "sc_error":
        txt =
          "There was an error. Please use the feedback button to report the error.";
        break;
      case "cannot_escrow_nft":
        txt = "One of the Items cannot be escrowed.";

      case "no_valid_nfts":
        txt =
          "You have no eligible Items for NeoSwap. Please acquire more Items and try again.";
        break;

      case "no_nfts":
        txt =
          "Your wallet doesn't contain any Items. Consider buying Items and try again. (users will receive normal trades as well.";
        break;
      case "no_balance":
        txt =
          "Your wallet has insufficient balance. Please add more funds and try again.";
        break;

      case "Not enough orders":
        txt =
          "There is not enough sell orders to construct a pack. Please try again using a different payment token.";
        break;
      case "no_bundle_found":
        txt =
          "There is not enough sell orders to construct a pack. Please try again using a different payment token.";
        break;

      case "solver_timeout":
        txt =
          "There was an error. Please use the feedback button to report the error then try again.";
        break;
      case "no_trade_found":
        txt =
          "We couldn't find a trade for you. Please try adding more funds and Items, then try again.";
        break;
      case "nft_not_listed":
        txt = "Sorry, this Item is not listed for sale, please try a different Item and make sure it's listed for sale.";
        break;
      case "permission_denied":
        txt = "You don't have the correct permissions to access this page.";
        break;
      case "no_valid_nfts":
        if (roomChain === "solana") {
          txt =
            "Make sure your Items are with floor from .05 to 10 SOL, and volume above 10 SOL.";
        }
        if (roomChain === "stacks") {
          txt =
            "Make sure your Items are with floor from 5 to 700 STX, and volume above 2000 STX.";
        }
        break;
      default:
        txt = "You don't have the correct permissions to access this page.";
        break;
    }
    return <p className="text-white text-xl font-normal">{txt}</p>;
  };

  return (
    <div className="flex flex-col items-center gap-16 mt-32">
      <div>
        <IconsTradesError />
      </div>
      <div className="text-center">
        {errors.map((error) => {
          return createErrorMsg(error);
        })}
      </div>

      <button
        className="w-64 h-16 px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
        onClick={() =>
          navigate(
            location.pathname.includes("/ai-trades")
              ? "/star-atlas"
              : "/pay-with-neoswap"
          )
        }
      >
        Back to Homepage
      </button>
    </div>
  );
}
