import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Image,
  ModalFooter,
  Text,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { AppConfig, UserSession, showConnect } from "@stacks/connect";

import {
  createContext,
  useContext,
  useReducer,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { GetAddressOptions, getAddress } from "sats-connect";
import { getAddressInfo } from "bitcoin-address-validation";
import { useAppContext } from "./appContext";

import leatherLogo from '../assets/Leather-logo-off-white.svg';
import { error } from "console";

declare global {
  interface Window {
    btc?: any;
    HiroWalletProvider?: any;
    unisat?: any;
  }
}

const appConfig = new AppConfig();
const userSession = new UserSession({ appConfig });

export type BtcAddress = {
  wallet: string;
  payment: {
    address: string | null;
    publicKey: string | null;
  };
  ordinals: {
    address: string | null;
    publicKey: string | null;
  };
};

type State = {
  showAlert: boolean;
  isConnecting: boolean;
  showWalletModal: boolean;
  wallet?: string;
  addresses?: BtcAddress;
  detected: string[];
};

// Define the context shape
interface BtcContextProps extends State {
  updateData: (newData: Partial<State>) => void;
  connect: () => Promise<BtcAddress>;
  signOut: () => void;
}

// Create the context
const BtcContext = createContext<BtcContextProps | undefined>(undefined);

// Create a custom hook to access the context
export const useBtcContext = () => {
  const context = useContext(BtcContext);
  if (!context) {
    throw new Error("useBtcContext must be used within a MyContextProvider");
  }
  return context;
};

const initialState = {
  showAlert: false,
  isConnecting: false,
  showWalletModal: false,
  wallet: undefined,
  addresses: undefined,
  detected: [],
};

enum ActionType {
  UPDATE,
  RESET,
}

type Action =
  | { type: ActionType.UPDATE; payload: any }
  | { type: ActionType.RESET; payload?: any };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE:
      return { ...state, ...action.payload };
    case ActionType.RESET:
      return {} as State;
    default:
      return state;
  }
};

const unisatInitialState = {
  wallet: "unisat",
  ordinals: { address: null, publicKey: null },
  payment: { address: null, publicKey: null },
};

const UnisatModal = ({
  updateData,
  isOpen,
  onClose,
}: {
  updateData: (data: Partial<State>) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const unisat = (window as any).unisat;
  const cancelRef = useRef(null);
  const [error, setError] = useState<string | undefined>();
  const [step, setStep] = useState("ordinals");

  const [walletInfo, setWalletInfo] = useState<BtcAddress>(unisatInitialState);

  const handleTaprootAddress = async () => {
    const result = await unisat.requestAccounts();
    const address = result[0];
    const addrInfo = getAddressInfo(address);
    if (addrInfo.type !== "p2tr") {
      return setError(
        `The address entered appears incorrect. You are trying to add a ${addrInfo.type} address. Please adjust your address type to p2tr in the Unisat Wallet extension.`
      );
    }
    const publicKey = await unisat.getPublicKey();

    setWalletInfo({
      ...walletInfo,
      ordinals: {
        address,
        publicKey,
      },
    });
    setStep("payment");
    setError(undefined);
  };

  const handlePaymentAddress = async () => {
    const result = await unisat.requestAccounts();
    const address = result[0];
    const addrInfo = getAddressInfo(address);
    if (addrInfo.type !== "p2wpkh") {
      return setError(
        `The address entered appears incorrect. You are trying to add a ${addrInfo.type} address. Please adjust your address type to p2wpkh in the Unisat Wallet extension.`
      );
    }
    const publicKey = await unisat.getPublicKey();

    const addresses = {
      ...walletInfo,
      payment: {
        address,
        publicKey,
      },
    } as unknown as BtcAddress;

    setWalletInfo(addresses);
    updateData({
      addresses,
    });
    onClose();
  };

  const returnTitle = () => {
    if (step === "ordinals") {
      return "Connect Your Ordinals Wallet";
    }
    if (step === "payment") {
      return "Connect Your Bitcoin Payment Wallet";
    }
  };

  const returnBody = () => {
    if (step === "ordinals") {
      return (
        <Flex direction="column">
          <Text>
            To get started, please follow these steps to select the Taproot
            address in UNISAT Wallet:
          </Text>
          <OrderedList marginY="4">
            <ListItem>
              Check that you have the Taproot address selected, if you do skip
              the next steps.
            </ListItem>
            <ListItem>
              If not, click on "Settings" to switch to the Taproot compatible
              address.
            </ListItem>
            <ListItem>Click on "Address Type".</ListItem>
            <ListItem>Select "Taproot".</ListItem>
          </OrderedList>
          <Image src="/images/unisatOrdinals.jpg" />
        </Flex>
      );
    }
    if (step === "payment") {
      return (
        <Flex direction="column">
          <Text>
            To get started, please follow these steps to select the Bitcoin
            payment address in UNISAT Wallet:
          </Text>
          <OrderedList marginY="4">
            <ListItem>
              Check that you have the Native Segwit address selected, if you do
              skip the next steps.
            </ListItem>
            <ListItem>
              If not, click on "Settings" to switch to the Bitcoin payment
              address.
            </ListItem>
            <ListItem>Click on "Address Type".</ListItem>
            <ListItem>Select "Native Segwit".</ListItem>
          </OrderedList>
          <Image src="/images/unisatPayments.jpg" />
        </Flex>
      );
    }
  };

  console.log(walletInfo);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
    // isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{returnTitle()}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {returnBody()}
          {error && <Text color="red">{error}</Text>}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={
              step === "ordinals" ? handleTaprootAddress : handlePaymentAddress
            }
          >
            Connect Wallet
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

// Create the provider component
const BtcContextProvider = ({ children }: { children?: ReactElement }) => {
  // const cancelRef = useRef(null);
  const { signOut } = useAppContext();
  const {
    isOpen: unisatOpen,
    onClose: handleUnisatClose,
    onOpen: handleUnisatOpen,
  } = useDisclosure();
  const [state, dispatch] = useReducer(reducer, initialState, (initState) => {
    const detected = [];
    if (window.btc) {
      detected.push("hiro");
    }
    if (window.BitcoinProvider) {
      detected.push("xverse");
    }

    if (window.unisat) {
      detected.push("unisat");
    }

    return {
      ...initState,
      detected,
      wallet: detected.length === 1 ? detected[0] : null,
    };
  });

  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  });
  const self = selfRef.current;
  const handleAccountsChanged = (_accounts: string[]) => {
    console.log("ACCOUNTS CHANGED", _accounts);
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    // if (_accounts.length > 0) {
    //   setAccounts(_accounts);
    //   // setConnected(true);

    //   setAddress(_accounts[0]);

    //   getBasicInfo();
    // } else {
    //   setConnected(false);
    // }
  };

  // const handleNetworkChanged = (network: string) => {
  //   setNetwork(network);
  //   getBasicInfo();
  // };

  useEffect(() => {
    async function checkUnisat() {
      let unisat = (window as any).unisat;

      for (let i = 1; i < 10 && !unisat; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 100 * i));
        unisat = (window as any).unisat;
      }

      // if (unisat) {
      //   setUnisatInstalled(true);
      // } else if (!unisat) return;

      unisat?.getAccounts().then((accounts: string[]) => {
        handleAccountsChanged(accounts);
      }).catch((error: any) => console.error("Unisat: failed to get accounts", error));

      unisat?.on("accountsChanged", handleAccountsChanged);
      // unisat.on("networkChanged", handleNetworkChanged);

      return () => {
        unisat?.removeListener("accountsChanged", handleAccountsChanged);
        // unisat.removeListener("networkChanged", handleNetworkChanged);
      };
    }

    checkUnisat().then();
  }, []);

  const updateData = (newData: Partial<State>) => {
    dispatch({ type: ActionType.UPDATE, payload: newData });
  };

  const xVerseLogin = async (): Promise<BtcAddress> => {
    return await new Promise((res, rej) => {
      const getAddressOptions = {
        payload: {
          purposes: ["ordinals", "payment"],
          message: "Address for receiving Ordinals and payments",
          network: {
            type: "Mainnet",
          },
        },
        onFinish: (response: any) => {
          const addresses: any = { wallet: "xverse" };
          response.addresses.forEach((address: any) => {
            if (address.purpose === "ordinals") {
              addresses["ordinals"] = address;
            }
            if (address.purpose === "payment") {
              addresses["payment"] = address;
            }
          });

          res(addresses);
        },
        onCancel: () => {
          rej(new Error("User Cancelled Auth Flow"));
        },
      };
      getAddress(getAddressOptions as GetAddressOptions);
      console.log("getAddressOptions Here", getAddressOptions);
    });
  };

  const hiroLogin = async (): Promise<BtcAddress> => {
    return new Promise((res, rej) => {
      showConnect({
        userSession,
        // network: StacksMainnet,
        appDetails: {
          name: "NeoSwap",
          icon: "https://neoswap.party/favicon.ico",
        },
        onFinish: async () => {
          const resHiro = await window.btc?.request("getAddresses");
          const retVal: any = {
            wallet: "hiro",
          };
          resHiro.result.addresses.forEach(
            ({ address, type, publicKey }: any) => {
              if (type === "p2tr") {
                retVal["ordinals"] = {
                  address,
                  publicKey,
                };
              }

              if (type === "p2wpkh") {
                retVal["payment"] = {
                  address,
                  publicKey,
                };
              }
            }
          );
          res(retVal);
        },
        onCancel: () => {
          rej(new Error("User Cancelled Auth Flow"));
        },
      });
    });
  };

  const connect = async (wallet?: string): Promise<BtcAddress | void> => {
    try {
      let connectRes;
      if (!wallet && !state.wallet) {
        updateData({ showWalletModal: true });
        return;
      }
      switch (wallet || state.wallet) {
        case "xverse":
          connectRes = await xVerseLogin();
          console.log(connectRes);
          break;
        case "hiro":
          connectRes = await hiroLogin();
          console.log(connectRes);
          break;
        case "unisat":
          handleUnisatOpen();
          break;
        default:
          throw new Error("No wallet selected");
      }
      updateData({ isConnecting: false, addresses: connectRes });
      return connectRes;
    } catch (e) {
      updateData({ isConnecting: false, wallet: undefined });
    }
  };

  useEffect(() => {
    if (!state.isConnecting || !state.wallet) return;
    connect();
  }, [state.isConnecting, state.wallet]);

  const handleSignOut = async () => {
    await signOut();
    dispatch({ type: ActionType.RESET });
  };

  const contextValue: BtcContextProps = {
    ...state,
    updateData,
    connect,
    signOut: handleSignOut,
  };

  const handleChooseWallet = (wallet: string) => {
    updateData({ wallet, showWalletModal: false });
    connect(wallet);
  };

  return (
    <BtcContext.Provider value={contextValue}>
      {/* <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          updateData({ showAlert: false });
        }}
        isOpen={state.showAlert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Please Choose Wallet</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            It appears that you have multiple Bitcoin Wallet extensions
            installed. Please, only install one and reload the page to try and
            sign in again.
          </AlertDialogBody>
          <AlertDialogFooter></AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog> */}
      {unisatOpen && (
        <UnisatModal
          updateData={updateData}
          isOpen={unisatOpen}
          onClose={handleUnisatClose}
        />
      )}
      <Modal
        isOpen={state.showWalletModal}
        onClose={() =>
          updateData({ showWalletModal: false, isConnecting: false })
        }
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Your Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4}>
              <Text textAlign="center" fontSize="sm">
                For a seamless experience, avoid simultaneous use of Hiro and
                Xverse wallets as it can disrupt transactions. Disconnect from
                NeoSwap and disable one wallet. Ensure you're using the latest
                XVerse or Hiro wallet for Bitcoin Smart Auctions, and disconnect
                and reconnect your wallet post update. Choose a wallet to get
                started.
              </Text>
              <Button
                width="100%"
                backgroundColor="#000"
                isDisabled={!state.detected.includes("xverse")}
                onClick={() => handleChooseWallet("xverse")}
              >
                <Image
                  height="50%"
                  src="https://assets.website-files.com/624b08d53d7ac60ccfc11d8d/645d01e85e0969992e9e4caa_Full_Logo.webp"
                />
              </Button>
              <Button
                width="100%"
                backgroundColor="#000"
                isDisabled={!state.detected.includes("hiro")}
                onClick={() => handleChooseWallet("hiro")}
              >
                <Image height="50%" src={leatherLogo} />
              </Button>
              <Button width="100%" onClick={() => handleChooseWallet("unisat")}>
                UniSat
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
      {children}
    </BtcContext.Provider>
  );
};

export default BtcContextProvider;
