import {
  Button,
  Box,
  Text,
  HStack,
  IconButton,
  Image,
  Heading,
  Spinner,
  VStack,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { FaGift } from "react-icons/fa";
import { CiUnlock } from "react-icons/ci";
import IconLock from "../../components/Icons/IconLock";
import { cancelBid, saveProgress } from "../../services/room.service";
import { useAppContext } from "../../contexts/appContext";
import SecretBidModal from "../../components/SecretBidModal";
import {
  capitalizeFirstLetter,
  createFloor,
  filterCompleted,
  getReserveLabel,
  getTimeLeft,
} from "../../utils";
import {
  BiddableNft,
  getHighestBid,
  Nft,
  Room,
  useRoom,
} from "../../contexts/roomContext";
import { BiddingCarousel } from "../../components/BiddingCarousel";
import ListedNfts from "../../components/ListedNfts";
import { createColumnHelper } from "@tanstack/react-table";
import BidCell from "../../components/BiddingDataTable/BidCell";
import ReserveCell from "../../components/Table/ReserveCell";
import ImageCell from "../../components/Table/ImageCell";
import NftCollection from "../../components/NftCollection";
import BiddingGridItem from "../../components/NftCollection/BiddingGridItem";
import IconCurveArrow from "../../components/Icons/IconCurveArrow";
import ReservePriceForm from "../../components/ReservePriceForm/ReservePriceForm";
import { FaExclamationTriangle, FaTrashAlt } from "react-icons/fa";
import IconRefresh from "../../components/Icons/IconRefresh";
import PendingImg from "../../assets/pending.jpg";
import IconTrash from "../../components/Icons/IconTrash";
import IconEye from "../../components/Icons/IconEye";
import LastRun from "../../components/LastRun";
import IconPurpleEye from "../../components/Icons/IconPurpleEye";
import BudgetCard from "../../components/BudgetCard";
import NumItemsLimit from "../../components/NumItemsLimit";
// import SmartAuctions from "../../components/SmartAuctions";
import SidebarListItem from "../../components/Sidebar/Parts/SidebarListItem";
import useNeoChain from "../../hooks/useNeoChain";
import { useChain } from "../../contexts/chainsContext";
import { Link } from "react-router-dom";
import useIsLive from "../../hooks/useIsLive";
import ContactMethodModal from "../../components/ContactMethodModal";
import useTimeLeft from "../../hooks/useTimeLeft";
import TestComponent from "../../components/TestComponent";
import TokenGated from "../../components/TokenGated";

interface Props {
  roomId: string;
  uid: string;
  isAdmin: boolean;
  markupPercent?: number;
}

const columnHelper = createColumnHelper<any>();

const AuctionStatus = (props: any) => {
  const id = props.row.original.itemId;
  const { uid } = useAppContext();
  const { highestBids, bids } = useRoom();
  const bid = bids?.[id]?.bidPrice;
  const hasBid = typeof bid === "number";
  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  return (
    <HStack>
      {isTied && <Text color="yellow">Tied</Text>}
      {isHighBidder && !isTied && <Text color="green">Highest bid</Text>}
      {!isHighBidder && highestBidAmount && hasBid && (
        <Text color="red">Outbid</Text>
      )}
    </HStack>
  );
};

const columns = [
  columnHelper.accessor("id", {
    cell: ImageCell,
    header: "",
  }),
  columnHelper.accessor("collectionName", {
    cell: (info) => {
      return info.row.original.collection.name;
    },
    header: "Collection Name",
  }),
  columnHelper.accessor("collectionFloorPrice", {
    cell: (info) => {
      return createFloor(info.row.original);
    },
    header: "Floor Price",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("collection.verified.spamScore", {
    header: "Spam Score",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("reservePrice", {
    cell: ReserveCell,
    header: "Reserve",
    meta: {
      isNumeric: true,
    },
  }),
  {
    cell: AuctionStatus,
    header: "Auction Status",
  },
  {
    header: "Set Bid Price",
    meta: {
      isNumeric: true,
    },
    cell: BidCell,
  },
];

const formatName = (name: string) => {
  // if name is longer than 10 characters, truncate it and add ellipsis
  if (name.length > 10) {
    return name.slice(0, 15) + "...";
  } else {
    return name;
  }
};

const Bids = ({ handleRemove }: { handleRemove: (id: string) => void }) => {
  const { uid } = useAppContext();
  const { bids, preview } = useRoom();
  const winning = preview?.results?.[uid!]?.get;
  const bidIds = Object.keys(bids);
  const losingIds = bidIds.filter((id) => !winning?.includes(id));
  const winningIds = bidIds.filter((id) => winning?.includes(id));
  return (
    <div>
      {bids && Object.keys(bids).length > 0 ? (
        <>
          {winningIds.length > 0 && (
            <>
              <div className="ns-status-tag  new-bg-transparent winning">
                <span />
                Winning
              </div>
              {Object.keys(bids)
                .filter((id) => winning.includes(id))
                .map((id) => {
                  return (
                    <BidItem
                      id={id}
                      key={id}
                      handleRemove={handleRemove}
                      bidAmount={bids[id].bidPrice}
                      isWinning
                    />
                  );
                })}
            </>
          )}

          {losingIds.length > 0 && (
            <>
              {" "}
              <div className="ns-status-tag  new-bg-transparent losing">
                <span />
                Not Winning
              </div>

              {winningIds.length == 0 && (
                <Alert
                  status="info"
                  className="info-alert"
                  background="linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                  borderRadius={"4px"}
                  mb={"12px"}
                >
                  <AlertIcon color={"#6C60FF"} />
                  <AlertDescription>
                    You are currently not winning any items. Consider bidding on more items or increasing your bids.
                  </AlertDescription>
                </Alert>
              )}
              {losingIds.map((id) => {
                return (
                  <BidItem
                    id={id}
                    key={id}
                    handleRemove={handleRemove}
                    bidAmount={bids[id].bidPrice}
                  />
                );
              })}
            </>
          )}
        </>
      ) : (
        <p className="no-bids-message">
          To get started, bid on some items and they will appear here.
        </p>
      )}
    </div>
  );
};

const TheListings = () => {
  const { uid } = useAppContext();
  const {
    preview,
    listings = {},
    getAvailableToList,
    availableToList,
    nftsLoading,
    ...rest
  } = useRoom();
  const listingIds = Object.keys(listings);

  const sellingIds: string[] = Object.keys(preview?.results || {}).reduce(
    (acc: string[], sellerId) => {
      if (sellerId !== uid) {
        acc.push(...preview.results[sellerId].get);
      }
      return acc;
    },
    []
  );
  const userSellingIds = listingIds.filter((id: string) =>
    sellingIds.includes(id)
  );
  const userUnsoldIds = listingIds.filter(
    (id: string) => !sellingIds.includes(id)
  );

  useEffect(() => {
    getAvailableToList();
  }, []);

  const getNftItem = (id: string, allowEditRemove?: boolean) => {
    const nft = availableToList?.find((ele) => ele.itemId == id);

    const decimals = nft
      ? nft?.collection?.floorPrice?.allPrices[0]?.decimals
      : 0;
    const abbr = nft?.collection?.floorPrice?.allPrices[0]?.symbol;
    if (!nft && !nftsLoading) return;
    return (
      <Skeleton isLoaded={!!nft} w={"100%"} h={!!nft ? "auto" : "40px"}>
        <SidebarListItem
          key={id}
          nft={nft}
          decimals={decimals}
          abbr={abbr}
          // handleRemove={handleRemove}
          handleRemove={() => { }}
          allowEditRemove={allowEditRemove}
          id={id}
        />
      </Skeleton>
    );
  };

  const timeLeft = useTimeLeft();
  const finalMinute = timeLeft < 60;

  return (
    <Flex direction={"column"} gap={"7px"}>
      <Alert
        status="info"
        className="info-alert"
        backgroundColor={finalMinute ? "#e34619" : ""}
        background={
          !finalMinute
            ? "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
            : ""
        }
        borderRadius={"4px"}
      >
        <AlertIcon color={"#6C60FF"} />
        <AlertDescription>
          {finalMinute
            ? "Please place your final bids before the timer runs out. An additional 60 seconds will be added to the auction for each new high bid."
            : "The solver will predict results every 60 seconds."}
        </AlertDescription>
      </Alert>
      <div className="update-status">
        <LastRun align="left" />
      </div>
      {listings && Object.keys(listings).length > 0 ? (
        <>
          {userSellingIds?.length > 0 && (
            <>
              <div className="ns-status-tag  new-bg-transparent winning">
                <span />
                Selling
              </div>
              {userSellingIds.map((id) => {
                return getNftItem(id, true);
              })}
            </>
          )}

          {userUnsoldIds?.length > 0 && (
            <>
              {" "}
              <div className="ns-status-tag  new-bg-transparent losing">
                <span />
                Not Selling
              </div>
              {userUnsoldIds.map((id) => {
                return getNftItem(id, true);
              })}
            </>
          )}
        </>
      ) : (
        <p className="no-bids-message">
          To get started, bid on some items and they will appear here.
        </p>
      )}
    </Flex>
  );
};

const BidItem = ({
  id,
  handleRemove,
  bidAmount,
  isWinning,
  type,
}: {
  id: string;
  handleRemove: (id: string) => void;
  bidAmount: number;
  isWinning?: boolean;
  type?: "bid" | "winloss";
}) => {
  const { uid } = useAppContext();
  const {
    availableForBid,
    setModalId,
    decimals,
    abbr,
    truncate,
    room,
    highestBids,
    isAdmin,
    isSilentAuction,
    canList,
  } = useRoom();

  const nft = availableForBid?.find((nft) => nft.itemId === id);
  const reservePrice = nft?.reservePrice?.value;

  const [isEditingPrice, setIsEditingPrice] = useState(false);

  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const isSecretReserve = room.info.secretReserve;
  const isSeller = isSilentAuction && canList;

  return (
    <div className="bid-item">
      <Image
        className="image pointer"
        minWidth="37px"
        boxSize="37px"
        objectFit="cover"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
        onClick={() => setModalId(nft?.itemId)}
      />
      <div className="details-and-input-holder">
        <span className="bid-amount">
          <h3 onClick={() => setModalId(nft?.itemId)} className="pointer">
            {nft && formatName(nft.name)}
          </h3>
          <div className="itemsep" />
          <div className="reserve-price-holder">
            <p>Bid Amount</p>
            {/* <p
              className="bold"
              style={{
                borderRadius: "5px",
                background: isSecretReserve
                  ? reservePrice === 0
                    ? "#6c60ff"
                    : isReserveMet
                    ? "#40cd56"
                    : "white"
                  : "inherit",
                color: isSecretReserve
                  ? reservePrice === 0
                    ? "#fff"
                    : isReserveMet
                    ? "#ffffff"
                    : "#0c416a"
                  : "inherit",
                padding: "1px 5px",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                width: "fit-content",
                paddingRight: "10px",
              }}
            >
              {isSecretReserve ? (
                reservePrice === 0 ? (
                  <FaGift />
                ) : isReserveMet ? (
                  <CiUnlock />
                ) : (
                  <IconLock />
                )
              ) : null}
              {isSecretReserve
                ? isReserveMet
                  ? "Met"
                  : "Not Met"
                : getReserveLabel(bidAmount, decimals, abbr, truncate)}
            </p> */}
          </div>
        </span>
        <div className="reserve-form-holder">
          {!isAdmin && (
            <ReservePriceForm
              nfts={availableForBid}
              biddingId={id}
              isEditingPrice={isEditingPrice}
              setIsEditingPrice={setIsEditingPrice}
            />
          )}
        </div>
      </div>
      <div className="row-button-holder">
        <IconButton
          className="remove-icon icon-button"
          onClick={() => handleRemove(id)}
          aria-label="Remove"
          icon={<IconTrash />}
        />
      </div>
    </div>
  );
};

const PassedItem = ({ id }: { id: string }) => {
  const { availableForBid, removePass, highestBids, room } = useRoom();
  const nft = availableForBid?.find((nft) => nft.itemId === id);
  const reservePrice = nft?.reservePrice?.value;
  const { uid } = useAppContext();

  const { truncate } = useRoom();

  const { isHighBidder, highestBidAmount, isTied } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const isSecretReserve = room.info.secretReserve;

  const handleRemovePassed = (id: string) => {
    removePass(id);
  };

  return (
    <div className="bid-item">
      <Image
        className="image"
        minWidth="37px"
        boxSize="37px"
        objectFit="cover"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
      />

      <h3>{nft && formatName(nft.name)}</h3>

      <div className="itemsep" />

      {/* <div className="reserve-price-holder">
        <p>Reserve</p>
        {nft && (
          <p className="reserve-label">
            {isSecretReserve
              ? isReserveMet
                ? "Met"
                : "Not Met"
              : getReserveLabel(
                  nft?.reservePrice?.value || 0,
                  nft?.reservePrice?.decimals || 0,
                  nft?.reservePrice?.symbol || "",
                  truncate
                )}
          </p>
        )}
      </div> */}

      <div style={{ marginLeft: "auto" }}>
        <Button
          leftIcon={<IconEye />}
          variant="outline"
          className="restore-btn"
          onClick={() => handleRemovePassed(id)}
        >
          Restore
        </Button>
        <div></div>
      </div>
    </div>
  );
};

const getChain = (roomChain: string, uid?: string) => {
  if (!uid) {
    return roomChain.toLowerCase();
  }
  return uid?.split("-")[0];
};

const SwitchButton = ({ roomChain }: { roomChain: string }) => {
  const { setChain, chain } = useChain();
  const chainFns = useNeoChain() as any;
  const { signIn } = chainFns[roomChain.toLowerCase() || "not_found"];
  const { signOut } = chainFns[chain?.name?.toLowerCase() || "not_found"];

  const handleSwitch = async () => {
    await signOut();
    setChain(roomChain);
    await signIn();
  };
  return (
    <Button
      bgColor="#6c60ff"
      color="white"
      size="sm"
      onClick={handleSwitch}
    >{`Join on ${capitalizeFirstLetter(roomChain)}`}</Button>
  );
};

export default function Bidding({ roomId, uid }: Props) {
  const isMountedRef = useRef(false);
  const {
    units,
    abbr,
    bids,
    budgets,
    room,
    availableForBid,
    getAvailableForBid,
    biddingDiff,
    preferredIds,
    isSilentAuction,
    availableForBidLoading,
    canList,
    passed,
    listings,
    isAdmin,
    showContactModal,
    setShowContactModal,
    preview,
  } = useRoom();

  // console.log(bids, preview)
  const winning = preview?.results?.[uid]?.get;
  const bidIds = bids ? Object.keys(bids) : null;
  const winningIds = bidIds && winning ? bidIds.filter((id) => winning?.includes(id)) : [];


  const isTicketGated = room?.info?.isTicketGated ? true : false;


  const hasJoined = room?.users && room?.users.includes(uid || "") ? true : false;

  const timeLeft = useTimeLeft();
  const isSeller = isSilentAuction && canList;

  const [currentTab, setCurrentTab] = useState(isSeller ? "listed" : "bids");
  const [secretBidModalOpen, setSecretBidModalOpen] = useState(false);

  useEffect(() => {
    if (isSeller || isAdmin) {
      setCurrentTab("listed");
    } else {
      setCurrentTab("bids");
    }
  }, [isSeller, isAdmin]);

  let IDsListed = listings ? Object.keys(listings) : [];
  if (isAdmin) {
    IDsListed = availableForBid?.map((nft) => nft.itemId);
  }
  // const IDsListed = listings ? Object.keys(listings) : [];

  const chain = getChain(room?.blockchain?.name, uid);

  const calulateIsLive = () => {
    return room?.info?.startAt < Date.now() / 1000;
  };

  const [isLive, setisLive] = useState(calulateIsLive());

  useEffect(() => {
    const timer = setTimeout(() => {
      setisLive(calulateIsLive());
    }, 1000);

    return () => clearTimeout(timer);
  });
  const budget = budgets?.budget;

  useEffect(() => {
    if (isMountedRef.current) return;
    getAvailableForBid();
    isMountedRef.current = true;
  }, [uid]);

  const handleRemove = (id: string) => {
    cancelBid(roomId, uid, id);
  };

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  const preferredNfts = useMemo(() => {
    return preferredIds
      ?.filter((id: string) => !passed.includes(id))
      .map((id: string) => availableForBid?.find((ele) => ele.itemId === id)!);
  }, [preferredIds, availableForBid, passed]);

  const availableNfts = useMemo(() => {
    return availableForBid?.filter((ele) => !passed.includes(ele.itemId));
  }, [canList, preferredIds, availableForBid, passed]);



  const additionalSortOptions = [
    {
      label: "Newest First",
      value: {
        key: "hasViewed",
        direction: "asc",
      },
    },
    {
      label: "Newest Last",
      value: {
        key: "hasViewed",
        direction: "desc",
      },
    }
  ];

  let data = availableNfts;

  if (isSilentAuction) {
    data = preferredNfts;
  }

  // const data = isSilentAuction ? preferredNfts : availableNfts;

  const roomChain = room?.blockchain?.name?.toLowerCase();
  const isCorrectChain = uid && uid?.includes(roomChain);
  let errorMsg;

  if (!uid || (!isCorrectChain && roomChain && chain !== roomChain)) {
    errorMsg = `This room is on ${capitalizeFirstLetter(
      room?.blockchain?.name
    )}. Please switch your wallet to ${capitalizeFirstLetter(
      roomChain
    )} to join.`;
  }

  const handleAcknowledgement = () => {
    sessionStorage.setItem(`${roomId}acknowledged`, "true");
    setSecretBidModalOpen(false);
  };

  const hasAcknowledged = sessionStorage.getItem(`${roomId}acknowledged`);

  const finalMinute = timeLeft < 60;

  useEffect(() => {
    if (finalMinute && !hasAcknowledged) {
      setSecretBidModalOpen(true);
    }
  }, [finalMinute, hasAcknowledged]);

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  return (
    <>
      <Box pb="150px">
        {hasJoined &&
          (
            <div>
              <div className="listing-area" style={{ marginBottom: "20px" }}>
                {isSilentAuction ? (
                  <div className="room-section" style={{ flexGrow: 1 }}></div>
                ) : canList ? (
                  <ListedNfts />
                ) : null}
              </div>
              <h2 className="room-title">
                <p className="room-title-header">
                  Set Your Limits
                </p>

              </h2>
              <div className="listing-area  budget-area shadow new-bg-transparent" style={{ marginBottom: "20px" }}>
                <BudgetCard
                  roomId={roomId}
                  uid={uid}
                  budget={budget}
                  units={units!}
                  abbr={abbr!}
                />
                {/* <Alert
              status="info"
              className="info-alert"
              background="linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
              borderRadius={"4px"}
              mb={"12px"}
            >
              <AlertIcon color={"#6C60FF"} />
              <AlertDescription>
                Please set a spending limit to increase your chances of
                getting a trade.
              </AlertDescription>
            </Alert> */}
                <NumItemsLimit />
              </div>


              <h2 className="room-title" style={{ marginBottom: "10px" }}>
                <p className="room-title-header">
                  Bid on Every Item You Like
                </p>
                <p className="room-title-text">
                  {" "}
                  Your limits protect you, and you don’t need to have the highest bid to win
                </p>
                {biddingDiff && !availableForBidLoading && !isSeller && (
                  <>
                    <Button
                      onClick={getAvailableForBid}
                      className="small-link"
                      variant="link"
                      ml="10px"
                      size="sm"
                      backgroundClip="#6C60FF"
                    >
                      Get Latest
                    </Button>
                    <span className="check-bids-message">
                      <IconCurveArrow className="icon-arrow" />
                      <FaExclamationTriangle className="icon" />
                      New Items added
                    </span>
                  </>
                )}
              </h2>
              {!isSilentAuction && preferredIds?.length > 0 && (
                <Box className="featured-items" mb={8}>
                  <h2>
                    <span>Featured {isOrdinals ? "Ordinals" : "items"}</span>
                  </h2>
                  {biddingDiff && (
                    <div className="refresh-button" onClick={getAvailableForBid}>
                      <IconRefresh />
                    </div>
                  )}
                  <div className="grid-view">
                    <BiddingCarousel data={preferredNfts} />
                  </div>
                </Box>
              )}
              <div className="bidding-wrapper">
                {availableForBidLoading ? (
                  <div className="nft-loader">
                    <Spinner color="#ed6cd8" />
                    <p className="loadingtext">
                      {" "}
                      Loading {isOrdinals ? "Ordinals" : "items"}, please wait...{" "}
                    </p>
                  </div>
                ) : (
                  <NftCollection
                    GridComponent={({ nft }: { nft: Nft | BiddableNft }) => (
                      <BiddingGridItem
                        nft={nft as BiddableNft}
                        isSeller={isSilentAuction && canList}
                      />
                    )}
                    showGating
                    data={data || []}
                    additionalSortOptions={additionalSortOptions}
                    columns={columns}
                    indexOptions={["name", "collection.name"]}
                    groupBy="collection.name"
                    emptyMessage={`Sorry no ${isOrdinals ? "Ordinals" : "items"
                      } to bid on yet!`}
                    isLoading={availableForBidLoading}
                    collectionType="bidding"
                    defaultSort={{
                      key: "hasViewed",
                      direction: "asc",
                    }}
                    preferredNfts={preferredNfts}
                    hasJoined={hasJoined}
                  />
                )}
                {errorMsg && (
                  <div className="manage-bids">
                    <div className="tab-content">
                      {errorMsg && (
                        <VStack
                          justifyContent="flex-start"
                          alignItems="center"
                          className="box"
                          textAlign="center"
                          backgroundColor="rgba(0,0,0,.1)"
                          m="20px"
                          p="60px"
                        >
                          <Text fontSize="16px" mb="25px">
                            {errorMsg}
                          </Text>
                          <SwitchButton roomChain={roomChain} />
                        </VStack>
                      )}
                    </div>
                  </div>
                )}
                {uid && !errorMsg && (
                  <div className="manage-bids ns-sidebar ">
                    <div className="tabs-wrapper">
                      <div className="tabs-buttons">
                        {(!isSeller && !isAdmin) ||
                          room?.info?.roomType === "smartAuction" ? (
                          <div
                            onClick={() => setCurrentTab("bids")}
                            className={`tab ${currentTab === "bids" ? "active" : ""}`}
                          >
                            {finalMinute
                              ? "Place your final bids"
                              : "Currently bidding"}
                          </div>
                        ) : null}
                        {((!isSeller && !isAdmin) ||
                          room?.info?.roomType === "smartAuction") &&
                          isLive ? (
                          <div
                            onClick={() => setCurrentTab("passed")}
                            className={`tab ${currentTab === "passed" ? "active" : ""
                              }`}
                          >
                            Hidden {isOrdinals ? "Ordinals" : "items"}
                          </div>
                        ) : null}
                        {(isAdmin ||
                          isSeller ||
                          room?.info?.roomType === "smartAuction") && (
                            <div
                              onClick={() => setCurrentTab("listed")}
                              className={`tab ${currentTab === "listed" ? "active" : ""
                                }`}
                            >
                              Listed {isOrdinals ? "Ordinals" : "items"}
                            </div>
                          )}
                      </div>
                      {!isSeller && currentTab === "bids" && (
                        <div className="tab-content new-bg-transparent">

                          <Alert
                            status="info"
                            className="info-alert"
                            backgroundColor={finalMinute ? "#e34619" : ""}
                            background={
                              !finalMinute
                                ? "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                                : ""
                            }
                            borderRadius={"4px"}
                          >
                            <AlertIcon color={"#6C60FF"} />
                            <AlertDescription>
                              {finalMinute
                                ? "Please place your final bids before the timer runs out. An additional 60 seconds will be added to the auction for each new high bid."
                                : "The solver will predict results every 60 seconds."}
                            </AlertDescription>
                          </Alert>

                          <div className="update-status">
                            <LastRun />
                          </div>
                          <Flex alignItems="center" gap="2px" mb="10px">
                            <Heading size="sm">Predicted Results</Heading>
                            <a // eslint-disable-line
                              data-tooltip-id="bidding-tooltip"
                              data-tooltip-content="Results show what would happen at last run of solver"
                              data-tooltip-place="right"
                            >
                              <IconPurpleEye />
                            </a>
                            <Tooltip
                              id="bidding-tooltip"
                              className="custom-tooltip"
                              classNameArrow="custom-tooltip-arrow"
                            />
                          </Flex>
                          {bids ? (
                            <Bids handleRemove={handleRemove} />
                          ) : (
                            <p className="no-bids-message">
                              To get started, bid on some items and they will appear
                              here.
                            </p>
                          )}
                        </div>
                      )}

                      {currentTab === "passed" && (
                        <div className="tab-content">
                          {passed.length > 0 ? (
                            <>
                              {passed.map((id) => (
                                <PassedItem id={id} key={id} />
                              ))}
                            </>
                          ) : (
                            <p className="no-bids-message">
                              There are no passed items.
                            </p>
                          )}
                        </div>
                      )}

                      {currentTab === "listed" && (
                        <div className="tab-content new-bg-transparent">
                          {canList && isSilentAuction && (
                            <button
                              onClick={goBack}
                              className="small-link"
                              style={{ color: "#6C60FF", marginBottom: "20px" }}
                            >
                              Edit Listings
                            </button>
                          )}
                          <TheListings />
                          {/* {IDsListed?.length ? (
                        IDsListed.map((id) => getNftItem(id, isSeller))
                      ) : (
                        <p className="no-bids-message">
                          There are no listed items.
                        </p>
                      )} */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

            </div>
          )


        }


        <SecretBidModal
          open={secretBidModalOpen}
          handleClose={() => handleAcknowledgement()}
          timeLeft={timeLeft || 0}
        />
      </Box>

      <ContactMethodModal
        open={showContactModal ?? false}
        handleClose={() => setShowContactModal(false)}
      />
    </>
  );
}
