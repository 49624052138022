import { useCallback, useEffect, useState } from "react";
import { Room, useRoom } from "../contexts/roomContext";

export default function useTimeLeft() {
  const { room, timeDiff } = useRoom();

  const calculateTimeLeft = useCallback(() => {
    const startAt = room?.info?.startAt;
    const partyDuration = room?.info?.partyDuration || 0;
    const end = startAt + partyDuration;
    let now = new Date().getTime() / 1000;
    now = now + timeDiff;
    return end - now;
  }, [room, timeDiff]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return timeLeft;
}
