import { useToast } from "@chakra-ui/react";
import { useEffect, useState, useContext } from "react";
import {
  getMessageToSign,
  verifyMessageToGetURL,
} from "../../components/blockchains/utils";
import { useAppContext } from "../../contexts/appContext";
import { PolygonSC } from "../../components/PolygonSmartContract/polygon";
import { useDynamicContext, getNetwork } from "@dynamic-labs/sdk-react";
import { WalletContext } from "../../contexts/walletContext";
import { useChain } from "../../contexts/chainsContext";
import { useUA } from "../../contexts/userTracking";

const chainName: string = "polygon";
const chainId: number = process.env.REACT_APP_POLYGON_CHAIN_ID
  ? parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID)
  : 137;

export default function usePolygon() {
  const toast = useToast();
  const { chain } = useChain();
  const currentChain = chain?.name?.toLowerCase() ?? "";
  const {
    getEncodedNonce,
    getToken,
    signInWithToken,
    signOut,
    signingIn,
    updateState,
    signedIn,
  } = useAppContext();
  const { gaSignWallet } = useUA();

  const { setShowAuthFlow, primaryWallet, handleLogOut } = useDynamicContext();
  const {
    isConnected,
    setIsConnected,
    isAuthenticating,
    setIsAuthenticating,
    connectionState,
    setConnectionState,
  } = useContext(WalletContext);

  //   console.log("Pass from App context:", signedIn)

  const returnNewError = (message?: string) => {
    if (message === undefined || message === null) {
      message = "Something went wrong. Please try again.";
    }
    toast({
      title: "Error",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const returnNewSuccess = (message: string) => {
    toast({
      title: "Success",
      description: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const onConnectionStateChange = async () => {
    if (currentChain != chainName) return;

    if (connectionState === "initiateAuth" && signedIn === false) {
      if (typeof primaryWallet?.address === "string") {
        const authenticate = await authenticateUser();
        setConnectionState("connected");
        setIsConnected(true);
        return "connected";
      } else {
        setConnectionState("aborted");
        returnNewError("Wallet connection aborted");
        return "aborted";
      }
    } else if (
      typeof primaryWallet != undefined &&
      connectionState == "notConnected"
    ) {
      setConnectionState("connected");
      setIsConnected(true);
      return;
    } else if (connectionState === "initiateAuth" && signedIn === true) {
      setConnectionState("connected");
      setIsConnected(true);
      return;
    }
  };

  // useEffect(() => {
  //   if (currentChain != chainName) return;
  //   if (signedIn === true && primaryWallet === null) {
  //     console.log("Window.ethreum object: ", window.ethereum)
  //     setShowAuthFlow(true);
  //   }
  // }, []);

  useEffect(() => {
    // console.log(window.ethereum);
    // console.log("Primary wallet", primaryWallet);
    if (currentChain != chainName) return;
    onConnectionStateChange();
    if (signingIn === true) {
      updateState({ signingIn: false });
    }
  }, [connectionState, primaryWallet]);

  const getCurrentWalletNetwork = async (): Promise<number> => {
    const walletConnector = primaryWallet?.connector;

    if (!walletConnector) {
      throw new Error("walletConnector is undefined");
    }

    const network = await getNetwork(walletConnector);

    // Check if network is not undefined
    if (typeof network === "undefined") {
      throw new Error("Network is undefined");
    }
    return Number(network);
  };

  const setCorrectNetwork = async (networkId: number) => {
    const walletConnector = primaryWallet?.connector;
    if (!walletConnector) {
      throw new Error("walletConnector is undefined");
    }

    const currentNetwork = await getCurrentWalletNetwork();

    if (currentNetwork != networkId) {
      walletConnector.switchNetwork({ networkChainId: networkId });
    }
  };

  const handlePolygonConnect = async () => {
    const address = primaryWallet?.address;
    if (typeof address === "string") {
      setCorrectNetwork(chainId);
      updateState({ signingIn: false });
      const bal = await getBalance();
      console.log(bal);
    }
    // console.log("Window.ethreum object: ", window.ethereum);
    setShowAuthFlow(true);
  };

  const signIn = async () => {
    try {
      updateState({ signingIn: true });
      await handlePolygonConnect();
    } catch (e: any) {
      console.log(e);
      returnNewError(e.reason);
    }
  };

  const authenticateUser = async () => {
    try {
      if (isAuthenticating) return;
      setIsAuthenticating(true);
      if (!primaryWallet) throw new Error("Wallet not connected");
      const address: string = primaryWallet.address;
      const nonce = await getEncodedNonce(address, chainName);
      if (!nonce) throw new Error("No nonce");
      const nonceString = nonce as string;
      //   const nonceUint8Array = nonce as Uint8Array;
      //   const textDecoder = new TextDecoder();
      //   const nonceString = textDecoder.decode(nonceUint8Array);
      //   console.log(nonceString)

      const signature = await primaryWallet.connector.signMessage(nonceString);

      // const signer = provider?.getSigner(account);
      // const signature = await signer?.signMessage(nonce as Uint8Array);
      if (!signature) throw new Error("No signature");
      const token = await getToken(address, chainName, signature);
      if (!token) throw new Error("No token");
      await signInWithToken(token);
      gaSignWallet();
      setIsAuthenticating(false);
    } catch (e: any) {
      console.log(e);
      returnNewError(e.reason);
    } finally {
      updateState({ signingIn: false });
    }
  };

  const handleSignout = async () => {
    handleLogOut();
    await signOut();
    setIsConnected(false);
    setConnectionState("notConnected");
  };

  const getBalance = async (): Promise<number> => {
    if (primaryWallet) {
      const value = await primaryWallet.connector.getBalance();

      // Check if value is not undefined
      if (typeof value === "undefined") {
        throw new Error("Balance value is undefined");
      }

      // Convert the string value to a number
      const balance = parseInt(value);
      return balance;
    } else {
      return 0; // Default return value when primaryWallet is falsy
    }
  };

  const getStatementUrl = async () => {
    await handlePolygonConnect();
    if (!primaryWallet?.address) throw new Error("No public key");
    const { nonce } = await getMessageToSign(chainName!, primaryWallet.address);
    if (!nonce) throw new Error("No nonce");
    const signature = await primaryWallet.connector.signMessage(nonce);
    if (!signature) throw new Error("No signature");
    const { statementUrl } = await verifyMessageToGetURL(
      chainName!,
      primaryWallet.address,
      signature
    );
    return statementUrl;
  };

  // const getStatementUrl = async () => {
  //   await handleConnect();
  //   if (!account) throw new Error("No public key");
  //   const { nonce } = await getMessageToSign(chainName!, account);
  //   if (!nonce) throw new Error("No nonce");
  //   const encoded = new TextEncoder().encode(nonce);
  //   const signer = provider?.getSigner(account);
  //   const signature = await signer?.signMessage(encoded);
  //   if (!signature) throw new Error("No signature");
  //   const { statementUrl } = await verifyMessageToGetURL(
  //     chainName!,
  //     account,
  //     signature
  //   );
  //   return statementUrl;
  // };

  const deposit = async (txConfig: any) => {
    if (!primaryWallet) {
      await handlePolygonConnect();
    }
    let trxs: any[] = [];

    if (txConfig.type === "setApproval") {
      const { userItemsToApprove, routerSc } = txConfig;
      for (const mintAddress of userItemsToApprove) {
        const txs = await PolygonSC.approveUserNFT({ routerSc, mintAddress });
        trxs.push(txs);
      }
    }
    if (txConfig.type === "deposit") {
      const { hasNft, userItemsToDepositData, polygonSc, routerSc } = txConfig;
      const txs = await PolygonSC.deposit({
        hasNft,
        userItemsToDepositData,
        polygonSc,
        routerSc,
      });
      trxs.push(...txs);
    }
    if (txConfig.type === "create-offer") {
      const txs = await PolygonSC.createOffer(txConfig);
      trxs.push(txs);
    }

    return { hashes: trxs };
  };

  const cancel = async (txConfig: any) => {
    await handlePolygonConnect();
    let trxs: any[] = [];

    txConfig = {
      ordinalsSc: "0xe6b701aa5ed3947b977eb4b852b3cbc5a7c4cb9d",
      offerId: 21,
    };
    const txs = await PolygonSC.cancelOffer(txConfig);
    trxs.push(txs);

    return trxs;
  };

  return {
    connect: handlePolygonConnect,
    signIn,
    signOut: handleSignout,
    deposit,
    cancel,
    connected: isConnected,
    connecting: signingIn,
    getStatementUrl,
  };
}
