import { ethers } from "ethers";
// import { AbiItem } from "web3-utils";

export default [
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "uint256",
						"name": "bidNumber",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "address",
						"name": "Bidder",
						"type": "address"
					}
				],
				"name": "BidCanceled",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "bytes32",
						"name": "bidHash",
						"type": "bytes32"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "bidder",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "CancelationRequested",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "bytes32",
						"name": "bidHash",
						"type": "bytes32"
					},
					{
						"indexed": true,
						"internalType": "string",
						"name": "ordinalsId",
						"type": "string"
					},
					{
						"indexed": false,
						"internalType": "string",
						"name": "bitcoinAddress",
						"type": "string"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "bidder",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "NewBid",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "previousOwner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "OwnershipTransferred",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "account",
						"type": "address"
					}
				],
				"name": "Paused",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "uint256",
						"name": "bidNumber",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "address",
						"name": "Seller",
						"type": "address"
					}
				],
				"name": "SaleApproved",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "uint256",
						"name": "bidNumber",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "address",
						"name": "Seller",
						"type": "address"
					}
				],
				"name": "SuccessfulSaleWithdraw",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "account",
						"type": "address"
					}
				],
				"name": "Unpaused",
				"type": "event"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_contractAddress",
						"type": "address"
					}
				],
				"name": "addNewApprovedERC20",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_executor",
						"type": "address"
					}
				],
				"name": "addNewApprovedExecutor",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_ercCode",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "_contractAddress",
						"type": "address"
					}
				],
				"name": "amendApprovedERC20Sold",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_bidNumber",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "_sellerAddress",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "_ordinalsId",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "_bitcoinAddress",
						"type": "string"
					}
				],
				"name": "approveSale",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ercCode",
						"type": "uint256"
					}
				],
				"name": "approvedERC20List",
				"outputs": [
					{
						"internalType": "address",
						"name": "erc20Contract",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "approvedExecutor",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bidNumber",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "bidNumber",
						"type": "uint256"
					}
				],
				"name": "bids",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "bidHash",
						"type": "bytes32"
					},
					{
						"internalType": "address",
						"name": "bidder",
						"type": "address"
					},
					{
						"internalType": "uint96",
						"name": "amount",
						"type": "uint96"
					},
					{
						"internalType": "address",
						"name": "seller",
						"type": "address"
					},
					{
						"internalType": "uint64",
						"name": "cancelationTimestamp",
						"type": "uint64"
					},
					{
						"internalType": "uint16",
						"name": "ercCode",
						"type": "uint16"
					},
					{
						"internalType": "enum OrdinalsBids.TokenType",
						"name": "tokenType",
						"type": "uint8"
					},
					{
						"internalType": "enum OrdinalsBids.BidStatus",
						"name": "status",
						"type": "uint8"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "cancelationDelay",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "string",
						"name": "_ordinalsId",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "_bitcoinAddress",
						"type": "string"
					},
					{
						"internalType": "address",
						"name": "_sellerAddress",
						"type": "address"
					}
				],
				"name": "createNewBid",
				"outputs": [],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "string",
						"name": "_ordinalsId",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "_bitcoinAddress",
						"type": "string"
					},
					{
						"internalType": "address",
						"name": "_sellerAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "_erc20Code",
						"type": "uint256"
					}
				],
				"name": "createNewERC20Bid",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ercCodeCount",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_bidNumber",
						"type": "uint256"
					}
				],
				"name": "onCancelationWithdrawal",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_bidNumber",
						"type": "uint256"
					}
				],
				"name": "onSaleWithdrawal",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "owner",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "pause",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "paused",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_ercCode",
						"type": "uint256"
					}
				],
				"name": "removeApprovedERC20",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_executor",
						"type": "address"
					}
				],
				"name": "removeApprovedExecutor",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "renounceOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_bidNumber",
						"type": "uint256"
					}
				],
				"name": "requestCancelation",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "sendGas",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_gasAmountInWei",
						"type": "uint256"
					}
				],
				"name": "setSendGas",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_seconds",
						"type": "uint256"
					}
				],
				"name": "setWithdrawalTimeframe",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "transferOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "unpause",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "string",
						"name": "_ordinalsId",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "_bitcoinAddress",
						"type": "string"
					}
				],
				"name": "verifyHash",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "bidHash",
						"type": "bytes32"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
	] as ethers.ContractInterface;