import { BuyDownBannerContent } from "../contexts/cmsContext";

const createHeaders = () => {
  const headers = new Headers()
  headers.set('Authorization', `Bearer ${process.env.REACT_APP_CMS_AUTH_TOKEN}`)
  return headers
}


export const getBuyDown = async (): Promise<BuyDownBannerContent> => {
  const res = await fetch(`${process.env.REACT_APP_CMS_BASE_URL}/top-banner`, {
    headers: createHeaders(),
  });
  const json = await res.json();
  if (json?.data) {
    return json.data
  }
  throw new Error('Error fetching from CMS')
};


export const getAiTradeNftsShowcases = async () => {
  const res = await fetch(`${process.env.REACT_APP_CMS_BASE_URL}/ai-trade-nfts-showcases`, {
    headers: createHeaders(),
  });
  const json = await res.json();
  if (json?.data) {
    return json.data
  }
  throw new Error('Error fetching from CMS')
}

export const getAiTradeRoomInfo = async (slug: string = 'default') => {
  const params = new URLSearchParams({
    'filters[slug][$eq]': slug,
    "populate": '*',
  })
  const res = await fetch(`${process.env.REACT_APP_CMS_BASE_URL}/trade-rooms?${params.toString()}`, {
    headers: createHeaders(),
  });
  const json = await res.json();
  if (json?.data) {
    return json.data
  }
  throw new Error('Error fetching from CMS')
}

export const getNftShowcaseCarousels = async (slug: string = 'default') => {
  const params = new URLSearchParams({
    'filters[slug][$eq]': slug,
    "populate": '*',
    'pagination[pageSize]': '25'
  })
  const res = await fetch(`${process.env.REACT_APP_CMS_BASE_URL}/nft-showcase-carousels?${params.toString()}`, {
    headers: createHeaders(),
  });
  const json = await res.json();
  if (json?.data) {
    return json.data
  }
  throw new Error('Error fetching from CMS')
}


