import { Button, Flex, Text } from "@chakra-ui/react";
import IconPurpleEye from "../Icons/IconPurpleEye";
import { Tooltip } from "react-tooltip";
import { roundValue, formatButtonLabel } from "../../utils";

interface Props {
  error: string;
  highestBidAmount: number;
  incrementToken: number;
  incrementPct: number;
  decimals: number;
  truncate: number;
  abbr: string;
  max: number;
  setBudgetAndBid: (value: number) => Promise<void>;
  handleBid: (val: number) => void;
}

export default function BiddingError({
  error,
  highestBidAmount,
  incrementToken,
  incrementPct,
  decimals,
  truncate,
  abbr,
  max,
  setBudgetAndBid,
  handleBid,
}: Props) {
  const higherValue =
    highestBidAmount +
    Math.max(incrementToken, highestBidAmount * incrementPct);

  return (
    <Flex
      backgroundColor="rgb(102 100 100 / 13%)"
      padding="10px"
      ml="20px"
      borderRadius={6}
      w="full"
      direction={"column"}
      justifyContent={"center"}
    >
      <Text size="lg">{error}</Text>
      {error.includes(
        "Your bid does not meet the minimum increment for a new highest bid"
      ) ? (
        <Flex gap="15px" mt="10px">
        <Button
          isDisabled={highestBidAmount === -Infinity}
          onClick={() => {
            handleBid(higherValue / 10 ** decimals);
          }}
          variant="outline"
          borderColor="#6C60FF"
          borderRadius={6}
        >
          <Flex direction={"column"} textAlign={"left"}>
            <Text fontWeight={"bold"}>Bid Higher</Text>
            <Text fontSize={".8em"}>
              {formatButtonLabel(
                roundValue(higherValue / 10 ** decimals, truncate, 'down'),
                abbr
              )}
            </Text>
          </Flex>
          <a // eslint-disable-line
            data-tooltip-id="bid-helpers-tooltip"
            data-tooltip-content="Will bid the next available higher bid"
            data-tooltip-place="bottom"
            style={{ marginLeft: "8px" }}
          >
            <IconPurpleEye />
          </a>
        </Button>
        </Flex>
      ) : null}
      {error.includes("Your bid exceeds") && (
        <Flex gap="15px" mt="10px">
          <Button
            onClick={async () => {
              setBudgetAndBid(max);
            }}
            variant="outline"
            borderColor="#6C60FF"
            borderRadius={6}
            isDisabled={max === 0 || !max}
          >
            <Flex direction={"column"} textAlign={"left"}>
              <Text fontWeight={"bold"}>Available</Text>
              <Text fontSize={".8em"}>
                {roundValue(max / 10 ** decimals, truncate, 'down')} {abbr})
              </Text>
            </Flex>
            <a // eslint-disable-line
              data-tooltip-id="match-highest-tooltip"
              data-tooltip-content="This will set the bid based on your spending limit."
              data-tooltip-place="right"
              style={{ marginLeft: "8px" }}
            >
              <IconPurpleEye />
            </a>
          </Button>
        </Flex>
      )}

      {error.includes("If you would like to bid") && (
        <Flex gap="15px" mt="10px">
          <Button
            onClick={async () => {
              setBudgetAndBid(higherValue);
            }}
            variant="outline"
            borderColor="#6C60FF"
            borderRadius={6}
            isDisabled={max === 0 || !max}
          >
            <Flex direction={"column"} textAlign={"left"}>
              <Text fontWeight={"bold"}>Higher</Text>
              <Text fontSize={".8em"}>
                {formatButtonLabel(
                  roundValue(higherValue / 10 ** decimals, truncate, 'down'),
                  abbr
                )}
              </Text>
            </Flex>
            <a // eslint-disable-line
              data-tooltip-id="match-highest-tooltip"
              data-tooltip-content="This will set the bid based on your spending limit."
              data-tooltip-place="right"
              style={{ marginLeft: "8px" }}
            >
              <IconPurpleEye />
            </a>
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
