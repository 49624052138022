import ImageWrapper from "../ImageWrapper";
import { checkImage } from "../../utils/index";

interface Props {
  image: string;
  name: string;
  onClick: () => void;
}

export default function GroupCard({
  image,
  name = "Unnamed Collection",
  onClick,
}: Props) {
  return (
    <div className="grouped-item" onClick={onClick}>
      <div className="grouped-image">
        <ImageWrapper url={checkImage(image)} collectionName={name} />
      </div>
      <h5>{name}</h5>
    </div>
  );
}
