import { Heading } from "@chakra-ui/react";
import LandingFooter from "./LandingFooter";

export default function Terms() {
  return (
    <div className="landing-page">
      <div className="content">
        <div className="page-header">
          {/* <div className="logo-wrapper">
            <Link to={"/"}>
              <img src={Logo} alt="NeoSwap" className="logo" />
            </Link>
            <span className="beta-tag">beta</span>
          </div> */}
          <Heading as="h1" fontSize="5xl">
            Terms of service
          </Heading>
        </div>
        <div className="terms">
          <p>Last Updated: Feb 21, 2023</p>
          <h3>1. Introduction</h3>
          <p>
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY AFFECT YOUR
            LEGAL RIGHTS. THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION
            AND CLASS ACTION WAIVER. EXCEPT WHERE PROHIBITED BY LAW, BY ENTERING
            INTO THESE TERMS YOU EXPRESSLY AGREE THAT DISPUTES BETWEEN YOU AND
            NEOSWAP WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU
            HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
            CLASS-⁠WIDE ARBITRATION.
          </p>
          <p>
            Welcome to NeoSwap, owned and operated by NeoSwap Inc. (“NeoSwap”,
            “we”, or “us”). NeoSwap is a multi-item, multi-party neo-barter
            platform for non-fungible tokens (“Items”) (collectively, the
            “Services”). By visiting neoswap.xyz, neoswap.party, or using the
            Services, including without limitation participating in Swap
            Parties, you accept and agree to be bound by these Terms of Service
            (“Terms”) as well as our Privacy Policy, which forms a binding
            agreement between you and NeoSwap. For purposes of these Terms,
            “user”, “you”, and “your” means you as the user of the Services. If
            you use the Services on behalf of a company or other entity then
            “you” includes you and that entity, and you represent and warrant
            that (a) you are an authorized representative of the entity with the
            authority to bind the entity to these Terms, and (b) you agree to
            these Terms on the entity’s behalf.
          </p>
          <p>
            These Terms apply to all visitors, users and others who wish to
            access or use the Services. If you disagree with any part of the
            Terms then you do not have permission to access the Services. To the
            extent that we use certain third party platforms to deliver or
            perform the Services, you agree to their Terms of Service and
            Privacy Policy, which are incorporated to these Terms by reference.
          </p>
          <p>
            These Terms may be amended or modified at any time by posting the
            revised Terms on neoswap.ai and/or providing a copy to you via
            email. Revised Terms shall be effective as of the time they are
            posted, but will not apply retroactively. Your continued use of the
            Services after the posting of revised Terms constitutes your
            acceptance of the revised Terms. If you do not agree with amendments
            or modifications to these Terms, your sole and exclusive remedy is
            to terminate your use of the Services and close your account.
          </p>

          <h3>2. License to Use the Services</h3>
          <p>
            Subject to your compliance with these Terms, NeoSwap grants you a
            limited, non-transferable, non-exclusive, revocable license to
            access and use the Services for your own personal, non-commercial
            purposes. This license includes the right to participate in Swap
            Parties (defined below) available on the Services. This license is
            personal to you and may not be assigned or sublicensed to anyone
            else.
          </p>
          <p>
            Except as expressly permitted in writing by an authorized
            representative of NeoSwap, you will not record, reproduce,
            redistribute, sell, create derivative works from, decompile, reverse
            engineer, or disassemble the Services, nor will you take any
            measures to interfere with or damage the Services. All rights not
            expressly granted by NeoSwap in these Terms are reserved.
          </p>

          <h3>3. Age Requirement</h3>
          <p>
            You must be at least 18 years old, or the age of legal majority in
            your jurisdiction of residence, to create a subscriber account
            and/or use the Services.
          </p>

          <h3>4. User Profiles</h3>
          <p>
            In order to use the Services, you will need a blockchain address and
            a third-party wallet. Your user profile will be associated with your
            blockchain address, but you must also provide a username and email
            address to create a user profile. Your user profile will display the
            Items and/or digital tokens held by that blockchain address. By using
            your wallet in connection with the Service, you agree that you are
            using that wallet under the terms and conditions of the applicable
            provider of the wallet. Wallets are not operated by, maintained by,
            or affiliated with NeoSwap. By using the Services, you also agree to
            receive email communications from us, including updates and
            notifications regarding trades you are involved in.
          </p>
          <p>
            You are solely responsible for keeping your wallet secure and you
            should never share your wallet credentials or seed phrase with
            anyone. If you discover an issue related to your wallet, please
            contact your wallet provider. Likewise, you are solely responsible
            for your user profile and any associated wallet and we are not
            liable for any acts or omissions by you in connection with your user
            profile or as a result of your user profile or wallet being
            compromised. You agree to immediately notify us if you discover or
            otherwise suspect any security issues related to the Services or
            your user profile.
          </p>
          <p>
            You also represent and warrant that you will comply with all
            applicable laws (e.g., local, state, federal and other laws) when
            using the Services. Without limiting the foregoing, by using the
            Services, you represent and warrant that: (a) you are not located in
            a country that is subject to a U.S. Government embargo; and (b) you
            have not been identified as a Specially Designated National or
            placed on any U.S. Government list of prohibited, sanctioned, or
            restricted parties. If you access or use the Services outside the
            United States, you are solely responsible for ensuring that your
            access and use of the Services in such country, territory or
            jurisdiction does not violate any applicable laws.
          </p>

          <h3>5. Events</h3>
          <p>
            In the NeoSwap events, Users may list Items they would like to trade
            or sell and select Items they would like to trade for or purchase.
            Users then set a budget and NeoSwap finds a multi-way trade that
            benefits all users involved. Users can then choose to accept the
            trade and sign a smart-contract to execute the trade or reject the
            trade and cancel the smart-contract.
          </p>

          <h3>6. Fees</h3>
          <p>
            Events may be subject to fees that NeoSwap collects in order to
            deliver the Services. These fees are subject to change at the sole
            discretion of NeoSwap. NeoSwap does not collect or pay taxes on
            behalf of Users who participate in Events. Each User shall be
            responsible for all taxes imposed on its income or property. In
            addition, interactions with the blockchain network may also result
            in transaction fees or Gas Fees (as defined below) imposed by the
            blockchain network, which are also solely your responsibility. “Gas
            fees” mean the fees that fund the network of computers that run the
            decentralized blockchain network, meaning that you will need to pay
            a Gas Fee for each transaction that occurs via the blockchain
            network.
          </p>

          <h3>7. General Prohibitions and NeoSwap’s Enforcement Rights</h3>
          <p>You agree not to do any of the following:</p>
          <ol type="a">
            <li>
              Use, display, mirror or frame the Services or any individual
              element within the Services, NeoSwap’s name, any NeoSwap
              trademark, logo or other proprietary information, or the layout
              and design of any page or form contained on a page, without
              NeoSwap’s express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Services;
            </li>
            <li>
              Attempt to probe, scan or test the vulnerability of any NeoSwap
              system or network or breach any security or authentication
              measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise
              circumvent any technological measure implemented by NeoSwap to
              protect the Services or Content;
            </li>
            <li>
              Attempt to access, scrape or search the Services, including
              through the use of any engine, software, tool, agent, device or
              mechanism (including spiders, robots, crawlers, data mining tools,
              plugins, add-ons or the like), other than the software and/or
              search agents provided by NeoSwap or other generally available
              third-party web browsers;
            </li>
            <li>
              Use the Services, or any portion thereof, for any commercial
              purpose or for the benefit of any third party or in any manner not
              permitted by these Terms or permitted expressly in writing by
              NeoSwap;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
              Encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ol>

          <p>
            Although we’re not obligated to monitor access to or use of the
            Services, we have the right to do so for the purpose of operating
            the Services, to ensure compliance with these Terms, to comply with
            applicable law or other legal requirements and to maintain the
            integrity and reputation of the Services and NeoSwap’s website. We
            have the right to investigate violations of these Terms or conduct
            that affects the Services. We may also consult and cooperate with
            law enforcement authorities to prosecute users who violate the law.
          </p>

          <h3>8. Indemnification</h3>
          <p>
            You agree to indemnify, defend, and hold harmless NeoSwap and its
            directors, officers, employees, and agents, from and against all
            claims, damages, losses and costs that arise from or relate to your
            activities on the Services or your violation of these Terms.
          </p>

          <h3>9. No Warranties</h3>
          <p>
            NeoSwap reserves the right to modify the Services, including, but
            not limited to updating, adding to, enhancing, modifying, removing
            or altering any features of the Services, at any time, in its sole
            discretion. You are responsible for providing your own access (e.g.,
            computer, mobile device, Internet connection, etc.) to the Services.
          </p>
          <p>
            The Services are provided on an “AS IS” and “AS AVAILABLE” basis.
            You therefore use the Services at your own risk. NeoSwap expressly
            disclaims any and all warranties of any kind, whether express or
            implied, including, but not limited to implied warranties of
            merchantability, fitness for a particular purpose, non-infringement,
            quiet enjoyment and any other warranty that might arise under any
            law. Without limiting the foregoing, NeoSwap makes no
            representations or warranties:
          </p>

          <ul>
            <li>That the Services will be uninterrupted or error-free;</li>
            <li>Concerning any Content;</li>
            <li>
              That the Services will meet your personal or professional needs;
            </li>
            <li>
              That NeoSwap will continue to support any particular feature of
              the Services.
            </li>
          </ul>

          <p>
            TO THE EXTENT ANY DISCLAIMER OR LIMITATION OF LIABILITY DOES NOT
            APPLY, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL APPLICABLE
            EXPRESS, IMPLIED, AND STATUTORY WARRANTIES WILL BE LIMITED IN
            DURATION TO A PERIOD OF 30 DAYS AFTER THE DATE ON WHICH YOU FIRST
            USED THE SERVICES, AND NO WARRANTIES SHALL APPLY AFTER SUCH PERIOD.
          </p>
          <p>
            Your access and use of the Services may be interrupted from time to
            time for any of several reasons, including, without limitation, the
            malfunction of equipment, periodic updating, maintenance, or repair
            of the Services or other actions that NeoSwap, in its sole
            discretion, may elect to take.
          </p>

          <h3>10. Taxes</h3>
          <p>
            The tax treatment of Item transactions is uncertain, and it is your
            responsibility to determine what taxes, if any, arise from
            transactions using the Services under this Agreement. Users are
            solely responsible for reporting and paying any applicable taxes
            arising from transactions using the Services, and acknowledge that
            NeoSwap does not provide legal or tax advice related to the tax
            treatment of these transactions. In addition, NeoSwap does provide
            information as to the value of Items traded using the Services.
            NeoSwap may provide access to limited trade information and/or
            transfers made by or to you with respect to your participation in a
            Swap Party or auction. However, it is your sole responsibility to
            determine the value of these transactions, how and whether these
            transactions must be reported to the appropriate taxing authority,
            and your potential tax liability, if any. You should conduct your
            own due diligence and consult your own tax advisors before making
            any decisions with respect to your use of the Services.
          </p>

          <h3>11. Limitation of Liability</h3>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW: (I) NEOSWAP SHALL NOT BE
            LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES
            FOR LOSS OF PROFITS, ECONOMIC OR PURE ECONOMIC LOSSES, GOODWILL,
            USE, DATA, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE,
            INABILITY TO USE THE SERVICES OR OTHER INTANGIBLE LOSSES, EVEN IF A
            LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS
            ESSENTIAL PURPOSE; AND (II) NEOSWAP’S TOTAL LIABILITY TO YOU FOR ALL
            CLAIMS, IN THE AGGREGATE, WILL NOT EXCEED THE AMOUNT ACTUALLY PAID
            BY YOU TO NEOSWAP OVER THE 12 MONTHS PRECEDING THE DATE YOUR FIRST
            CLAIM(S) AROSE. IF YOU LIVE IN A JURISDICTION THAT DOES NOT ALLOW
            THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, THE ABOVE LIMITATION DOES NOT APPLY TO YOU. TO
            THE EXTENT THAT ONE OR ANY ASPECT OF NEOSWAP’S LIMITATIONS SET OUT
            ABOVE DOES NOT APPLY, ALL REMAINING ASPECTS SURVIVE. THE EXCLUSIONS
            AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
            OF THE BASIS OF THE BARGAIN BETWEEN NEOSWAP AND YOU.
          </p>

          <h3>12. Intellectual Property</h3>
          <p>
            You acknowledge that the Services contain software and other content
            that is protected by copyrights, patents, trademarks, trade secrets
            or other proprietary rights, and that these rights are valid and
            protected in all forms, media and technologies existing now or
            hereafter developed. All website content is copyrighted individually
            and/or as a collective work under the U.S. copyright laws and all
            applicable international copyright (or equivalent) laws in all
            jurisdictions; further, as between you and NeoSwap, NeoSwap owns a
            copyright in the selection, coordination, arrangement and
            enhancement of all website content in the Services. Subject to your
            compliance with these Terms, and solely for so long as you are
            permitted by us to access and use the Services, NeoSwap hereby
            grants you a limited, revocable, non-exclusive, non-transferable
            right and license to access and use the content made available on
            the Services for your personal, non-commercial use of the Services
            and for no other purpose whatsoever. Unless otherwise specified,
            copying or modifying any content or using content for any purpose
            other than your personal, non-commercial use of the Services,
            including use of any such content on any other website or networked
            computer environment, is strictly prohibited.
          </p>

          <p>
            The NeoSwap name, logos and affiliated properties, designs and marks
            are the exclusive property of NeoSwap Inc., whether registered or
            unregistered, and may not be used in connection with any product or
            service that is not ours, or in any manner that is likely to cause
            confusion as to our endorsement, affiliation or sponsorship of any
            person, product or service. Nothing contained in the Services should
            be construed as granting, by implication, estoppel or otherwise, any
            license or right to use any of our trade names, trademarks or other
            intellectual property without our express prior written consent.
          </p>

          <p>
            Any other trademarks appearing on the Services are trademarks of
            their respective owners. Our partners or service providers may also
            have additional proprietary rights in the content that they make
            available through the Services. All rights not expressly granted in
            these Terms are reserved.
          </p>

          <h3>13. Termination</h3>
          <p>
            NeoSwap reserves the right to monitor use of the Services to
            determine compliance with these Terms, as well as the right to edit,
            refuse to post, or remove any information or materials, in whole or
            in part, at its sole discretion. NeoSwap reserves the right to
            terminate your access to any or all of the Services at any time
            without notice for any reason whatsoever.
          </p>

          <h3>14. Disputes</h3>
          <p>
            If a dispute arises from or relates to this contract or the breach
            thereof, you and NeoSwap agree to first attempt to resolve such
            dispute informally through direct discussions and good faith
            negotiations. The parties further agree that any unresolved
            controversy or claim arising out of or relating to this contract, or
            breach thereof, shall be settled by arbitration administered by the
            American Arbitration Association in accordance with its Commercial
            Arbitration Rules and judgment on the award rendered by the
            arbitrator(s) may be entered in any court having jurisdiction
            thereof. Neither party may initiate arbitration under this Agreement
            for at least sixty (60) days after written notice of a dispute has
            been delivered to NeoSwap pursuant to Section 19 below.
          </p>
          <p>
            The arbitration shall be governed by the laws of the Commonwealth of
            Virginia and the U.S. Federal Arbitration Act. Hearings will take
            place pursuant to the standard procedures of the Commercial
            Arbitration Rules that contemplate in person hearings. Any
            arbitration hearings will take place in the city or county where you
            live, unless we both agree to a different location. The parties
            agree that the arbitrator shall have exclusive authority to decide
            all issues relating to the interpretation, applicability,
            enforceability and scope of this arbitration agreement. Payment of
            all filing, administration and arbitrator fees will be governed by
            the AAA Rules, and NeoSwap will not seek to recover the
            administration and arbitrator fees it is responsible for paying,
            unless the arbitrator finds your demand for arbitration frivolous.
            If we prevail in arbitration, we’ll pay all of our attorneys’ fees
            and costs and won’t seek to recover them from you. If you prevail in
            arbitration you will be entitled to an award of attorneys’ fees and
            expenses to the extent provided under applicable law. Except as may
            be required by law, neither a party nor an arbitrator may disclose
            the existence, content, or results of any arbitration hereunder
            without the prior written consent of both parties.
          </p>

          <p>
            As limited exceptions to this section, either of the parties may
            seek injunctive or other equitable relief from a court to prevent
            (or enjoin) the infringement or misappropriation of our intellectual
            property rights. This arbitration provision shall survive
            termination of these Terms.
          </p>

          <h3>15. Class Action Waiver</h3>
          <p>
            THE PARTIES HERETO AGREE THAT BY ENTERING INTO THIS AGREEMENT, EACH
            PARTY WAIVES ITS RIGHT TO PARTICIPATE IN A CLASS ACTION, PRIVATE
            ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE ACTION AGAINST THE
            OTHER IN A COURT OR IN ARBITRATION. THE PARTIES FURTHER AGREE THAT
            EACH MAY BRING DISPUTES AGAINST EACH OTHER ONLY IN THEIR INDIVIDUAL
            CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
            CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both parties
            agree otherwise, arbitration claims may not be joined or
            consolidated in the arbitration proceeding. In no event shall the
            arbitrator have authority to preside over any form of representative
            or class proceeding or to issue any relief that applies to any
            person or entity other than users individually. If this Class Action
            Waiver is found to be invalid or unenforceable in whole or in part,
            then the entirety of this Binding Arbitration section (except for
            this sentence) shall be null and void with respect to such
            proceeding, subject to the right to appeal the limitation or
            invalidation of the Class Action Waiver.
          </p>

          <h3>16. Governing Law and Jurisdiction</h3>
          <p>
            These Terms shall be governed by the laws of the Commonwealth of
            Virginia, without regard to principles of conflicts of law.
          </p>

          <h3>17. Relationship</h3>
          <p>
            Nothing in these Terms will be construed as creating a relationship
            of partnership, joint venture, agency, or employment between the
            parties. NeoSwap shall not be responsible for the acts or omissions
            of a user, and the user shall not represent NeoSwap, neither does it
            have any power or authority to speak for, represent, bind, or assume
            any obligation on behalf of NeoSwap.
          </p>

          <h3>18. Interpretation; Severability; Waiver; Remedies</h3>
          <p>
            Headings are for convenience only and shall not be used to construe
            these Terms. If any term of these Terms is found invalid or
            unenforceable by any court of competent jurisdiction, that term will
            be severed from these Terms. No failure or delay by NeoSwap in
            exercising any right hereunder will waive any further exercise of
            that right. The waiver of any such right or provision will be
            effective only if in writing and signed by a duly authorized
            representative of NeoSwap. NeoSwap’s rights and remedies hereunder
            are cumulative and not exclusive.
          </p>

          <h3>19. Successors; Assignment; No Third Party Beneficiaries</h3>
          <p>
            These Terms are binding upon and will inure to the benefit of both
            parties and their respective successors, heirs, executors,
            administrators, personal representatives, and permitted assigns. You
            may not assign or transfer these Terms without NeoSwap’s prior
            written consent. NeoSwap may assign its rights, obligations and/or
            these Terms at any time at its sole discretion without notice to
            you.
          </p>

          <h3>20. Notices</h3>
          <p>
            You consent to receive all communications including notices,
            agreements, disclosures, or other information from NeoSwap
            electronically. NeoSwap may communicate by email or by posting to
            the Services. For support-related inquiries, you may email
            help@neoswap.ai. For all other notices to NeoSwap, write to the
            following addresses:
          </p>

          <p>
            NeoSwap Inc.
            <br />
            2505 Whispering Oaks Court
            <br />
            Midlothian, VA 23112
          </p>

          <p>
            Nothing in these Terms or otherwise limits NeoSwap’s right to object
            to subpoenas, claims, or other demands.
          </p>

          <h3>21. Entire Agreement</h3>
          <p>
            These Terms and the Privacy Policy, incorporated by reference
            herein, comprise the entire understanding and agreement between you
            and NeoSwap as to the subject matter hereof, and supersedes any and
            all prior discussions, agreements and understandings of any kind
            (including without limitation any prior versions of these Terms),
            and every nature between and among you and NeoSwap.
          </p>

          <h3>22. Force Majeure</h3>
          <p>
            Neither party shall be liable for any failure or delay in
            performance under these terms (other than for delay in the payment
            of money due and payable in accordance with these terms) for causes
            beyond the party’s reasonable control and not caused by that party’s
            fault, or negligence, including, but not limited to, “acts of God”,
            acts of government, flood, fire, civil unrest, acts of terror,
            strikes or other labour problems, computer attacks or malicious
            acts, such as attacks on or through the internet, any internet
            service provider, telecommunications or hosting facility, but in
            each case, only if and to the extent that the non-performing party
            is without fault in causing such failure or delay, and the failure
            or delay could not have been prevented by reasonable precautions and
            measures and cannot reasonably be circumvented by the non-conforming
            party through the use of alternate sources, workaround plans,
            disaster recovery, business continuity measures or other means.
            Dates by which performance obligations are scheduled to be met will
            be extended for a period of time equal to the time lost due to any
            delay so caused.
          </p>

          <p>
            NeoSwap is not registered with the U.S. Securities and Exchange
            Commission and does not offer securities services in the United
            States or to U.S. persons.
          </p>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
}
