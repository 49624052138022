import React, { useEffect, useState } from 'react';
import { PackGroup } from '../types/packs';
import { userAnalyticsClient } from '../clients/userAnalyticsClient'

export const CHATBOT_GROUP_ID = '_chatbot';
export const CRAFTING_GROUP_ID = '_crafting';
export const HOW_IT_WORKS_GROUP_ID = `_how_it_works`

const EXTRA_PACKS_GROUP_IDS = [CHATBOT_GROUP_ID, CRAFTING_GROUP_ID, HOW_IT_WORKS_GROUP_ID]

const PackGroupTabs: React.FC<{ partner: string, packGroups: PackGroup[]; onTabChange: (packGroupId: string) => void }> = ({ partner, packGroups, onTabChange }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Threshold at 1024px for mobile or tablet vs. larger screens
  const isMobileOrTablet = windowWidth <= 1024;

  const [activeTab, setActiveTab] = useState<string>(packGroups[0]?.id || '');

  const handleTabClick = (packGroupId: string) => {
    userAnalyticsClient.packsSelectPackGroup(partner, packGroupId);
    setActiveTab(packGroupId);
    onTabChange(packGroupId);
  };

  const sortedPackGroups = React.useMemo(() => {
    const chatbotGroup = packGroups.find(group => group.id === CHATBOT_GROUP_ID);
    const howItWorksGroup = packGroups.find(group => group.id === HOW_IT_WORKS_GROUP_ID);
    const otherGroups = packGroups.filter(group =>
      group.id !== CHATBOT_GROUP_ID && group.id !== HOW_IT_WORKS_GROUP_ID
    );

    return [
      ...(chatbotGroup ? [chatbotGroup] : []),
      ...otherGroups,
      ...(howItWorksGroup ? [howItWorksGroup] : [])
    ];
  }, [packGroups]);

  useEffect(() => {
    const initialActiveTab = sortedPackGroups[0]?.id || '';
    setActiveTab(initialActiveTab);
    onTabChange(initialActiveTab);
  }, [sortedPackGroups, onTabChange]);

  return (
    isMobileOrTablet ? (
      <div className="packs-pack-group-tabs">
        <select
          value={activeTab}
          onChange={(e) => handleTabClick(e.target.value)}
          className="packs-tab-dropdown"
        >
          {sortedPackGroups.map((group) => (
            <option
              key={group.id}
              value={group.id}
            >
              {group.name}
            </option>
          ))}
        </select>
      </div>) :
      (
        <div className="packs-pack-group-tabs">
          {sortedPackGroups.map((group) => {
            const isNotReady = group.packs.length === 0 && !EXTRA_PACKS_GROUP_IDS.includes(group.id)

            if (isNotReady) {
              return null
            }

            return (
              <button
                key={group.id}
                className={`packs-tab-button ${activeTab === group.id ? 'active' : ''}`}
                onClick={() => handleTabClick(group.id)}
              >
                {group.name} {isNotReady ? "(Coming soon)" : ""}
              </button>
            )
          })}
        </div>
      )

  );
};

export default PackGroupTabs;
