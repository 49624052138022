export default function IconJUP() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5096_12123)">
        <path d="M1.83841 14.0354C2.58105 15.0684 3.53365 15.9327 4.63375 16.5717C5.73385 17.2107 6.95659 17.6099 8.22177 17.7433C7.57089 16.7637 6.62469 15.8625 5.44346 15.1764C4.26224 14.4903 3.0113 14.1156 1.83841 14.0354Z" fill="url(#paint0_linear_5096_12123)" />
        <path d="M7.03073 12.4446C4.75496 11.1225 2.29182 10.7856 0.529358 11.3807C0.699502 11.9431 0.923895 12.4875 1.19937 13.0064C2.73064 12.971 4.40254 13.3869 5.95824 14.2905C7.51395 15.1941 8.70378 16.4411 9.43193 17.7892C10.0195 17.7711 10.6039 17.6963 11.1771 17.5657C10.8208 15.7401 9.30586 13.767 7.03073 12.4446Z" fill="url(#paint1_linear_5096_12123)" />
        <path d="M17.8755 7.07787C17.5861 5.90098 17.0626 4.79445 16.3361 3.82436C15.6097 2.85428 14.6951 2.04053 13.6472 1.43171C12.5992 0.822895 11.4394 0.43149 10.2368 0.280871C9.03425 0.130251 7.8137 0.223505 6.64798 0.555065C8.59543 0.793399 10.7571 1.52447 12.877 2.75605C14.997 3.98763 16.7044 5.50311 17.8755 7.07787Z" fill="url(#paint2_linear_5096_12123)" />
        <path d="M15.042 11.3941C14.0451 9.73867 12.3375 8.15341 10.234 6.9314C8.13039 5.70936 5.90796 5.01134 3.97778 4.96517C2.27968 4.92495 1.0052 5.41859 0.482062 6.31918C0.479083 6.32455 0.474912 6.32961 0.471635 6.33498C0.424565 6.50389 0.384048 6.6731 0.347107 6.84291C1.07759 6.55453 1.92396 6.39396 2.86508 6.37609C4.95791 6.33676 7.30014 7.00617 9.46147 8.2619C11.6228 9.51761 13.3659 11.2214 14.3681 13.0583C14.8174 13.8859 15.0977 14.7007 15.2088 15.4791C15.3381 15.3635 15.465 15.2441 15.5881 15.1198C15.5913 15.1142 15.5934 15.1082 15.5967 15.1023C16.1199 14.2008 15.9176 12.8494 15.042 11.3941Z" fill="url(#paint3_linear_5096_12123)" />
        <path d="M8.63353 9.68588C5.41221 7.81437 1.85244 7.52124 0.140625 8.82729C0.143987 9.23609 0.175241 9.64418 0.234169 10.0488C0.737726 9.89611 1.25671 9.8 1.78153 9.76217C3.69444 9.61824 5.80337 10.1512 7.71715 11.2636C9.63098 12.376 11.1396 13.9448 11.9624 15.6766C12.1899 16.151 12.3633 16.6495 12.4796 17.1626C12.8603 17.0134 13.2305 16.8385 13.5875 16.6392C13.8741 14.505 11.8558 11.5577 8.63353 9.68588Z" fill="url(#paint4_linear_5096_12123)" />
        <path d="M16.6989 8.62327C15.6908 6.96958 13.9682 5.37991 11.8498 4.14983C9.73132 2.91974 7.50023 2.21071 5.56379 2.1538C4.08763 2.1112 2.94423 2.46899 2.35138 3.14496C4.81304 2.72788 8.05999 3.42887 11.2072 5.25717C14.3543 7.08547 16.5726 9.55934 17.4288 11.9042C17.7223 11.0549 17.467 9.8844 16.6989 8.62327Z" fill="url(#paint5_linear_5096_12123)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_5096_12123" x1="11.9509" y1="3.78154" x2="3.80273" y2="17.7891" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <linearGradient id="paint1_linear_5096_12123" x1="11.9509" y1="3.7816" x2="3.80274" y2="17.7892" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <linearGradient id="paint2_linear_5096_12123" x1="11.951" y1="3.7815" x2="3.80275" y2="17.7891" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <linearGradient id="paint3_linear_5096_12123" x1="11.951" y1="3.78154" x2="3.80275" y2="17.7891" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <linearGradient id="paint4_linear_5096_12123" x1="11.9509" y1="3.78155" x2="3.80274" y2="17.7891" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <linearGradient id="paint5_linear_5096_12123" x1="11.9509" y1="3.78157" x2="3.80274" y2="17.7891" gradientUnits="userSpaceOnUse">
          <stop offset="0.0001" stop-color="#A4D756" />
          <stop offset="1" stop-color="#00B6E7" />
        </linearGradient>
        <clipPath id="clip0_5096_12123">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}