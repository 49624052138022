import { Helmet } from "react-helmet-async";
import { Formik, FormikProps, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Button,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  InputGroup,
  InputRightAddon,
  FormErrorMessage,
  Switch,
  Text,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { uniqBy } from "lodash";

import * as Yup from "yup";

import { DateTime } from "luxon";
import { create, getAdmin } from "../services/room.service";

import { useAppContext } from "../contexts/appContext";
import { hasError } from "../utils";
import { useChain } from "../contexts/chainsContext";
import { useUA } from "../contexts/userTracking";
import { useEffect, useState } from "react";
import { loadNfts } from "../services/nfts.service";
import { Nft } from "../contexts/roomContext";
import NeoSelect from "../components/NeoSelect";
import IconSmartAuction from "../components/Icons/IconSmartAuction";
import IconSmartSilentAuction from "../components/Icons/IconSmartSilentAuction";
import IconBitcoin from "../components/Icons/IconBitcoin";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  chain: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please select a chain!"),

  ordinalsWallets: Yup.array().when("isOrdinals", {
    is: true,
    then: Yup.array()
      .min(1, "Preferred User BTC Taproot Address is required")
      .required("Preferred User BTC Taproot Address is required"),
    otherwise: Yup.array().notRequired(),
  }),

  itemsPreference: Yup.object().when("roomType", {
    is: "smartSilentAuction",
    then: Yup.object().shape({
      preferredUsers: Yup.array()
        .min(1, "Preferred User Address is required")
        .required("Preferred User Address is required"),
    }),
    otherwise: Yup.object(),
  }),
});

type CollectionOptions = {
  label: string;
  value: string;
}[];

type FormValues = {
  durationUnits: string;
  adminId: string;
  name: string;
  roomType: string;
  description: string;
  startAt: number;
  partyDuration: number;
  markupPercent: number;
  minReservePrice: number;
  maxReservePrice: number;
  incrementPct: number;
  incrementToken: number;
  maxUsers: number;
  numNftsToList: number;
  chain: string;
  allowedCollections: CollectionOptions;
  accessPolicy: {
    policy: string;
    collections: CollectionOptions | null;
  };
  isTicketGated: boolean;
  itemsPreference: {
    preferAdmin: boolean;
    preferredItems: CollectionOptions;
    preferredUsers: CollectionOptions;
  };
  addToEvents: boolean;
  ordinalsWallets: CollectionOptions;
  secretReserve: boolean;
  isPromoted: boolean;
  token: string | null;
};

const multiplier = (durationUnits: string) => {
  switch (durationUnits) {
    case "minutes":
      return 60;
    case "hours":
      return 3600;
    case "days":
      return 86400;
    default:
      return 1;
  }
};

const TimePicker = () => {
  const { values, setValues } = useFormikContext();
  const vals = values as unknown as FormValues;
  return (
    <Select
      variant="unstyled"
      value={vals.durationUnits}
      name="durationUnits"
      onChange={(e) => {
        console.log(vals.partyDuration, e.target.value);

        const val = e.target.value;
        const prevMultiplier = multiplier(vals.durationUnits);
        const dur = vals.partyDuration / prevMultiplier;
        setValues({
          ...vals,
          durationUnits: val,
          partyDuration: dur * multiplier(e.target.value),
        });
      }}
    >
      <option value="minutes">Minutes</option>
      <option value="hours">Hours</option>
      <option value="days">Days</option>
    </Select>
  );
};

export default function CreateRoom() {
  const [collectionOptions, setCollectionOptions] = useState<string[]>([]);
  const { gaCreateParty } = useUA();
  const { chain, chains } = useChain();
  const { uid, rights } = useAppContext();
  const navigate = useNavigate();
  const roomChain = uid?.split("-")[0];
  const toast = useToast();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const cleanValues = (values: Partial<FormValues>) => {
    return {
      ...values,
      incrementPct: values.incrementPct ? values.incrementPct / 100 : 0,
      incrementToken:
        chain && values.incrementToken
          ? values.incrementToken * 10 ** chain?.decimals
          : 0,
      allowedCollections: values.allowedCollections?.map((c) => c.value),
      ordinalsWallets:
        values.ordinalsWallets?.map((c) => c.value.trim()) || null,
      itemsPreference: {
        ...values.itemsPreference,
        preferredItems:
          values.itemsPreference?.preferredItems?.map((c) => c.value.trim()) ||
          null,
        preferredUsers:
          values.itemsPreference?.preferredUsers?.map((c) => c.value.trim()) ||
          null,
      },
      minReservePrice: values.minReservePrice
        ? values.minReservePrice * 10 ** (chain?.decimals ?? 0)
        : 0,
      maxReservePrice: values.maxReservePrice
        ? values.maxReservePrice * 10 ** (chain?.decimals ?? 0)
        : 0,
      accessPolicy: {
        policy: values.accessPolicy?.policy,
        collections:
          values.accessPolicy?.collections?.map((c) => c.value) || null,
      },
    };
  };

  // @TODO - Get is admin info
  // const isAdmin = true;

  useEffect(() => {
    const getCollectionOptions = async () => {
      try {
        let { data } = (await loadNfts(uid!)) as { data: any };
        let opts = data.map((nft: Nft) => {
          return {
            value: nft.collection.collectionId,
            label: nft.collection.name,
          };
        });
        opts = uniqBy(opts, "value");
        setCollectionOptions(opts);
      } catch (e: any) {
        console.error("error fetching collection options", e);
      }
    };
    getCollectionOptions();
  }, []);

  useEffect(() => {
    const checkAdmin = async () => {
      if (uid) {
        const adminData = await getAdmin(uid);
        setIsAdmin(adminData != null);
      }
    };

    checkAdmin();
  }, [uid]);

  const initialValues = {
    adminId: "",
    name: "",
    roomType: "liveParty",
    description: "",
    startAt: Math.floor(DateTime.now().toUTC().toSeconds()),
    partyDuration: 60 * 10,
    durationUnits: "minutes",
    // listingSeconds: 60 * 10,
    // biddingSeconds: 60 * 10,
    incrementPct: (chain && chain?.incrementPct * 100) || 0,
    incrementToken:
      (chain && chain?.incrementToken / 10 ** chain?.decimals) || 0,
    markupPercent: 5,
    minReservePrice: 0,
    maxReservePrice: 1,
    maxUsers: 30,
    numNftsToList: 7,
    chain: roomChain,
    allowedCollections: [],
    accessPolicy: {
      policy: "open",
      collections: [],
    },
    itemsPreference: {
      preferAdmin: false,
      preferredItems: [],
      preferredUsers: [],
    },
    addToEvents: false,
    isPromoted: false,
    isTicketGated: false,
    isOrdinals: false,
    ordinalsWallets: [],
    secretReserve: false,
    token: "native",
  };

  // chain.incrementToken
  // chain.incrementPct

  const canUseButton = rights.hasOwnProperty("create-room");

  return (
    <>
      <Helmet>
        <title>{`NeoSwap | Create Room`}</title>
      </Helmet>
      <div id="create-room">
        <Heading className="page-title" as="h2" size="lg">
          Create a Room
        </Heading>

        <Formik
          initialValues={{
            ...initialValues,
            adminId: uid!,
            secretReserve: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values: typeof initialValues) => {
            const { chain, durationUnits, ...data } = values;

            try {
              if (!chain) throw new Error("No chain selected");
              const res = await create(cleanValues(data), chain);
              gaCreateParty(res);
              navigate(`/rooms/${res.id}`, { replace: true });
            } catch (e: any) {
              console.log("ERROR", e, data);
              toast({
                title: "Error creating the room.",
                description:
                  e?.message ||
                  "Please report an issue through the feedback button.",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          }}
          minW={{ base: "100%", lg: "500px" }}
        >
          {(props: FormikProps<typeof initialValues>) => {
            const selectedChain = chains?.find(
              (c) => c.name.toLowerCase() === props.values.chain
            );

            return (
              <form onSubmit={props.handleSubmit}>
                {isAdmin && (
                  <div className="settings-wrapper box highlight">
                    <h4>
                      Room Admin <span className="tag dark">Admin Only</span>
                    </h4>
                    <FormControl>
                      <FormLabel htmlFor="markupPercent">
                        Price Markup
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          name="markupPercent"
                          id="markupPercent"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          backgroundColor="#041b28"
                          w="full"
                          rounded="md"
                          onChange={props.handleChange}
                          value={props.values.markupPercent}
                        />
                        <InputRightAddon mt={1} children="%" />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="addToEvents"
                        name="addToEvents"
                        isChecked={props.values?.addToEvents}
                        mt={1}
                        onChange={props.handleChange}
                      >
                        Add To Events?
                      </Switch>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isPromoted"
                        name="isPromoted"
                        isChecked={props.values?.isPromoted}
                        mt={1}
                        onChange={props.handleChange}
                      >
                        Promote Event
                      </Switch>
                    </FormControl>
                  </div>
                )}

                <div className="settings-wrapper box">
                  <h4>Details</h4>
                  <FormControl>
                    <FormLabel>Select Room Type:</FormLabel>
                    <div className="type-selector">
                      <input
                        id="smartAuction"
                        type="radio"
                        name="roomType"
                        className="item-input"
                        value="smartAuction"
                        onChange={() => {
                          props.setValues({
                            ...props.values,
                            maxUsers: 30,
                            roomType: "smartAuction",
                            isOrdinals: false,
                          });
                        }}
                        required={true}
                      />
                      <label htmlFor="smartAuction" className="item">
                        <IconSmartAuction />
                        <span className="text">Smart Swap</span>
                      </label>

                      <input
                        id="smartSilentAuction"
                        type="radio"
                        name="roomType"
                        className="item-input"
                        value="smartSilentAuction"
                        onChange={() => {
                          props.setValues({
                            ...props.values,
                            maxUsers: 250,
                            roomType: "smartSilentAuction",
                            isOrdinals: false,
                          });
                        }}
                        required={true}
                      />
                      <label htmlFor="smartSilentAuction" className="item">
                        <IconSmartSilentAuction />
                        <span className="text">Smart Auction</span>
                      </label>

                      <input
                        id="ordinalsSmartAuction"
                        type="radio"
                        name="roomType"
                        className="item-input"
                        value="ordinalsSmartAuction"
                        required={true}
                        //disabled={true}
                        onChange={() => {
                          props.setValues({
                            ...props.values,
                            roomType: "smartSilentAuction",
                            isOrdinals: true,
                          });
                        }}
                      />
                      <label htmlFor="ordinalsSmartAuction" className="item">
                        <IconBitcoin />
                        <span className="text">Ordinals Smart Auction</span>
                      </label>
                    </div>
                  </FormControl>

                  <FormControl
                    isInvalid={!!hasError(props.errors, props.touched, "name")}
                  >
                    <FormLabel htmlFor="first_name">Name</FormLabel>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      mt={1}
                      w="full"
                      shadow="sm"
                      rounded="md"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.name}
                      backgroundColor="#041b28"
                    />
                    <FormErrorMessage>
                      {hasError(props.errors, props.touched, "name")}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <Textarea
                      name="description"
                      id="description"
                      mt={1}
                      backgroundColor="#041b28"
                      shadow="sm"
                      w="full"
                      rounded="md"
                      rows={5}
                      onChange={props.handleChange}
                      value={props.values.description}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="chain">Select Chain</FormLabel>
                    <Input
                      type="text"
                      name="chain"
                      id="chain"
                      mt={1}
                      w="full"
                      shadow="sm"
                      rounded="md"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      value={props.values.chain}
                      backgroundColor="#041b28"
                      isDisabled
                    />
                    {/* <Select
                        id="chain"
                        name="chain"
                        placeholder="Select option"
                        mt={1}
                        backgroundColor="#041b28"
                        w="full"
                        rounded="md"
                        shadow="sm"
                        onChange={props.handleChange}
                        value={props.values.chain}
                      >
                        {chains.map((chain) => (
                          <option key={chain.name} value={chain.name}>
                            {chain.name}
                          </option>
                        ))}
                      </Select> */}
                  </FormControl>
                  {Object.keys(chain?.tokens || {}).length > 0 && (
                    <FormControl>
                      <FormLabel htmlFor="chain">Room Token</FormLabel>
                      <Select
                        id="token"
                        name="token"
                        placeholder="Select Room Token"
                        mt={1}
                        backgroundColor="#041b28"
                        w="full"
                        rounded="md"
                        shadow="sm"
                        onChange={(e) => {
                          const found = chain?.tokens?.[e.target.value];
                          if (!found) return;
                          const { incrementToken, incrementPct, decimals } = found;
                          props.setValues({
                            ...props.values,
                            token: e.target.value,
                            incrementPct: incrementPct ? incrementPct * 100 : 0,
                            incrementToken: incrementToken ? incrementToken / 10 ** decimals : 0,
                          });
                        }}
                        value={props.values.token || ""}
                      >
                        {chain &&
                          chain.tokens &&
                          Object.keys(chain.tokens || {}).map(
                            (chainTokenKey) => {
                              if (!chain.tokens) return;
                              const chainToken = chain.tokens[chainTokenKey];
                              return (
                                <option
                                  key={chainTokenKey}
                                  value={chainTokenKey}
                                >
                                  {chainToken.name} ({chainToken.abbr})
                                </option>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  )}
                </div>

                <div className="settings-wrapper box">
                  <h4>Duration</h4>
                  <FormControl>
                    <FormLabel htmlFor="startAt">Start Time</FormLabel>
                    <InputGroup mt={1}>
                      <Input
                        name="startAt"
                        id="startAt"
                        placeholder="Select Date and Time"
                        type="datetime-local"
                        value={
                          props.values.startAt !== null
                            ? DateTime.fromSeconds(props.values.startAt)
                                .toUTC()
                                .startOf("minute")
                                .toISO({
                                  includeOffset: false,
                                  suppressSeconds: true,
                                  suppressMilliseconds: true,
                                }) || "" // Convert potential null to an empty string
                            : ""
                        }
                        step={"900"}
                        min={DateTime.now()
                          .toUTC()
                          .toFormat("MM-dd-yyyy'T'HH:mm")}
                        onChange={(e) => {
                          props.setFieldValue(
                            "startAt",
                            Math.floor(
                              DateTime.fromISO(e.target.value, {
                                zone: "UTC",
                              }).toSeconds()
                            )
                          );
                        }}
                        backgroundColor="#041b28"
                      />
                      <InputRightAddon children="UTC" />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="partyDuration">
                      Event Duration
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        name="partyDuration"
                        id="partyDuration"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        backgroundColor="#041b28"
                        w="full"
                        rounded="md"
                        onChange={(e) => {
                          props.setFieldValue(
                            "partyDuration",
                            Number(e.target.value || 1) *
                              multiplier(props.values.durationUnits)
                          );
                        }}
                        value={
                          props.values.partyDuration /
                          multiplier(props.values.durationUnits)
                        }
                      />
                      <InputRightAddon mt={1} children={<TimePicker />} />
                    </InputGroup>
                  </FormControl>
                  {/* <FormControl>
                      <FormLabel htmlFor="listingSeconds">
                        Time To List
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          name="listingSeconds"
                          id="listingSeconds"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          backgroundColor="#041b28"
                          w="full"
                          rounded="md"
                          onChange={(e) => {
                            props.setFieldValue(
                              "listingSeconds",
                              Number(e.target.value || 1) * 60
                            );
                          }}
                          value={props.values.listingSeconds / 60}
                        />
                        <InputRightAddon mt={1} children="Minutes" />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="biddingSeconds">
                        Time To Bid
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          name="biddingSeconds"
                          id="biddingSeconds"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          backgroundColor="#041b28"
                          w="full"
                          rounded="md"
                          onChange={(e) => {
                            props.setFieldValue(
                              "biddingSeconds",
                              Number(e.target.value || 1) * 60
                            );
                          }}
                          value={props.values.biddingSeconds / 60}
                        />
                        <InputRightAddon mt={1} children="Minutes" />
                      </InputGroup>
                    </FormControl> */}
                </div>

                <div className="settings-wrapper box">
                  <h4>Listing</h4>
                  <Flex gap={8}>
                    <FormControl className="sidebyside">
                      <FormLabel htmlFor="incrementPct">
                        Increment Percentage
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          name="incrementPct"
                          id="incrementPct"
                          autoComplete="incrementPct"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          w="full"
                          rounded="md"
                          onChange={props.handleChange}
                          value={props.values.incrementPct}
                          backgroundColor="#041b28"
                        />
                        <InputRightAddon mt={1} children={"%"} bg="#2c4453" />
                      </InputGroup>
                    </FormControl>
                    <FormControl className="sidebyside">
                      <FormLabel htmlFor="incrementToken">
                        Increment Token
                      </FormLabel>
                      <InputGroup>
                        <Input
                          type="number"
                          name="incrementToken"
                          id="incrementToken"
                          autoComplete="incrementToken"
                          mt={1}
                          focusBorderColor="brand.400"
                          shadow="sm"
                          w="full"
                          rounded="md"
                          onChange={props.handleChange}
                          value={props.values.incrementToken}
                          backgroundColor="#041b28"
                        />
                        <InputRightAddon
                          mt={1}
                          children={selectedChain?.abbr}
                          bg="#2c4453"
                        />
                      </InputGroup>
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel htmlFor="minReservePrice">
                      Minimum Reserve Price
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        name="minReservePrice"
                        id="minReservePrice"
                        autoComplete="minReservePrice"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        onChange={props.handleChange}
                        value={props.values.minReservePrice}
                        backgroundColor="#041b28"
                      />
                      <InputRightAddon
                        mt={1}
                        children={selectedChain?.abbr}
                        bg="#2c4453"
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="maxReservePrice">
                      Max Reserve Price
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        name="maxReservePrice"
                        id="maxReservePrice"
                        autoComplete="maxReservePrice"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        onChange={props.handleChange}
                        value={props.values.maxReservePrice}
                        backgroundColor="#041b28"
                      />
                      <InputRightAddon
                        mt={1}
                        children={selectedChain?.abbr}
                        bg="#2c4453"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="numNftsToList">
                      Max Number of Items to List
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        name="numNftsToList"
                        id="numNftsToList"
                        autoComplete="numNftsToList"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        onChange={props.handleChange}
                        value={props.values.numNftsToList}
                        backgroundColor="#041b28"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="numNftsToList">Max Users</FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        name="maxUsers"
                        id="maxUsers"
                        mt={1}
                        focusBorderColor="brand.400"
                        shadow="sm"
                        w="full"
                        rounded="md"
                        onChange={props.handleChange}
                        value={props.values.maxUsers}
                        backgroundColor="#041b28"
                      />
                    </InputGroup>
                  </FormControl>

                  {props.values.isOrdinals === true && (
                    <FormControl
                      isInvalid={
                        !!hasError(
                          props.errors,
                          props.touched,
                          "ordinalsWallets"
                        )
                      }
                    >
                      <FormLabel htmlFor="ordinalsWallets">
                        Preferred User BTC Taproot Address
                      </FormLabel>
                      <InputGroup width="100%" mt={1}>
                        <NeoSelect
                          isMulti
                          isCreatable
                          onChange={(values: any) => {
                            props.setFieldValue("ordinalsWallets", values);
                          }}
                          value={props.values.ordinalsWallets}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {hasError(
                          props.errors,
                          props.touched,
                          "ordinalsWallets"
                        )}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                  <FormControl
                    isInvalid={
                      !!(
                        props.errors.itemsPreference?.preferredUsers &&
                        props.touched.itemsPreference?.preferredUsers
                      )
                    }
                    style={{ zIndex: "9" }}
                  >
                    <FormLabel htmlFor="itemsPreference.preferredUsers">
                      Preferred User Address
                    </FormLabel>
                    <InputGroup width="100%" mt={1}>
                      <NeoSelect
                        isMulti
                        isCreatable
                        onChange={(values: any) => {
                          props.setFieldValue(
                            "itemsPreference.preferredUsers",
                            values
                          );
                        }}
                        value={props.values.itemsPreference.preferredUsers}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {props.errors.itemsPreference?.preferredUsers}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl style={{ display: "none" }}>
                    <Switch
                      id="preferAdmin"
                      name="itemsPreference.preferAdmin"
                      isChecked={props.values?.itemsPreference?.preferAdmin}
                      mt={1}
                      onChange={props.handleChange}
                    >
                      Prefer Admin?
                    </Switch>
                  </FormControl>

                  <FormControl style={{ zIndex: "9" }}>
                    <FormLabel htmlFor="allowedCollections">
                      Collections Allowed in the Room
                    </FormLabel>
                    <InputGroup width="100%" mt={1}>
                      <NeoSelect
                        isMulti
                        isCreatable
                        onChange={(values: any) => {
                          props.setFieldValue("allowedCollections", values);
                        }}
                        options={collectionOptions}
                        value={props.values.allowedCollections}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                      <Switch
                        id="isTicketGated"
                        name="isTicketGated"
                        isChecked={props.values?.isTicketGated}
                        mt={1}
                        onChange={props.handleChange}
                      >
                        Ticket room
                      </Switch>
                  </FormControl>
                  <FormControl>
                    <Switch
                      id="secretReserve"
                      name="secretReserve"
                      isChecked={props.values?.secretReserve}
                      mt={1}
                      onChange={props.handleChange}
                    >
                      Hide reserve prices until met
                    </Switch>
                    <Text mt={4}>
                      Note: Bidders will not see the reserve prices until their
                      bid meets the reserve price set
                    </Text>
                  </FormControl>
                </div>

                <div className="settings-wrapper box">
                  <h4>Room Access</h4>
                  <FormControl style={{ zIndex: "9" }}>
                    <FormLabel htmlFor="accessPolicy">Access Policy</FormLabel>
                    <Select
                      id="accessPolicy"
                      name="accessPolicy.policy"
                      mt={1}
                      backgroundColor="#041b28"
                      w="full"
                      rounded="md"
                      shadow="sm"
                      onChange={props.handleChange}
                      value={props.values.accessPolicy.policy}
                    >
                      <option value="open">open</option>
                      <option value="collection">collection</option>
                    </Select>
                  </FormControl>
                  {props.values.accessPolicy.policy === "collection" && (
                    <FormControl>
                      <FormLabel htmlFor="accessPolicy.collections">
                        Allowed Collections
                      </FormLabel>
                      <InputGroup width="100%" mt={1}>
                        <NeoSelect
                          isMulti
                          isCreatable
                          options={collectionOptions}
                          onChange={(values: any) => {
                            props.setFieldValue(
                              "accessPolicy.collections",
                              values
                            );
                          }}
                          value={props.values.accessPolicy.collections}
                        />
                      </InputGroup>
                    </FormControl>
                  )}
                </div>

                <Box width="100%">
                  <Button
                    mt={5}
                    type="submit"
                    fontWeight="md"
                    background="#6C60FF"
                    color="white"
                    width="100%"
                    isLoading={props.isSubmitting}
                    loadingText="Submitting..."
                    spinnerPlacement="end"
                    isDisabled={!canUseButton}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
