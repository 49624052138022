import { memo } from "react";
import {
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import PendingImg from "../../assets/pending.jpg";
import { LockIcon } from "@chakra-ui/icons";

const ListingImageCell = memo((props: any) => {
  const nft = props.row.original;
  const name = nft.name;
  const thumbnail = nft.thumbnail;

  const lockedRoomId = nft.locks.length ? nft.locks?.[0]?.lockedRoom_id : undefined;
  const isLocked = !!lockedRoomId;

  return (
    <HStack>
      <div className="image-wrap">
        {isLocked && (
          <>
            <div className="locked-overlay">
              <LockIcon />
            </div>
          </>
        )}
        <Image
          height="64px"
          objectFit="cover"
          src={thumbnail || PendingImg}
          fallbackSrc={PendingImg}
          loading="lazy"
        />
      </div>
      <VStack alignItems="baseline">
        <Text>{name}</Text>
      </VStack>
    </HStack>
  );
});

export default ListingImageCell;
