import { VersionedTransaction } from '@solana/web3.js'
import { Buffer } from 'buffer'
import type { PackTransaction, PackTransactionSerialized } from '../types/transactions'
import { PackGroup, Pack, TokenAmount, PartnerConfiguration } from '../types'

export class PacksClient {
  private endpoint: string = process.env.REACT_APP_PACKS_ENDPOINT!
  private jwtToken: string

  constructor({ jwtToken }: { jwtToken: string }) {
    this.jwtToken = jwtToken
  }

  private async getHeaders() {
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${this.jwtToken}`,
    }
    return headers
  }

  async fetchPackGroups(): Promise<PackGroup[]> {
    const headers = await this.getHeaders()
    const response = await fetch(`${this.endpoint}/list`, {
      headers,
    })
    if (!response.ok) {
      throw new Error('Failed to fetch packs')
    }
    const data = await response.json()
    return data as PackGroup[]
  }

  async fetchPackDetails({ packId, pack }: { packId?: string; pack?: Pack }): Promise<Pack> {
    const headers = await this.getHeaders()
    if (pack) {
      return this.fetchPackDetailsPost(pack, headers)
    } else if (packId) {
      return this.fetchPackDetailsGet(packId, headers)
    } else {
      throw new Error('Invalid input. Either packId or pack must be provided')
    }
  }

  private async fetchPackDetailsGet(packId: string, headers: HeadersInit): Promise<Pack> {
    const response = await fetch(`${this.endpoint}/details?packId=${packId}`, {
      headers,
    })
    if (!response.ok) {
      throw new Error('Failed to fetch pack details')
    }
    const data = await response.json()
    return data as Pack
  }

  private async fetchPackDetailsPost(pack: Pack, headers: HeadersInit): Promise<Pack> {
    console.log(JSON.stringify({ pack }))
    const response = await fetch(`${this.endpoint}/details`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pack }),
    })
    if (!response.ok) {
      throw new Error('Failed to fetch pack details')
    }
    const data = await response.json()
    return data as Pack
  }

  async fetchTransactions(pack: Pack, userPublicKey: string): Promise<PackTransaction[]> {
    const headers = await this.getHeaders()
    const response = await fetch(`${this.endpoint}/transactions`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pack, userPublicKey }),
    })
    if (!response.ok) {
      throw new Error('Failed to fetch transactions')
    }
    const data: PackTransactionSerialized[] = await response.json()
    const txs: PackTransaction[] = data.map((tx: PackTransactionSerialized) => {
      return {
        ...tx,
        tx: VersionedTransaction.deserialize(Buffer.from(tx.tx, 'base64')),
      } as PackTransaction
    })

    return txs
  }

  async retryTransactions(
    transactions: PackTransaction[],
    userPublicKey: string
  ): Promise<PackTransaction[]> {
    const headers = await this.getHeaders()
    const response = await fetch(`${this.endpoint}/retry`, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ transactions, userPublicKey }),
    })
    if (!response.ok) {
      throw new Error('Failed to fetch transactions')
    }
    const data: PackTransactionSerialized[] = await response.json()
    const txs: PackTransaction[] = data.map((tx: PackTransactionSerialized) => {
      return {
        ...tx,
        tx: VersionedTransaction.deserialize(Buffer.from(tx.tx, 'base64')),
      } as PackTransaction
    })

    return txs
  }

  async fetchBalances(userPublicKey: string): Promise<TokenAmount[]> {
    const headers = await this.getHeaders()
    const response = await fetch(`${this.endpoint}/balances?userPublicKey=${userPublicKey}`, {
      headers,
    })
    if (!response.ok) {
      throw new Error('Failed to fetch balances')
    }
    const data = await response.json()
    return data as TokenAmount[]
  }

  async fetchPartnerConfiguration(): Promise<PartnerConfiguration> {
    const headers = await this.getHeaders()
    const response = await fetch(`${this.endpoint}/config`, {
      headers,
    })
    if (!response.ok) {
      throw new Error('Failed to fetch partner configuration')
    }
    const data = await response.json()
    return data as PartnerConfiguration
  }
}
