import { memo, useEffect, useState } from "react";
import ImageWrapper from "../ImageWrapper";
import { createFloor, getReserveLabel, getMinBidLabel } from "../../utils";
// import BidCell from "../BiddingDataTable/BidCell";
import {
  BiddableNft,
  Nft,
  createPlaceLabel,
  getHighestBid,
  useRoom,
} from "../../contexts/roomContext";
import {
  AspectRatio,
  Badge,
  Button,
  Flex,
  // IconButton,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
// import { FaTrashAlt } from "react-icons/fa";
import { CiUnlock } from "react-icons/ci";
import { useInView } from "react-intersection-observer";
import { cancelBid } from "../../services/room.service";
import { useAppContext } from "../../contexts/appContext";
import TxtBlock from "../TxtBlock";
import { CgSearchFound } from "react-icons/cg";
import { GrFormCheckmark } from "react-icons/gr";
import { BsCircleFill } from "react-icons/bs";
import { FaGift } from "react-icons/fa";
import { WarningIcon } from "@chakra-ui/icons";

import IconPurpleCheck from "../Icons/IconPurpleCheck";
import IconLock from "../Icons/IconLock";
import { checkImage } from "../../utils/index";
import HTMLBlock from "../HTMLBlock";
import useTimeLeft from "../../hooks/useTimeLeft";
import PassButton from "../BiddingModal/PassButton";
import IconEye from "../Icons/IconEye";
import IconEyeSlash from "../Icons/IconEyeSlash";

interface Props {
  nft: BiddableNft;
  updateNew?: boolean;
  isSeller?: boolean;
  isPassing?: boolean;
  isPassed?: boolean;

}

const createImage = (nft: Nft, bid: number) => {
  const img = nft.thumbnail || nft.image;
  if (nft?.assetType?.includes("text/plain")) {
    return <TxtBlock nft={nft} width="100%" fontSize="12px" minH="200px" />;
  }
  if (nft?.assetType?.includes("text/html")) {
    return (
      <AspectRatio ratio={1} width="100%">
        <HTMLBlock nft={nft} />
      </AspectRatio>
    );
  }
  return <ImageWrapper url={checkImage(img)} bid={bid} assetType={nft.assetType} />;
};

const BiddingGridItem = memo(({ nft, updateNew = true, isSeller, isPassed }: Props) => {
  const {
    roomId,
    setModalId,
    truncate,
    bids,
    handleInView,
    highestBids,
    availableForBid,
    handlePass,
    room,
    isAdmin,
    canBid,
    setShowContactModal,
    isPassing
  } = useRoom();
  const roomChain = room?.blockchain?.name?.toLowerCase() || "";

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  const suggestedPrice =
    room?.info?.suggestedPrice || "Floor";

  const { uid } = useAppContext();
  const bid = bids?.[nft?.itemId || ""]?.bidPrice;
  const reservePrice = nft.reservePrice?.value;
  const decimals = nft.reservePrice?.decimals;
  const abbr = nft.reservePrice?.symbol;
  const hasBid = typeof bid === "number" || bid === 0;
  const id = nft.itemId;
  const image = nft.thumbnail || nft.image;
  const toast = useToast();
  const startingBid = nft?.startingBid || 1;

  const { isHighBidder, highestBidAmount, isTied, place } = getHighestBid(
    highestBids,
    id,
    uid
  );

  const isReserveMet =
    room.info.secretReserve &&
    highestBidAmount >=
    (typeof reservePrice !== "undefined" ? reservePrice : 0);

  const isSecretReserve = room.info.secretReserve;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && updateNew && !nft.hasViewed) {
        handleInView(nft.itemId!);
      }
    },
  });

  const handleErrorToast = (reason: string, title: string) => {
    let message = reason;
    if (reason.includes("You are not on the correct chain to bid")) {
      message = `Please connect to ${abbr} to bid on this item`;
    }
    toast({
      title: title,
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
      styleConfig: {
        zIndex: 999999,
      },
    });
  };

  const getOnClickHandler = () => {
    if (canBid.canBid || isSeller || isAdmin) {
      return () => setModalId(nft.itemId);
    } else if (
      canBid.reason.includes("You must have a contact method to bid")
    ) {
      return () => setShowContactModal(true);
      // Some kind of global
    } else {
      return () => handleErrorToast(canBid.reason, canBid.errorTitle);
    }
  };

  const timeLeft = useTimeLeft();
  const finalMinute = timeLeft < 60;

  return (
    <div
      ref={ref}
      className={`bidding-grid-item grid-item new-bg-transparent ${typeof bid === "number" ? "selected" : ""
        }`}
    >
      <div className="contents">
        <div className="media-holder">
          {!nft.hasViewed ? (
            <Badge position="absolute" right="0" top="0" background="#ed6cd8">
              NEW!
            </Badge>
          ) : null}
          {createImage(nft, bid)}
          {bid || bid === 0 ? (
            <div className="is-bid-check">
              <IconPurpleCheck />
            </div>
          ) : null}
        </div>
        <div className="details">
          <div className="nft-details">
            {nft.collection.name && <h4>{nft.collection.name}</h4>}
            <h3>{nft.name}</h3>
          </div>
          <Flex justifyContent="center">
            {isTied ? <Text className="high-bid tied">Tied</Text> : null}
            {isHighBidder && !isTied ? (
              <Text className="high-bid winning">
                <span />
                Highest bid
              </Text>
            ) : null}
            {!isHighBidder && highestBidAmount && hasBid ? (
              <Text className="high-bid" textAlign="center">
                {finalMinute
                  ? "Place your final bids"
                  : createPlaceLabel(place, availableForBid.length)}
              </Text>
            ) : null}
          </Flex>
          <div className="price-info">
            {/* {!isPassing &&
                        (<div className="price">
              <span className="label">Reserve</span>
              <p
                className="bold"
                style={{
                  borderRadius: "5px",
                  background: isSecretReserve
                    ? reservePrice === 0
                      ? "#6c60ff"
                      : isReserveMet
                      ? "#40cd56"
                      : "white"
                    : "inherit",
                  color: isSecretReserve
                    ? reservePrice === 0
                      ? "#fff"
                      : isReserveMet
                      ? "#ffffff"
                      : "#0c416a"
                    : "inherit",
                  padding: "1px 5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {isSecretReserve ? (
                  reservePrice === 0 ? (
                    <FaGift />
                  ) : isReserveMet ? (
                    <CiUnlock />
                  ) : (
                    <IconLock />
                  )
                ) : null}
                {isSecretReserve 
                  ? reservePrice === 0
                    ? "Free"
                    : isReserveMet
                    ? "Met"
                    : "Not Met"
                  : getReserveLabel(reservePrice, decimals, abbr, truncate) } 
              </p>
            </div>)} */}
            <div className="price">
              <span className="label">Minimum Bid</span>{" "}
              <span className="font-bold">{startingBid && decimals && abbr
                ? getMinBidLabel(startingBid, decimals, abbr, truncate)
                : "--"}</span>
            </div>
            {/* {!isOrdinals &&
              <div className="price">
                <span className="label">{suggestedPrice}</span>
                <span className="font-bold">{createFloor(nft, truncate)}</span>
              </div>
            } */}
            {highestBidAmount ? (
              <div className="price">
                <span className="label">Highest Bid</span>{" "}
                <span className="font-bold">{highestBidAmount && decimals && abbr
                  ? getReserveLabel(highestBidAmount, decimals, abbr, truncate)
                  : "--"}</span>
              </div>
            ) : null}


          </div>
          <div className="grid-item-footer">
            <div className="list-controls">
              <Button
                size="sm"
                onClick={getOnClickHandler()}
                minWidth="100%"
                bg={bid || bid === 0 ? "#6C60FF" : "#FF4ADB"}
              >
                {isSeller ||
                  isAdmin ||
                  room?.info?.roomType !== "smartSilentAuction" ? (
                  <span className="bid-placed">
                    <Flex alignItems="center">
                      <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }}>
                          <polyline fill="none" stroke="currentColor" strokeWidth="2" points="6 13 10.2 16.6 18 7" />
                        </svg>
                        View
                      </div>
                    </Flex>
                  </span>
                ) : (
                  <span
                    className={bid || bid === 0 ? "bid-placed" : "place-bid"}
                  >
                    {bid || bid === 0 || isPassing ? (
                      <Flex alignItems="center">
                        <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ width: '20px', height: '20px' }}>
                            <polyline fill="none" stroke="currentColor" strokeWidth="2" points="6 13 10.2 16.6 18 7" />
                          </svg>
                          View
                        </div>
                      </Flex>
                    ) : (
                      <>
                        <CgSearchFound size={15} /> Place Bid
                      </>
                    )}
                  </span>
                )}
              </Button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
});

export default BiddingGridItem;