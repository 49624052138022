import { TokenDetails, TokenId, BundleDetails, ItemSpec } from './types'

export const tokens: Record<TokenId, TokenDetails> = {
    native: {
        id: 'native',
        mint: 'So11111111111111111111111111111111111111112',
        abbr: 'SOL',
        name: 'Solana',
        decimals: 9,
    },
    usdc: {
        id: 'usdc',
        mint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        abbr: 'USDC',
        name: 'USD Coin',
        decimals: 6,
    },
    atlas: {
        id: 'atlas',
        mint: 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx',
        abbr: 'ATLAS',
        name: 'Atlas',
        decimals: 8,
    },
    jup: {
        id: 'jup',
        mint: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
        abbr: 'JUP',
        name: 'Jupiter',
        decimals: 6,
    },
    puri: {
        id: 'puri',
        mint: 'CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB',
        abbr: 'PURI',
        name: 'Pur',
        decimals: 9,
    },
}

const resources: Record<string, ItemSpec> = {
    ammo: {
        mint: 'ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK',
        name: 'Ammo',
        description: 'Ammo is used to shoot things',
        type: 'resource',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/hG9bAn8pVL2YUpC2s4U5qypOvKRHFVdP4xNB-wOwfbABmy08BHNpPgxYWaBfjzlDLB7MfVT0_d7R-CrNOqklYVy25Pjyd0us9s-h',
    },
    food: {
        mint: 'foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG',
        name: 'Food',
        description: 'Food is used to eat things',
        type: 'resource',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/K4gnvIqEjiQz5MWe1OdpAtX_sNlEVYiKf71pIwjbzdLz5nJT7kmsSrRxFKl9M2w-iz0iJ59qo_BmtLoKZHB8bdBxq-ZCOtUDBR0Z',
    },
    fuel: {
        mint: 'fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim',
        name: 'Fuel',
        description: 'Fuel is used to power things',
        type: 'resource',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/q-QqQ4KwbpBaPwT5bG1l3nq87u-YPFHb98U7wsepgRA5zVY3QPMonykn7VLgUxkVI6ATVc8caKcLDz7AWP07dSQ23EqG0hge2hA',
    },
    tools: {
        mint: 'tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL',
        name: 'Toolkits',
        description: 'Toolkits are used to fix things',
        type: 'resource',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/rUb-UdLlqSdshSEanOsIq4TmN30X6TZYERFY8uf3K7lpKX29NK6Dx2DpGfG7gSg8_vqcibIFvU1FwbdlzXrR08ONzkpiMZjZCA',
    },
}

const packTokens: Record<string, ItemSpec> = {
    atlas: {
        mint: tokens.atlas.mint,
        name: tokens.atlas.abbr,
        description: 'ATLAS is the currency of Star Atlas',
        type: 'token',
        quantity: 0,
        image: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png',
    },
}

const ships: Record<string, ItemSpec> = {
    bike: {
        mint: 'Fw8PqtznYtg4swMk7Yjj89Tsj23u5CJLfW5Bk8ro4G1s',
        name: 'Fimbul Airbike',
        description: 'The FIMBUL AIRBIKE is a small ship',
        type: 'ship',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/DwCCrTyvdKELDR-_lLJLOqha47QhW5UIkEe98mM6en4zSuBlEPKBgITjadA3mwvBh9bcYRzZR7kkv4tGlq589FisC7snLM8LzORI',
    },
    vzus: {
        mint: 'HjFijcGWKgfDwGpFX2rqFwEU9jtEgFuRQAJe1ERXFsA3',
        name: 'VZUS solos',
        description: 'The VZUS is a small ship',
        type: 'ship',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/9GAxPlJlJzwSsuupXt1pjtKSLj95m9ISgEX66HEKPGcNnTSuhNJdb5-nbHNqc7XdrLyiYUM6IJNCPXNbz9MdHNMc-hBkpM8Shjs',
    },
    fimbul: {
        mint: '7Xs3yt9eJPuEexZrKSGVbQMXHwWUKHGeDZnM4ZksZmyS',
        name: 'Fimbul Lowbie',
        description: 'The FIMBUL is a small ship',
        type: 'ship',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/lsrfhMMWcz2_zPvojB0w0zdeSEVn4nowEiYtvh1egmRK7nyoAKWSEp9lq5yeaxrPNzMHTkhIvk-dK8NAFbZ7dQeFE74l0QbUz-ju',
    },
    ogrika: {
        mint: 'FMHHwUB6amLWYhWxtiZHC2g5azy9usPTLMq46N3HEgFU',
        name: 'Ogrika Mik',
        description: 'The OGRIKA is a small ship',
        type: 'ship',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/ihnYbaaSeWAWocUxmAqtTrOTIsWhM6uHpGxGXbF-1CTaYuf4Fe3O_J6JPJyB8aFcVnet0q8GEKwJfKOPh3tN-s5crzxQDtT2iIE',
    },
    calico: {
        mint: '2XYd22LSFGxN7kWgoEeaXVZqgrsPeQLHLEgNhnS12Mny',
        name: 'Calico ATS Enforcer',
        description: 'The CALICO is a small ship',
        type: 'ship',
        quantity: 0,
        image: 'https://lh3.googleusercontent.com/-bwo4OeMVrS0sghd86NJSJKN11HtJBmg_PDAZVYn5slh1F92lwli6CdF3SF-IoTrba4E94ATYUJhUqk78R_gC0E3dWnPCrrjCg',
    },
}

export const itemsList: Record<string, ItemSpec> = {
    ...resources,
    ...packTokens,
    ...ships,
}

export const bundlesList: Record<string, BundleDetails> = {
    // "test-bundle": {
    //     id: "test-bundle",
    //     name: "Test Bundle",
    //     description: "This is a test bundle",
    //     image: ships.bike.image,
    //     items: [
    //         { ...ships.bike, quantity: 1 },
    //         { ...resources.ammo, quantity: 15 },
    //         { ...resources.fuel, quantity: 9 },
    //         { ...resources.tools, quantity: 3 },
    //         { ...resources.food, quantity: 2 },
    //         { ...packTokens.atlas, quantity: 15 },
    //     ],
    // },
    'xx-small-bundle': {
        id: 'xx-small-bundle',
        name: 'VZUS SOLOS',
        description: 'XX-SMALL BUNDLE',
        image: ships.vzus.image,
        items: [
            { ...ships.vzus, quantity: 1 },
            { ...packTokens.atlas, quantity: 1500 },
            { ...resources.fuel, quantity: 900 },
            { ...resources.ammo, quantity: 312 },
            { ...resources.food, quantity: 249 },
            { ...resources.tools, quantity: 249 },
        ],
    },
    'x-small-bundle': {
        id: 'x-small-bundle',
        name: 'FIMBUL LOWBIE',
        description: 'X-SMALL BUNDLE',
        image: ships.fimbul.image,
        items: [
            { ...ships.fimbul, quantity: 1 },
            { ...packTokens.atlas, quantity: 4160 },
            { ...resources.fuel, quantity: 2780 },
            { ...resources.ammo, quantity: 864 },
            { ...resources.food, quantity: 1965 },
            { ...resources.tools, quantity: 1965 },
        ],
    },
    'small-bundle': {
        id: 'small-bundle',
        name: 'OGRIKA MIK',
        description: 'SMALL BUNDLE',
        image: ships.ogrika.image,
        items: [
            { ...ships.ogrika, quantity: 1 },
            { ...packTokens.atlas, quantity: 11849 },
            { ...resources.fuel, quantity: 8046 },
            { ...resources.ammo, quantity: 13188 },
            { ...resources.food, quantity: 3832 },
            { ...resources.tools, quantity: 3832 },
        ],
    },
    'medium-bundle': {
        id: 'medium-bundle',
        name: 'CALICO ATS ENFORCER',
        description: 'MEDIUM BUNDLE',
        image: ships.calico.image,
        items: [
            { ...ships.calico, quantity: 1 },
            { ...packTokens.atlas, quantity: 50294 },
            { ...resources.fuel, quantity: 27848 },
            { ...resources.ammo, quantity: 45648 },
            { ...resources.food, quantity: 16135 },
            { ...resources.tools, quantity: 16135 },
        ],
    },
}
