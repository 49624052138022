import { useEffect, useState } from "react";
import { Button, Flex, HStack, Heading, Image, Tooltip, VStack } from "@chakra-ui/react";
import { useRoom } from "../contexts/roomContext";
import { getNftCollectionMetadata } from "../services/nfts.service";
import PendingImg from "../assets/pending.jpg";
import { Link } from "react-router-dom";

const CollectionItem = ({ name, image }: { name?: string; image?: string }) => {
  return (
    <Tooltip label={name} zIndex={999999999}>
      <Image
        boxSize="64px"
        objectFit="cover"
        src={image}
        minWidth="64px"
        fallbackSrc={PendingImg}
      />
    </Tooltip>
  );
};

export default function TokenGated() {
  const { room, canList } = useRoom();
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const allowedCollections = room?.info?.accessPolicy?.collections || [];
  const isTokenGated = room?.info?.accessPolicy?.policy === "collection";
  const isTicketGated = room?.info?.isTicketGated? true : false;

  const [collections, setCollections] = useState<any>({});

  useEffect(() => {
    const getCollections = async () => {
      try {
        const res = await getNftCollectionMetadata({
          ids: allowedCollections,
          blockchain: roomChain,
        });
        const collectionData = res.data.reduce((acc: any, ele: any) => {
          acc[ele.collectionId] = ele;
          return acc;
        }, {});
        setCollections(collectionData);
      } catch (e) {
        console.log("ERROR", e);
      }
    };
    getCollections();
  }, [allowedCollections]);

  if (!isTokenGated || canList) return null;

  const renderCollections = () => (
    <HStack justifyItems={"flex-start"} paddingRight={"20px"}>
      {allowedCollections.map((collection) => {
        const { name, image } = collections[collection] || {};
        return <CollectionItem key={collection} name={name} image={image} />;
      })}
    </HStack>
  );

  return (

    <Flex  width="100%"  mt={"30px"} className="flex-col   gap-16 inline-flex">
    {isTicketGated &&
      (
         <div className="auction-list">
           
                <div  className="auction-list-item">
                  {renderCollections()}
                  <h3>Ticket required for entry to this smart auction</h3>
                  <Link
                    to={`/ticket-dispenser`}
                     >
                    <Button
                     backgroundColor="#6C60FF"
                     >
                    Claim Ticket
                  </Button>
                  </Link>

                
             
          
  
                </div>
           
          </div>
        
      //   <div className=" text-center self-stretch flex-col justify-center items-center gap-8 flex">
      //     <h2 className="room-title">
      //       <p className="room-title-header">
      //         Ticket required for entry to this smart auction
      //       </p>         
      //     </h2>
      //     {renderCollections()}

      //     <p className="room-title-text">
      //       Eligible attendees should proceed to the ticket dispenser to collect their ticket.
      //     </p>

      //     <Link
      //        to={`/ticket-dispenser`}
      //         className="button"
      //        >
      //       Claim Ticket
      //     </Link>
      //  </div> 
      )
    }
    {!isTicketGated &&
      (
         <div className="auction-list">
           
                <div  className="auction-list-item">
                  {renderCollections()}
                  <h3>Item/s required for entry to this smart auction</h3>
                  <Link
                    to={`https://www.tensor.trade/`}
                     >
                    <Button
                     backgroundColor="#6C60FF"
                     >
                    Buy on Tensor
                  </Button>
                  </Link>

                
             
          
  
                </div>
           
          </div>
        
      //   <div className=" text-center self-stretch flex-col justify-center items-center gap-8 flex">
      //     <h2 className="room-title">
      //       <p className="room-title-header">
      //         Ticket required for entry to this smart auction
      //       </p>         
      //     </h2>
      //     {renderCollections()}

      //     <p className="room-title-text">
      //       Eligible attendees should proceed to the ticket dispenser to collect their ticket.
      //     </p>

      //     <Link
      //        to={`/ticket-dispenser`}
      //         className="button"
      //        >
      //       Claim Ticket
      //     </Link>
      //  </div> 
      )
    }
    </Flex>

    // <VStack className="box" width="100%" justifyItems="flex-start" zIndex={999999999}>
    //   <Heading as="h3" size="xs" color="gray.300" textAlign="left" width="100%">
    //     Preview for {isTicketGated ? "ticket" : "token"} gated
    //   </Heading>
    //   {!isTicketGated && (
    //     <>
    //       <Heading as="h4" size="xs" color="gray.300" textAlign="left" width="100%">
    //         To Access the room you need the following collections:
    //       </Heading>
    //       {renderCollections()}
    //     </>
    //   )}
    // </VStack>
  );
}
