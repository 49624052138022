import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addFeedback, getFeedback } from "../../services/room.service";
import { useChain } from "../../contexts/chainsContext";
import { roundValue } from "./utils";

import NeoImage from "./Img";

import { ReasonState, RejectReason } from "ns-types";

// ICONS
import IconPaperX from "../Icons/IconPaperX";
import { Images, Offer } from "./types";

interface DeclineModalProps {
  roomChain: string;
  roomId: string;
  uid: string;
  declineLoading: boolean;
  handleDecline: (reason: any) => void;
  goBack: () => void;
  offer: Offer;
  offerSequence: string;
  images: Images;
}

export const DeclineComplete = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="flex flex-col items-center gap-32 mt-32">
      <div>
        <IconPaperX />
      </div>
      <div className="text-center">
        <h1 className="text-white text-3xl font-medium leading-10">
          Your trade has been declined.
        </h1>
        <p className="text-white text-xl font-normal">
          If you have already escrowed your Items or Tokens, they will be sent
          back to your wallet shortly.
        </p>
      </div>

      <button
        className="w-64 h-16 px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
        onClick={() =>
          navigate(
            location.pathname.includes("/ai-trades")
              ? "/star-atlas"
              : "/pay-with-neoswap"
          )
        }
      >
        Back to Homepage
      </button>
    </div>
  );
};

const Declined: React.FC<DeclineModalProps> = ({
  roomChain,
  roomId,
  uid,
  handleDecline,
  offer,
  offerSequence,
  goBack,
  images,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userRejectReasons, setUserRejectReasons] = useState<number[]>([]);
  const [reason, setReason] = React.useState<ReasonState>({
    declinedReasons: [],
    declinedReasonOther: "",
  });

  const [isSent, setIsSent] = useState(false);

  const rejectReasons: RejectReason[] = [
    {
      label: "Want to Keep Item",
      slug: "give-nft",
    },
    {
      label: "Poor value",
      slug: "value",
    },
    {
      label: "Don't want offered collection",
      slug: "get-collection",
    },
    {
      label: "Don't want offered Item",
      slug: "get-nft",
    },
    {
      label: "Other (please specify)",
      slug: "other",
    },
  ];

  const handleReasonChange = (itemIndex: number) => {
    const isReasonExists = userRejectReasons.includes(itemIndex);
    let updatedReasons: number[] = [];
    if (isReasonExists) {
      // Remove the itemIndex from userRejectReasons
      updatedReasons = userRejectReasons.filter(
        (reason) => reason !== itemIndex
      );
      setUserRejectReasons(updatedReasons);
    } else {
      // Add the itemIndex to userRejectReasons
      updatedReasons = [...userRejectReasons, itemIndex];
      setUserRejectReasons(updatedReasons);
    }

    // Update the reason state based on selected reasons
    const selectedReasons: string[] = updatedReasons.map(
      (itemIndex: number) => rejectReasons[itemIndex].slug
    );
    setReason({ declinedReasons: selectedReasons });
  };

  const handleSubmit = async () => {
    // await addFeedback(roomId, uid, reason);
    // setReason({});
    setIsSent(true);
    handleDecline(reason);
  };

  useEffect(() => {
    const checkIfRated = async () => {
      if (roomId && uid) {
        const userFeedback = await getFeedback(roomId, uid);
        if (userFeedback) {
          setIsSent(true);
        }
      }
    };
    checkIfRated();
  }, []);

  const { getChainDetails } = useChain();
  const chain = getChainDetails(roomChain);

  const tokenValue =
    roundValue(offer.token, chain.decimals, chain.truncate) || 0;

  if (isSent) {
    return <DeclineComplete />;
  } else {
    return (
      <div className="flex flex-col items-center gap-10">
        <div className="text-center">
          <h2 className="text-white text-3xl font-medium leading-10 mb-6 ">
            Why did you decline this trade? (Select all that apply)
          </h2>
          <p className="text-white text-xl font-normal">
            This will help us propose better trades to you in future.
          </p>
        </div>

        <div>
          <h3
            className={
              "text-white text-center text-3xl font-medium leading-10 mb-8"
            }
          >
            Trade {offerSequence}
          </h3>
          <div className="flex items-center gap-6">
            <div className="hidden md:flex justify-start items-center gap-4 flex-col md:flex-row flex-1">
              {tokenValue > 0 && (
                <>
                  <div className="justify-center items-center gap-4 flex">
                    <div className="text-center text-white text-[21px] font-medium leading-loose min-w-[130px]">
                      {Math.abs(tokenValue)} {chain.abbr}
                    </div>
                  </div>
                </>
              )}
              <div className="justify-start items-start gap-1.5 flex">
                {offer.get?.map((id, index) => {
                  const img = images[id];

                  const src = img.thumbnail || img.image || "";
                  const alt = img.name;

                  return (
                    <div
                      key={`get_${index}`}
                      className="w-16 h-16 rounded-md border-l-2 md:border-l-0 md:border-b-2 border-green-500 justify-center items-center flex overflow-hidden"
                    >
                      <NeoImage
                        src={src}
                        alt={alt} // You can use src as the alt text if needed
                        className="w-16 h-16"
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-[25.97px] h-[16.97px] hidden md:block relative">
              <svg
                width="30"
                height="20"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.96875 10.3169H27.9688"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M19.4835 1.83158L27.9688 10.3169L19.4835 18.8021"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M10.4853 1.83164L2 10.3169L10.4853 18.8022"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            <div className="justify-start items-center gap-[5.87px] hidden md:flex flex-col md:flex-row flex-1">
              {tokenValue < 0 ? (
                <>
                  <div className="justify-center items-center gap-4 flex">
                    <div className="text-center text-white text-[21px] font-medium leading-loose">
                      {Math.abs(tokenValue)} {chain.abbr}
                    </div>
                  </div>
                </>
              ) : (
                <div className="h-12" />
              )}
              {offer.give?.map((id, index) => {
                const img = images[id];

                const src = img.thumbnail || img.image || "";
                const alt = img.name;
                return (
                  <div
                    key={index}
                    className="w-16 h-16 rounded-md border-l-2 md:border-l-0 md:border-b-2 border-red-600 justify-center items-center flex overflow-hidden"
                  >
                    <NeoImage
                      src={src}
                      alt={alt} // You can use src as the alt text if needed
                      className="w-16 h-16"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 max-w-full">
          {rejectReasons.map((reason, index) => (
            <div
              key={index}
              onClick={() => handleReasonChange(index)}
              className={`
                flex
                w-96 
                h-12 
                px-6 
                py-2.5 
                rounded-md 
                cursor-pointer
                border-fuchsia-500 
                border-2
                flex-col 
                justify-center 
                items-center
                hover:bg-fuchsia-500
                max-w-full
                ${
                  userRejectReasons.includes(index)
                    ? "bg-fuchsia-500"
                    : "bg-transparent"
                }`}
            >
              {reason.label}
            </div>
          ))}

          {userRejectReasons.includes(rejectReasons.length - 1) ? (
            <textarea
              className="decline-input w-96 h-48 p-6 max-w-full bg-black bg-opacity-90 rounded-2xl border border-black border-opacity-90"
              value={reason.declinedReasonOther}
              onChange={(e) =>
                setReason({
                  declinedReasons: reason.declinedReasons,
                  declinedReasonOther: e.target.value,
                })
              }
              placeholder="write here..."
              disabled={!userRejectReasons.includes(rejectReasons.length - 1)}
            />
          ) : null}

          <div className="flex gap-6">
            <button
              className="w-full px-10 py-6 bg-transparent border border-sky-500 rounded-md justify-center items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
              onClick={() => goBack()}
            >
              Go Back
            </button>
            <button
              className="w-full px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-center items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Declined;
