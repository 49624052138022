import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { addRating, getRating } from "../services/room.service";
import { RiEmotionSadFill, RiEmotionHappyLine } from "react-icons/ri";
import { HiEmojiSad, HiEmojiHappy } from "react-icons/hi";
import { useMediaQuery } from "@chakra-ui/react";
import {  Text } from "@chakra-ui/react";
import {
  BsFillEmojiNeutralFill,
  BsFillEmojiHeartEyesFill,
} from "react-icons/bs";

interface RatingButtonProps {
  roomId: string;
  uid: string;
}

const RatingButton = ({ roomId, uid }: RatingButtonProps) => {
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [hasRated, setHasRated] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const iconSize = isLargerThan768 ? 32 : 26;
  const iconSize2 = isLargerThan768 ? 40 : 32;
  const toast = useToast();

  useEffect(() => {
    const checkIfRated = async () => {
      const userRating = await getRating(roomId, uid);
      if (userRating) {
        setHasRated(true);
      }
    };
    checkIfRated();
  }, [roomId, uid]);

  const handleRating = async (value: number) => {
    if (hasRated) {
      toast({
        title: "Already rated",
        description: "You have already provided your rating.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await addRating(roomId, uid, value);
      // console.log("Rating added successfully!");
      toast({
        title: "Rating sent, thank you!",
        duration: 3000,
        isClosable: true,
      });
      setHasRated(true);
    } catch (error) {
      console.error("Error adding rating:", error);
      toast({
        title: "Error adding rating!",
        //description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {hasRated ? (
        <Text fontSize="md" fontWeight="bold">
           Rating sent, thank you! 
        </Text>
      ) : (
        <div>
          <Text  className="satisfied-text"> How satisfied are you with this trade? </Text>

          <div className="feedback-div ">
            <RiEmotionSadFill
              className="rating-icon red"
              color="red"
               size={iconSize2}
              onClick={() => handleRating(1)}
            />

            <HiEmojiSad
              className="rating-icon brown"
              color="brown"
              size={iconSize2}
              onClick={() => handleRating(2)}
            />
            <BsFillEmojiNeutralFill
              className="rating-icon yellow"
              color="yellow"
               size={iconSize}
              onClick={() => handleRating(3)}
            />
            <HiEmojiHappy
              className="rating-icon green"
              color="green"
               size={iconSize2}
              onClick={() => handleRating(4)}
            />
            <BsFillEmojiHeartEyesFill
              className="rating-icon green"
              color="green"
               size={iconSize}
              onClick={() => handleRating(5)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RatingButton;
