import { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

if (!process.env.REACT_APP_MEASUREMENT_ID)
  throw new Error("REACT_APP_MEASUREMENT_ID is not set");

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID!);

interface Props {
  children?: ReactNode;
}

interface UserAnalyticsInterface {
  gaSignIn: (userId: string) => void;
  gaSignOut: () => void;
  gaBalance: (chain: string, value: number) => void;
  gaNickname: (name: string) => void;
  gaCreateParty: (params: any) => void;
  gaArchiveParty: (partyId: string) => void;
  gaJoinParty: (partyId: string) => void;
  gaSetBudget: (partyId: string, budget: number) => void;
  gaAddNft: (partyId: string, nftId: string) => void;
  gaRemoveNft: (partyId: string, nftId: string | string[]) => void;
  gaSetReservePrice: (partyId: string, nftId: string, price: number) => void;
  gaSelectNft: (partyId: string, nftId: string) => void;
  gaFinishListing: (partyId: string) => void;
  gaFinishBidding: (partyId: string) => void;
  gaSetBidPrice: (partyId: string, nftId: string, price: number) => void;
  gaSignSmartContract: (partyId: string, swapIds: string[]) => void;
  gaGenerateSwap: () => void;
  gaCreateProfile: () => void;
  gaDeploySwap: () => void;
  gaSignSwap: (txId: string) => void;
  gaConnectWalletClick: () => void;
  gaSignWallet: (wallet?: string) => void;
  gaVerifyEmail: () => void;
  gaTryAiTrades: () => void;
  gaRefreshSwap: () => void;
  gaProposeSwap: () => void;
  gaPropose: () => void;
  gaAcceptSwap: () => void;
  gaCancelSwap: () => void;
  gaRemoveBid: () => void;
  gaPostOnXSwap: () => void;
  gaSignedSwap: () => void;
  gaConnectedWalletStarAtlasSkin: () => void;
  gaClickedBuyStarAtlasSkin: (skinType: string) => void;
  gaSharedStarAtlasSkin: (skinType: string) => void;
  gaSuceessfullyBoughtStarAtlasSkin: (skinType: string) => void;
  gaFailedSwapTokenStarAtlasSkin: (skinType: string) => void;
  gaFailedBuyStarAtlasSkin: (skinType: string) => void;
  gaClickedCollectionSwapCard: (swapId: string) => void;
  gaCSShareOnX: () => void;
  gaCSSortEvent: () => void;
  gaCSFilterEvent: () => void;
  gaCSUserBalance: (uid: string | undefined, balance: number | undefined, collections: Record<string, number>) => void;

  gaPackSelectPack: (uniqueId: string | undefined, packId: string) => void;
  gaPackSelectPayment: (uniqueId: string | undefined, packId: string, paymentOptionId: string, paymentMint: string, price: number) => void;
  gaPackSignTransaction: (uniqueId: string | undefined, packId: string, txHash: string, txData: Object) => void;
  gaPackUpdateTransaction: (uniqueId: string | undefined, packId: string, txHash: string, txStatus: string) => void;
  gaPackSuccess: (uniqueId: string | undefined, packId: string) => void;
}

const UserAnalytics = createContext<UserAnalyticsInterface>(
  {} as UserAnalyticsInterface
);

export default function UserAnalyticsProvider({ children }: Props) {
  let location = useLocation();

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  const gaSignIn = (userId: string) => {
    ReactGA.set({ userId });
    ReactGA.event({
      category: "User",
      action: "Sign In",
      label: "User signed in",
    });
  };

  const gaSignOut = () => {
    ReactGA.event({
      category: "User",
      action: "Sign out",
      label: "User signed in",
    });
  };

  const gaBalance = (chain: string, value: number) => {
    ReactGA.event({
      category: "User",
      action: "Balance",
      label: chain?.toLowerCase(),
      value,
    });
  };

  const gaNickname = (name: string) => {
    ReactGA.event({
      category: "User",
      action: "Balance",
      label: name,
    });
  };

  const gaCreateParty = (params: any) => {
    ReactGA.event("Create Party", params);
  };

  const gaArchiveParty = (partyId: string) => {
    ReactGA.event({
      category: "Party",
      action: "Archive Party",
      label: partyId,
    });
  };

  const gaJoinParty = (partyId: string) => {
    ReactGA.event({
      category: "Party",
      action: "Join Party",
      label: partyId,
    });
  };

  const gaSetBudget = (partyId: string, budget: number) => {
    ReactGA.event({
      category: "Party",
      action: "Set Budget",
      label: partyId,
      value: budget,
    });
  };

  const gaAddNft = (partyId: string, nftId: string) => {
    ReactGA.event("Add Item", {
      category: "Party",
      partyId,
      nftId,
    });
  };

  const gaRemoveNft = (partyId: string, nftId: string | string[]) => {
    ReactGA.event("Remove Item", {
      category: "Party",
      partyId,
      nftId,
    });
  };

  const gaSetReservePrice = (partyId: string, nftId: string, price: number) => {
    ReactGA.event("Set Reserve Price", {
      category: "Party",
      partyId,
      nftId,
      price,
    });
  };

  const gaSelectNft = (partyId: string, nftId: string) => {
    ReactGA.event("Select Item", {
      category: "Party",
      partyId,
      nftId,
    });
  };

  const gaFinishListing = (partyId: string) => {
    ReactGA.event({
      category: "Party",
      action: "Finish Listing",
      label: partyId,
    });
  };

  const gaFinishBidding = (partyId: string) => {
    ReactGA.event({
      category: "Party",
      action: "Finish Bidding",
      label: partyId,
    });
  };

  const gaSetBidPrice = (partyId: string, nftId: string, price: number) => {
    ReactGA.event("Set Bid Price", {
      category: "Party",
      partyId,
      nftId,
      price,
    });
  };

  const gaSignSmartContract = (partyId: string, swapIds: string[]) => {
    ReactGA.event("Sign Smart Contract", {
      category: "Party",
      partyId,
      swapIds,
    });
  };





  const gaGenerateSwap = () => {
    ReactGA.event("Generate Swap");
  };

  const gaCreateProfile = () => {
    ReactGA.event("Create Profile");
  };

  const gaDeploySwap = () => {
    ReactGA.event("Deploy Swap");
  };

  const gaSignSwap = (txId: string) => {
    ReactGA.event("Sign Swap", {
      txId,
    });
  };

  const gaConnectWalletClick = () => {
    ReactGA.event("Connect Wallet Click");
  };
  const gaSignWallet = (wallet?: string) => {
    console.log("SIGN WALLET", wallet)
    ReactGA.event("Sign Wallet", { wallet });
  };
  const gaVerifyEmail = () => {
    ReactGA.event("Verify Email");
  };

  const gaTryAiTrades = () => {
    ReactGA.event("Try AI-Trades");
  };

  const gaRefreshSwap = () => {
    ReactGA.event("Click Refresh Collection Swap");
  };
  const gaProposeSwap = () => {
    ReactGA.event("Click Propose Collection Swap");
  };
  const gaPropose = () => {
    ReactGA.event("Click Propose");
  };
  const gaAcceptSwap = () => {
    ReactGA.event("Accept Swap");
  };
  const gaCancelSwap = () => {
    ReactGA.event("Cancel Swap");
  };
  const gaRemoveBid = () => {
    ReactGA.event("Remove Bid");
  };

  const gaPostOnXSwap = () => {
    ReactGA.event("Click Post on X Collection Swap");
  };

  const gaSignedSwap = () => {
    ReactGA.event("Signed Up Collection Swap");
  };

  const gaConnectedWalletStarAtlasSkin = () => {
    ReactGA.event("Connected Wallet Star Atlas Skin");
  };

  const gaClickedBuyStarAtlasSkin = (skinType: string) => {
    ReactGA.event(`Clicked Buy ${skinType} Skin`);
  }

  const gaSharedStarAtlasSkin = (skinType: string) => {
    ReactGA.event(`Shared ${skinType} Skin on X`);
  }

  const gaSuceessfullyBoughtStarAtlasSkin = (skinType: string) => {
    ReactGA.event(`Successfully Bought ${skinType} Skin`);
  }

  const gaFailedSwapTokenStarAtlasSkin = (skinType: string) => {
    ReactGA.event(`Failed Swap Token ${skinType}`);
  }

  const gaFailedBuyStarAtlasSkin = (skinType: string) => {
    ReactGA.event(`Failed Buy ${skinType} Skin`);
  }

  const gaClickedCollectionSwapCard = (swapId: string) => {
    ReactGA.event("Clicked Collection Swap Card", {
      swapId,
    });
  }

  const gaCSShareOnX = () => {
    ReactGA.event("Clicked Share on X");
  };

  const gaCSSortEvent = () => {
    ReactGA.event("Clicked Sort Event");
  }

  const gaCSFilterEvent = () => {
    ReactGA.event("Clicked Filter Event");
  }

  const gaCSUserBalance = (uid: string | undefined, balance: number | undefined, collections: Record<string, number>) => {
    ReactGA.event("Collection Swap User Balance", {
      uid: uid || "undefined",
      balance: balance || 0,
      collections: collections
    });
  };


  const gaPackSelectPack = (uniqueId: string | undefined, packId: string) => {
    ReactGA.event("pack_select_pack", { pack_id: packId, unique_id: uniqueId || "undefined" });
  }

  const gaPackSelectPayment = (uniqueId: string | undefined, packId: string, paymentOptionId: string, paymentMint: string, price: number) => {
    ReactGA.event("pack_select_payment", {
      payment_option_id: paymentOptionId,
      pack_id: packId,
      payment_mint: paymentMint,
      price: price,
      unique_id: uniqueId || "undefined",
    });
  }
  const gaPackSignTransaction = (uniqueId: string | undefined, packId: string, txHash: string, txData: Object) => {
    ReactGA.event("pack_sign_transaction", {
      tx_hash: txHash,
      pack_id: packId,
      unique_id: uniqueId || "undefined",
      ...txData
    });

  }

  const gaPackUpdateTransaction = (uniqueId: string | undefined, packId: string, txHash: string, txStatus: string) => {
    ReactGA.event("pack_update_transaction", {
      tx_hash: txHash,
      pack_id: packId,
      unique_id: uniqueId || "undefined",
      tx_status: txStatus
    });
  }

  const gaPackSuccess = (uniqueId: string | undefined, packId: string) => {
    ReactGA.event("pack_success", {
      pack_id: packId,
      unique_id: uniqueId || "undefined",
    });
  }

  return (
    <UserAnalytics.Provider
      value={{
        gaSignIn,
        gaSignOut,
        gaBalance,
        gaNickname,
        gaCreateParty,
        gaArchiveParty,
        gaJoinParty,
        gaSetBudget,
        gaAddNft,
        gaRemoveNft,
        gaSetReservePrice,
        gaSelectNft,
        gaFinishListing,
        gaFinishBidding,
        gaSetBidPrice,
        gaSignSmartContract,
        gaGenerateSwap,
        gaCreateProfile,
        gaDeploySwap,
        gaSignSwap,
        gaConnectWalletClick,
        gaSignWallet,
        gaVerifyEmail,
        gaTryAiTrades,
        gaRefreshSwap,
        gaProposeSwap,
        gaPropose,
        gaAcceptSwap,
        gaCancelSwap,
        gaRemoveBid,
        gaPostOnXSwap,
        gaSignedSwap,
        gaConnectedWalletStarAtlasSkin,
        gaClickedBuyStarAtlasSkin,
        gaSharedStarAtlasSkin,
        gaSuceessfullyBoughtStarAtlasSkin,
        gaFailedSwapTokenStarAtlasSkin,
        gaFailedBuyStarAtlasSkin,
        gaClickedCollectionSwapCard,
        gaCSShareOnX,
        gaCSSortEvent,
        gaCSFilterEvent,
        gaCSUserBalance,
        gaPackSelectPack,
        gaPackSelectPayment,
        gaPackSignTransaction,
        gaPackUpdateTransaction,
        gaPackSuccess,
      }}
    >
      {children}
    </UserAnalytics.Provider>
  );
}

export function useUA(): UserAnalyticsInterface {
  const ctx = useContext(UserAnalytics);
  if (ctx === undefined) {
    throw new Error("useAppContext requires AppCtx.Provider");
  }
  return ctx;
}
