import {
  Image,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Link,
} from "@chakra-ui/react";
import { memo, useEffect, useRef, useState } from "react";
import { createTotalPages, paginate, roundValue } from "../utils";
import Pagination from "./Pagination";
import PendingImg from "../assets/pending.jpg";
import NeoTable from "./Table/NeoTable";
import NeoTr from "./Table/NeoTr";
import NeoTd from "./Table/NeoTd";
import { Nft, useRoom } from "../contexts/roomContext";
import { getNftDetails } from "../services/nfts.service";
import TxtBlock from "./TxtBlock";
import HTMLBlock from "./HTMLBlock";

const createImage = (nft: Nft) => {
  const image = nft?.thumbnail || nft?.image;
  if (nft?.assetType?.includes("text/plain")) {
    return <TxtBlock nft={nft} boxSize="64px" maxW="64px" fontSize="4px" />;
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "64px" }} />;
  }
  return (
    <Image
      boxSize="64px"
      objectFit="cover"
      src={image}
      minWidth="64px"
      fallbackSrc={PendingImg}
    />
  );
};

const ListingRow = ({
  nft,
  reservePrice,
}: {
  nft: Nft;
  reservePrice: number;
}) => {
  const { decimals, abbr, truncate } = useRoom();
  const name = nft?.name;
  const units = 10 ** decimals;

  return (
    <NeoTr>
      <NeoTd paddingLeft="8px">{createImage(nft)}</NeoTd>
      <NeoTd>{name}</NeoTd>
      <NeoTd isNumeric>
        {decimals && reservePrice && roundValue(reservePrice / units, truncate)}{" "}
        {abbr}
      </NeoTd>
      <NeoTd>
        <Link
          href={nft?.marketplace?.url}
          target="_blank"
          color="#6C60FF"
          textDecor="underline"
        >
          More Info
        </Link>
      </NeoTd>
    </NeoTr>
  );
};

const ListingsTable = memo(({ listings }: { listings: any }) => {
  const isMountedRef = useRef(false);
  const [limit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<{ [nftId: string]: Nft }>();

  let nftIds: string[] = [];
  let reservePrices: { [id: string]: { listingPrice: number } } = {};

  listings.forEach(
    (userListings: { [id: string]: { listingPrice: number } }) => {
      nftIds.push(...Object.keys(userListings));
      reservePrices = {
        ...reservePrices,
        ...userListings,
      };
    }
  );

  const getNfts = async () => {
    try {
      const { data } = (await getNftDetails(nftIds)) as {
        data: Nft[];
      };
      const updates = data.reduce((acc: { [id: string]: Nft }, nft) => {
        acc[nft?.itemId] = nft;
        return acc;
      }, {});
      setData(updates);
    } catch (e) {
      console.log("error", e);
    } finally {
      isMountedRef.current = true;
    }
  };

  useEffect(() => {
    if (isMountedRef.current === true) return;
    getNfts();
  }, []);

  nftIds = nftIds.sort((a, b) => a.localeCompare(b));
  const totalPages = createTotalPages(nftIds, limit);
  return (
    <div className="listings-table-wrap">
      <TableContainer>
        <NeoTable>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Name</Th>
              <Th isNumeric>Reserve</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginate(nftIds, limit, currentPage)?.map((id) => {
              const reservePrice = reservePrices[id].listingPrice || 0;
              const nft = data?.[id];
              if (!nft) return;
              return (
                <ListingRow key={id} nft={nft} reservePrice={reservePrice} />
              );
            })}
          </Tbody>
        </NeoTable>
      </TableContainer>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
});

export default ListingsTable;
