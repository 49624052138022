import { Box, Center, Heading } from "@chakra-ui/react";
import ImageSlider from "../../components/ImageSlider";

// Investors
import stacksventures from "../../assets/investors/stacksventures.png";
import dhunaventures from "../../assets/investors/dhunaventures.png";
import dacm from "../../assets/investors/dacm.png";
import cossamer from "../../assets/investors/cossamer.png";
import cavalry from "../../assets/investors/cavalry.png";
import angelhub from "../../assets/investors/angelhub.png";
import northrock from "../../assets/investors/northrock.png";
import wrc from "../../assets/investors/wrc.png";
import whitesoraventures from "../../assets/investors/whitesoraventures.png";

// Communities
import vapingapes from "../../assets/communities/vapingapes.png";
import undeadpunkz from "../../assets/communities/undeadpunkz.png";
import satoshibles from "../../assets/communities/satoshibles.png";
import pixel from "../../assets/communities/pixel-lordz.jpeg";
import odk from "../../assets/communities/odk.jpeg";
import megapont from "../../assets/communities/megapont.png";
import chads from "../../assets/communities/chads.png";
import btcmachines from "../../assets/communities/btcmachines.png";

// Partners
import gamma from "../../assets/partners/gamma.png";
import solana from "../../assets/partners/solana.png";
import simplehash from "../../assets/partners/simplehash.png";
import mattereum from "../../assets/partners/mattereum.png";
import liquidium from "../../assets/partners/liquidium.png";
import footprint from "../../assets/partners/footprint.png";
import biconomy from "../../assets/partners/biconomy.png";
import aigora from "../../assets/partners/aigora.png";
import assetdash from "../../assets/partners/assetdash.jpg";
import baxus from "../../assets/partners/baxus.png";
import collectorcrypt from "../../assets/partners/collectorcrypt.png";
import smb from "../../assets/partners/smb.png";
import soraventures from "../../assets/partners/soraventures.png";
import tensorians from "../../assets/partners/tensorians.gif";

// Appeared In
import yahoo from "../../assets/appearedin/yahoo.png";
import crunchbase from "../../assets/appearedin/crunchbase.png";
import cointelegraph from "../../assets/appearedin/cointelegraph.png";
import benzinga from "../../assets/appearedin/benzinga.png";
import alexablockchain from "../../assets/appearedin/alexablockchain.png";
import aithority from "../../assets/appearedin/aithority.png";

type ImageProps = {
  name: string;
  image: string;
  link?: string;
};

export default function PartnerGroup() {
  const investors = [
    {
      name: "stacksventures",
      image: stacksventures,
      link: "https://stacksventures.io/",
    },
    {
      name: "whitesoraventures",
      image: whitesoraventures,
      link: "#",
    },
    {
      name: "dhunaventures",
      image: dhunaventures,
      link: "https://www.dhunaventures.com/",
    },
    {
      name: "dacm",
      image: dacm,
      link: "https://www.dacm.io/",
    },
    {
      name: "cossamer",
      image: cossamer,
      link: "https://www.gossamercap.com/",
    },
    {
      name: "cavalry",
      image: cavalry,
    },
    {
      name: "angelhub",
      image: angelhub,
      link: "https://angelhub.io/",
    },
    {
      name: "northrock",
      image: northrock,
      link: "https://northrockdigital.com/",
    },
    {
      name: "wrc",
      image: wrc,
      link: "https://whiterhinocapital.nl/",
    },
  ];

  const partners = [
    {
      name: "assetdash",
      image: assetdash,
      link: "https://www.assetdash.com/",
    },
    {
      name: "baxus",
      image: baxus,
      link: "https://www.baxus.co/",
    },
    {
      name: "collectorcrypt",
      image: collectorcrypt,
      link: "https://collectorcrypt.com/",
    },
    {
      name: "smb",
      image: smb,
      link: "https://solanamonkey.business/",
    },

    {
      name: "tensorians",
      image: tensorians,
      link: "https://www.tensorians.com/"
    },
    {
      name: "pixel",
      image: pixel,
      link: "https://twitter.com/PixelLordz",
    },
    {
      name: "odk",
      image: odk,
      link: "https://twitter.com/onedopekids",
    },
    {
      name: "megapont",
      image: megapont,
      link: "https://twitter.com/MegapontNFT",
    },
    {
      name: "chads",
      image: chads,
      link: "https://twitter.com/CHADSwtf",
    },
    {
      name: "btcmachines",
      image: btcmachines,
      link: "https://twitter.com/btcordinal",
    },
    {
      name: "solana",
      image: solana,
      link: "https://solana.org/",
    },
    {
      name: "simplehash",
      image: simplehash,
      link: "https://simplehash.com/",
    },
    {
      name: "mattereum",
      image: mattereum,
      link: "https://mattereum.com/",
    },
    {
      name: "liquidium",
      image: liquidium,
      link: "https://liquidium.fi/",
    },
    {
      name: "gamma",
      image: gamma,
      link: "https://gamma.io/",
    },
    {
      name: "footprint",
      image: footprint,
      link: "https://www.footprint.network/",
    },
    {
      name: "biconomy",
      image: biconomy,
      link: "https://www.biconomy.io/",
    },
    {
      name: "aigora",
      image: aigora,
      link: "https://www.aigora.ai/",
    },
  ];

  const appearedIn = [
    {
      name: "yahoo",
      image: yahoo,
      link: "https://finance.yahoo.com/news/neoswap-introduces-ai-proposed-trades-175100825.html",
    },
    {
      name: "crunchbase",
      image: crunchbase,
      link: "https://www.crunchbase.com/organization/neoswap",
    },
    {
      name: "cointelegraph",
      image: cointelegraph,
      link: "https://cointelegraph.com/news/on-combining-ai-and-blockchains-qa-with-mattereum-memriio-and-neoswap",
    },
    {
      name: "benzinga",
      image: benzinga,
      link: "https://www.benzinga.com/pressreleases/23/05/n32392739/neoswap-ai-conducts-worlds-first-smart-auction-of-ordinals-for-bitcoin-unveiling-a-new-era-in-the-",
    },
    {
      name: "alexablockchain",
      image: alexablockchain,
      link: "https://alexablockchain.com/neoswap-ai-makes-history-with-worlds-first-bitcoin-nfts-smart-auction/",
    },
    {
      name: "aithority",
      image: aithority,
      link: "https://aithority.com/machine-learning/neoswap-introduces-ai-proposed-trades-for-personalized-nft-trading-experience/",
    },
  ];

  // // Function to shuffle an array using the Fisher-Yates shuffle algorithm
  // const shuffle = (array: ImageProps[]): ImageProps[] => {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // };

  // // Shuffle the partners array
  // shuffle(partners);

  // // Calculate the size of each smaller array
  // const size = Math.ceil(partners.length / 3);

  // // Split the shuffled array into 3 smaller arrays
  // const splitArrays = [
  //   partners.slice(0, size),
  //   partners.slice(size, size * 2),
  //   partners.slice(size * 2),
  // ];

  return (
    <Box>
      <Center mt={"20px"}>
        <Heading as={"h2"} size={"xl"} my={"20px"}>
          Partners
        </Heading>
      </Center>
      <ImageSlider images={partners} />

      <Center mt={"20px"}>
        <Heading as={"h2"} size={"xl"} my={"20px"}>
          Investors
        </Heading>
      </Center>
      <ImageSlider images={investors} />

      <Center mt={"20px"}>
        <Heading as={"h2"} size={"xl"} my={"20px"}>
          Media
        </Heading>
      </Center>
      <ImageSlider images={appearedIn} />
    </Box>
  );
}
