import { calculateRoi, ItemData } from "./utils";
import React, { useMemo } from 'react';
import { Pack, ItemType, SourceMarketplace, Item } from '../../types/packs';
import PackCard from "../PackCard";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { InfoIcon } from "@chakra-ui/icons";
import { DESCRPTIONS } from "./descriptions"

const TULLE_FEE = 5;
const MARKETPLACE_FEE = 6;

const CraftingPacks: React.FC<{ items: ItemData[], handleOpenModal: ({ pack, packId }: { pack?: Pack; packId?: string }) => void }> = ({ items: itemData, handleOpenModal }) => {
    let craftingPacks = useMemo(() => {
        // Filter craftable items (those with a recipe)
        const craftableItems = itemData.filter(item => item.recipe);

        // Sort by ROI
        const sortedCraftableItems = craftableItems.sort((a, b) => calculateRoi({ item: b, tulleFee: TULLE_FEE, marketplaceFee: MARKETPLACE_FEE, units: 10000, resources: itemData }) - calculateRoi({ item: a, tulleFee: TULLE_FEE, marketplaceFee: MARKETPLACE_FEE }));

        // Take top 5 items with positive ROI, or fewer if there aren't 5 positive ROI items
        const topCraftableItems = sortedCraftableItems.filter(item => calculateRoi({ item: item, tulleFee: TULLE_FEE, marketplaceFee: MARKETPLACE_FEE, units: 10000, resources: itemData }) > 0).slice(0, 5);

        // Create packs for top craftable items
        return topCraftableItems.map(item => {
            const ingredients = item.recipe!.map(ing => {
                const ingredientItem = itemData.find(i => i.onChainId === ing.mint);
                return {
                    details: {
                        id: ingredientItem!.id,
                        name: ingredientItem!.name,
                        image: ingredientItem!.image,
                        onChainId: ingredientItem!.onChainId,
                        type: "sft" as ItemType,
                        sourceMarketplace: ingredientItem!.sourceMarketplace as SourceMarketplace,
                    },
                    quantity: 10000 * ing.amount
                };
            });

            return {
                id: `craft_${item.id}`,
                name: `${item.name} Crafting Pack: ROI ${Math.round(calculateRoi({ item: item, tulleFee: TULLE_FEE, marketplaceFee: MARKETPLACE_FEE }))}%`,
                description: `Materials needed to craft ${item.name}`,
                items: ingredients,
                summary: ingredients
            };
        });
    }, [itemData]);


    const food = [
        {
            details: {
                id: "food",
                name: "Food",
                onChainId: "foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
                type: "sft" as ItemType,
                url: "https://solscan.io/token/foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG",
                sourceMarketplace: "staratlas" as SourceMarketplace,
                image: "https://storage.googleapis.com/nft-assets/items/FOOD.png",
            },
            quantity: 1000000
        }
    ] as Item[]

    const toolkit = [{
        details: {
            id: "toolkit",
            name: "Toolkit",
            onChainId: "tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL",
            type: "sft" as ItemType,
            url: "https://solscan.io/token/tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL",
            sourceMarketplace: "staratlas" as SourceMarketplace,
            image: "https://storage.googleapis.com/nft-assets/items/TOOL.png",
        },
        quantity: 1000000
    }] as Item[]


    const createResupplyPack = (type: 'food' | 'toolkit', size: string, quantity: number) => ({
        id: `${type}_pack_${size}`,
        name: `${size.charAt(0).toUpperCase() + size.slice(1)} ${type.charAt(0).toUpperCase() + type.slice(1)} Resupply Pack`,
        description: `Speed up crafting process by resupplying your starbase with ${quantity.toLocaleString()} ${type}`,
        items: [{ ...(type === 'food' ? food[0] : toolkit[0]), quantity }],
        summary: [{ ...(type === 'food' ? food[0] : toolkit[0]), quantity }]
    }) as Pack;

    const smallFoodPack = createResupplyPack('food', 'small', 10000);
    const mediumFoodPack = createResupplyPack('food', 'medium', 100000);
    const largeFoodPack = createResupplyPack('food', 'large', 1000000);

    const smallToolkitPack = createResupplyPack('toolkit', 'small', 10000);
    const mediumToolkitPack = createResupplyPack('toolkit', 'medium', 100000);
    const largeToolkitPack = createResupplyPack('toolkit', 'large', 1000000);

    const foodPacks = [smallFoodPack, mediumFoodPack, largeFoodPack];
    const toolkitPacks = [smallToolkitPack, mediumToolkitPack, largeToolkitPack];


    const packs = [...craftingPacks, ...foodPacks, ...toolkitPacks] as Pack[]

    return (
        <Box borderWidth="1px" borderRadius="lg" p={4} >
            <h2 className="text-amber-300 text-2xl rogan-regular leading-10 tracking-widest uppercase mb-4" style={{ textAlign: 'center', marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Crafting Packs
                <Flex ml={2} fontSize={"0.9rem"}>
                    <Tooltip label={DESCRPTIONS.crafting_packs}  ><InfoIcon color="yellow.100" /></Tooltip>
                </Flex>
            </h2>

            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                className="mySwiper"
                style={{ width: '100%', maxWidth: '1000px', margin: '0 auto' }}
                pagination={true} modules={[Pagination]}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {packs.map((pack) => (
                    <SwiperSlide key={pack.id} >
                        <PackCard pack={pack} onOpenModal={handleOpenModal} />
                    </SwiperSlide>
                ))}
            </Swiper>

        </ Box >
    );
};

export default CraftingPacks;
