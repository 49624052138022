import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Tooltip,
  Icon,
  Button,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { Formik } from "formik";

import * as Yup from "yup";
import { useAppContext } from "../contexts/appContext";
import { updateInfo, updateTerms } from "../services/user.service";
import { hasError } from "../utils";
import DiscordInput from "./Inputs/DiscordInput";
import TwitterInput from "./Inputs/TwitterInput";
import TelegramInput from "./Inputs/TelegramInput";

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter a display name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required!"), // pass your error message string
});

export default function ProfileForm() {
  const toast = useToast();
  const {
    uid,
    email,
    user,
    displayName,
    avatar,
    updateEmail,
    updateUserProfile,
  } = useAppContext();

  const initialValues = {
    email: user?.email || email?.value || "",
    displayName: user?.displayName || displayName || "",
    tacChecked: false,
    notificationsChecked: false,
  };

  const handleError = (e: any) => {
    let message = "Please Try Again!";

    if (e.code === "auth/requires-recent-login") {
      message = "Please re-login to update your email!";
    }

    if (e.code === "auth/email-already-in-use") {
      message = "Email already in use!";
    }

    if (e.code === "auth/too-many-requests") {
      message = "Too many requests, please try again later!";
    }

    toast({
      title: "Error updating your email!",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleSubmit = async (values: typeof initialValues, props: any) => {
    try {
      await updateUserProfile({ displayName: values.displayName });

      await updateEmail(values.email);

      await updateInfo(uid!, "email", { value: values.email });

      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      });
      toast({
        title: "Profile Saved Successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      handleError(e);
    } finally {
      props.setSubmitting(false);
    }
  };
  return (
    <Box width="100%" marginTop={50}>
      <Center>
        <Box width={{ base: "100%", md: "75%" }} padding={10}>
          <Container width={{ base: "100%", md: "md" }}>
            <div className="new-bg-transparent p-6 rounded-lg">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ errors, touched, values, handleChange, ...props }) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <Flex
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="6"
                      >
                        <Avatar size="xl" src={avatar} />
                        <FormControl
                          isRequired
                          isInvalid={hasError(errors, touched, "displayName")}
                        >
                          <FormLabel htmlFor="displayName">
                            Display Name
                          </FormLabel>
                          <Input
                            id="displayName"
                            name="displayName"
                            onChange={handleChange}
                            value={values.displayName}
                          />
                          <FormErrorMessage>
                            {errors.displayName}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isRequired
                          isInvalid={hasError(errors, touched, "email")}
                        >
                          <Flex>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Tooltip label="The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.">
                              <Icon as={InfoIcon} />
                            </Tooltip>
                          </Flex>
                          <Input
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <TwitterInput />
                        <TelegramInput />
                        <DiscordInput />
                        <Button
                          type="submit"
                          width="100%"
                          isLoading={props.isSubmitting}
                        >
                          Update
                        </Button>
                      </Flex>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Container>
        </Box>
      </Center>
    </Box>
  );
}
