import { starAtlasFont } from "./utils";
import React from 'react';
import { Box, Text } from "@chakra-ui/react";

const HowItWorksCard: React.FC<{}> = () => {
    return (
        <Box className="packs-container">
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box style={{ marginBottom: '20px', background: '#202024' }} m={4} p={4}>
                    <Text color="#ffbe4d" style={{ ...starAtlasFont, fontSize: '2rem' }}> How it works? </Text>
                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem', color: "white" }}> Tulle Star Atlas packs are designed to help with onboarding and optimizing your playtime in Star Atlas. We support both new and experienced players in acquiring game data and items.</Text>
                </Box>
                <Box style={{ marginBottom: '20px', background: '#202024' }} m={4} p={4}>
                    <Text color="#ffbe4d" style={{ ...starAtlasFont, fontSize: '2rem' }}> Starter packs </Text>
                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>  Starter packs are designed to help onboard new players to the game. They contain essential items for your first space missions.
                        After buying a pack you can go directly to <a href="https://based.staratlas.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#ffbe4d' }}>STAR ATLAS</a>, desposit items from your wallet in LOCAL INVENTORY and PORT OF ENTRY and start playing.</Text>
                </Box>
                <Box style={{ marginBottom: '20px', background: '#202024' }} m={4} p={4}>
                    <Text color="#ffbe4d" style={{ ...starAtlasFont, fontSize: '2rem' }}> Crafting helper </Text>
                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>  Whether you're an experienced player or just starting and want to explore the "Play to Earn" concept in Star Atlas, you can use our crafting helper. This tool is designed to provide essential game data and help you find the most profitable items to craft, which you can later sell on the Star Atlas
                        <a href="https://play.staratlas.com/market/" target="_blank" rel="noopener noreferrer" style={{ color: '#ffbe4d' }}> MARKETPLACE</a>.</Text>
                </Box>



                <Box style={{ marginBottom: '20px', background: '#202024' }} m={4} p={4}>
                    <Text color="#ffbe4d" style={{ ...starAtlasFont, fontSize: '2rem' }}> Theo </Text>
                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>  If you need a customized item pack, want to explore game lore, check current prices, or learn about specific game mechanics, you can ask our chatbot, Theo, for help. Theo is still learning, but he's quite knowledgeable about the game!</Text>
                </Box>
                <Box style={{ marginBottom: '20px', background: '#202024' }} m={4} p={4}>
                    <Text color="#ffbe4d" style={{ ...starAtlasFont, fontSize: '2rem' }}> More content </Text>
                    <Text style={{ ...starAtlasFont, fontSize: '1.2rem' }}>  More Star Atlas content is coming soon! If you have any ideas for features to add next, feel free to share them with us using the feedback button on the left.</Text>
                </Box>
            </Box>
        </Box>
    );
};

export default HowItWorksCard;
