import { ItemData } from "../../utils";
import { useState, useMemo, useCallback } from "react";
import { Box, Flex, Text, Table, Button, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Checkbox, Tooltip, Select, VStack, FormControl, FormLabel } from "@chakra-ui/react";
import { calculateRevenue, calculateRoi } from "../../utils";
import { DESCRPTIONS } from "../../descriptions";
import { TULLE_FEE, starAtlasFont, calculateSellPrice, calculateBuyPrice, formatCost, calculateIngredientsCost } from "../../utils"
import { InfoIcon, SearchIcon, SmallCloseIcon } from '@chakra-ui/icons'


interface CraftableItemsTableProps {
    items: ItemData[];
}

const HTMLTooltip: React.FC<{ label: string }> = ({ label }) => (
    <div dangerouslySetInnerHTML={{ __html: label }} />
);

const formatUnits = (units: number) => {
    if (units === 1) return '1 unit';
    if (units === 10) return '10 units';
    if (units === 100) return '100 units';
    if (units === 1000) return '1K';
    if (units === 10000) return '10K';
    if (units === 100000) return '100K';
    if (units === 1000000) return '1M';
    return `${units} units`;
};


const IngredientsCost: React.FC<{ item: ItemData; items: ItemData[]; units: number, tulleFee: number }> = ({ item, items, units, tulleFee }) => {
    if (!item.recipe) {
        return (<Text>-</Text>)
    }

    const getIngredientTooltip = () => {
        return item.recipe!.map((ingredient) => {
            const ingredientData = items.find(i => i.onChainId === ingredient.mint)!;
            const sellPrice = formatCost(calculateSellPrice(ingredientData, units, tulleFee));
            return `${ingredient.amount} x ${ingredientData?.name} (Sell: ${sellPrice} ATLAS/${formatUnits(units)})`;
        }).join('<br />');
    };

    const totalIngredientsCost = calculateIngredientsCost(item, items, units, tulleFee);

    return (
        <Tooltip label={<HTMLTooltip label={getIngredientTooltip()} />} hasArrow shouldWrapChildren>
            <Text color="yellow.100" cursor="pointer">
                {formatCost(totalIngredientsCost) || 'N/A'}
            </Text>
        </Tooltip>
    );
};


export const CraftableItemsTable: React.FC<CraftableItemsTableProps> = ({ items }) => {
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [marketplaceFee, setMarketplaceFee] = useState<number>(6);
    const [tulleFee, setTulleFee] = useState<number>(0);
    const [units, setUnits] = useState<number>(1000);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showOnlyProfitable, setShowOnlyProfitable] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [includeMarketplaceFee, setIncludeMarketplaceFee] = useState(false);
    const [fromBuyPrice, setCalculateForBuyPrice] = useState(false);
    const [includeTulleFee, setIncludeTulleFee] = useState(false)
    const [crewMembers, setCrewMembers] = useState<number>(1);
    const [showNonCraftableResources, setShowNonCraftableResources] = useState(false);


    const itemsToDisplay = useMemo(() => {
        if (showNonCraftableResources)
            return items.filter(item => item.category === "resource")

        return items.filter(item => item.recipe && item.recipe.length > 0 && item.category === "resource")
    }, [items, showNonCraftableResources]);

    const handleIncludeTulleFee = (checked: boolean) => {
        if (checked) {
            setTulleFee(TULLE_FEE)
            setIncludeTulleFee(true)
        }
        else {
            setTulleFee(0)
            setIncludeTulleFee(false)
        }
    }

    const handleCrewMembersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCrewMembers(Number(event.target.value));
    };

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const calculateItemSellPrice = useCallback((item: ItemData) => {
        let price;
        if (includeMarketplaceFee)
            price = calculateSellPrice(item, units, tulleFee) * (1 - marketplaceFee / 100);
        else price = calculateSellPrice(item, units, tulleFee);

        return formatCost(price)
    }, [units, tulleFee, marketplaceFee, includeMarketplaceFee]);

    const calculateItemBuyPrice = useCallback((item: ItemData) => {
        const price = calculateBuyPrice(item, units);
        return formatCost(price)
    }, [units]);


    const calculateItemRoi = useCallback((item: ItemData) => {
        if (!item.recipe) return;

        return calculateRoi({ item, tulleFee, marketplaceFee, fromBuyPrice, units, resources: items });
    }, [tulleFee, marketplaceFee, includeMarketplaceFee, fromBuyPrice, units]);

    const calculateItemRevenue = useCallback((item: ItemData, duration: number) => {
        if (!item.recipe) return;

        return calculateRevenue({ item, time: duration, crewMembers, tulleFee, marketplaceFee, fromBuyPrice, resources: items });
    }, [tulleFee, marketplaceFee, includeMarketplaceFee, fromBuyPrice, crewMembers]);

    const filteredAndSortedItems = useMemo(() => {
        let result = itemsToDisplay;

        // Filter items based on search term
        if (searchTerm) {
            result = result.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Sort items (existing sorting logic)
        if (sortColumn) {
            result = result.sort((a, b) => {
                let aValue: any, bValue: any;

                switch (sortColumn) {
                    case 'name':
                        aValue = a.name;
                        bValue = b.name;
                        break;
                    case 'buyPrice':
                        aValue = a.buyPrice.atlas;
                        bValue = b.buyPrice.atlas;
                        break;
                    case 'sellPrice':
                        aValue = a.sellPrice.atlas;
                        bValue = b.sellPrice.atlas;
                        break;
                    case 'ingredientsCost':
                        aValue = a.ingredientsCost;
                        bValue = b.ingredientsCost;
                        break;
                    case 'craftingFee':
                        aValue = a.craftingFee;
                        bValue = b.craftingFee;
                        break;
                    case 'duration':
                        aValue = a.craftingDuration;
                        bValue = b.craftingDuration;
                        break;
                    case 'roi':
                        aValue = calculateItemRoi(a);
                        bValue = calculateItemRoi(b);
                        break;
                    case 'dailyRevenue':
                        aValue = calculateItemRevenue(a, 24 * 3600);
                        bValue = calculateItemRevenue(b, 24 * 3600);
                        break;
                    default:
                        return 0;
                }

                if (aValue === undefined && bValue === undefined) return 0;
                if (aValue === undefined) return 1;
                if (bValue === undefined) return -1;
                if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
                return 0;
            });
        }

        if (showOnlyProfitable) {
            result = result.filter(item => item.recipe && calculateItemRoi(item)! > 0);
        }

        return result;
    }, [itemsToDisplay, searchTerm, sortColumn, sortDirection, marketplaceFee, tulleFee, showOnlyProfitable, calculateItemRoi, calculateItemRevenue]);

    const getSortIcon = (column: string) => {
        if (sortColumn !== column) return '▲▼';
        return sortDirection === 'asc' ? '▲' : '▼';
    };


    const TableHeader: React.FC<{ label: string, column: string, description?: string }> = ({ label, column, description }) => (
        <th onClick={() => handleSort(column)} style={{ cursor: 'pointer', ...starAtlasFont, padding: '0 5px', fontSize: '1.1rem', marginTop: "4px", marginBottom: "4px" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {label} <span style={{ marginLeft: '5px', fontSize: '0.5rem' }}>{getSortIcon(column)}</span>
                {description && <Tooltip label={description}><InfoIcon ml={1} color="yellow.100" fontSize={"0.8rem"} /></Tooltip>}
            </div>
        </th>
    );

    return (
        <Box overflowX="auto" borderWidth="1px" borderRadius="lg" p={4} >
            <h2 className="text-amber-300 text-2xl rogan-regular leading-10 tracking-widest uppercase mb-4" style={{ textAlign: 'center', marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Crafting Data
                <Flex ml={2} style={{ fontSize: "1.0rem" }}>
                    <Tooltip label={DESCRPTIONS.crafting_data} ><InfoIcon color="yellow.100" /></Tooltip>
                </Flex>
            </h2>


            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="center" width="100%" mb={4}>
                <Box position="relative" width={{ base: "100%", md: "50%" }} mb={{ base: 4, md: 0 }}>
                    <Input
                        type="text"
                        placeholder="Search items..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            ...starAtlasFont,
                            padding: '0.5rem',
                            width: '100%',
                            paddingLeft: '35px',
                            borderWidth: '1px',
                            borderRadius: '4px'
                        }}
                    />
                    <SearchIcon
                        position="absolute"
                        left="10px"
                        top="50%"
                        transform="translateY(-50%)"
                        zIndex={2}
                        pointerEvents="none"
                    />
                    <SmallCloseIcon
                        background="gray.600"
                        rounded="full"
                        _hover={{ bg: 'gray.400' }}
                        position="absolute"
                        right="10px"
                        top="50%"
                        transform="translateY(-50%)"
                        zIndex={2}
                        onClick={() => setSearchTerm('')}
                    />
                </Box>
                <Flex direction={{ base: "column", md: "row" }} alignItems="center" justifyContent="space-between" width={{ base: "100%", md: "50%" }}>
                    <Button ml={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }} onClick={onOpen} style={{ fontFamily: "RoganRegular", fontWeight: "bold", padding: '0.25rem 0.5rem', backgroundColor: '#1e293b', color: '#ffffff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Advanced Options</Button>
                    <Text mr={2} className="text-amber-300" style={{ ...starAtlasFont, fontSize: '1.2rem' }}>
                        Prices are in ATLAS for {formatUnits(units)}
                    </Text>
                </Flex>
            </Flex>
            <Table borderWidth={"1px"} className="crafting-calculator-table" style={{ textAlign: 'center', fontSize: '0.9rem', borderSpacing: '0 8px', borderRadius: '8px', background: '#202024' }}>
                <thead style={{ borderWidth: "1px" }}>
                    <tr>
                        <TableHeader label="Item" column="name" />
                        <TableHeader label="Instant Sell Price" description={DESCRPTIONS.instant_sell_price} column="buyPrice" />
                        <TableHeader label="Instant Buy Price" description={DESCRPTIONS.instant_buy_price} column="sellPrice" />
                        <TableHeader label="Ingredients Cost" column="ingredientsCost" />
                        <TableHeader label="Crafting Fee" description={DESCRPTIONS.crafting_fee} column="craftingFee" />
                        <TableHeader label="Duration" description={DESCRPTIONS.duration} column="duration" />
                        <TableHeader label="ROI" column="roi" description={DESCRPTIONS.roi} />
                        <TableHeader label="Daily Revenue" description={DESCRPTIONS.daily_revenue} column="dailyRevenue" />
                    </tr>
                </thead>
                <tbody>
                    {filteredAndSortedItems.map((item) => {

                        const roi = calculateItemRoi(item);
                        const dailyRevenue = calculateItemRevenue(item, 24 * 3600);

                        return (
                            <tr key={item.id}>
                                <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center', ...starAtlasFont }}>
                                    <a
                                        href={`https://play.staratlas.com/market/${item.onChainId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            transition: 'opacity 0.3s ease',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                                        onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                                    >
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            className="item-image"
                                            width="40"
                                            height="40"
                                            style={{ marginRight: '10px', marginLeft: '10px', marginBottom: `5px` }}
                                        />
                                        <Text color="yellow.100" style={{ fontSize: '1.0rem' }}>{item.name}</Text>
                                    </a>
                                </td>
                                <td>
                                    <Box sx={{ ...starAtlasFont }} key={item.name}>{calculateItemBuyPrice(item)}</Box>
                                </td>
                                <td>
                                    <Box sx={{ ...starAtlasFont }} key={item.name}>{calculateItemSellPrice(item)}</Box>
                                </td>
                                <td style={{ ...starAtlasFont }}>
                                    <IngredientsCost item={item} items={items} units={units} tulleFee={tulleFee} />
                                </td>
                                <td style={{ ...starAtlasFont }}>
                                    {item.craftingFee! ? formatCost(item.craftingFee! * units) : "-"}
                                </td>
                                <td style={{ ...starAtlasFont }}>{item.craftingDuration ? `${item.craftingDuration}` : '-'}</td>
                                <td style={{ ...starAtlasFont }}>{roi ? `${roi.toFixed(0)}%` : "-"}</td>
                                <td style={{ ...starAtlasFont }}>{item.craftingDuration ? dailyRevenue!.toFixed(2) : "-"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className="packs-container" borderRadius="4px" maxWidth="640px" width="90%">
                    <ModalHeader style={{ ...starAtlasFont, fontSize: '1.5rem' }}>Advanced Options</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex alignItems="center" mb={4}>
                            <Text mr={2} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Marketplace Fee:</Text>
                            <Tooltip label={DESCRPTIONS.marketplace_fee}><InfoIcon color="yellow.100" /></Tooltip>
                            <Flex ml={2}>
                                {[6, 5.4, 5.1, 4.5, 3.3, 0.9].map((fee) => (
                                    <Button
                                        key={fee}
                                        onClick={() => setMarketplaceFee(fee)}
                                        style={{
                                            fontFamily: "RoganRegular",
                                            fontWeight: "bold",
                                            marginRight: '0.5rem',
                                            padding: '0.25rem 0.5rem',
                                            backgroundColor: marketplaceFee === fee ? '#fcd34d' : '#1e293b',
                                            color: marketplaceFee === fee ? '#000000' : '#ffffff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {fee}%
                                    </Button>
                                ))}
                            </Flex>
                        </Flex>

                        {/* Existing options */}
                        <VStack align="start">
                            <Checkbox
                                isChecked={showOnlyProfitable}
                                onChange={(e) => setShowOnlyProfitable(e.target.checked)}
                                style={{ ...starAtlasFont, fontSize: '1.2rem' }}
                                mb={2}
                                sx={{
                                    '.chakra-checkbox__control': {
                                        backgroundColor: showOnlyProfitable ? '#fcd34d' : 'inherit',
                                        borderColor: showOnlyProfitable ? '#fcd34d' : 'inherit',
                                    }
                                }}
                            >
                                <Text ml={1} fontSize="1.2rem"> Show only profitable items </Text>
                            </Checkbox>
                            <Checkbox
                                isChecked={showNonCraftableResources}
                                onChange={(e) => setShowNonCraftableResources(e.target.checked)}
                                style={{ ...starAtlasFont, fontSize: '1.2rem' }}
                                mb={2}
                                sx={{
                                    '.chakra-checkbox__control': {
                                        backgroundColor: showNonCraftableResources ? '#fcd34d' : 'inherit',
                                        borderColor: showNonCraftableResources ? '#fcd34d' : 'inherit',
                                    }
                                }}
                            >
                                <Text ml={1} fontSize="1.2rem"> Show non-craftable resources </Text>
                            </Checkbox>
                            <Checkbox
                                isChecked={includeMarketplaceFee}
                                onChange={(e) => setIncludeMarketplaceFee(e.target.checked)}
                                style={{ ...starAtlasFont, fontSize: '1.2rem' }}
                                mb={2}
                                sx={{
                                    '.chakra-checkbox__control': {
                                        backgroundColor: includeMarketplaceFee ? '#fcd34d' : 'inherit',
                                        borderColor: includeMarketplaceFee ? '#fcd34d' : 'inherit',
                                    }
                                }}
                            >
                                <Text ml={1} fontSize="1.2rem"> Include marketplace fee in sell price </Text>
                            </Checkbox>
                            <Checkbox
                                isChecked={fromBuyPrice}
                                onChange={(e) => setCalculateForBuyPrice(e.target.checked)}
                                style={{ ...starAtlasFont }}
                                mb={2}
                                sx={{
                                    '.chakra-checkbox__control': {
                                        backgroundColor: fromBuyPrice ? '#fcd34d' : 'inherit',
                                        borderColor: fromBuyPrice ? '#fcd34d' : 'inherit',
                                    }
                                }}
                            >
                                <Text ml={1} fontSize="1.2rem" display="flex" alignItems="center">
                                    Calculate ROI and Daily Revenue for instant sell price
                                    <Tooltip label={DESCRPTIONS.instant_sell_price_roi}>
                                        <InfoIcon ml={2} color="yellow.100" fontSize="1.0rem" />
                                    </Tooltip>
                                </Text>
                            </Checkbox>
                            <Checkbox
                                isChecked={includeTulleFee}
                                onChange={(e) => handleIncludeTulleFee(e.target.checked)}
                                style={{ ...starAtlasFont }}
                                mb={2}
                                sx={{
                                    '.chakra-checkbox__control': {
                                        backgroundColor: includeTulleFee ? '#fcd34d' : 'inherit',
                                        borderColor: includeTulleFee ? '#fcd34d' : 'inherit',
                                    }
                                }}
                            >
                                <Text ml={1} fontSize="1.2rem" display="flex" alignItems="center">
                                    Include Tulle Fee
                                    <Tooltip label={DESCRPTIONS.tulle_fee_info}>
                                        <InfoIcon ml={2} color="yellow.100" fontSize="1.0rem" />
                                    </Tooltip>
                                </Text>
                            </Checkbox>
                        </VStack>
                        <Flex alignItems="center" mt={4}>
                            <Text mr={1} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>Display prices for:</Text>
                            <Tooltip label={DESCRPTIONS.price_for_units}>
                                <InfoIcon ml={1} color="yellow.100" fontSize="1.0rem" />
                            </Tooltip>
                            <Flex>
                                {[1, 10, 100, 1000, 10000, 100000, 1000000].map((value) => (
                                    <Button
                                        key={value}
                                        onClick={() => setUnits(value)}
                                        style={{
                                            fontFamily: "RoganRegular",
                                            fontWeight: "bold",
                                            marginLeft: '0.5rem',
                                            padding: '0.25rem 0.5rem',
                                            backgroundColor: units === value ? '#fcd34d' : '#1e293b',
                                            color: units === value ? '#000000' : '#ffffff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {value === 1 ? '1' :
                                            value === 10 ? '10' :
                                                value === 100 ? '100' :
                                                    value === 1000 ? '1K' :
                                                        value === 10000 ? '10K' :
                                                            value === 100000 ? '100K' : '1M'}
                                    </Button>
                                ))}
                            </Flex>
                            <Text ml={2} style={{ ...starAtlasFont, fontSize: '1.2rem' }}>units</Text>
                        </Flex>
                        <FormControl mt={4} display="flex" alignItems="center">
                            <FormLabel htmlFor="crewMembers" sx={{ ...starAtlasFont, fontSize: '1.2rem', marginRight: '1rem', marginBottom: 0 }}>
                                Crew Members
                            </FormLabel>
                            <Input
                                id="crewMembers"
                                type="number"
                                value={crewMembers === 0 ? '0' : crewMembers.toString()}
                                onChange={(e) => {
                                    const value = e.target.value === '' ? 0 : Math.max(0, parseInt(e.target.value));
                                    if (!isNaN(value) && value >= 0) {
                                        handleCrewMembersChange(e);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                        e.preventDefault();
                                    }
                                }}
                                min={0}
                                step={1}
                                width="90px"
                            />
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box >
    );
};
