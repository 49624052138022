import { DurationObjectUnits } from "luxon";


interface Props {
  expired?: boolean;
  timeRemainingSec?: number;
  timeLeftUnits?: DurationObjectUnits
}

export default function TimeLeft({ expired, timeRemainingSec, timeLeftUnits }: Props) {

  if (!timeRemainingSec) return null;

  return (
    <>
      {expired ? (
        <span className="text-red-400 text-[42px] font-medium">
          Time Expired
        </span>
      ) : (
        <span
          className={`${
            timeRemainingSec && timeRemainingSec > 60
              ? "text-green-400"
              : "text-red-400"
          } text-[42px] font-medium`}
        >
          {timeLeftUnits?.minutes}m:{timeLeftUnits?.seconds}s
        </span>
      )}
    </>
  );
}
