export default function IconSmartAuction() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <circle cx="15" cy="3.2" r="3.2" fill="currentColor"/>
      <path d="M9.2,24.3c-0.9,1-2.2,1.6-3.6,1.6s-2.7-0.6-3.6-1.6C0.8,25.1,0,26.4,0,27.9v1.2C0,29.6,0.4,30,0.9,30h9.4
		c0.5,0,0.9-0.4,0.9-0.9v-1.2C11.1,26.4,10.4,25.1,9.2,24.3z" fill="currentColor"/>
      <path d="M28.1,24.3c-0.9,1-2.2,1.6-3.6,1.6s-2.7-0.6-3.6-1.6c-1.2,0.8-1.9,2.1-1.9,3.6v1.2c0,0.5,0.4,0.9,0.9,0.9h9.4
		c0.5,0,0.9-0.4,0.9-0.9v-1.2C30,26.4,29.2,25.1,28.1,24.3z" fill="currentColor"/>
      <path d="M21.3,21.4c0.2,1.5,1.6,2.7,3.2,2.7c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2c-1.3,0-2.5,0.8-3,2l-3.8-1.2
		c0-1.1-0.8-2-1.8-2.4v-3.8h3.8c0.5,0,0.9-0.4,0.9-0.9v-1.2c0-1.5-0.8-2.8-1.9-3.6c-0.9,1-2.2,1.6-3.6,1.6s-2.7-0.6-3.6-1.6
		c-1.2,0.8-1.9,2.1-1.9,3.6v1.2c0,0.5,0.4,0.9,0.9,0.9h3.8v3.8c-1,0.4-1.7,1.3-1.8,2.4l-3.8,1.2c-0.5-1.2-1.6-2-3-2
		c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2c1.6,0,2.9-1.2,3.2-2.7l4.1-1.3c0.5,0.6,1.2,1.1,2.1,1.1c0.9,0,1.6-0.4,2.1-1.1L21.3,21.4z
		" fill="currentColor"/>
    </svg>
  );
}
