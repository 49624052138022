// @ts-nocheck
import React, { useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormErrorMessage,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";

function SecretBidModal({
  open = false,
  handleClose,
  timeLeft,
}: {
  open: boolean;
  handleClose: any;
  timeLeft: number;
}) {
  const cancelRef = useRef<HTMLDivElement>(null);

  return (
    <AlertDialog
      id="notification-info-modal"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
      isOpen={open}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxWidth="500px" className="ns-dialog-content">
        <AlertDialogCloseButton />
        <AlertDialogBody py={"30px"}>
          <Box textAlign={"center"}>
            <Text as={"h3"} fontSize={"30px"} mb={"10px"}>
              Only{" "}
              <Text as={"span"} color={"#e34619"}>
                {Math.max(0, Math.round(timeLeft))} seconds
              </Text>{" "}
              remaining
            </Text>
            <Text fontSize="18px" mb={"8px"}>
            In this last minute, an additional 60 seconds will be added to the auction for each new high bid.
            </Text>
            <Button mt={"8px"} onClick={() => handleClose()}>
              Got It!
            </Button>
          </Box>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default SecretBidModal;
