import ReactSelect, { Props } from "react-select";
import CreatableSelect from "react-select/creatable";

export const neoSelectStyles = {
  container: (styles: any) => ({
    ...styles,
    width: "100%",
    pointerEvents: "auto",
    valueContainer: {
      color: "red",
    },
  }),
  input: (styles: any) => ({
    ...styles,
    color: "inherit",
    lineHeight: 1,
    width: "100%",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    backgroundColor: "#425661",
  }),
  control: (styles: any) => ({
    ...styles,
    "&:hover": {
      cursor: "pointer",
      borderColor: "#425661",
    },
    borderRadius: "7px",
    padding: "1px",
    backgroundColor: "transparent",
    width: "100%",
    position: "relative",
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: "#041b28",
  }),
  menuPortal: (styles: any) => ({
    ...styles,
  }),
  listbox: (styles: any) => ({
    ...styles,
    background: "red",
    zIndex: 9999
  }),
  option: (styles: any) => ({
    ...styles,
    backgroundColor: "#041b28",
  }),
};

const theme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral80: "#fff",
    neutral60: "#425661",
    neutral50: "#425661",
    primary25: "#425661",
    primary: "#59b5ea",
  },
});

export default function NeoSelect({
  isCreatable,
  ...props
}: Props & { isCreatable?: boolean }) {
  if (isCreatable)
    return (
      <CreatableSelect
        styles={neoSelectStyles}
        {...props}
        theme={theme}
        defaultValue={{
          label: "Select...",
          value: "",
        }}
        classNames={{
          control: (state) => (state.isFocused ? "dropdownfocused" : ""),
        }}
      />
    );
  return <ReactSelect styles={neoSelectStyles} {...props} theme={theme} />;
}

// const chakraStyles = {
//   // When disabled, react-select sets the pointer-state to none
//   // which prevents the `not-allowed` cursor style from chakra
//   // from getting applied to the Control
//   container: (provided) => ({
//     ...provided,
//     pointerEvents: "auto"
//   }),
//   input: (provided) => ({
//     ...provided,
//     color: "inherit",
//     lineHeight: 1
//   }),
//   menu: (provided) => ({
//     ...provided,
//     boxShadow: "none"
//   }),
//   valueContainer: (provided, { selectProps: { size } }) => {
//     const px = {
//       sm: "0.75rem",
//       md: "1rem",
//       lg: "1rem"
//     };

//     return {
//       ...provided,
//       padding: `0.125rem ${px[size]}`
//     };
//   },
//   loadingMessage: (provided, { selectProps: { size } }) => {
//     const fontSizes = {
//       sm: "0.875rem",
//       md: "1rem",
//       lg: "1.125rem"
//     };

//     const paddings = {
//       sm: "6px 9px",
//       md: "8px 12px",
//       lg: "10px 15px"
//     };

//     return {
//       ...provided,
//       fontSize: fontSizes[size],
//       padding: paddings[size]
//     };
//   },
//   // Add the chakra style for when a TagCloseButton has focus
//   multiValueRemove: (
//     provided,
//     { isFocused, selectProps: { multiValueRemoveFocusStyle } }
//   ) => (isFocused ? multiValueRemoveFocusStyle : {}),
//   control: () => ({}),
//   menuList: () => ({}),
//   option: () => ({}),
//   multiValue: () => ({}),
//   multiValueLabel: () => ({}),
//   group: () => ({})
// };
