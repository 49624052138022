import { useState } from "react";
import PendingImg from "../../assets/pending.jpg";

export default function NeoImage(props: any) {
  const [hasErr, setHasErr] = useState(false);
  const { src, alt, quantity } = props;

  if (hasErr) {
    return <img src={PendingImg} alt={alt || "image not found"} />;
  }
  return (
    <div className="relative">
      {quantity ? (
        <div className="absolute bg-[#0F151B] rounded-lg text-white bottom-3 left-3 p-2 flex gap-2">
          <span className="text-xs font-normal leading-3">Quantity</span>
          <span className="text-white text-sm font-bold leading-3">
            {quantity.toLocaleString()}
          </span>
        </div>
      ) : null}
      <img
        src={src!}
        alt={alt}
        onError={() => setHasErr(true)}
        {...(props ?? {})}
      />
    </div>
  );
}
