import { useAppContext } from "../../../../../contexts/appContext";
import { useEffect, useState } from "react";

import { ItemData } from "../utils";
import { Pack } from "../../../types/packs";
import { Stack, Alert, AlertIcon, AlertDescription, Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { CraftableItemsTable } from "./components/Table";
import { Calculator } from "./components/Calculator";
import CraftingPacks from "../CraftingPacks";


interface CraftingCalculatorProps {
    partner: string
    onOpenModal: ({ pack, packId }: { pack?: Pack; packId?: string }) => void
}

export const CraftingHelper: React.FC<CraftingCalculatorProps> = ({
    partner,
    onOpenModal
}) => {
    const { getPartnerGameData } = useAppContext()
    const [gameData, setGameData] = useState<ItemData[] | null>(null);
    const [activeTab, setActiveTab] = useState<'crafting_data' | 'crafting_packs' | 'crafting_calculator'>('crafting_data');

    useEffect(() => {
        const fetchGameData = async () => {
            if (!partner || typeof partner !== 'string') return;
            const data = await getPartnerGameData(partner)
            setGameData(data)
        }
        fetchGameData()
    }, [partner, getPartnerGameData])

    return (
        <Stack width="100%">
            <div className="packs-pack-group-tabs">
                <button
                    key="crafting_data"
                    className={`packs-tab-button ${activeTab === 'crafting_data' ? 'active' : ''}`}
                    onClick={() => setActiveTab('crafting_data')}
                >
                    Crafting Data
                </button>
                <button
                    key="crafting_packs"
                    className={`packs-tab-button ${activeTab === 'crafting_packs' ? 'active' : ''}`}
                    onClick={() => setActiveTab('crafting_packs')}
                >
                    Crafting Packs
                </button>
                <button
                    key="crafting_calculator"
                    className={`packs-tab-button ${activeTab === 'crafting_calculator' ? 'active' : ''}`}
                    onClick={() => setActiveTab('crafting_calculator')}
                >
                    Crafting Calculator
                </button>
            </div>

            {gameData ? (
                <>
                    {activeTab === 'crafting_data' && (
                        <Box p={2}>
                            <CraftableItemsTable items={gameData} />

                        </Box>
                    )}
                    {activeTab === 'crafting_packs' && (
                        <Box p={2}>
                            <CraftingPacks items={gameData} handleOpenModal={onOpenModal} />
                        </Box>
                    )}
                    {activeTab === 'crafting_calculator' && (
                        <Box p={2}>
                            <Calculator gameData={gameData} onOpenModal={onOpenModal} />
                        </Box>
                    )}
                </>
            ) : (
                <Flex flexDirection="column" justifyContent="center" alignItems="center" height="200px">
                    <Spinner size="xl" />
                    <Text className="buying-modal-header" mt={2}>Loading...</Text>
                </Flex>)}

            {gameData && (
                <Box display="flex" justifyContent="center" width="100%">
                    <Alert
                        status="info"
                        className="buying-modal-alert"
                        borderRadius={'4px'}
                        maxWidth="450px"
                        mb={2}
                    >
                        <AlertIcon className="buying-modal-alert-icon" />
                        <AlertDescription className="buying-modal-alert-description">
                            The calculation is based on the current market prices. The ROI and daily revenue are not guaranteed and are subject to change, since the market prices are dynamic and can be affected by various factors such as supply and demand, external events, and updates to the game.
                        </AlertDescription>
                    </Alert>
                </Box>
            )}
        </Stack>
    )
}