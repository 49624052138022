import { useEffect } from "react";
import { useUserback } from "@userback/react";

export default function Feedback() {
  const { open } = useUserback();

  useEffect(() => {
    open();
    document.body.classList.add("feedback-modal");
  }, [open]);

  return <></>;
}
