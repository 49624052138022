import React from 'react'
import { Pack } from '../../../types/packs'
import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { StateFrom } from 'xstate'

type LeftPanelProps = {
  state: StateFrom<any>
  pack?: Pack
}

const LeftPanel: React.FC<LeftPanelProps> = ({ state, pack }) => {
  return (
    <div className="buying-modal-items buying-modal-left">
      <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}>
        {pack?.items.map((item) => (



          <div key={`item_${item.details.id}`} className="pack-card-item" style={{ width: "100%" }}>
            <div className="pack-card-item-content">
              <div className="pack-card-item-image">
                <img src={item?.details?.image} alt={item?.details?.name} className="pack-card-item-img" />
              </div>
              <span className="pack-card-item-name">{item?.details?.name}</span>
              <span className={`pack-card-item-quantity`}>x{item.quantity}</span>
            </div>
          </div>


        ))}
      </Grid>
    </div>
  )
}

export default LeftPanel
