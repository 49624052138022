import { Box, Link, Skeleton, Spinner } from '@chakra-ui/react'
import { PackTransaction } from '../../../types'

interface TransactionTableProps {
  isLoadingTransactions: boolean
  transactions: PackTransaction[] | null
  previousTxs: PackTransaction[]
}

const TransactionTable: React.FC<TransactionTableProps> = ({
  isLoadingTransactions,
  transactions,
  previousTxs,
}) => {
  const formatStatus = (status: string) => {
    let statusConfig = {
      text: '',
      color: '',
      spinner: false,
    }
    switch (status) {
      case 'pending':
        statusConfig.color = 'text-starAtlas-brightYellow'
        statusConfig.text = 'Sign'
        break
      case 'broadcast':
        statusConfig.color = 'text-starAtlas-brightYellow'
        statusConfig.text = 'Verifying'
        statusConfig.spinner = true
        break
      case 'success':
        statusConfig.color = 'text-green-500'
        statusConfig.text = 'Success'
        break
      case 'failed':
        statusConfig.color = 'text-red-500'
        statusConfig.text = 'Failed'
        break
      case 'timeout':
        statusConfig.color = 'text-red-500'
        statusConfig.text = 'Timeout'
        break
      default:
        statusConfig.color = 'text-starAtlas-brightYellow'
        statusConfig.text = 'Sign'
        break
    }
    return (
      <span className={`${statusConfig.color} capitalize`}>
        {statusConfig.text}
        {statusConfig.spinner && <Spinner size="xs" />}
      </span>
    )
  }

  return (
    <>
      <Box overflowX="auto" width="full" overflowY="auto" maxHeight="230px">
        <table className="w-full text-white border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Action</th>
              <th style={{ textAlign: 'left' }}>Tx hash</th>
              <th style={{ textAlign: 'left' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingTransactions
              ? Array.from({ length: 5 }).map((_, index) => (
                <tr
                  key={`skeleton_row_${index}`}
                  className="text-gray-100 text-xs p-2 h-10 bg-white bg-opacity-10 !rounded-lg"
                >
                  <td className="pl-4">
                    <Skeleton height="20px" />
                  </td>
                  <td>
                    <Skeleton height="20px" />
                  </td>
                  <td>
                    <Skeleton height="20px" />
                  </td>
                </tr>
              ))
              : transactions &&
              transactions.map((tx, index) => {
                const isPastFailedTransaction = previousTxs.some(prevTx => prevTx.hash === tx.hash && (prevTx.status === 'failed' || prevTx.status === 'timeout'))
                return (
                  <tr
                    key={tx.description + '-' + index}
                    className={`text-gray-100 text-xs p-2 h-10 bg-white bg-opacity-10 !rounded-lg`}
                    style={isPastFailedTransaction ? { opacity: 0.5 } : {}}
                  >
                    <td className="pl-4">{tx.description ? tx.description.slice(0, 30) : ''}</td>
                    <td>
                      {tx.hash ? (
                        <Link
                          href={`https://solscan.io/tx/${tx.hash}`}
                          isExternal
                          textDecoration="underline"
                          _hover={{ textDecoration: 'underline' }}
                          color="#FFBE4D"
                          _focus={{ boxShadow: 'none' }}
                        >
                          {tx.hash.slice(0, 3) + '...' + tx.hash.slice(-3)}
                        </Link>
                      ) : (
                        '...'
                      )}
                    </td>
                    <td>{formatStatus(tx.status)}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </Box>
    </>
  )
}

export default TransactionTable
