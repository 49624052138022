import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import partyIcon from "../assets/party.svg";
import PendingImg from "../assets/pending.jpg";
import { Nft, useRoom } from "../contexts/roomContext";
import { confetti } from "../services/confetti";
import Firebase from "../services/firebase";
import { getNftDetails } from "../services/nfts.service";
import { roundValue } from "../utils";
import HTMLBlock from "./HTMLBlock";
import IconArrow from "./Icons/IconArrow";
import IconClose from "./Icons/IconClose";
import { NFTModal } from "./NFTModal";
import RatingButton from "./RatingButton";
import TxtBlock from "./TxtBlock";
import UserRevenue from "./UserRevenue";

interface Props {
  resultData: any;
  uid: string;
  userRevenue: string | undefined;
  roomChain: string;
}

const Participant = ({ id }: { id: string }) => {
  const db = Firebase.getDBApp();
  const [data] = useDocument(doc(db, "users", id), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const user = data?.data();
  const displayName = user?.displayName;
  const avatar = user?.avatar;

  return (
    <li>
      {!displayName ? (
        <Spinner speed="1s" />
      ) : (
        <>
          <Avatar src={avatar} loading={"lazy"} />
          <Text textOverflow="ellipsis">{displayName}</Text>
        </>
      )}
    </li>
  );
};

const createImage = (nft: Nft) => {
  const img = nft?.thumbnail || nft?.image;

  if (nft?.assetType?.includes("text/plain")) {
    return <TxtBlock nft={nft} boxSize="64px" maxW="64px" fontSize="4px" />;
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "64px" }} />;
  }
  return (
    <Image
      className="ntfimage"
      objectFit="cover"
      src={img}
      fallbackSrc={PendingImg}
    />
  );
};

export default function TradeResult({
  resultData,
  uid,
  userRevenue,
  roomChain,
}: Props) {
  const { decimals, abbr, roomState, roomId, truncate } = useRoom();
  const [nfts, setNfts] = useState<{ [id: string]: Nft }>();
  const [showModal, setShowModal] = useState(false);
  const [isGiveLoading, setIsGiveLoading] = useState(true);
  const [isGetLoading, setIsGetLoading] = useState(true);

  const get = resultData?.results?.[uid]?.get || [];
  const give = resultData?.results?.[uid]?.give || [];
  const token = resultData?.results?.[uid]?.token;

  const allNfts = [...get, ...give];

  const getNfts = async () => {
    try {
      const { data } = (await getNftDetails(allNfts)) as { data: Nft[] };
      const updates = data.reduce((acc: { [id: string]: Nft }, nft) => {
        acc[nft?.itemId] = nft;
        return acc;
      }, {});
      setNfts(updates);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    let key = `completed-room-${roomId}`,
      alreadyShown = localStorage.getItem(key);

    if (!alreadyShown && roomState === "completed") {
      setShowModal(true);
      confetti.fire();
      localStorage.setItem(key, "true");
    }
  }, [roomState, roomId]);

  const closeCompleteModal = () => {
    console.log("close");
    setShowModal(false);
    console.log("id", roomId);
  };

  useEffect(() => {
    if (allNfts.length > 0) {
      getNfts();
    }
  }, [JSON.stringify(allNfts)]);

  useEffect(() => {
    if (give?.length > 0 || token < 0) {
      setIsGiveLoading(false);
    }
    if (get?.length > 0 || token > 0) {
      setIsGetLoading(false);
    }
  }, [give, get]);

  return (
    <>
      {userRevenue !== undefined ? (
        <UserRevenue userRevenue={userRevenue} />
      ) : null}
      <div className="trade-items-div ">
        <div className="trade-items">
          {(token < 0 || give.length > 0) && (
            <div className="trade-give">
              <h4>
                You Give <IconArrow />
              </h4>
              <div className="trade-results flex flex-row flex-wrap gap-2">
                {token < 0 && (
                  <Skeleton isLoaded={!isGiveLoading} className="flex-none">
                    <Box className="result-item" w={"78px"}>
                      {roundValue((token * -1) / 10 ** decimals, truncate)}{" "}
                      {abbr}
                    </Box>
                  </Skeleton>
                )}
                {give.map((id: string) => {
                  const nft = nfts?.[id];
                  if (!nft) return null;
                  return (
                    <Skeleton isLoaded={!isGiveLoading} className="flex-none">
                      <Box className="result-item" key={id} w={"78px"}>
                        <NFTModal nft={nft}>{createImage(nft)}</NFTModal>
                      </Box>
                    </Skeleton>
                  );
                })}
              </div>
            </div>
          )}
          {(token > 0 || get.length > 0) && (
            <div className="trade-get">
              <h4>
                You Get <IconArrow />
              </h4>
              <div className="trade-results flex flex-wrap gap-2">
                <Skeleton isLoaded={!isGetLoading}>
                  <Flex gap={"10px"}>
                    {token > 0 && (
                      <Box className="result-item" maxW={"78px"}>
                        {roundValue(token / 10 ** decimals, truncate)} {abbr}
                      </Box>
                    )}
                    {get.map((id: string) => {
                      const nft = nfts?.[id];
                      if (!nft) return null;
                      return (
                        <Box className="result-item" key={id} maxW={"78px"}>
                          <NFTModal nft={nft}>{createImage(nft)}</NFTModal>
                        </Box>
                      );
                    })}
                  </Flex>
                </Skeleton>
              </div>
            </div>
          )}

          {!token && get.length === 0 && give.length === 0 && (
            <div className="trade-notice">
             <br></br>
              <br></br>

              <div className="icon">
                <IconClose />
              </div>
              <h3>No sale found for you this time</h3>
              <p>Join another event and try again!</p>

 
            </div>
          )}
        </div>

        {!token && get.length === 0 && give.length === 0 ? (
          <div className="trade-notice"></div>
        ) : (
          <div className="feedback">
            <div>
              <RatingButton roomId={roomId} uid={uid} />
            </div>
          </div>
        )}
      </div>

      {showModal ? (
        <div className="complete-modal">
          <div className="content">
            <img src={partyIcon} alt="Congrats!" />
            <h2>Woohoo! The trade is complete!</h2>
            <p>
              Great news, the trade you were in has been signed by everyone and
              is now complete! Click below to see the trade.
            </p>
            <button onClick={closeCompleteModal} className="button">
              Show Trade
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}
