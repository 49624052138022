import { memo } from "react";
import { flexRender } from "@tanstack/react-table";
import NeoTd from "./NeoTd";
import NeoTr from "./NeoTr";

const Cell = memo(({ meta, cell, ctx }: { meta: any; cell: any; ctx: any }) => {
  return (
    <NeoTd key={cell.id} isNumeric={meta?.isNumeric} ref={cell.measureRef}>
      {flexRender(cell.column.columnDef.cell, ctx)}
    </NeoTd>
  );
});

export const Row = memo(({ row, cells }: { row: any; cells: any }) => {
  return (
    <NeoTr key={row.id} ref={row.measureRef} height="80px">
      {cells.map((cell: any) => {
        const meta: any = cell.column.columnDef.meta;
        const ctx = cell.getContext();
        return <Cell key={cell.id} cell={cell} meta={meta} ctx={ctx} />;
      })}
    </NeoTr>
  );
});
