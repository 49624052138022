import { Link } from "react-router-dom";
import { useCallback, useEffect, useState, useRef } from "react";
import { filterAndSort } from "../utils/filterAndSort";
import { collection, getDocs, query, where } from "firebase/firestore";
import Firebase from "../services/firebase";
import HandleTime from "./HandleTime";
import { getIcon } from "../utils";
import { Button, ButtonGroup, HStack, Heading, IconButton, Switch } from "@chakra-ui/react";
import IconSolana from "./Icons/IconSolana";
import IconStacks from "./Icons/IconStacks";
import IconPolygon from "./Icons/IconPolygon";
import IconEthereum from "./Icons/IconEthereum";
import IconBitcoin from "./Icons/IconBitcoin";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { DateTime } from "luxon";
import { useRoom } from "../contexts/roomContext";

type AuctionResponse = {
  id: string;
  name: string;
  startAt: number;
  endsAt: number;
  partyDuration: number;
  chain: string;
  joinedUsers: number;
};

interface AuctionCount {
  solana: number;
  stacks: number;
  polygon: number;
  ethereum: number;
}

type State = {
  isLive: boolean;
};

interface Props {
  hideHeader?: boolean;
  onlyLive?: boolean;
  chain?: string;
  exclude?: string[];
  maxVisible?: number;
  customHeader?: string;
  isRelated?: boolean;
}

const transformResponse = (response: any): AuctionResponse => {
  const data = response.data() || {};
  const start = data.info.startAt;
  const duration = data.info.partyDuration;
  return {
    id: response.id,
    name: data.info.name,
    startAt: start,
    endsAt: start + duration,
    partyDuration: duration,
    chain: data.blockchain.name,
    joinedUsers: data.users.length,
  };
};

// const isLive = auction.startAt < Date.now() / 1000;
export default function SmartAuctions({
  onlyLive,
  chain,
  exclude,
  maxVisible,
  hideHeader,
  customHeader,
  isRelated,
}: Props) {
  const [data, setData] = useState<AuctionResponse[]>([]);
  const chainCheck = useRef(null);
  const [filterChain, setFilterChain] = useState<string | undefined>("all");
  const [showLive, setShowLive] = useState<boolean>(
    onlyLive ? onlyLive : false
  );

  const { room, canList } = useRoom();

  const [auctions, setAuctions] = useState<AuctionResponse[]>([]);
  const [auctionCounts, setAuctionCounts] = useState<AuctionCount>({
    polygon: 0,
    solana: 0,
    stacks: 0,
    ethereum: 0,
  });

  const fetchAuctions = useCallback(async () => {
    try {
      const db = Firebase.getDBApp();
      const querySnapshot = await getDocs(
        query(
          collection(db, "rooms"),
          where("status", "==", "listing"),
          where("info.addToEvents", "==", true)
        )
      );

      let response = querySnapshot.docs
        .map((ele) => transformResponse(ele))
        .filter((x) => {
          let excluded = exclude ? exclude.includes(x.id) : false;
          return !showLive
            ? x.endsAt >= Date.now() / 1000 && !excluded
            : x.endsAt >= Date.now() / 1000 &&
            x.startAt < Date.now() / 1000 &&
            !excluded;
        });

      setData(response);

      setAuctionCounts(() => {
        let polygon = 0,
          solana = 0,
          ethereum = 0,
          stacks = 0;

        response.forEach((ele) => {
          if (ele.chain === "polygon") polygon++;
          if (ele.chain === "solana") solana++;
          if (ele.chain === "stacks") stacks++;
          if (ele.chain === "ethereum") ethereum++;
        });

        return {
          polygon,
          solana,
          stacks,
          ethereum,
        };
      });
    } catch (e) {
      console.log("error fetching auctions", e);
    }
  }, []);

  useEffect(() => {
    let filtered = filterAndSort(data, ["name"], "", {
      key: "endsAt",
      direction: "asc",
    }).results as AuctionResponse[];
    if (filterChain && filterChain !== "all") {
      filtered = filtered.filter((ele) => ele.chain === filterChain);
    }
    setAuctions(filtered);
  }, [data, filterChain]);

  const toggleLive = () => {
    setShowLive(!showLive);

    setAuctions(
      data.filter((x) => {
        let excluded = exclude ? exclude.includes(x.id) : false;
        return showLive
          ? x.endsAt >= Date.now() / 1000 && !excluded
          : x.endsAt >= Date.now() / 1000 &&
          x.startAt < Date.now() / 1000 &&
          !excluded;
      })
    );

    if (chain) {
      setFilterChain(chain);
    }
  };
  useEffect(() => {
    fetchAuctions();

    if (chain) {
      setFilterChain(chain);
    }
  }, []);

  const handleChainSelect = (chain: string) => {
    if (chainCheck.current) {
      (chainCheck.current as HTMLInputElement).checked = false;
    }

    setFilterChain(chain);
  };

  return (
    <>
      {auctions.length < 1 ? null : (
        <div id="smart-auctions">
          {!hideHeader && (
            <div className="smart-auctions-header">
              <h3>Smart Auctions</h3>

              <div className="chain-select-wrapper">
                <label
                  id="chain-select-button"
                  htmlFor="chain-select-mobile"
                  className="button small"
                >
                  Select Chain
                </label>

                <input
                  type="checkbox"
                  id="chain-select-mobile"
                  ref={chainCheck}
                />

                <ul className="chain-selector">
                  <li
                    className={filterChain === "all" ? "active" : ""}
                    onClick={(e) => handleChainSelect("all")}
                  >
                    All Chains
                  </li>
                  <li
                    className={filterChain === "solana" ? "active" : ""}
                    onClick={(e) => handleChainSelect("solana")}
                  >
                    <IconSolana />
                  </li>
                  {/* <li
                  className={filterChain === "stacks" ? "active" : ""}
                  onClick={(e) => handleChainSelect("stacks")}
                >
                  <IconStacks />
                </li> */}
                  <li
                    className={filterChain === "bitcoin" ? "active" : ""}
                    onClick={(e) => handleChainSelect("bitcoin")}
                  >
                    <IconBitcoin />
                  </li>
                </ul>
              </div>
            </div>
          )}
          {auctions.length < 1 ?
            !isRelated ? (
              <HStack className="listing-section box new-bg-transparent">
                <div className="no-auctions">
                  There are currently no smart auctions available.
                </div>
              </HStack>
            ) : null
            : (
              <HStack className="listing-section box new-bg-transparent">
                <div className="auction-list">
                  {auctions.length > 1 && customHeader && (
                    <Heading
                      className="section-title"
                      size="md"
                      marginTop={7}
                      justifyContent={"space-between"}
                    >
                      {customHeader}
                    </Heading>
                  )}
                  {auctions.map((auction: AuctionResponse) => {
                    const isLive = auction.startAt < Date.now() / 1000;
                    const start = auction.startAt || 0;
                    const end = start + (room?.info?.partyDuration || 0);
                    const DTstart = DateTime.fromSeconds(start);
                    const DTend = DateTime.fromSeconds(end);

                    return (
                      <div key={auction.id} className="auction-list-item">
                        {getIcon(auction.chain)}
                        <h3>{auction.name}</h3>
                        {isLive && <span className="tag">LIVE</span>}
                        {!isLive && (
                          <div className="time-wrapper">
                            <p className="room-settings-text">Starting in</p>
                            <HandleTime time={auction.startAt} />
                          </div>
                        )}
                        {isLive && (
                          <div className="time-wrapper">
                            <p className="room-settings-text">Ending in</p>
                            <HandleTime time={auction.endsAt} />
                          </div>
                        )}

                        {isLive && (
                          <Link to={`/rooms/${auction.id}`} className="button">
                            Join
                          </Link>
                        )}

                        {!isLive && (
                          <AddToCalendarButton
                            name={auction.name}
                            options={["Apple", "Google"]}
                            startDate={DTstart.toFormat("yyyy-MM-dd")}
                            endDate={DTend.toFormat("yyyy-MM-dd")}
                            startTime={DTstart.toFormat("HH:mm")}
                            endTime={DTend.toFormat("HH:mm")}
                            location={`${window.location.href}preview/${auction.id}`}
                            timeZone="UTC"
                            uid={auction.id}
                            size="4|4|4"
                          ></AddToCalendarButton>
                        )}
                      </div>
                    );
                  })}
                </div>
              </HStack>
            )}
        </div>
      )}
    </>
  );
}
