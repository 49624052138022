import FinalizeLoading from "../../components/FinalizeProgress";
import TradeGif from "../../components/TradeGif";
import TradeResult from "../../components/TradeResult";
import { useRoom } from "../../contexts/roomContext";
import dateFormat from "dateformat";
import RoomContractButtons from "../../components/RoomContractButtons";
import { getFormattedId } from "../../utils";
import { Alert, AlertIcon, Box, HStack } from "@chakra-ui/react";
import SmartAuctions from "../../components/SmartAuctions";

interface Props {
  room: any;
  uid: string;
  isAdmin: boolean;
  roomId: string;
  adminId: string;
}

const createUserRevenue = (
  userRevenue: number | undefined,
  units: number,
  abbr: string
) => {
  if (!userRevenue || userRevenue === 0) return;
  return `${userRevenue / units} ${abbr}`;
};

export default function Finalize({ room, uid, isAdmin, roomId }: Props) {
  const { abbr, units, result, roomState, canList } = useRoom();
  const swapId = room?.swapId?.swapId;
  const gifUrl = room?.gifUrl;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  // let tradeFound = result?.tradeFound;
  const gifData = result?.gifData;

  const userRevenue: string | undefined = createUserRevenue(
    result?.revenue?.[uid],
    units!,
    abbr!
  );

  const hasSwaps = room.info.name === "AI trade" || !!room?.userSwaps?.[uid!];

  if (roomState === "solving") return <FinalizeLoading />;
  return (
    <>
      <div className="trade-result">
        <div className="col trade-side new-bg-transparent">
          {room?.info?.roomType !== "ai" ? (
            <TradeGif gifUrl={gifUrl} gifData={gifData} />
          ) : null}

          <Box
            className="trade-details"
            mb={{
              base: "140px",
              xl: "0px",
            }}
          >
            <h3>
              {/* {roomChain !== "stacks" && `Trade ${getFormattedId(swapId)}`} */}
              {roomState === "signing" ? (
                <span className="tag orange">Waiting</span>
              ) : (
                <span className="tag">Complete</span>
              )}
            </h3>
            <ul>
              <li className="chain">
                <span>Chain:</span> {roomChain}
              </li>
              <li>
                <span>Status:</span>{" "}
                {roomState === "signing"
                  ? "Waiting on all to sign"
                  : "Complete"}
              </li>
              <li>
                <span>Created:</span>{" "}
                {dateFormat(room.createdAt.seconds * 1000, "mmmm dS yyyy")}
              </li>
            </ul>
            {room?.info?.roomType === "ai" ? (
              <Alert
                status="info"
                mt={"10px"}
                borderRadius={"6px"}
                background={
                  "linear-gradient(90deg,#ffffff1a -1.52%,hsla(0,0%,100%,.024) 104.35%)"
                }
              >
                <AlertIcon color={"#6C60FF"} />
                AI Trades, human-signed. Signed within 24 hours, often faster.
                Please be patient.
              </Alert>
            ) : null}
          </Box>
        </div>
        <div className="col trade-content new-bg-transparent">
          <TradeResult
            resultData={result}
            uid={uid}
            userRevenue={userRevenue}
            roomChain={roomChain}
          />


          <SmartAuctions
            chain={"solana"}
            onlyLive={true}
            exclude={[roomId]}
            hideHeader={true}
            customHeader={"Related Events"}
            isRelated={true}
          />
        </div>
      </div>
      {hasSwaps || isAdmin || canList ? <RoomContractButtons /> : null}
    </>
  );
}
