import react, { useState } from "react";
import { Image, IconButton } from "@chakra-ui/react";

import {
  getReserveLabel,
  createInitialPrice,
  formatName,
} from "../../../utils";

import ReservePriceForm from "../../ReservePriceForm/ReservePriceForm";

import "../Sidebar.scss";
import PendingImg from "../../../assets/pending.jpg";
import IconTrash from "../../../components/Icons/IconTrash";
import { useRoom, Nft } from "../../../contexts/roomContext";

interface PropTypes {
  nft?: Nft;
  decimals: number;
  abbr?: string;
  handleRemove: (id: string) => void;
  id: string;
  allowEditRemove?: boolean;
}

export default function SidebarListItem({
  nft,
  decimals,
  abbr,
  handleRemove,
  id,
  allowEditRemove = true,
}: PropTypes) {
  const { listings, availableToList, truncate, setModalId } = useRoom();
  const listing = listings?.[id];
  const [listedPrice, setListedPrice] = useState<number | string>(
    createInitialPrice(nft, decimals, listing)
  );

  const [isEditingPrice, setIsEditingPrice] = useState(false);

  return (
    <div className="bid-item" key={id}>
      <Image
        className="image pointer"
        minWidth="37px"
        boxSize="37px"
        objectFit="cover"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
        onClick={() => setModalId(id)}
      />
      <div className="details-and-input-holder">
        <span className="bid-amount">
          <h3 onClick={() => setModalId(id)}>{nft && formatName(nft.name)}</h3>
           {/* <div className="reserve-price-holder">
            <p>Reserve</p>
            <p className="reserve-label">
              {decimals &&
                abbr &&
                getReserveLabel(
                  listings?.[id]?.listingPrice,
                  decimals,
                  abbr,
                  truncate
                )}
            </p>
          </div> */}
        </span>
        {allowEditRemove && (
          <div className="reserve-form-holder">
            {nft ? (
              <ReservePriceForm
                nfts={availableToList}
                biddingId={nft.itemId}
                type="listing"
                listedPrice={listings?.[id]?.listingPrice}
                startingBid={listings?.[id]?.startingBid}
                setListedPrice={setListedPrice}
                isEditingPrice={isEditingPrice}
                setIsEditingPrice={(status: boolean) =>
                  setIsEditingPrice(status)
                }
              />
            ) : null}
          </div>
        )}
      </div>
      {allowEditRemove && (
        <div className="row-button-holder">
          {nft ? (
            <IconButton
              className="remove-icon icon-button"
              onClick={() => handleRemove(nft.itemId)}
              aria-label="Remove"
              icon={<IconTrash />}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
