import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Tooltip } from "react-tooltip";
import IconPurpleEye from "../Icons/IconPurpleEye";
import { formatButtonLabel, roundValue } from "../../utils";

interface Props {
  highestBidAmount: number;
  max: number;
  budget: number;
  startingBid?: number;
  handleBid: (val: number) => void;
  decimals: number;
  abbr: string;
  floor: number;
  incrementToken: number;
  incrementPct: number;
  truncate: number;
  isOrdinals: boolean;
  suggestedPrice: string;
  isHighBidder: boolean;
  isTied: boolean;
}


export default function QuickActions({
  highestBidAmount,
  max,
  budget,
  startingBid,
  handleBid,
  decimals,
  abbr,
  floor,
  incrementToken,
  incrementPct,
  truncate,
  isOrdinals,
  suggestedPrice,
  isHighBidder,
  isTied
}: Props) {
  const higherValue =
    highestBidAmount +
    Math.max(incrementToken, highestBidAmount * incrementPct);

  return (
    <Box mt="auto">
      <Heading as="h3" size="sm" mb="5px">
        Quick Actions
      </Heading>
      <Flex
        gap="10px"
        direction={{
          sm: "column",
          md: "row",
        }}
      >
                <Button
          onClick={() => {
            if (!startingBid) return;
            handleBid( roundValue(startingBid / 10 ** decimals, truncate, 'up'));
          }}
          variant="outline"
          borderColor="#6C60FF"
          borderRadius={6}
          isDisabled={!startingBid}
        >
          <Flex direction={"column"} textAlign={"left"}>
            <Text fontWeight={"bold"}>Min</Text>
            <Text fontSize={".8em"}>
              {startingBid
                ? formatButtonLabel(
                    roundValue(startingBid / 10 ** decimals, truncate, 'up'),
                    abbr
                  )
                : "--"}
            </Text>
          </Flex>
          <a // eslint-disable-line
            data-tooltip-id="bid-helpers-tooltip"
            data-tooltip-content="This will set the bid based on the min."
            data-tooltip-place="bottom"
            style={{ marginLeft: "8px" }}
          >
            <IconPurpleEye />
          </a>
        </Button>
        <Button
        isDisabled={highestBidAmount === -Infinity || (isHighBidder && !isTied)}
          onClick={() => {
            handleBid(higherValue / 10 ** decimals);
          }}
          variant="outline"
          borderColor="#6C60FF"
          borderRadius={6}
        >
          <Flex direction={"column"} textAlign={"left"}>
            <Text fontWeight={"bold"}>Higher</Text>
            <Text fontSize={".8em"}>
              {formatButtonLabel(
                roundValue(higherValue / 10 ** decimals, truncate, 'down'),
                abbr
              )}
            </Text>
          </Flex>
          <a // eslint-disable-line
            data-tooltip-id="bid-helpers-tooltip"
            data-tooltip-content="Will bid the next available higher bid"
            data-tooltip-place="bottom"
            style={{ marginLeft: "8px" }}
          >
            <IconPurpleEye />
          </a>
        </Button>
        {/* {!isOrdinals &&
        
        <Button
          onClick={() => {
            handleBid(floor / 10 ** decimals);
          }}
          variant="outline"
          borderColor="#6C60FF"
          borderRadius={6}
          isDisabled={!floor}
        >
          <Flex direction={"column"} textAlign={"left"}>
            <Text fontWeight={"bold"}>{suggestedPrice}</Text>
            <Text fontSize={".8em"}>
              {startingBid
                ? formatButtonLabel(
                    roundValue(startingBid / 10 ** decimals, truncate, 'up'),
                    abbr
                  )
                : "--"}
            </Text>
          </Flex>
          <a // eslint-disable-line
            data-tooltip-id="bid-helpers-tooltip"
            data-tooltip-content="This will set the bid based on the Item's floor price."
            data-tooltip-place="bottom"
            style={{ marginLeft: "8px" }}
          >
            <IconPurpleEye />
          </a>
        </Button>
        } */}

        <Button
          onClick={async () => {
            handleBid(budget / 10 ** decimals);
          }}
          variant="outline"
          borderColor="#6C60FF"
          borderRadius={6}
          isDisabled={budget === 0 || !budget}
        >
          <Flex direction={"column"} textAlign={"left"}>
            <Text fontWeight={"bold"}>Limit</Text>
            <Text fontSize={".8em"}>
              {formatButtonLabel(
                roundValue(budget / 10 ** decimals, truncate, "down"),
                abbr
              )}
            </Text>
          </Flex>
          <a // eslint-disable-line
            data-tooltip-id="bid-helpers-tooltip"
            data-tooltip-content="This will set the bid based on your spending limit."
            data-tooltip-place="bottom"
            style={{ marginLeft: "8px" }}
          >
            <IconPurpleEye />
          </a>
        </Button>
      </Flex>
      <Tooltip
        id="bid-helpers-tooltip"
        className="custom-tooltip"
        classNameArrow="custom-tooltip-arrow"
        clickable
      />
    </Box>
  );
}
