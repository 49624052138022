import React, { ReactNode, MouseEvent } from "react";
import Spinner from "./Spinner";

interface ButtonProps {
  children: ReactNode;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  handleClick,
  className,
  isDisabled,
  isLoading,
}) => {
  return (
    <button className={className} onClick={handleClick} disabled={isDisabled}>
      {isLoading && (
        <Spinner className="inline w-4 h-4 mr-3 text-white animate-spin" />
      )}
      {children}
    </button>
  );
};

export default Button;
