export default function IconTick() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M11.5,26.2c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.2-1.1-0.4l-8.6-8.6c-0.9-0.9-0.9-2.4,0-3.2l1.1-1.1c0.9-0.9,2.4-0.9,3.2,0
	l5.4,5.4L25,4c0.9-0.9,2.4-0.9,3.2,0l1.1,1.1c0.9,0.9,0.9,2.4,0,3.2L11.5,26.2z" fill="currentColor"/>
    </svg>
  );
}
