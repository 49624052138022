import { Td, TableCellProps, forwardRef } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props extends TableCellProps {
  children: ReactNode;
}

const NeoTd = forwardRef(({ children, ...rest }: Props, ref) => {
  return (
    <Td ref={ref} pt={2} pb={2} {...rest} border="none">
      {children}
    </Td>
  );
});

export default NeoTd;
