import { FaDiscord, FaTwitter } from "react-icons/fa";

export default function LandingFooter() {
  return <div className="footer">
    <ul className="social-icons">
      <li>
        <a
          href="https://x.com/tulle_ai"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter fontSize={"34px"} />
        </a>

        <a
          href="https://discord.gg/4mfqbBkGRS"
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord fontSize={"34px"} />
        </a>
      </li>
    </ul>
    <p>Copyright &copy; {new Date().getFullYear()} Tulle</p>
  </div>;
}
