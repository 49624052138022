import {
  Avatar,
  AvatarBadge,
  Badge,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { memo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import Firebase from "../services/firebase";
import IconUsers from "./Icons/IconUsers";
import { useRoom } from "../contexts/roomContext";

export const AvatarItem = memo(
  ({
    id,
    showDisplayName,
    completed,
    muted,
    toggleMute,
    showStatus,
    type,
    ...rest
  }: {
    id: string;
    showDisplayName?: boolean;
    completed?: boolean;
    muted?: boolean;
    toggleMute?: () => void;
    showStatus?: boolean;
    type?: string;
  }) => {
    const db = Firebase.getDBApp();
    const [data, loading] = useDocument(doc(db, "users", id), {
      snapshotListenOptions: { includeMetadataChanges: true },
    });
    const user = data?.data();

    const displayName = user?.displayName;
    const avatar = user?.avatar;

    return (
      <HStack overflowX="clip">
        <Tooltip label={displayName || id} bg="#051a28" color="white">
          <Avatar
            src={avatar}
            loading={"lazy"}
            name={displayName || ""}
            {...rest}
          >
            {showStatus && (
              <AvatarBadge bg={completed ? "green" : "red"} boxSize="1em" />
            )}
          </Avatar>
        </Tooltip>
        <VStack alignItems="start">
          <HStack>
            {showStatus && (
              <Badge bg={completed ? "green" : "red"}>
                {completed ? "Signed" : "Not Signed"}
              </Badge>
            )}
            {showDisplayName && !loading && (
              <Text mb="0" textOverflow="ellipsis">
                {displayName || id}
              </Text>
            )}
          </HStack>
          {toggleMute && (
            <IconButton
              size="sm"
              aria-label={muted ? "Unmute" : "Mute"}
              icon={muted ? <FaVolumeUp /> : <FaVolumeMute />}
              onClick={toggleMute}
            />
          )}
        </VStack>
      </HStack>
    );
  }
);
