export const TULLE_FEE = 2.5

export type ItemData = {
  id: string
  name: string
  onChainId: string
  type: string
  sourceMarketplace: string
  image: string
  url: string
  category: string
  buyPrice: { [key: string]: number }
  sellPrice: { [key: string]: number }
  ingredientsCost: number
  craftingFee?: number
  recipe?: { amount: number; mint: string }[]
  craftingDuration?: number
  loyalityPointsPerUnit?: number
  pricesByQuantity?: Record<
    string,
    {
      buyPrice: number
      sellPrice: number
    }
  >
}

export const starAtlasFont = {
  fontFamily: 'RoganRegular',
  fontWeight: 600,
}

export const calculateRevenue = ({
  item,
  time = 24 * 3600,
  crewMembers = 1,
  tulleFee = 1,
  marketplaceFee = 6,
  fromBuyPrice = false,
  resources = null,
}: {
  item: ItemData
  time?: number
  crewMembers?: number
  tulleFee?: number
  marketplaceFee?: number
  fromBuyPrice?: boolean
  resources?: ItemData[] | null
}) => {
  const units = (time / item.craftingDuration!) * crewMembers
  let price, totalCost

  // More accurate calculation based on average prices for given quantities
  if (resources) {
    const ingredientsCost = calculateIngredientsCost(item, resources, units, tulleFee)
    totalCost = ingredientsCost + item.craftingFee! * units

    if (fromBuyPrice) {
      price = calculateBuyPrice(item, units)
    } else {
      price = item.sellPrice.atlas * units * (1 - marketplaceFee / 100)!
    }
  } else {
    price = fromBuyPrice
      ? item.buyPrice.atlas! * units
      : item.sellPrice.atlas * units * (1 - marketplaceFee / 100)!
    totalCost = (item.ingredientsCost! * (1 + tulleFee / 100) + item.craftingFee!) * units
  }

  const profit = price - totalCost
  return profit
}

export const calculateRoi = ({
  item,
  tulleFee = 1,
  marketplaceFee = 6,
  fromBuyPrice = false,
  units = null,
  resources = null,
}: {
  item: ItemData
  tulleFee?: number
  marketplaceFee?: number
  fromBuyPrice?: boolean
  units?: number | null
  resources?: ItemData[] | null
}) => {
  let price, totalCost, profit

  // more accurate calculation based on average prices for given quantities
  if (resources && units) {
    const ingredientsCost = calculateIngredientsCost(item, resources, units, tulleFee)
    totalCost = ingredientsCost + item.craftingFee! * units

    if (fromBuyPrice) {
      price = calculateBuyPrice(item, units)
    } else {
      price = item.sellPrice.atlas * units * (1 - marketplaceFee / 100)!
    }
  } else {
    price = fromBuyPrice ? item.buyPrice.atlas! : item.sellPrice.atlas * (1 - marketplaceFee / 100)!
    totalCost = item.ingredientsCost! * (1 + tulleFee / 100) + item.craftingFee!
  }

  profit = price - totalCost
  const roi = totalCost > 0 ? (profit / totalCost) * 100 : 0

  return roi
}

export const calculateSellPrice = (
  item: ItemData,
  units: number | null,
  tulleFee: number
): number => {
  if (units !== null && item.pricesByQuantity) {
    const keys = Object.keys(item.pricesByQuantity)
      .map(Number)
      .sort((a, b) => a - b)
    let lowerKey = keys[0]
    let upperKey = keys[keys.length - 1]

    for (let i = 0; i < keys.length; i++) {
      if (units === keys[i]) {
        return units * item.pricesByQuantity[units.toString()].sellPrice * (1 + tulleFee / 100)
      } else if (units < keys[i]) {
        upperKey = keys[i]
        break
      }
      lowerKey = keys[i]
    }

    if (units > upperKey) {
      return units * item.pricesByQuantity[upperKey.toString()].sellPrice * (1 + tulleFee / 100)
    }

    const lowerPrice = item.pricesByQuantity[lowerKey.toString()].sellPrice
    const upperPrice = item.pricesByQuantity[upperKey.toString()].sellPrice
    const interpolatedPrice =
      lowerPrice + ((upperPrice - lowerPrice) * (units - lowerKey)) / (upperKey - lowerKey)

    return units * interpolatedPrice * (1 + tulleFee / 100)
  } else {
    return item.sellPrice.atlas * (1 + tulleFee / 100)
  }
}

export const calculateBuyPrice = (item: ItemData, units: number | null): number => {
  if (units !== null && item.pricesByQuantity) {
    const keys = Object.keys(item.pricesByQuantity)
      .map(Number)
      .sort((a, b) => a - b)
    let lowerKey = keys[0]
    let upperKey = keys[keys.length - 1]

    for (let i = 0; i < keys.length; i++) {
      if (units === keys[i]) {
        return units * item.pricesByQuantity[units.toString()].buyPrice
      } else if (units < keys[i]) {
        upperKey = keys[i]
        break
      }
      lowerKey = keys[i]
    }

    if (units > upperKey) {
      return units * item.pricesByQuantity[upperKey.toString()].buyPrice
    }

    const lowerPrice = item.pricesByQuantity[lowerKey.toString()].buyPrice
    const upperPrice = item.pricesByQuantity[upperKey.toString()].buyPrice
    const interpolatedPrice =
      lowerPrice + ((upperPrice - lowerPrice) * (units - lowerKey)) / (upperKey - lowerKey)

    return units * interpolatedPrice
  } else {
    return item.buyPrice.atlas
  }
}

export const calculateIngredientsCost = (
  item: ItemData,
  resources: ItemData[],
  units: number | null,
  tulleFee: number
) => {
  return item.recipe!.reduce((total, ingredient) => {
    const ingredientData = resources.find((i) => i.onChainId === ingredient.mint)!
    const sellPrice = calculateSellPrice(ingredientData, units, tulleFee)
    return total + sellPrice * ingredient.amount
  }, 0)
}

export const formatCost = (cost: number) => {
  if (cost > 100) {
    return Math.round(cost)
  } else if (cost > 10) {
    return cost.toFixed(1)
  } else if (cost > 1) {
    return cost.toFixed(2)
  } else if (cost > 0) {
    return cost.toFixed(3)
  } else if (cost > 0.1) {
    return cost.toFixed(4)
  } else if (cost > 0.01) {
    return cost.toFixed(5)
  } else {
    return cost.toFixed(6)
  }
}

export const formatTime = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 3600))
  const hours = Math.floor((seconds % (24 * 3600)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  let result = ''
  if (days > 7) {
    return `${days}d`
  } else if (days > 0) {
    result += `${days}d ${hours}h`
  } else if (hours > 0) {
    result += `${hours}h ${minutes}m`
  } else if (minutes > 0) {
    result += `${minutes}m ${remainingSeconds}s`
  } else {
    result += `${remainingSeconds}s`
  }

  return result.trim()
}
