import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { UserbackProvider } from '@userback/react';
import { HelmetProvider } from 'react-helmet-async';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import theme from './theme';
import Firebase from './services/firebase';
import AppProvider from './contexts/appContext';
import ErrorBoundary from './components/ErrorBoundary';
import UserAnalyticsProvider from './contexts/userTracking';
import ChainsProvider from './contexts/chainsContext';
import NeoSwapWalletProvider from './contexts/walletContext';
import WalletPassthroughProvider from './components/PacksWidget/contexts/WalletPassthroughContext';
import { isNil } from 'lodash'

import 'react-tooltip/dist/react-tooltip.css';
import './css/app.scss';
import './tailwind-output.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App = ({ dehydratedState }: { dehydratedState?: any }) => {
  useEffect(() => {
    document.addEventListener(
      'scroll',
      (e: Event) => {
        let nav = document.getElementById('nav-bar');

        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          if (nav) {
            nav.classList.add('fixed');
          }
        } else {
          if (nav) {
            nav.classList.remove('fixed');
          }
        }
      },
      { passive: true }
    );
  }, []);

  useEffect(() => {
    const getReferralId = () => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get('r');
    };

    const ref = getReferralId();
    const hasCurrentRef = localStorage.getItem('buddyLink');

    if (ref && isNil(hasCurrentRef)) {
      localStorage.setItem('buddyLink', ref);
    }
  }, []);

  const [queryClient] = useState(() => new QueryClient());

  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <UserbackProvider
            token={process.env.REACT_APP_USERBACK_TOKEN!}
            options={{
              after_send: () => {
                if (window.location.pathname !== '/feedback') return;
                window.location.href = '/';
              },
            }}
          >
            <UserAnalyticsProvider>
              <ChakraProvider theme={theme}>
                <ChainsProvider>
                  <NeoSwapWalletProvider>
                    <WalletPassthroughProvider>
                      <AppProvider>
                        <ErrorBoundary>
                          <QueryClientProvider client={queryClient}>
                            <Hydrate state={dehydratedState}>
                              <Router />
                            </Hydrate>
                          </QueryClientProvider>
                        </ErrorBoundary>
                      </AppProvider>
                    </WalletPassthroughProvider>
                  </NeoSwapWalletProvider>
                </ChainsProvider>
              </ChakraProvider>
            </UserAnalyticsProvider>
          </UserbackProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  );
};

Firebase.initApp.then(() => {
  root.render(<App />);
});

reportWebVitals();
