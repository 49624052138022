import react, { useState } from "react";
import { Box } from "@chakra-ui/react";

import "./Sidebar.scss";
import { useRoom } from "../../contexts/roomContext";

import SidebarListItem from "./Parts/SidebarListItem";

interface PropTypes {
  handleRemove: (id: string) => void;
}

export default function Sidebar({ handleRemove }: PropTypes) {
  const { room, listings, availableToList } = useRoom();
  const [currentTab, setCurrentTab] = useState("listed-nfts");

  const IDsListed = listings ? Object.keys(listings) : [];

  const getNftItem = (id: string) => {
    const nft = availableToList?.find(
      (nft: { itemId: string }) => nft.itemId === id
    );

    const decimals = nft
      ? nft?.collection?.floorPrice?.allPrices[0]?.decimals
      : 0;
    const abbr = nft?.collection?.floorPrice?.allPrices[0]?.symbol;

    return (
      <SidebarListItem
        key={id}
        nft={nft}
        decimals={decimals}
        abbr={abbr}
        handleRemove={handleRemove}
        id={id}
      />
    );
  };

  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";

  return (
    <Box w={{ lg: "520px", base: "100%" }} className="ns-sidebar">
      <div className="tabs-wrapper">
        <div className="tabs-buttons">
          <div
            onClick={() => setCurrentTab("listed-nfts")}
            className={`tab ${currentTab === "listed-nfts" ? "active" : ""}`}
          >
            Listed {isOrdinals ? "Ordinals" : "items"}
          </div>
        </div>

        <div className="tab-content new-bg-transparent">
          {currentTab === "listed-nfts" ? (
            <>
              {IDsListed.length ? (
                IDsListed.map((id) => getNftItem(id))
              ) : (
                <div className="no-nfts">
                  <p>
                    To get started, list some {isOrdinals ? "Ordinals" : "items"}{" "}
                    and they will appear here.
                  </p>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </Box>
  );
}
