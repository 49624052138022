import { Button, Flex, Image } from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BiddableNft } from "../../contexts/roomContext";
import HTMLBlock from "../HTMLBlock";
import TxtBlock from "../TxtBlock";

import PendingImg from "../../assets/pending.jpg";
import IconPurpleCheck from "../Icons/IconPurpleCheck";
import { memo } from "react";
import ImageWrapper from "../ImageWrapper";
import { checkImage } from "../../utils";

interface Props {
  nft?: BiddableNft;
  hasBid: boolean;
  nftIndex: number;
  total: number;
  handleNext: () => void;
  handlePrev: () => void;
}

const createImage = (nft?: BiddableNft) => {
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }

  if (!nft) return;

  const img = nft.thumbnail || nft.image;

  if (nft.assetType === "video/mp4") {
    return <ImageWrapper url={checkImage(img)} assetType={nft.assetType} />;
  }

  return (
    <Image
      objectFit="cover"
      src={nft?.image || nft?.thumbnail}
      fallbackSrc={PendingImg}
    />
  );
};

// const createImage = (nft: Nft, bid: number) => {
//   const img = nft.thumbnail || nft.image;
//   if (nft?.assetType?.includes("text/plain")) {
//     return <TxtBlock nft={nft} width="100%" fontSize="12px" minH="200px" />;
//   }
//   if (nft?.assetType?.includes("text/html")) {
//     return (
//       <AspectRatio ratio={1} width="100%">
//         <HTMLBlock nft={nft} />
//       </AspectRatio>
//     );
//   }
//   return <ImageWrapper url={checkImage(img)} bid={bid} assetType={nft.assetType} />;
// };

function ModalImages({
  nft,
  hasBid,
  nftIndex,
  total,
  handleNext,
  handlePrev,
}: Props) {
  return (
    <Flex direction="column" className="image-arrows">
      <div
        className={`asset-container ${
          nft?.assetType?.includes("text/plain") ? "textitem" : ""
        } ${hasBid ? "is-listed" : ""}`}
      >
        {createImage(nft)}
        {hasBid && (
          <div className="is-listed-check">
            <IconPurpleCheck />
          </div>
        )}
      </div>

      <Flex
        gap="4"
        justifyContent="space-between"
        alignItems="center"
        className="nft-items-nav"
      >
        <Button
          className="nav-button"
          onClick={() => handlePrev()}
          isDisabled={total < 2}
        >
          <FaChevronLeft className="btn-icon" />
          <span className="btn-text">Prev</span>
        </Button>
        <p>
          Viewing [{nftIndex}/{total}]
        </p>
        <Button
          className="nav-button"
          onClick={() => handleNext()}
          isDisabled={total < 2}
        >
          <span className="btn-text">Next</span>
          <FaChevronRight className="btn-icon" />
        </Button>
      </Flex>
    </Flex>
  );
}

export default memo(ModalImages);
