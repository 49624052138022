import {
  AvatarGroup,
  Box,
  Flex,
  Heading,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import { useRoom } from "../contexts/roomContext";
import { saveProgress } from "../services/room.service";
import NFTThumb from "./NftThumb";

export default function ListedNfts() {
  const { uid } = useAppContext();
  const { roomId, listings, availableToList } = useRoom();
  const listed = Object.keys(listings || {});

  const goBack = () => {
    saveProgress(roomId, "listings", uid!, false);
  };

  const max = useBreakpointValue({ base: 1, md: 5 });

  return (
    <Box className="listing-page">
      <div className="room-title-wrapper">
        <div className="room-title">Your listed Items ({listed?.length})</div>
        <button onClick={goBack} className="small-link">
          Edit Listings
        </button>
      </div>
      <HStack className="listing-section box">
        {listed
          ?.sort()
          ?.slice(0, max)
          ?.map((id) => {
            const nft = availableToList?.find((ele) => ele.itemId === id)!;
            return <NFTThumb key={id} nft={nft} />;
          })}
        {listed.length > max! && (
          <Flex
            justifyContent="center"
            alignItems="center"
            boxShadow="0 0.5rem 1rem #0000001a"
            borderWidth="1px"
            minWidth="64px"
            boxSize="64px"
          >
            +{listed.length - max!}
          </Flex>
        )}
        {listed.length === 0 && <p>Nothing Listed Yet</p>}
      </HStack>
      {/* <Flex flexGrow={1} alignItems="baseline" flexDir="column">
        <button onClick={goBack} className="small-link">
          Edit Listings
        </button>
        <HStack mt="10px">
          {listed
            ?.sort()
            .slice(0, max)
            .map((id) => {
              const nft = availableToList?.find((ele) => ele.itemId === id)!;
              return <NFTThumb key={id} nft={nft} />;
            })}
          {listed.length > max! && (
            <Flex
              justifyContent="center"
              alignItems="center"
              boxShadow="0 0.5rem 1rem #0000001a"
              borderWidth="1px"
              minWidth="64px"
              boxSize="64px"
            >
              +{listed.length - max!}
            </Flex>
          )}
        </HStack>
      </Flex> */}
    </Box>
  );
}
