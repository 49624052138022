import {
  StacksMainnet,
  StacksTestnet,
  StacksNetwork,
} from "micro-stacks/network";

import { fetchAccountStxBalance } from "micro-stacks/api";

export let STACKS_NETWORK: StacksNetwork;
// if (process.env.NODE_ENV === "production") {
STACKS_NETWORK = new StacksMainnet();
// } else {
//   STACKS_NETWORK = new StacksTestnet();
// }

export const getBalance = async (address: string) => {
  const res = await fetchAccountStxBalance({
    url: STACKS_NETWORK.getCoreApiUrl(),
    principal: address,
  });
  if (!res.balance) return 0;
  return Number(res.balance);
};
