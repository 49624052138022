import {
  AvatarGroup,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { AvatarItem } from "./AvatarItem";

export const AvatarModal = ({
  onClose,
  isOpen,
  avatars,
  completed,
  title,
}: {
  onClose: () => void;
  isOpen: boolean;
  avatars?: string[];
  completed?: string[];
  title?: string;
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior={"inside"} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title || "Participants"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            {avatars
              ?.sort((a, b) => a.localeCompare(b))
              ?.map((id) => {
                return (
                  <AvatarItem
                    key={id}
                    id={id}
                    showDisplayName
                    completed={completed?.includes(id)}
                    showStatus={!!title}
                  />
                );
              })}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface Props {
  max?: number;
  size?: string;
  avatars: string[];
  completed?: string[];
}

export default function AvatarList({
  max = 2,
  size = "md",
  avatars,
  completed,
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AvatarModal
        avatars={avatars}
        completed={completed}
        isOpen={isOpen}
        onClose={onClose}
      />
      <div className="pointer" onClick={onOpen}>
        <Flex alignItems={"center"} gap={"5px"} direction={{ base: "row", lg: "column" }}>
          <div className="avatar-group-wrapper">
            <AvatarGroup
              size={{
                base: "sm",
                lg: size,
              }}
              mt="5px"
              max={max}
            >
              {avatars?.length
                ? avatars.map((avatarItem) => {
                  return <AvatarItem id={avatarItem} key={avatarItem} />;
                })
                : null}
            </AvatarGroup>
          </div>
        </Flex>
      </div>
    </>
  );
}
