export default function IconGradientClock() {
  return (
    <svg
      width="300"
      height="240"
      viewBox="0 0 300 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_68_2925)">
        <rect
          x="62"
          y="219"
          width="187"
          height="176"
          rx="88"
          transform="rotate(-90 62 219)"
          fill="black"
          fillOpacity="0.3"
        />
        <rect
          x="56"
          y="225"
          width="199"
          height="188"
          rx="94"
          transform="rotate(-90 56 225)"
          stroke="url(#paint0_linear_68_2925)"
          strokeWidth="12"
        />
      </g>
      <rect
        x="73.5"
        y="126.063"
        width="1.12483"
        height="7"
        rx="0.562416"
        transform="rotate(-90 73.5 126.063)"
        fill="url(#paint1_linear_68_2925)"
        stroke="url(#paint2_linear_68_2925)"
      />
      <rect
        x="225.5"
        y="124.938"
        width="1.12483"
        height="7"
        rx="0.562416"
        transform="rotate(90 225.5 124.938)"
        fill="url(#paint3_linear_68_2925)"
        stroke="url(#paint4_linear_68_2925)"
      />
      <rect
        x="149"
        y="44.7187"
        width="0.999921"
        height="7.50008"
        rx="0.49996"
        fill="url(#paint5_linear_68_2925)"
        stroke="url(#paint6_linear_68_2925)"
        strokeWidth="0.999921"
      />
      <rect
        x="150"
        y="206.281"
        width="0.999921"
        height="7.50008"
        rx="0.49996"
        transform="rotate(-180 150 206.281)"
        fill="url(#paint7_linear_68_2925)"
        stroke="url(#paint8_linear_68_2925)"
        strokeWidth="0.999921"
      />
      <path
        d="M131.5 136.125L202 95.2188"
        stroke="url(#paint9_linear_68_2925)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M166 138.25L110 94.6871"
        stroke="url(#paint10_linear_68_2925)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M149.5 131.812C152.87 131.812 155.5 128.928 155.5 125.5C155.5 122.072 152.87 119.188 149.5 119.188C146.13 119.188 143.5 122.072 143.5 125.5C143.5 128.928 146.13 131.812 149.5 131.812Z"
        fill="url(#paint11_linear_68_2925)"
        stroke="url(#paint12_linear_68_2925)"
        strokeWidth="2"
      />
      <path
        d="M152.25 125.5C152.25 127.137 151.005 128.438 149.5 128.438C147.995 128.438 146.75 127.137 146.75 125.5C146.75 123.863 147.995 122.562 149.5 122.562C151.005 122.562 152.25 123.863 152.25 125.5Z"
        fill="url(#paint13_linear_68_2925)"
        stroke="black"
        strokeWidth="0.5"
      />
      <defs>
        <filter
          id="filter0_b_68_2925"
          x="-15"
          y="-45"
          width="330"
          height="341"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_68_2925"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_68_2925"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_68_2925"
          x1="188.423"
          y1="170.722"
          x2="52.3363"
          y2="387.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_68_2925"
          x1="74.4365"
          y1="124.368"
          x2="69.7086"
          y2="126.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_68_2925"
          x1="74.4365"
          y1="124.368"
          x2="69.7086"
          y2="126.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_68_2925"
          x1="227.437"
          y1="122.243"
          x2="222.709"
          y2="124.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_68_2925"
          x1="227.437"
          y1="122.243"
          x2="222.709"
          y2="124.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_68_2925"
          x1="149.852"
          y1="41.8872"
          x2="145.267"
          y2="43.5064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_68_2925"
          x1="149.852"
          y1="41.8872"
          x2="145.267"
          y2="43.5064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_68_2925"
          x1="151.852"
          y1="204.45"
          x2="147.267"
          y2="206.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_68_2925"
          x1="151.852"
          y1="204.45"
          x2="147.267"
          y2="206.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_68_2925"
          x1="164.079"
          y1="92.1839"
          x2="187.234"
          y2="124.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_68_2925"
          x1="123.618"
          y1="113.528"
          x2="138.364"
          y2="102.246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_68_2925"
          x1="151.261"
          y1="117.273"
          x2="142.653"
          y2="129.433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_68_2925"
          x1="151.261"
          y1="117.273"
          x2="142.653"
          y2="129.433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_68_2925"
          x1="150.556"
          y1="120.564"
          x2="145.392"
          y2="127.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF000F" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
