export default function IconPurpleEye() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346625 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153722C7.99113 -0.154961 9.59966 0.00346661 11.0615 0.608968C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16ZM7.3 12.65C7.3 12.8357 7.37375 13.0137 7.50503 13.145C7.6363 13.2763 7.81435 13.35 8 13.35C8.18565 13.35 8.3637 13.2763 8.49498 13.145C8.62625 13.0137 8.7 12.8357 8.7 12.65V6.65C8.7 6.46435 8.62625 6.2863 8.49498 6.15503C8.3637 6.02375 8.18565 5.95 8 5.95C7.81435 5.95 7.6363 6.02375 7.50503 6.15503C7.37375 6.2863 7.3 6.46435 7.3 6.65V12.65ZM8 2.75C7.822 2.75 7.64799 2.80279 7.49999 2.90168C7.35198 3.00057 7.23663 3.14113 7.16851 3.30559C7.10039 3.47004 7.08257 3.651 7.1173 3.82558C7.15202 4.00017 7.23774 4.16053 7.36361 4.2864C7.48947 4.41227 7.64984 4.49798 7.82442 4.53271C7.999 4.56744 8.17996 4.54961 8.34442 4.48149C8.50887 4.41338 8.64943 4.29802 8.74832 4.15002C8.84722 4.00201 8.9 3.82801 8.9 3.65C8.9 3.41131 8.80518 3.18239 8.6364 3.01361C8.46762 2.84482 8.2387 2.75 8 2.75Z"
        fill="#FF4ADA"
      />
    </svg>
  );
}
