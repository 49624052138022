import { Image } from "@chakra-ui/react";
import InteractiveTrade from "./InteractiveTrade";
import PendingImg from "../assets/pending.jpg";

interface Props {
  gifUrl: string | undefined;
  gifData?: any;
}

export default function TradeGif({ gifUrl, gifData }: Props) {
  console.log("gifData", gifData);
  return (
    <div className="trade-gif">
      {gifData ? (
        <InteractiveTrade data={gifData} />
      ) : (
        <Image
          src={`https://${gifUrl}`}
          alt="Animated Gif"
          fallbackSrc={PendingImg}
          height="500px"
        />
      )}
    </div>
  );
}
