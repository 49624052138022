import { useState } from "react";
import { Room, useRoom } from "../contexts/roomContext";
import { Box, ResponsiveValue } from "@chakra-ui/react";

type Props = {
  align?: ResponsiveValue<"left" | "center" | "right" | "justify">;
};

export default function LastRun({ align = "right" }: Props) {
  const [countdownTime, setCountdownTime] = useState("");
  const [isSpinning, setSpinning] = useState(false);
  const { timeDiff } = useRoom();
  const currentTime = new Date().getTime() + timeDiff * 1000;

  // Set the target time for the countdown
  let targetTime = Math.ceil((currentTime) / 1000 / 60) * 1000 * 60;

  // Update the countdown every second
  let countdownInterval = setInterval(updateCountdown, 1000);

  function updateCountdown() {
    // Get the current time
    const currentTime = new Date().getTime() + timeDiff * 1000;

    // Calculate the remaining time in milliseconds
    const remainingTime = targetTime - currentTime;

    if (remainingTime <= 0) {
      clearInterval(countdownInterval);
      resetCountdown();
      return;
    }

    // Convert remaining time to seconds
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    // Format minutes and seconds with leading zeros
    const formattedSeconds = String(seconds).padStart(2, "0");

    // Return the countdown in mm:ss format
    const countdown = formattedSeconds + " seconds";

    setCountdownTime(countdown);
  }

  function resetCountdown() {
    // Show spinning animation
    setSpinning(true);
    const currentTime = new Date().getTime() + timeDiff * 1000;

    // Reset the target time for the countdown
    targetTime = Math.ceil(currentTime / 1000 / 60) * 1000 * 60;

    // Start the countdown again
    countdownInterval = setInterval(updateCountdown, 1000);

    // Hide spinning animation after a delay (e.g., 2 seconds)
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  }

  return (
    <>
      {isSpinning ? (
        <Box className="custom-loader">
          Running <span className="loader" />
        </Box>
      ) : (
        <Box textAlign={align}>Next prediction in: {countdownTime}</Box>
      )}
    </>
  );
}
