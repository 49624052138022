import React, { useState, useEffect } from "react";
import {
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  IconButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import IconPencil from "../Icons/IconPencil";
import IconCheck from "../Icons/IconCheck";
import { addBid, addListing } from "../../services/room.service";
import { useAppContext } from "../../contexts/appContext";
import { useUA } from "../../contexts/userTracking";

import { roundValue, checkCanSubmitVal, getReserveLabel } from "../../utils";

import {
  BiddableNft,
  useRoom,
  getHighestBid,
} from "../../contexts/roomContext";
import { type } from "os";

const createInitialBidVal = (
  reservePrice: number,
  decimals: number,
  truncate: number,
  bid?: number
) => {
  if (bid) {
    return roundValue(bid / 10 ** decimals, truncate, 'up'); ;
  } else {
    return 0;
  }
  // return reservePrice / 10 ** decimals;
};
 
interface Props {
  nfts: BiddableNft[];
  biddingId: string;
  type?: string;
  listedPrice?: string | number;
  startingBid?: string | number;
  setListedPrice?: (price: number) => void;
  itemId?: string;
  isEditingPrice: boolean;
  setIsEditingPrice: (isEditing: boolean) => void;
}

export default function ReservePriceForm({
  nfts,
  biddingId,
  type,
  listedPrice,
  startingBid,
  setListedPrice,
  isEditingPrice,
  setIsEditingPrice,
}: Props) {
  // const [isActive, setIsActive] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [bidVal, setBidVal] = useState<number | string>();

  const {
    bids,
    roomId,
    abbr,
    isSilentAuction,
    budgets,
    room,
    decimals,
    truncate,
    highestBids,
    incrementToken,
    incrementPct,
  } = useRoom();

  const [listVal, setListVal] = useState<number | string>(
    Number(listedPrice) / 10 ** decimals || 0
  );

  useEffect(() => {
    if (!decimals || !listedPrice) return;
    setListVal(Number(listedPrice) / 10 ** decimals || 0);
  }, [listedPrice, decimals]);

  // const { onOpen } = useDisclosure();

  const budget = budgets?.budget;
  const nft = nfts?.find((nft) => nft?.itemId === biddingId);
  const id = nft?.itemId;
  const bid = bids?.[id || ""]?.bidPrice;
  // const decimals = 10 ** (nft?.reservePrice?.decimals || 0);
  const reservePrice = nft?.reservePrice?.value || 0;
  const { gaSelectNft, gaSetBidPrice } = useUA();

  // const min = 0;
  const listmax = room?.info?.maxReservePrice;

  const { uid } = useAppContext();
  const toast = useToast();

  const sBid = nft?.startingBid;
  const max = roundValue(Number(budget || 0));
  const min = Number(sBid) / 10 ** decimals;
  const handleBid = async (
    value: string | number | undefined,
    ignoreCheck?: boolean
  ) => {
    const theDecimals = 10 ** (nft?.reservePrice?.decimals || 0);
    const roundedValue = roundValue(Number(value), truncate);
    const theValue = roundValue(Number(value), truncate);
    const isSecretReserve = room.info.secretReserve;
    const { isHighBidder, highestBidAmount, isTied, place } = getHighestBid(
      highestBids,
      id,
      uid
    );

    try {
      const { canSubmit, reason } = checkCanSubmitVal({
        theValue,
        min,
        max,
        reservePrice,
        decimals,
        isSecretReserve,
        isSilentAuction,
        currentHighestBid: highestBidAmount,
        incrementToken: incrementToken,
        incrementPct: incrementPct,
        abbr,
        truncate,
        roomType: room.info.roomType,
        maxbalance: budget
      });
      if (!id) return;
      setBidVal(roundedValue);
      let submissionVal = Number(roundedValue);
      submissionVal = submissionVal * theDecimals;

      if (fetching) return;
      setFetching(true);

      if (isSilentAuction && !ignoreCheck) {
        if (submissionVal > max) {
          throw new Error("Bid is more than spending limit");
        }
      }

      if (!canSubmit) {
        throw new Error(reason[0]);
      }

      // if (submissionVal < reservePrice)
      //   throw new Error("Bid is less than reserve price");

      await addBid(roomId, uid!, id, Math.ceil(submissionVal));
      gaSelectNft(roomId, id);
      gaSetBidPrice(roomId, id, submissionVal);
      toast({
        title: "Bid Set!",
        description: `Bid Set for ${Number(roundedValue)} ${abbr}`,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setIsEditingPrice(false);
    } catch (e: any) {
      if (e.message) {
        return toast({
          title: "Something went wrong!",
          description: e.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } finally {
      setFetching(false);
    }
  };

  const handleCheckError = (value: string | number) => {
    const val = Number(value);
    if (val > listmax || val < min) {
      setError("Price too high for the room!");
      return toast({
        title: "Something went wrong!",
        description: "Price too high for the room!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setError("");
    }
  };

  const handleUpdateListing = () => {
    let listedNum = Number(listVal);
    listedNum = roundValue(listedNum, truncate);
    setListVal(listedNum);

    if (listedNum > listmax || listedNum < min) {
      return handleCheckError(listedNum);
    } else {

      const listingPrice = listedNum * 10 ** decimals;
      let adjustedReservePrice = listingPrice / (1 + (room.info.markupPercent / 100) + (nft?.royalty?.rate || 0));

      let newStartingBid = Number(startingBid);
      if (newStartingBid >= listingPrice) {
        newStartingBid = listingPrice; // Update starting bid to match the new listing price
      }
      console.log(newStartingBid)
      addListing(roomId, uid!, id || "", {
        reservePrice: adjustedReservePrice,
        listingPrice: listingPrice,
        startingBid: newStartingBid,
      });

      toast({
        title: "Listing Updated!",
        description: `Listing updated for ${Number(listedNum)} ${abbr}`,
        status: "success",
        duration: 1500,
        isClosable: true,
      });

      setIsEditingPrice(false);
    }
  };

  // Enter key detection
  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      if (type === "listing") {
        handleUpdateListing();
      } else {
        handleBid(bidVal);
      }
    }
  };

  useEffect(() => {
    if (!decimals) return;
    setError(undefined);
    setBidVal(createInitialBidVal(reservePrice, decimals, truncate, bid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft?.itemId, bids, decimals]);

  return (
    <div className={`reserve-form ${isEditingPrice ? "active" : ""}`}>
      {isEditingPrice === true ? (
        <InputGroup>
          <NumberInput
            placeholder="Set Spending Limit"
            min={0}
            step={0.01}
            value={type === "listing" ? listVal : bidVal}
            _dark={{
              backgroundColor: "transparent",
              opacity: 1,
            }}
            width="110px"
            onChange={type === "listing" ? setListVal : setBidVal}
            keepWithinRange
            clampValueOnBlur
            onKeyDown={handleKeyDown}
            allowMouseWheel={false}
            lang="en"
          >
            <NumberInputField className="reserve-price-input" />
            <NumberInputStepper>
              <NumberIncrementStepper className="stepper-controls" />
              <NumberDecrementStepper className="stepper-controls" />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
      ) : (
        <div className="reserve-price-disabled">
          {type === "listing" ? listVal : bidVal} {abbr}
        </div>
      )}
      <IconButton
        className={`icon-button ${isEditingPrice ? "active" : ""}`}
        onClick={() => {
          if (isEditingPrice) {
            if (type === "listing") {
              handleUpdateListing();
            } else {
              handleBid(bidVal);
            }
          } else {
            setIsEditingPrice(true);
          }
        }}
        aria-label="Edit"
        icon={isEditingPrice ? <IconCheck /> : <IconPencil />}
      />
    </div>
  );
}
