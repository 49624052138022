import { Box } from "@chakra-ui/react";
import { Nft } from "../contexts/roomContext";
import { NftMetadata } from "ns-types";

interface Props {
  nft: Nft | NftMetadata;
  style?: { [key: string]: string | number };
}

export default function HTMLBlock({ nft, style }: Props) {
  return (
    <iframe
      src={`https://ordinals.com/content/${nft.address}`}
      datatype={nft.assetType}
      style={style}
    />
  );
}
