import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";

import PendingImg from "../../assets/pending.jpg";
import { useAppContext } from "../../contexts/appContext";
import { useRoom } from "../../contexts/roomContext";
import useNeoChain from "../../hooks/useNeoChain";
import { getNftDetail } from "../../services/nfts.service";
import { Nft } from "ns-types";

interface Props {
  nfts: any;
  saveProgress: () => void;
  handleRemove: (id: string) => void;
  removeAll: () => void;
  solving?: boolean;
  // part: string[];
  // total: number;
  // connected: string[];
  finishLabel: string;
}

const BottomNft = ({
  id,
  handleRemove,
}: {
  id: string;
  handleRemove: (id: string) => void;
}) => {
  const { availableForBid } = useRoom();
  const nft = availableForBid?.find((nft) => nft.itemId === id);

  return (
    <Box position="relative">
      <IconButton
        position="absolute"
        right="-2.5"
        top="-2.5"
        aria-label="remove"
        icon={<FaTrashAlt />}
        size="xs"
        variant="ghost"
        onClick={() => handleRemove(id)}
        background="#051a28"
      />
      <Image
        minWidth="64px"
        boxSize="64px"
        objectFit="cover"
        src={nft?.thumbnail || nft?.image || ""}
        fallbackSrc={PendingImg}
        alt=""
      />
    </Box>
  );
};

export default function BottomBar({
  nfts,
  saveProgress,
  handleRemove,
  removeAll,
  finishLabel,
}: Props) {
  const { uid } = useAppContext();
  const { room } = useRoom();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const max = useBreakpointValue({ base: 1, md: 5 });
  const chainFns = useNeoChain() as any;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const { cancel } = chainFns[roomChain || "not_found"];

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your Bids</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap="5" flexWrap="wrap">
              {nfts?.sort().map((id: string) => {
                return (
                  <BottomNft key={id} id={id} handleRemove={handleRemove} />
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        className="bottom-fixed-bar"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        display="flex"
        background="gray"
        paddingX="5%"
        alignItems="center"
        borderWidth="1px"
        _dark={{
          backgroundColor: "#1c2944",
          boxShadow: "0 0.5rem 1rem #0000001a",
        }}
        py="1"
        zIndex={99}
        flexDir="column"
      >
        <VStack width="100%" spacing={2}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <HStack minH={"84px"}>
              {uid === "ethereum-0xd54DA03AF0Fd56f57820f94f4245F51ED349493f" &&
                cancel && <Button onClick={cancel}>Cancel Swap</Button>}
              <Button
                className="bar-button"
                onClick={saveProgress}
                bg="#6C60FF"
              >
                {finishLabel}
              </Button>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
