import { Table, TableProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props extends TableProps {
  children: ReactNode;
}

export default function NeoTable({ children, ...rest }: Props) {
  return (
    <Table
      sx={{
        borderCollapse: "separate",
        borderSpacing: "0 .5em",
        th: {
          borderBottom: "hidden",
        },
      }}
      {...rest}
    >
      {children}
    </Table>
  );
}
