import { useLocation, useNavigate } from "react-router-dom";

// ICONS
import IconGradientClock from "../Icons/IconGradientClock";

const Expired = () => {
  const navigate = useNavigate();
  const location = useLocation()
  return (
    <div className="flex flex-col items-center gap-20 mt-6 md:mt-16">
      <div>
        <IconGradientClock />
      </div>
      <div className="text-center">
        <h1 className="text-white text-3xl font-medium leading-10">
          Time to sign has expired. You've lost your Pack
        </h1>
        <p className="text-white text-xl font-normal">
         Please try again.
        </p>
      </div>

      <button
        className="w-64 h-16 px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
        onClick={() =>
          navigate(
            location.pathname.includes("/ai-trades")
              ? "/star-atlas"
              : "/pay-with-neoswap"
          )
        }
      >
        Back to Homepage
      </button>
    </div>
  );
};

export default Expired;
