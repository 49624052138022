import { Icon, IconProps } from "@chakra-ui/react";

export default function IconSolana(props: IconProps) {
  return (
    <Icon id="svg-solana" viewBox="0 0 30 30" {...props}>
      <path
        d="M5.76,20.65c0.17-0.17,0.39-0.26,0.63-0.26h21.84c0.4,0,0.6,0.48,0.32,0.76l-4.31,4.31 c-0.17,0.17-0.39,0.26-0.63,0.26H1.77c-0.4,0-0.6-0.48-0.32-0.76L5.76,20.65z"
        fill="currentColor"
      ></path>
      <path
        d="M5.76,4.54c0.17-0.17,0.4-0.26,0.63-0.26h21.84c0.4,0,0.6,0.48,0.32,0.76l-4.31,4.31 c-0.17,0.17-0.39,0.26-0.63,0.26H1.77c-0.4,0-0.6-0.48-0.32-0.76L5.76,4.54z"
        fill="currentColor"
      ></path>
      <path
        d="M24.24,12.54c-0.17-0.17-0.39-0.26-0.63-0.26H1.77c-0.4,0-0.6,0.48-0.32,0.76l4.31,4.31 c0.17,0.17,0.39,0.26,0.63,0.26h21.84c0.4,0,0.6-0.48,0.32-0.76L24.24,12.54z"
        fill="currentColor"
      ></path>
    </Icon>
  );
}
