export const DESCRPTIONS = {
  roi: `ROI (Return on Investment) represents the percentage of profit relative to the total cost. It is calculated based on the profit gained from selling the crafted item on the marketplace, taking into account the marketplace fee, at the current lowest market price or by fulfilling the highest buy orders. The cost refers to the total cost of the materials required to craft the item, including the crafting fee. This estimate assumes that the market prices of both the item and its ingredients remain constant. Network fees are excluded from this calculation.`,
  roi_negative:
    'Negative ROI indicates that crafting the given item will result in a loss if the market prices remain unchanged.',
  daily_revenue: `Daily Revenue is calculated as the total profit earned from crafting a specific item with a given number of crew members over a day. The profit is determined by subtracting the total cost of crafting (materials and crafting fees) from the revenue generated by selling the items on the marketplace. The revenue is based on either the current lowest market price of the item or by fulfilling the highest buy orders, and it takes into account the marketplace fees.`,
  daily_revenue_negative:
    'Negative Daily Revenue indicates that crafting the given item will result in a loss if the market prices remain unchanged.',
  duration:
    'Duration refers to the time (in seconds) required to create one unit of an item with a single crew member. The shorter the duration, the faster the item is produced. The more crew members you have, the more units you can produce within a given period of time.',
  crafting_calculator:
    "Crafting Calculator helps to estimate time or quantity of items that can be crafted with the given ingredients and crew members and veryfies if it's profitable to craft them.",
  crafting_data:
    'The table shows the most profitable items to craft in Star Atlas based on current market conditions. Both the sell price and buy price are based on the average of the top units listed in the marketplace (you can specify the number in "Advanced Options"). Other metrics are calculated using these sell and buy prices. The values for ROI and Daily Revenue are estimated under the assumption that prices will remain constant, though this is not guaranteed.',
  crafting_fee:
    "Crafting Fee refers to the cost associated with manufacturing an item. This fee is displayed in the crafting interface, allowing you to view it in advance. Currently, the Star Atlas team has set the crafting fee at 7% of the item's average Marketplace price over the last 7 days.",
  marketplace_fee: `Marketplace Sell Fee is a fee applied when creating a sell order for items on the Star Atlas Marketplace. Buyers are not charged any fees. By default, this fee is set at 6%, but it can be reduced by staking ATLAS in the locker.`,
  tulle_fee_info: 'Tulle takes 2.5% convenience fee for Star Atlas packs. ',
  crafting_cost:
    "Crafting Cost includes the price of the items required for crafting as well as the crafting fee. The crafting fee is 7% of the item's average Marketplace price over the last 7 days.",
  crating_speed:
    'If the starbase is not supplied with food or toolkits, its crafting speed is reduced. A 50% reduction applies to starbases of tier 5 or 6.',
  crafting_packs: `Here, you can buy packs with ingredients needed to craft the most profitable items and resupply packs to improve crafting time in your starbase. For more customized packs, visit the crafting calculator tab or ask our chatbot, Theo.`,
  sell_and_buy_price: `Fulfilling buy orders means selling at the highest current buy orders, providing faster liquidation compared to placing a sell order. When choosing to place a sell order, the profit is calculated based on the assumption that the item will sell at the lowest current sell price on the market. While this approach can maximize profit, it may take longer to find a buyer, and there is a risk that your offer could be undercut by lower-priced listings.`,
  instant_sell_price_roi: `Selling at the instant sell price means accepting the highest current buy orders, offering faster liquidation compared to placing a sell order. If this option is not selected, the profit is calculated based on the assumption that the item will sell at the lowest current sell price on the market. While this approach can maximize profit, it may take longer to find a buyer, and there is a risk that your listing could be undercut by lower-priced offers.`,
  price_for_units:
    'We calculate the true prices based on the number of units by summing the prices of orders listed on the marketplace, rather than simply multiplying by the lowest (or highest) listed price. This method prevents market manipulation from single-unit orders and provides a more accurate reflection of the real price for purchasing crafting ingredients.',
  instant_sell_price:
    'The instant sell price refers to fulfilling the highest active buy orders in the marketplace. It offers the fastest liquidation but typically results in lower revenue.',
  instant_buy_price:
    'The instant buy price refers to accepting the lowest active sell order in the marketplace. It provides a quick purchase but usually at a higher cost compared to placing bids.',
}
