import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Center,
  Container,
  Flex,
  Heading,
  Text,
  Box,
  Image,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../contexts/appContext";
import SignInButton from "../components/SignInButton";
import { ChainSwitcher } from "../components/navbar";
import PartnerGroup from "../components/PartnerGroup";

// Appeared In
import yahoo from "../assets/appearedin/yahoo.png";
import crunchbase from "../assets/appearedin/crunchbase.png";
import cointelegraph from "../assets/appearedin/cointelegraph.png";
import benzinga from "../assets/appearedin/benzinga.png";
import alexablockchain from "../assets/appearedin/alexablockchain.png";
import aithority from "../assets/appearedin/aithority.png";

import ImageSlider from "../components/ImageSlider";

type ImageProps = {
  name: string;
  image: string;
  link?: string;
};

export default function Connect() {
  const { user, returnUrl } = useAppContext();

  if (user && returnUrl) {
    return <Navigate to={returnUrl} replace={true} />;
  }

  const appearedin = [
    {
      name: "yahoo",
      image: yahoo,
      link: "https://finance.yahoo.com/news/neoswap-introduces-ai-proposed-trades-175100825.html",
    },
    {
      name: "crunchbase",
      image: crunchbase,
      link: "https://www.crunchbase.com/organization/neoswap",
    },
    {
      name: "cointelegraph",
      image: cointelegraph,
      link: "https://cointelegraph.com/news/on-combining-ai-and-blockchains-qa-with-mattereum-memriio-and-neoswap",
    },
    {
      name: "benzinga",
      image: benzinga,
      link: "https://www.benzinga.com/pressreleases/23/05/n32392739/neoswap-ai-conducts-worlds-first-smart-auction-of-ordinals-for-bitcoin-unveiling-a-new-era-in-the-",
    },
    {
      name: "alexablockchain",
      image: alexablockchain,
      link: "https://alexablockchain.com/neoswap-ai-makes-history-with-worlds-first-bitcoin-nfts-smart-auction/",
    },
    {
      name: "aithority",
      image: aithority,
      link: "https://aithority.com/machine-learning/neoswap-introduces-ai-proposed-trades-for-personalized-nft-trading-experience/",
    },
  ];

  return (
    <>
      <Helmet>
        <title>NeoSwap | Connect Wallet</title>
      </Helmet>
      <Container maxW="550px">
        <Flex
          flexDir="column"
          mt={{
            lg: "50px",
            base: "10px",
          }}
          textAlign="center"
        >
          <Heading
            as={"h2"}
            size={{
              lg: "2xl",
              base: "lg",
            }}
            mb={5}
            p={{
              md: "0px 70px",
              sm: "0px",
            }}
          >
            Connect Wallet
          </Heading>
        </Flex>
        <Flex
          direction={"column"}
          gap={4}
          justifyContent="center"
          alignItems={"center"}
          rounded={"28px"}
          className="new-bg-transparent"
          p={8}
          w={"fit-content"}
          ml={"auto"}
          mr={"auto"}
        >
          <Text
            mb={{ md: 5, base: 0 }}
            fontSize={"md"}
          >
            Select a chain and connect your wallet
          </Text>
          <Flex justifyContent="center" mb="10px">
            <ChainSwitcher type="tile" />
          </Flex>
          <SignInButton version="connect-page" />

          <Flex
            maxWidth={"600px"}>
            <Alert
              status="info"
              className="info-alert"
              background={
                "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"

              }
              borderRadius={"4px"}
            >
              <AlertIcon color={"#6C60FF"} />
              <AlertDescription>
                {"This product is still in beta.  If you encounter any problems, please use the feedback form on the left."}
              </AlertDescription>
            </Alert>
          </Flex>
          {/* <Text
            textAlign={"center"}
            fontSize={"12px"}
          >
            AI Trades are now live on <strong>Stacks</strong> and{" "}
            <strong>Solana</strong>. Coming soon to Bitcoin and Ethereum.
            Connect your Bitcoin and ETH wallet to join the waitlist or explore
            smart auctions{" "}
            <Link to="/" style={{ color: "#F646D5" }}>
              here
            </Link>
            .
          </Text> */}
        </Flex>
      </Container>
      <PartnerGroup />
    </>
  );
}
