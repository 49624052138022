import { useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { useAppContext } from "../../contexts/appContext";
import isAuthorized from "../Authorized";
import SignInButton from "../SignInButton";
import { FaBars } from "react-icons/fa";
import { useChain } from "../../contexts/chainsContext";
import { useUA } from "../../contexts/userTracking";

import IconSolana from "../../components/Icons/IconSolana";
import IconStacks from "../../components/Icons/IconStacks";
import IconEthereum from "../../components/Icons/IconEthereum";
import IconBitcoin from "../../components/Icons/IconBitcoin";
import IconPolygon from "../../components/Icons/IconPolygon";
import IconATLAS from "../../components/Icons/IconATLAS";

const AuthedButton = (props: any) => {
  const Btn = isAuthorized(Button, props.rights);
  return <Btn {...props} />;
};

export const ChainSwitcher = ({ type }: { type?: string }) => {
  const { chains, chain, setChain } = useChain();
  const formattedChains = chains?.sort((a, b) => {
    const order = ["Solana", "Bitcoin", "Stacks"];
    let indexA = order.indexOf(a.name);
    let indexB = order.indexOf(b.name);

    if (indexA === -1) {
      indexA = order.length;
    }

    if (indexB === -1) {
      indexB = order.length;
    }

    return indexA - indexB;
  });
  if (type === "tile") {
    return (
      <Flex
        gap={"20px"}
        justifyContent={{
          base: "space-between",
          md: "space-between",
          sm: "center",
        }}
        flexWrap={"wrap"}
      >
        {formattedChains?.map((theChain: any) => {
          return (
            <Flex
              direction={"column"}
              alignItems={"center"}
              gap={"10px"}
              cursor={"pointer"}
              data-group
              onClick={() => setChain(theChain.name)}
              key={theChain.name}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                transition={"all .2s ease-in-out"}

                className="text-earth packs-widget"
                _groupHover={{ background: "rgba(255,255,255,.2)" }}
                rounded="xl"
                w={{ lg: "95px", base: "70px" }}
                h={{ lg: "95px", base: "70px" }}
                border={
                  chain?.name === theChain.name
                    ? "3px solid #6C60FF"
                    : "3px solid transparent"
                }
              >
                {theChain.name === "Solana" ? (
                  <IconSolana
                    w={{ lg: "47px", base: "30px" }}
                    h={{ lg: "47px", base: "30px" }}
                  />
                ) : null}
                {theChain.name === "Stacks" ? (
                  <IconStacks
                    w={{ lg: "47px", base: "30px" }}
                    h={{ lg: "47px", base: "30px" }}
                  />
                ) : null}
                {/* {theChain.name === "Ethereum" ? (
                  <IconEthereum
                    w={{ lg: "47px", base: "30px" }}
                    h={{ lg: "47px", base: "30px" }}
                  />
                ) : null} */}
                {theChain.name === "Bitcoin" ? (
                  <IconBitcoin
                    w={{ lg: "47px", base: "30px" }}
                    h={{ lg: "47px", base: "30px" }}
                  />
                ) : null}
              </Flex>
              <Text className="text-earth" >{theChain.name}</Text>
            </Flex>
          );
        })}
      </Flex>
    );
  } else {
    return (
      <Box mr="20px" >
        <Menu>
          <MenuButton
            fontSize="sm"
            as={Button}
            variant="outline"
            size="sm"
            _hover={{ bg: "none" }}
            _focus={{ bg: "none" }}
            className="text-earth"
          >
            <Flex alignItems={"center"} gap={"4px"}>
              {chain?.name === "Bitcoin" ? (
                <IconBitcoin w="17px" h="17px" />
              ) : null}
              {chain?.name === "Solana" ? (
                <IconSolana w="17px" h="17px" />
              ) : null}
              {chain?.name === "Stacks" ? (
                <IconStacks w="17px" h="17px" />
              ) : null}
              {chain?.name || "Select a chain"}

              <ChevronDownIcon />
            </Flex>
          </MenuButton>
          <MenuList zIndex={99}>
            {formattedChains?.map((chain: any) => {
              return (
                <MenuItem
                  key={chain.name}
                  onClick={() => setChain(chain.name)}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                >
                  <Flex gap={"5px"} alignItems={"center"}>
                    {chain.name === "Bitcoin" ? (
                      <IconBitcoin w="17px" h="17px" />
                    ) : null}
                    {chain.name === "Solana" ? (
                      <IconSolana w="17px" h="17px" />
                    ) : null}
                    {chain.name === "Stacks" ? (
                      <IconStacks w="17px" h="17px" />
                    ) : null}

                    {chain.name}
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Box>
    );
  }
};

export default function Navbar({ top }: { top?: string }) {
  const { chains, chain, setChain } = useChain();
  const { uid, token } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { gaTryAiTrades } = useUA();

  const [isNavOpen, setIsNavOpen] = useState(false);

  interface isExternal {
    id: string;
    label: string;
    to: string;
    isExternal?: boolean;
    logo?: boolean
    isNew?: boolean
  }

  const routes: isExternal[] = [
    // { id: "ai-trades", label: "AI Trades", to: "/ai-trades" },
    //    { id: "star-atlas", label: "Starter Packs", to: "/star-atlas", logo: true, isNew: false },
    //{ id: "collection-swap", label: "Collection Swap Live Demo", to: "/collection-swap", isNew: true },
    // {
    //   id: "Host an Event",
    //   label: "Host an Event",
    //   to: "https://neoswap.cc/run-an-event",
    //   isExternal: true,
    // },
    // { id: "create", label: "Create", to: "/rooms/create" },
    // { id: "events", label: "Events", to: "/rooms" },
    // {
    //   id: "points",
    //   label: "Points",
    //   to: "https://neoswap.cc/points",
    //   isExternal: true,
    // },
  ];

  const location = useLocation();

  return (
    <nav
      id="nav-bar"
      className={`main-menu-content text-earth ${location.pathname === "/rooms" ? "isroomroute" : ""
        }`}
      style={top ? { top: top } : {}}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={"10px"}
        className="nav-content page-width"
      // p={"10"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
          className="avatar-menu-holder text-earth"
        >
          <Box className="logo" as={NavLink} to="/" minW="150px" mr={2}>
            <img src={Logo} alt="NeoSwap" className="logo" />
          </Box>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"full"}
            direction={"row-reverse"}
            pl={5}
            pr={4}
            pb={4}
          >
            <label className="hamburger-menu" htmlFor="toggle">
              <input
                id="toggle"
                type="checkbox"
                onClick={() => setIsNavOpen(!isNavOpen)}
              />
              <FaBars />
            </label>

            <Box display={{ base: "flex", md: "none" }}>
              {!(token || uid) && <ChainSwitcher />}
              <SignInButton />
            </Box>
          </Flex>
        </Flex>

        <ButtonGroup
          className={`main-nav ${isNavOpen ? "open" : null}`}
          variant="link"
          spacing="8"
        >
          {/* <Menu placement="bottom-end">
            <MenuButton
              as={NavLink}
              to="#"
              className="main-menu-dropdown"
              onClick={onOpen}
            // textAlign="end"
            >
              Starter Packs <ChevronDownIcon />
            </MenuButton>
            <MenuList className="main-menu-dropdown-content">

              <MenuItem as={NavLink} to="/star-atlas">
                Star Atlas
              </MenuItem>
              <MenuItem as={NavLink} to="/genopets">
                Genopets
              </MenuItem>

            </MenuList>
          </Menu> */}
          {routes.map(({ id, label, to, isExternal, logo, isNew }) => {
            if (isExternal) {
              return (
                <Link
                  key={id}
                  href={to}
                  target="_blank"
                  className="main-menu-dropdown text-earth"
                >


                  {label}
                </Link>
              );
            }
            if (
              id === "room-create" ||
              id === "my-swaps" ||
              id === "my-items" ||
              id === "ai-history"
            ) {
              let rights;

              if (id == "room-create") {
                rights = ["create-room"];
              }

              return (
                <AuthedButton
                  key={id}
                  as={NavLink}
                  to={to}
                  rights={rights}
                  className={uid && id === "my-swaps" ? "" : "!hidden"}
                >
                  {label}
                </AuthedButton>
              );
            }

            return (
              <Button
                key={id}
                as={NavLink}
                className="main-menu-dropdown text-white"
                to={to}
                onClick={() => {
                  if (id === "ai-trades") gaTryAiTrades();
                }}
              >
                {logo && <IconATLAS width="21px" height="21px" />}
                {label}
                {isNew && <span style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#FCD34D',
                  transform: 'translate(50%, -50%)', // Center it on the top-right corner
                }}></span>}
              </Button>
            );
          })}
          {/* <Menu placement="bottom-end">
            <MenuButton
              as={NavLink}
              to="#"
              className="main-menu-dropdown"
              onClick={onOpen}
            // textAlign="end"
            >
              Resources <ChevronDownIcon />
            </MenuButton>
            <MenuList className="main-menu-dropdown-content">
              <MenuItem
                as={"a"}
                href="https://neoswap.cc/about"
                target="_blank"
              >
                About
              </MenuItem>
              <MenuItem
                as={"a"}
                href="https://neoswap.cc/feedback"
                target="_blank"
              >
                Feedback
              </MenuItem>
              <MenuItem as={NavLink} to="/faq">
                FAQ
              </MenuItem>
              <MenuItem as={NavLink} to="/privacy-policy">
                Privacy Policy
              </MenuItem>
              <MenuItem as={NavLink} to="/terms-of-service">
                Terms
              </MenuItem>
              <MenuItem as={NavLink} to="/updates">
                Updates
              </MenuItem>
              <MenuItem
                as={"a"}
                href="https://neoswap.cc/subscribe"
                target="_blank"
              >
                Newsletter
              </MenuItem>
            </MenuList>
          </Menu> */}
        </ButtonGroup>
        <Box className="profile" display={{ base: "none", md: "flex" }}>
          {!(token || uid) && <ChainSwitcher />}
          <SignInButton />
        </Box>
      </Flex>
    </nav>
  );
}
