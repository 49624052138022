import { ethers } from "ethers";
// import { AbiItem } from "web3-utils";

export default [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_executor",
                type: "address",
            },
        ],
        name: "addNewApprovedExecutor",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "approvedPoolAddress",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "enum NeoswapDepositRouter.ContractStatus",
                name: "_status",
                type: "uint8",
            },
        ],
        name: "changeContractStatus",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "enum NeoswapDepositRouter.TokenType[]",
                name: "_tokenTypes",
                type: "uint8[]",
            },
            {
                internalType: "address[]",
                name: "_contracts",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_tokenIds",
                type: "uint256[]",
            },
            {
                internalType: "uint256[]",
                name: "_qty",
                type: "uint256[]",
            },
            {
                internalType: "address",
                name: "_poolAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_poolId",
                type: "uint256",
            },
        ],
        name: "depositAssets",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_poolAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_poolId",
                type: "uint256",
            },
        ],
        name: "depositNativeAssetOnly",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_executor",
                type: "address",
            },
        ],
        name: "removeApprovedExecutor",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_poolAddress",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_status",
                type: "uint256",
            },
        ],
        name: "setApprovedPoolAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "status",
        outputs: [
            {
                internalType: "enum NeoswapDepositRouter.ContractStatus",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
] as ethers.ContractInterface;
