import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  Flex,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useAppContext } from "../../contexts/appContext";
import { Nft } from "../../contexts/roomContext";
import { updateUser } from "../../services/user.service";
import PendingImg from "../../assets/pending.jpg";
import MYNFTModal from "../MYNFTmodal";
import { useEffect, useState } from "react";
import { NFTModal } from "../NFTModal";
import { BiLockAlt } from "react-icons/bi";
import { BsUnlockFill, BsFillLockFill } from "react-icons/bs";
import { LockNftModal } from "../LockNftModal";
import { getLockStatus } from "../../services/room.service";
import { FaLock, FaUnlockAlt } from "react-icons/fa";

interface Props {
  nft: Nft;
}

export default function MyNftCard({ nft }: Props) {
  const toast = useToast();
  const { name, thumbnail, itemId } = nft;
  const { uid } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState(false);

  const handlePfp = async () => {
    try {
      await updateUser(uid!, { avatar: thumbnail });
      toast({
        title: "User Avatar Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Updating Avatar!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const checkLockStatus = async () => {
      const lockStatus = await getLockStatus(uid!, itemId);
      if (lockStatus) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    };
    checkLockStatus();
  });

  return (
    <Stack spacing={useBreakpointValue({ base: "4", md: "5" })}>
      <LockNftModal
        nft={nft}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        userId={uid!}
        itemId={itemId}
      />

      <Box position="relative">
        {isLocked && (
          <Box className="lock-icon-div">
            <FaLock className="lock-icon" color="white" />
          </Box>
        )}
        <AspectRatio ratio={4 / 3}>
          <NFTModal nft={nft}>
            <Image
              src={thumbnail}
              alt={name}
              draggable="false"
              fallbackSrc={PendingImg}
              borderRadius={useBreakpointValue({ base: "md", md: "xl" })}
              onClick={() => setIsModalOpen(true)}
              cursor="pointer"
              className="ntfimage"
              style={{
                opacity: isLocked ? 0.3 : isHovering ? 0.3 : 1, // apply the opacity style
                transition: "opacity 0.2s", // add a transition effect
              }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            />
          </NFTModal>
        </AspectRatio>
      </Box>
      <MYNFTModal
        nft={nft}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <HStack>
        <div className="locking-nft-div">
          <Box>
            <Text
              fontWeight="medium"
              color={useColorModeValue("gray.700", "gray.400")}
              className="nft-card-name"
            >
              {name || "Name not found"}
            </Text>
          </Box>

          <Box>
            {isLocked ? (
              <Button px={0} onClick={handleButtonClick}>
                <FaUnlockAlt color="white" size="20" />
              </Button>
            ) : (
              <Button px={0} onClick={handleButtonClick}>
                <FaLock color="white" size="20" />
              </Button>
            )}

            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoEllipsisHorizontalSharp />}
                variant="outline"
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                ml="2"
              />

              <MenuList>
                <MenuItem
                  icon={<AddIcon />}
                  onClick={handlePfp}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                >
                  Make PFP
                </MenuItem>
                <MenuItem
                  icon={<InfoIcon />}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  onClick={() => {
                    window.open(nft.marketplace.url, "_blank");
                  }}
                >
                  View More Info
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </div>
      </HStack>
    </Stack>
  );
}
