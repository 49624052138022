import React from 'react'
import { Helmet } from 'react-helmet-async'

const CollectionSwap: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NeoSwap | Collection Swap Live Demo</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-6">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold">Collection Swap is now Potato Cat</h1>
          <p className="text-white mt-2">
            Click{' '}
            <a
              style={{ color: 'hotpink', textDecoration: 'underline' }}
              href="https://app.potatocat.ai/"
              target="_blank"
            >
              here
            </a>{' '}
            to go to the Potato Cat page.
          </p>

          {/* add clickable image from ../assets/potatoCatLogo.png */}
          <a href="https://app.potatocat.ai/" target="_blank">
            <img src={require('../assets/potatoCatLogo.png')} alt="Potato Cat Logo" />
          </a>
        </div>
      </div>
    </>
  )
}

export default CollectionSwap
