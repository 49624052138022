import { Button } from "@chakra-ui/react";
import IconEyeSlash from "../Icons/IconEyeSlash";

const PassButton = ({
  hasBid,
  handlePass,
}: {
  hasBid: boolean;
  handlePass: any;
}) => {
  return (
    <>
      <Button
        variant="solid"
        isDisabled={hasBid}
        onClick={handlePass}
        className="button bid-modal-pass-btn"
        rounded="md"
      >
        <IconEyeSlash />
        Hide
      </Button>
    </>
  );
};

export default PassButton;
