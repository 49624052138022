import React, { useState } from "react";
import {
  Button,
  Center,
  Container,
  Heading,
  useToast,
  Box,
  Text,
  CheckboxGroup,
  Stack,
  Checkbox,
  Input,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { joinBuyDownWaitlist } from "../services/user.service";
import { useAppContext } from "../contexts/appContext";
import { validateEmail } from "../utils";

function BuyDownWaitlist() {
  const [joining, setJoining] = useState(false);
  const [email, setEmail] = useState("");
  const [isOtherChecked, setIsOtherChecked] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    email: "",
    blockchains: [],
    otherSuggestedBlockchain: "",
    emaillist: false,
  });

  const { uid } = useAppContext();

  const toast = useToast();

  const handleJoinWaitlist = async () => {
    try {
      setJoining(true);
      await joinBuyDownWaitlist(formData);
      if (!validateEmail(formData.email)) {
        throw new Error("Invalid email");
      }

      toast({
        title: "Congratulations!",
        description: "You were added to the waitlist.",
        status: "success",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });

      setFormData({
        email: "",
        blockchains: [],
        otherSuggestedBlockchain: "",
        emaillist: false,
      });

      localStorage.setItem("buydown-waitlist-subscribed", "true");
    } catch (e: any) {
      toast({
        title: "Something went wrong adding you to the waitlist",
        description: e?.message || "",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    } finally {
      setJoining(false);
    }
  };

  type FormData = {
    email: string;
    blockchains: string[];
    emaillist: boolean;
    otherSuggestedBlockchain?: string;
  };

  const handleCheckboxChange = (values: string[]) => {
    if (values.includes("other")) {
      setIsOtherChecked(true);
    } else {
      setIsOtherChecked(false);
      setFormData({
        ...formData,
        otherSuggestedBlockchain: "",
      });
    }
    const newBlockchains = values.filter((val) => val !== "other");
    setFormData((prev) => ({ ...prev, blockchains: newBlockchains }));
  };

  return (
    <>
      <Helmet>
        <title>NeoSwap | Pay with Items Beta Test Waitlist</title>
      </Helmet>
      <Center>
        <Container maxW="xl" textAlign="center" gap={8}>
          <Heading className="wait-title" as="h2" size="lg">
            NeoSwap Pay with Items <br></br> Beta Test Waitlist
          </Heading>
          <p className="sub-waitlist">
            NeoSwap's new “Pay with Items” technology will allow you to buy down the price of Items using Items you already own.
          </p>
          
          <Container maxW="xl">
            <Box mb={"10px"} mt={"40px"}>
              <hr />
            </Box>

            <Box mb={"40px"}>
              <Box>
                <Text
                  textAlign={"left"}
                  fontWeight={"bolder"}
                  fontFamily={"InterBold"}
                  fontSize={"xl"}
                >
                  For which blockchain platforms are you interested in using Pay with Items? (Select all that apply)
                </Text>
              </Box>

              <Box mt={"10px"}>
                <CheckboxGroup
                  colorScheme="green"
                  defaultValue={[]}
                  onChange={handleCheckboxChange}
                >
                  <Stack spacing={[1, 5]} direction={["column", "row"]}>
                    <Checkbox value="solana">Solana</Checkbox>
                    <Checkbox value="ethereum">Ethereum</Checkbox>
                    <Checkbox value="bitcoin">Bitcoin</Checkbox>
                    <Checkbox value="other">Other (please specify)</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>

              {isOtherChecked === true ? (
                <Box mt={"10px"}>
                  <Input
                    name={"other"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otherSuggestedBlockchain: e.target.value,
                      })
                    }
                  />
                </Box>
              ) : null}
            </Box>

            <Box mb={"20px"}>
              <Box>
                <Text
                  textAlign={"left"}
                  fontFamily={"InterBold"}
                  fontSize={"xl"}
                >
                  Which email should we add to the waiting list?
                </Text>
              </Box>

              <Box mt={"10px"}>
                <Input
                  name="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Box>
            </Box>

            <Box mb={"20px"}>
              <Box>
                <Checkbox
                  value="emaillist"
                  name="emaillist"
                  onChange={(e) =>
                    setFormData({ ...formData, emaillist: e.target.checked })
                  }
                >
                  Add me to NeoSwap email list to receive the latest updates
                </Checkbox>
              </Box>
            </Box>

            <Button
              width="100%"
              className="grad-btn"
              mt="30px"
              onClick={handleJoinWaitlist}
              isDisabled={joining}
              isLoading={joining}
            >
              Join Waitlist
            </Button>
          </Container>
        </Container>
      </Center>
    </>
  );
}

export default BuyDownWaitlist;
