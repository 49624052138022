import { httpsCallable } from 'firebase/functions'
import { doc, getDoc, collection, getDocs } from 'firebase/firestore'
import Firebase from './firebase'
import { scrubOfUndefined } from '../utils'

// https://www.bezkoder.com/react-hooks-firestore/

export async function loadNfts(userId: string) {
    const nfts = httpsCallable(Firebase.getFnsApp(), 'nft-userNfts')
    return await nfts({ userId })
}

export const getNftsAvailableToList = async (
    roomId: string,
    userId: string,
    start = 0,
    limit = 0,
    address?: string
): Promise<any> => {
    const nfts = httpsCallable(Firebase.getFnsApp(), 'nft-nftsAvailableToList')
    return nfts({ roomId, userId, start, limit, address })
}

export const getNftsAvailableForBid = async (roomId: string): Promise<any> => {
    const nfts = httpsCallable(Firebase.getFnsApp(), 'nft-nftsAvailableForBid')
    return nfts({ roomId })
}

export const getNftDetails = async (ids: string[]): Promise<any> => {
    if (!ids) throw new Error('MISSING PARAMS')
    if (ids.length < 1) {
        return []
    }
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-nftMetadata')
    return ref({ ids })
    // const ref = collection(Firebase.getDBApp(), `/items`);
    // const batches = [];

    // while (nfts.length) {
    //   const batch = nfts.splice(0, 10);

    //   batches.push(
    //     getDocs(query(ref, where("__name__", "in", [...batch]))).then((res) =>
    //       res.docs.map((doc) => doc.data())
    //     )
    //   );
    // }
    // return Promise.all(batches).then((nfts) => nfts.flat());
}

export const getNftDetail = async (id: string) => {
    const ref = doc(Firebase.getDBApp(), 'items', id)
    return getDoc(ref)
}

export const getNftCollections = async (): Promise<any> => {
    const ref = collection(Firebase.getDBApp(), 'nftCollections')

    const docs = await getDocs(ref)

    // return a array of objects with the data and add doc.id as the collectionId
    return docs.docs.map((doc) => ({ ...doc.data(), collectionId: doc.id }))
}

export const getNftMetadata = (
    ids: string[],
    userId?: string,
    blockchain?: string
): Promise<{ data: any }> => {
    console.log(ids, userId, blockchain)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-nftMetadata')
    console.log(scrubOfUndefined({ ids, userId, blockchain }))
    return ref(scrubOfUndefined({ ids, userId, blockchain }))
}

export const getNftCollectionMetadata = ({
    ids,
    blockchain,
    userId,
    getVolume,
}: {
    ids: string[]
    blockchain?: string
    userId?: string
    getVolume?: boolean
}): Promise<{ data: any }> => {
    console.log(ids)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadata')
    return ref({ ids, blockchain, getVolume })
}

export const getNftCollectionMetadataWithRoyalty = ({
    ids,
    blockchain,
    userId,
    getVolume,
}: {
    ids: string[]
    blockchain?: string
    userId?: string
    getVolume?: boolean
}): Promise<{ data: any }> => {
    console.log(ids)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadataWithRoyalty')
    return ref({ ids, blockchain, getVolume })
}

export const getNftCollectionMetadataByIds = ({
    ids,
    blockchain,
}: {
    ids: string[]
    blockchain: string
}): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadataByIds')
    return ref({ ids, blockchain })
}

export const getCollectionStats = ({
    slugs,
    collectionIds,
}: {
    slugs?: string[]
    collectionIds?: string[]
}): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionStats')
    return ref({ slugs, collectionIds })
}

export const getExchangeRates = (blockchain: string): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-getExchangeRates')
    return ref({ blockchain })
}

export const getUserCollections = (uid: string): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-userCollections')
    return ref({ userId: uid })
}
