/* eslint-disable no-throw-literal */
import { ethers, BigNumber } from "ethers";
import erc721Abi from "./utils/erc721Abi";
import polygonScAbi from "./utils/polygonScAbi";
import routerScAbi from "./utils/routerScAbi";
import ordinalsScAbi from "./utils/ordinalsBidsAbi";
import { PolygonScData, UserItemsToDepositData} from "./utils/types";
// import { transferErc721, transferNative } from "./function/smartContract";

declare let primaryWallet: any;

declare let window: any;
window.Buffer = window.Buffer || require("buffer").Buffer;

function parseBigNumber(x:any) : BigNumber {
    return BigNumber.from(x.hex);
}

function parseUserItemsToDepositData(x:UserItemsToDepositData): UserItemsToDepositData {
    x.value = parseBigNumber(x.value);
    x.tokenIds = x.tokenIds.map((x:any) => parseBigNumber(x));
    return x
}

export async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export module PolygonSC {
    const gasLLimit = { gasLimit: 2900000 };

    export async function deposit(params: {hasNft: boolean , userItemsToDepositData:UserItemsToDepositData, polygonSc: string, routerSc:string}): Promise<string[]> {
        let trxs: string[] = [];

        // deep copy
        params = JSON.parse(JSON.stringify(params));
        // parse BigNumber
        params.userItemsToDepositData = parseUserItemsToDepositData(params.userItemsToDepositData);
        
        const polygonScData = await getPolygonContext({
            polygonSc: params.polygonSc,
            routerSc: params.routerSc,
        });
        if(params.hasNft) {
            const assetDeposit = await depositAsset({
                polygonScData,
                poolId: params.userItemsToDepositData.poolId,
                userItemsToDepositData: params.userItemsToDepositData,
                parrams: {
                    value: ethers.utils.parseUnits(params.userItemsToDepositData.value.toString(), "wei").toString(),
                    gasLimit: gasLLimit.gasLimit,
                }
            })
            console.log(assetDeposit);
            trxs.push(assetDeposit);
            return trxs;

        } else {
            const nativeDeposit = await depositNativeOnly({
                amount: params.userItemsToDepositData.value,
                polygonScData,
                poolId: params.userItemsToDepositData.poolId
            })
            console.log(nativeDeposit)
            trxs.push(nativeDeposit);
            return trxs;
        }
    }

    export async function approveUserNFT(params: { routerSc: string; mintAddress: string }) {
        const mintContext = await getPolygonContext({
            routerSc: params.routerSc,
            mint: params.mintAddress,
        });
        console.log("mintContext", mintContext);

        const isApproved = await mintContext.contract.isApprovedForAll(
            mintContext.connectedUser,
            mintContext.routerContract.address
        );
        let approvingTx;
        if (!isApproved) {
            console.log("setApprovalForAll", mintContext.connectedUser, " to ", params.routerSc);

            approvingTx = await mintContext.contract.setApprovalForAll(
                params.routerSc,
                mintContext.connectedUser,
                gasLLimit
            );
            console.log("approvingTx", approvingTx);

            const approveRes = await mintContext.contract.provider.waitForTransaction(
                approvingTx.hash
            );

            if (approveRes.status === 0) {
                throw { message: `tX unsuccessful : ${approvingTx}` };
            }
        }

        return approvingTx.hash;
    }

    export async function createOffer (params: {
        ordinalsSc: string, ordinalsId: string, bitcoinAddress:string, sellerAddress:string, bidAmountInEthers:number
    }) {
        try {
            
            let ethereum = await window.ethereum;
            const windowEthereumProvider = new ethers.providers.Web3Provider(ethereum, "any");
            const signer = windowEthereumProvider.getSigner();
            // const signer = (await primaryWallet?.connector.getSigner()) as ethers.Signer;
            const contract = new ethers.Contract(params.ordinalsSc, ordinalsScAbi, signer);     
           
            // return contract.requestCancelation(3).then(console.log)
            const tx = await contract.createNewBid(
              params.ordinalsId, params.bitcoinAddress, params.sellerAddress,
              { value: ethers.utils.parseUnits(params.bidAmountInEthers.toString(), 'ether') }
            )
            return tx.hash;
        } catch (error) {
            alert(error)
        } 
    }

    
    export async function cancelOffer (params: {
        ordinalsSc: string, offerId:number
    }) {
        try {
            
            let ethereum = await window.ethereum;
            const windowEthereumProvider = new ethers.providers.Web3Provider(ethereum, "any");
            const signer = windowEthereumProvider.getSigner();
            // const signer = (await primaryWallet?.connector.getSigner()) as ethers.Signer;
            const contract = new ethers.Contract(params.ordinalsSc, ordinalsScAbi, signer);     
           
            // return contract.requestCancelation(3).then(console.log)
            const tx = await contract.requestCancelation(
              params.offerId
            )
            return tx.hash;
        } catch (error) {
            alert(error)
        } 
    }

    export async function depositNativeOnly(params: {
        amount: ethers.BigNumber;
        polygonScData: PolygonScData;
        poolId: number;
    }): Promise<string> {

        const parrams = {
            value: ethers.utils.parseUnits(params.amount.toString(), "wei").toString(),
            gasLimit: gasLLimit.gasLimit,
        };
        console.log("routerContract.address", params.polygonScData.routerContract.address);
        console.log("params.poolId", params.poolId);
        console.log("parrams", parrams);

        const tx = await params.polygonScData.routerContract.depositNativeAssetOnly(
            params.polygonScData.contract.address,
            params.poolId.toString(),
            parrams
        );
        return tx.hash;
    }

    export async function depositAsset(params: {
        polygonScData: PolygonScData;
        poolId: number;
        userItemsToDepositData: UserItemsToDepositData;
        parrams: {
            value: string;
            gasLimit: number;
        };
    }): Promise<string> {
        console.log("tokenTypes", params.userItemsToDepositData.tokenTypes);
        console.log("contracts", params.userItemsToDepositData.contracts);
        console.log("tokenIds", params.userItemsToDepositData.tokenIds);
        console.log("qty", params.userItemsToDepositData.qty);
        console.log("address", params.polygonScData.contract.address);
        console.log("poolId", params.poolId);
        console.log("parrams", params.parrams);

        const tx = await params.polygonScData.routerContract.depositAssets(
            params.userItemsToDepositData.tokenTypes,
            params.userItemsToDepositData.contracts,
            params.userItemsToDepositData.tokenIds,
            params.userItemsToDepositData.qty,
            params.polygonScData.contract.address,
            params.poolId,
            params.parrams
        );
        return tx.hash;
    }

    export async function getPolygonContext(params: {
        mint?: string;
        polygonSc?: string;
        routerSc: string;
    }): Promise<PolygonScData> {
        let sCAddress: string;
        let sCAbi;

        if (!!params.polygonSc) {
            sCAddress = params.polygonSc;
            sCAbi = polygonScAbi;
        } else if (!!params.mint) {
            sCAddress = params.mint;
            sCAbi = erc721Abi;
        } else {
            // eslint-disable-next-line no-throw-literal
            throw { message: "no mint or PolygonSc" };
        }
        console.log("sCAddress", sCAddress);

        let ethereum = await window.ethereum;
        const windowEthereumProvider = new ethers.providers.Web3Provider(ethereum, "any");
        const signer = windowEthereumProvider.getSigner();
        // if(!primaryWallet) {throw new Error("Wallet not connected. If you encounter this while connected, please sign out and then sign in again")}
        // const windowEthereumProvider = await primaryWallet?.connector.getWeb3Provider()
        // const signer = (await primaryWallet?.connector.getSigner()) as ethers.Signer;
        const chainId = parseInt(await window.ethereum.request({ method: "eth_chainId" }), 16).toString();
        // if (!process.env.REACT_APP_POLYGON_CHAIN_ID) {
        //     throw console.error({ message: "REACT_APP_POLYGON_CHAIN_ID undefined" });
        // }

        // if (!chainId.includes(process.env.REACT_APP_POLYGON_CHAIN_ID)) {
        //     throw console.error("please select ", process.env.REACT_APP_CONTRACT_NETWORK);
        // }

        // const connectedUser = await primaryWallet?.connector.fetchPublicAddress();
        const connectedUser = await signer.getAddress();
        console.log("Connected user",connectedUser)


        const routerContract = new ethers.Contract(params.routerSc, routerScAbi, signer);
        const contract = new ethers.Contract(sCAddress, sCAbi, signer);
        console.log("Contract", contract);

        return {
            windowEthereumProvider,
            contract,
            chainId,
            connectedUser,
            routerContract,
        };
    }
}
