export default function IconsTradesError() {
  return (
    <svg
      width="300"
      height="240"
      viewBox="0 0 300 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_3078_5656)">
        <path
          d="M232.066 133.547H31.4309C25.6701 133.547 21 138.217 21 143.978V181.132C21 186.893 25.6701 191.563 31.4309 191.563H232.066C237.827 191.563 242.497 186.893 242.497 181.132V143.978C242.497 138.217 237.827 133.547 232.066 133.547Z"
          fill="black"
          fill-opacity="0.3"
          stroke="url(#paint0_linear_3078_5656)"
          stroke-width="6"
        />
      </g>
      <path
        d="M126.545 148.436H87.9039C85.4415 148.436 83.4453 150.432 83.4453 152.895C83.4453 155.357 85.4415 157.353 87.9039 157.353H126.545C129.007 157.353 131.003 155.357 131.003 152.895C131.003 150.432 129.007 148.436 126.545 148.436Z"
        fill="url(#paint1_linear_3078_5656)"
      />
      <path
        d="M153.296 167.757H87.9039C85.4415 167.757 83.4453 169.753 83.4453 172.215C83.4453 174.678 85.4415 176.674 87.9039 176.674H153.296C155.759 176.674 157.755 174.678 157.755 172.215C157.755 169.753 155.759 167.757 153.296 167.757Z"
        fill="url(#paint2_linear_3078_5656)"
      />
      <path
        d="M71.5565 162.555C71.5565 150.654 61.9083 141.005 50.0068 141.005C38.1052 141.005 28.457 150.654 28.457 162.555C28.457 174.457 38.1052 184.105 50.0068 184.105C61.9083 184.105 71.5565 174.457 71.5565 162.555Z"
        fill="url(#paint3_linear_3078_5656)"
      />
      <path
        d="M59.8018 172.352C59.0556 173.098 57.8457 173.098 57.0995 172.352L50.3438 165.596L43.588 172.352C42.8418 173.098 41.6319 173.098 40.8857 172.352C40.1395 171.606 40.1395 170.396 40.8857 169.65L47.6415 162.894L40.8857 156.138C40.1395 155.392 40.1395 154.182 40.8857 153.436C41.6319 152.69 42.8418 152.69 43.588 153.436L50.3438 160.192L57.0995 153.436C57.8457 152.69 59.0556 152.69 59.8018 153.436C60.548 154.182 60.548 155.392 59.8018 156.138L53.046 162.894L59.8018 169.65C60.548 170.396 60.548 171.606 59.8018 172.352Z"
        fill="black"
      />
      <g filter="url(#filter1_b_3078_5656)">
        <path
          d="M268.855 52H68.22C62.4592 52 57.7891 56.6701 57.7891 62.4309V99.5856C57.7891 105.346 62.4592 110.017 68.22 110.017H268.855C274.616 110.017 279.286 105.346 279.286 99.5856V62.4309C279.286 56.6701 274.616 52 268.855 52Z"
          fill="black"
          fill-opacity="0.3"
          stroke="url(#paint4_linear_3078_5656)"
          stroke-width="6"
        />
      </g>
      <path
        d="M163.334 66.8896H124.693C122.231 66.8896 120.234 68.8858 120.234 71.3482C120.234 73.8106 122.231 75.8068 124.693 75.8068H163.334C165.796 75.8068 167.792 73.8106 167.792 71.3482C167.792 68.8858 165.796 66.8896 163.334 66.8896Z"
        fill="url(#paint5_linear_3078_5656)"
      />
      <path
        d="M190.085 86.2101H124.693C122.231 86.2101 120.234 88.2062 120.234 90.6686C120.234 93.131 122.231 95.1272 124.693 95.1272H190.085C192.548 95.1272 194.544 93.131 194.544 90.6686C194.544 88.2062 192.548 86.2101 190.085 86.2101Z"
        fill="url(#paint6_linear_3078_5656)"
      />
      <path
        d="M108.346 81.0085C108.346 69.1069 98.6974 59.4587 86.7958 59.4587C74.8942 59.4587 65.2461 69.1069 65.2461 81.0085C65.2461 92.91 74.8942 102.558 86.7958 102.558C98.6974 102.558 108.346 92.91 108.346 81.0085Z"
        fill="url(#paint7_linear_3078_5656)"
      />
      <path
        d="M96.5909 90.8053C95.8446 91.5515 94.6348 91.5515 93.8886 90.8053L87.1328 84.0495L80.3771 90.8053C79.6309 91.5515 78.421 91.5515 77.6748 90.8053C76.9286 90.0591 76.9286 88.8492 77.6748 88.103L84.4305 81.3472L77.6748 74.5915C76.9286 73.8453 76.9286 72.6354 77.6748 71.8892C78.421 71.143 79.6308 71.143 80.3771 71.8892L87.1328 78.6449L93.8886 71.8892C94.6348 71.143 95.8446 71.143 96.5909 71.8892C97.3371 72.6354 97.3371 73.8453 96.5909 74.5915L89.8351 81.3472L96.5909 88.103C97.3371 88.8492 97.3371 90.0591 96.5909 90.8053Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_b_3078_5656"
          x="-47"
          y="65.5466"
          width="357.496"
          height="194.017"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3078_5656"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3078_5656"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_3078_5656"
          x="-10.2109"
          y="-16"
          width="357.496"
          height="194.017"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="32.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3078_5656"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3078_5656"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3078_5656"
          x1="169.689"
          y1="122.278"
          x2="155.68"
          y2="209.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3078_5656"
          x1="115.597"
          y1="145.99"
          x2="113.713"
          y2="161.074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3078_5656"
          x1="133.683"
          y1="165.311"
          x2="132.466"
          y2="180.533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3078_5656"
          x1="57.5948"
          y1="129.183"
          x2="23.4295"
          y2="180.466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3078_5656"
          x1="206.478"
          y1="40.7316"
          x2="192.47"
          y2="127.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3078_5656"
          x1="152.386"
          y1="64.4436"
          x2="150.502"
          y2="79.5277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3078_5656"
          x1="170.472"
          y1="83.7641"
          x2="169.255"
          y2="98.9863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3078_5656"
          x1="94.3839"
          y1="47.6363"
          x2="60.2186"
          y2="98.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF000F" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
