import { Icon, IconProps } from "@chakra-ui/react";

export default function IconBitcoin(props: IconProps) {
  return (
    <Icon id="svg-bitcoin" viewBox="0 0 30 30" {...props}>
      <path
        d="M25,15.7c-0.7-0.9-1.7-1.5-3.2-1.9c1.8-0.9,2.6-2.5,2.4-4.7c-0.1-0.8-0.3-1.5-0.7-2.1c-0.4-0.6-0.9-1.1-1.5-1.4
	c-0.6-0.4-1.3-0.6-2-0.8c-0.7-0.2-1.6-0.3-2.5-0.4V0h-2.8v4.4c-0.5,0-1.2,0-2.2,0V0H9.7v4.5c-0.4,0-1,0-1.7,0l-3.8,0v3h2
	c0.9,0,1.4,0.4,1.6,1.2v5.2c0.1,0,0.2,0,0.3,0H7.8v7.2c-0.1,0.6-0.4,0.9-1,0.9h-2l-0.6,3.3h3.6c0.2,0,0.6,0,1,0c0.4,0,0.8,0,1,0V30
	h2.8v-4.5c0.5,0,1.2,0,2.2,0V30h2.8v-4.6c1,0,1.9-0.1,2.6-0.3c0.8-0.1,1.5-0.3,2.2-0.6c0.7-0.3,1.3-0.6,1.7-1.1
	c0.5-0.4,0.9-1,1.2-1.6c0.3-0.6,0.5-1.4,0.6-2.3C26,17.9,25.7,16.6,25,15.7z M12.6,7.7c0.1,0,0.3,0,0.7,0c0.4,0,0.7,0,1,0
	c0.3,0,0.6,0,1,0c0.4,0,0.8,0.1,1.1,0.1c0.3,0.1,0.6,0.2,1,0.3c0.4,0.1,0.6,0.3,0.9,0.5c0.2,0.2,0.4,0.5,0.5,0.8
	C19,9.7,19,10.1,19,10.5c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.3-0.7,0.5c-0.2,0.1-0.5,0.2-0.9,0.3
	c-0.4,0.1-0.7,0.2-0.9,0.2c-0.3,0-0.6,0.1-1,0.1c-0.4,0-0.7,0-0.9,0c-0.2,0-0.5,0-0.8,0c-0.4,0-0.6,0-0.6,0L12.6,7.7L12.6,7.7
	L12.6,7.7z M20.1,20c-0.1,0.3-0.3,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.2-1,0.3
	c-0.4,0.1-0.7,0.1-1,0.2c-0.3,0-0.6,0.1-1.1,0.1c-0.4,0-0.7,0-1,0c-0.2,0-0.5,0-0.9,0c-0.3,0-0.6,0-0.7,0V16c0.1,0,0.4,0,0.9,0
	c0.5,0,0.9,0,1.2,0c0.3,0,0.7,0,1.2,0.1c0.5,0,1,0.1,1.3,0.2c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.2,0.8,0.3,1,0.5
	c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.3,0.7,0.3,1.2C20.3,19.4,20.3,19.7,20.1,20z"
        fill="currentColor"
      />
    </Icon>
  );
}
