import DetailsModal from "../DetailsModal";
import NeoImage from "./Img";
import { ChainDetails, Img } from "./types";

interface Props {
  showBorder?: boolean;
  showTradeFeedback?: boolean;
  tokenValue?: number;
  chain: ChainDetails;
  get?: Img[];
  give?: Img[];
  isSelected?: boolean;
  getQuantity?: {
    [itemId: string]: number;
  };
  giveQuantity?: {
    [itemId: string]: number;
  };
  tokenName: string;
}

export default function OfferPanel({
  showBorder = true,
  showTradeFeedback = true,
  tokenValue,
  chain,
  get,
  give,
  isSelected,
  getQuantity,
  giveQuantity,
  tokenName
}: Props) {
  return (
    <div
      className={`w-full h-full px-8 md:px-1 py-4 ${
        isSelected ? "bg-[#151515]" : "bg-transparent"
      } rounded-2xl rounded-tl-none rounded-tr-none mt-4 flex-col justify-center items-start gap-8 inline-flex`}
    >
      <div className="flex-col justify-start items-start gap-4 flex w-full">
        <div className="justify-center md:justify-start w-full items-baseline inline-flex flex-col md:flex-row md:items-center">
          <div
            className="
          text-white text-lg font-medium leading-10 inter-bold text-center w-full md:w-auto mr-0
          md:text-green-400 md:text-3xl md:mr-4
          "
          >
            You Get
          </div>
          <div className="justify-center items-center gap-4 flex w-full md:w-auto">
            {tokenValue && tokenValue > 0 && (
              <>
                <div
                  className="
                  text-white text-[21px] font-medium leading-loose
                  md:text-xl
                "
                >
                  {Math.abs(tokenValue)} {tokenName}{" "}
                </div>
                {get && get?.length > 0 && (
                  <div className="text-white text-[21px] font-medium leading-loose">
                    +
                  </div>
                )}
              </>
            )}
            {/* {get && get?.length > 0 && (
              <div className="text-white text-[21px] font-medium leading-loose">
                {" "}
                {get?.length} Pack{`${get?.length > 1 ? "s" : ""}`}{" "}
              </div>
            )} */}
          </div>
        </div>
        <div className="justify-start w-full items-start gap-4 inline-flex flex-col flex-wrap md:flex-row">
          {get?.map(
            ({ id, src, alt, name, collectionName, floorPrice, metadata }) => {
              return (
                <div
                  key={`get_${id}_panel`}
                  className="flex-col justify-start items-start gap-2 inline-flex w-full md:w-1/5 lg:w-60"
                >
                  <div className="bg-white rounded-2xl border-b-2 border-green-500 justify-center items-center inline-flex overflow-hidden w-full min-h-[250px]">
                    <NeoImage
                      className="w-full max-w-[250px]"
                      src={src}
                      alt={alt}
                      quantity={getQuantity?.[id]}
                    />
                  </div>
                  <div className="px-3 py-[10.58px] flex-col justify-start items-start gap-2 flex w-full">
                    <div className="self-stretch text-white text-[13.885246276855469px] font-bold leading-tight">
                      {name}
                    </div>
                    <div className="self-stretch justify-start items-start gap-[6.61px] inline-flex">
                      <div className="grow shrink basis-0 text-white text-[10.579235076904297px] font-normal">
                        {collectionName}
                      </div>
                      <div className="text-right text-green-500 text-[10.579235076904297px] font-normal">
                        {floorPrice}
                      </div>
                    </div>
                    <DetailsModal
                      title={`${name === null ? "" : name} Details`}
                      nft={metadata}
                      truncate={chain.truncate!}
                      abbr={chain.abbr!}
                      target={
                        <div
                          role="button"
                          className="text-[10.579235076904297px]"
                        >
                          View more details
                        </div>
                      }
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="self-stretch flex-col justify-center items-end gap-4 flex">
        <div className="justify-center w-full items-baseline inline-flex flex-col md:flex-row md:items-center md:justify-end">
          <div
            className="
            text-white text-lg font-medium leading-10 inter-bold text-center w-full md:w-auto mr-0
            md:text-red-600 md:text-3xl md:mr-4
          "
          >
            Estimated Price
          </div>
          <div className="justify-center items-center gap-4 flex w-full md:w-auto">
            {tokenValue && tokenValue < 0 && (
              <>
                <div className="text-white text-[21px] font-medium leading-loose">
                  {Math.abs(tokenValue)} {tokenName}{" "}
                </div>
                {give && give.length > 0 && (
                  <div className="text-white text-[21px] font-medium leading-loose">
                    +
                  </div>
                )}
              </>
            )}
            {give && give.length > 0 && (
              <div className="text-white text-[21px] font-medium leading-loose">
                {" "}
                {give?.length} Item {`${give?.length > 1 ? "s" : ""}`}{" "}
              </div>
            )}
          </div>
        </div>
        <div className="justify-start items-start gap-4 inline-flex">
          {give?.map(
            ({ id, src, alt, name, collectionName, floorPrice, metadata }) => {
              return (
                <div
                  key={`give_${id}_panel`}
                  className="flex-col justify-start items-start gap-2 inline-flex w-1/2 sm:w-1/3 md:w-60"
                >
                  <div className="bg-white rounded-2xl border-b-2 border-red-500 justify-center items-center inline-flex overflow-hidden">
                    <NeoImage
                      className="w-full"
                      src={src}
                      alt={alt}
                      quantity={giveQuantity?.[id]}
                    />
                  </div>
                  <div className="px-3 py-[10.58px] flex-col justify-start items-start gap-2 flex w-full">
                    <div className="self-stretch text-white text-[13.885246276855469px] font-bold leading-tight">
                      {name}
                    </div>
                    <div className="self-stretch justify-start items-start gap-[6.61px] inline-flex">
                      <div className="grow shrink basis-0 text-white text-[10.579235076904297px] font-normal">
                        {collectionName}
                      </div>
                      <div className="text-right text-red-600 text-[10.579235076904297px] font-normal">
                        {floorPrice}
                      </div>
                    </div>
                    <DetailsModal
                      title={`${name}`}
                      nft={metadata}
                      truncate={chain.truncate!}
                      abbr={chain.abbr!}
                      target={
                        <div
                          role="button"
                          className="text-[10.579235076904297px]"
                        >
                          {/* <a target="_blank" href={marketplace}> */}
                          View more details
                          {/* </a> */}
                        </div>
                      }
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {/* {showTradeFeedback && (
        <div className="h-28 py-4 flex-col justify-center items-center gap-4 flex w-full">
          <div className="w-[214px] text-stone-50 text-[16px] font-normal leading-none">
            How do you like this trade?
          </div>
          <div className="justify-start items-start gap-6 inline-flex" />
        </div>
      )} */}
    </div>
  );
}
