import { forwardRef, useEffect, useMemo, useState } from "react";
import { Button, Container, Flex, HStack, Heading, Image, Tooltip, Text, Box, BoxProps, Spinner, Checkbox } from "@chakra-ui/react";
import { BiddableNft, Nft, useRoom } from "../contexts/roomContext";
import { getNftCollectionMetadata } from "../services/nfts.service";
import PendingImg from "../assets/pending.jpg";
import { Link } from "react-router-dom";
import { count } from "console";
import React from "react";
import { filterCompleted, getIcon } from "../utils";
import { getRoomType } from "../utils/getRoomType";
import AvatarList from "./AvatarList";
import CountdownTimer from "./CountdownTimer";
import { useAppContext } from "../contexts/appContext";
import TokenGated from "./TokenGated";
import { Room } from "../types/room";
import NftCollection from "./NftCollection";
import BiddingGridItem from "./NftCollection/BiddingGridItem";
import BudgetCard from "./BudgetCard";
import NumItemsLimit from "./NumItemsLimit";
import { setBudget } from "../services/room.service";
import { ExpandableText } from "./ExpandableText";

const TopBoxListings = ({ count }: { count: number }) => {
  const { room } = useRoom();
  const isOrdinals =
    room?.info?.isOrdinals || room.blockchain.name === "bitcoin";
  return (
    <Flex
      alignItems={{ base: "center", lg: "flex-start" }}
      gap={"5px"}
      direction={{ base: "row", lg: "column" }}
      minW={"95px"}
    >
      <Text className="room-settings-text">Listed items:</Text>
      <Text as={"h4"} className="font-bold" fontSize={{ base: "initial", lg: "2xl" }}>{count}</Text>
    </Flex>
  );
};

export default function LandingAuction({ roomId, step, setStep }: { roomId: string, step: number, setStep: (newStep: number) => void }) {
  const {
    units,
    abbr,
    bids,
    budgets,
    room,
    stepProgress,
    availableForBid,
    getAvailableForBid,
    biddingDiff,
    preferredIds,
    isSilentAuction,
    availableForBidLoading,
    canList,
    passed,
    listings,
    isAdmin,
    showContactModal,
    setShowContactModal,
    preview,
  } = useRoom();

  const budget = budgets?.budget;

  const { uid, displayName, agreements, email, initialized } = useAppContext();
  const isTokenGated = room?.info?.accessPolicy?.policy === "collection";
  const hasJoined = room?.users && room?.users.includes(uid || "") ? true : false;


  const part = filterCompleted(stepProgress);

  const roomChain = room?.blockchain?.name?.toLowerCase();
  const count = availableForBid?.length || 0;

  const toggleStep = (newStep: number) => {
    setStep(newStep);
  };

  const preferredNfts = useMemo(() => {
    return preferredIds
      ?.filter((id: string) => !passed.includes(id))
      .map((id: string) => availableForBid?.find((ele) => ele.itemId === id)!);
  }, [preferredIds, availableForBid, passed]);

  const availableNfts = useMemo(() => {
    return availableForBid?.filter((ele) => !passed.includes(ele.itemId));
  }, [canList, preferredIds, availableForBid, passed]);




  let data = availableNfts;

  if (isSilentAuction) {
    data = preferredNfts;
  }
  return (
    <StepperAuction
      roomId={roomId}
      uid={uid}
      budget={budget}
      units={units!}
      abbr={abbr!}

      count={count}
      part={part}
      step={step}
      room={room}
      data={data}
      passed={passed}
      preferredNfts={preferredNfts}
      availableForBidLoading={availableForBidLoading}
      isSilentAuction={isSilentAuction}
      canList={canList}
      hasJoined={hasJoined}
      toggleStep={toggleStep}
    />

    // <VStack className="box" width="100%" justifyItems="flex-start" zIndex={999999999}>
    //   <Heading as="h3" size="xs" color="gray.300" textAlign="left" width="100%">
    //     Preview for {isTicketGated ? "ticket" : "token"} gated
    //   </Heading>
    //   {!isTicketGated && (
    //     <>
    //       <Heading as="h4" size="xs" color="gray.300" textAlign="left" width="100%">
    //         To Access the room you need the following collections:
    //       </Heading>
    //       {renderCollections()}
    //     </>
    //   )}
    // </VStack>
  );
}

interface StepperProps {
  roomId: string;
  uid: any;
  budget: number;
  units: number;
  abbr: string;
  count: number;
  part: string[];
  step: number;
  room: any;
  data: any;
  passed: any,
  preferredNfts: any;
  availableForBidLoading: any;
  hasJoined: boolean;
  isSilentAuction: boolean;
  canList: boolean;

  toggleStep: (newStep: number) => void;
}

const StepperAuction = ({ roomId, uid, budget, units, abbr, count, part, step, room, data, passed, preferredNfts, availableForBidLoading, hasJoined, isSilentAuction, canList, toggleStep }: StepperProps) => {
  let content;

  if (step === -1) {
    content = (
      <Flex w="100%" >
        <div className="title-div">

          <div className="user-properties">
            <p className="room-type ">{getRoomType(room.info.roomType)}</p>
            <h3 className="room-name font-bold">{room.info.name}</h3>

            {room.info.description && (
              <ExpandableText noOfLines={2}>
                <p>{room.info.description}</p>
              </ExpandableText>
            )}
          </div>
        </div>
        <Flex
          direction={{ base: "column", lg: "row" }}
          gap={{ base: "30px" }}
        >
          <Flex
            alignItems={{ base: "center", lg: "flex-start" }}
            gap={"5px"}
            direction={{ base: "row", lg: "column" }}
          >
            {getIcon(room?.blockchain?.name, "60px", "60px")}
          </Flex>
          <CountdownTimer />
          <AvatarList avatars={room?.users} completed={part} />

          <TopBoxListings count={count} />


          {/* <TopBoxChain roomChain={roomChain} /> */}
        </Flex>
      </Flex>
    );
  } else {
    // For step 0, 1, 2, etc.
    switch (step) {
      case 0:
        content = (
          <Flex
            maxWidth={"600px"}
            direction={"column"}
            borderRadius={"5px"}
            gap={4}

          >
            <div className="title-div">

              <div className="user-properties">
                <p className="room-type ">{getRoomType(room.info.roomType)}</p>
                <h3 className="room-name font-bold">{room.info.name}</h3>

                {room.info.description && (
                  <ExpandableText noOfLines={2}>
                    <p>{room.info.description}</p>
                  </ExpandableText>
                )}
              </div>
            </div>
            <Flex
              direction={{ base: "column", lg: "row" }}
              gap={{ base: "10px" }}
            >
              <Flex
                alignItems={{ base: "center", lg: "flex-start" }}
                gap={"5px"}
                direction={{ base: "row", lg: "column" }}
              >
                {getIcon(room?.blockchain?.name, "60px", "60px")}
              </Flex>
              <CountdownTimer />
              <AvatarList avatars={room?.users} completed={part} />

              <TopBoxListings count={count} />


              {/* <TopBoxChain roomChain={roomChain} /> */}
            </Flex>
            <p>Smart auctions are different from regular auctions.  Click "Get Started" to learn more.</p>


          </Flex>
        );
        break;
      case 1:
        content = (
          <Flex
            maxWidth={"600px"}
            direction={"column"}
            borderRadius={"5px"}
            gap={4}

          >
            <div className="title-div">

              <div className="user-properties">
                <Flex justifyContent="space-between" w="100%">
                  <p className="room-type " style={{ float: "left" }}>Step 1</p>
                  <p className="room-type " style={{ float: "right" }}>{step}/3</p>
                </Flex>

                <h3 className="room-name font-bold">Explore Listed Items</h3>

                <p>Browse the items available in this auction, and click 'Hide' if you are not interested.</p>

              </div>
            </div>
            <div className="bidding-wrapper">
              {availableForBidLoading ? (
                <div className="nft-loader">
                  <Spinner color="#ed6cd8" />
                  <p className="loadingtext">
                    {" "}
                    Loading items, please wait...{" "}
                  </p>
                </div>
              ) : (
                <NftCollection
                  GridComponent={({ nft }: { nft: Nft | BiddableNft }) => (
                    <BiddingGridItem
                      nft={nft as BiddableNft}
                      isSeller={isSilentAuction && canList}
                      isPassed={passed ? passed.includes(nft?.itemId) : false}
                    />
                  )}
                  showGating
                  data={data || []}
                  indexOptions={["name", "collection.name"]}
                  groupBy="collection.name"
                  emptyMessage={`Sorry no items to bid on yet!`}
                  isLoading={availableForBidLoading}
                  collectionType="bidding"
                  defaultSort={{
                    key: "hasViewed",
                    direction: "asc",
                  }}
                  preferredNfts={preferredNfts}
                  hasJoined={hasJoined}
                />
              )}


            </div>

          </Flex>
        );
        break;
      case 2:
        content = (
          <Flex maxWidth={"600px"} justifyContent="center" alignItems="center">
            <div className="title-div">

              <div className="user-properties">
                <Flex justifyContent="space-between" w="100%">
                  <p className="room-type " style={{ float: "left" }}>Step 2</p>
                  <p className="room-type " style={{ float: "right" }}>{step}/3</p>
                </Flex>

                <h3 className="room-name font-bold">Set Your Limits</h3>

                <p>Set a spending limit and specify the number of items you wish to receive; this will protect your bids and allow you to bid confidently without overspending.</p>
                <Flex

                  p={2}
                  w={"fit-content"}
                  direction={"column"}
                  mb={"50px"}
                  mt={"20px"}
                  gap={"4"}

                  className=" shadow new-bg-transparent" style={{ marginBottom: "20px" }}>
                  <BudgetCard
                    roomId={roomId}
                    uid={uid}
                    budget={budget}
                    units={units!}
                    abbr={abbr!}
                  />
                  {/* <Alert
                      status="info"
                      className="info-alert"
                      background="linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"
                      borderRadius={"4px"}
                      mb={"12px"}
                    >
                      <AlertIcon color={"#6C60FF"} />
                      <AlertDescription>
                        Please set a spending limit to increase your chances of
                        getting a trade.
                      </AlertDescription>
                    </Alert> */}
                  <NumItemsLimit />
                </Flex>
                <p>To learn more, please watch the video below.</p>

                <iframe width="560" height="315" src="https://www.youtube.com/embed/UEGc1iqJyMQ?si=bV4Mo7cDiyvZobgu" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

              </div>
            </div>


          </Flex>
        );
        break;
      // Add cases for other steps if necessary
      default:
        content = null;
    }
  }

  return (
    step == -1 ? (
      <Container maxW={"100%"} p={"0"} mb={"20px"}>

        <Flex
          direction={"column"}
          justifyContent="center"
          alignItems={"center"}
          rounded={"5px"}
          className="new-bg-transparent"
          p={8}
          w={"100%"}

        >
          {content}

          {hasJoined && (
            <FooterButtons roomId={roomId} uid={uid} step={step} toggleStep={toggleStep} />
          )}
          {!hasJoined && <TokenGated />}

        </Flex>

      </Container>
    ) :
      <Container maxW={"600px"}>

        <Flex
          direction={"column"}
          justifyContent="center"
          alignItems={"center"}
          rounded={"5px"}
          className="new-bg-transparent"
          p={10}
          w={"fit-content"}
          ml={"auto"}
          mr={"auto"}
          mb={"50px"}
        >
          {content}

          {hasJoined && (
            <FooterButtons roomId={roomId} uid={uid} step={step} toggleStep={toggleStep} />
          )}
          {!hasJoined && <TokenGated />}

        </Flex>

      </Container>


  );
};

interface FooterButtonsProps {
  roomId: string;
  uid: string;
  step: number;
  toggleStep: (newStep: number) => void;
}

const FooterButtons = ({ roomId, uid, step, toggleStep }: FooterButtonsProps) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
    setBudget(roomId, uid, { skipLandingPage: e.target.checked });
  };

  const handleSkip = () => {
    toggleStep(-1);
  };

  const handleNext = () => {
    if (step === 2) {
      toggleStep(-1);
    } else {
      toggleStep(step + 1);
    }
  };

  if (step === 0) {
    return (
      <Flex justifyContent="space-between" className="footer-buttons" w="100%" mt="20px">
        <Flex direction="column">
          <Button size="lg" onClick={handleSkip}>
            Skip
          </Button>
          <Checkbox mt="10px" isChecked={isCheckboxChecked} onChange={handleCheckboxChange}>
            Don't show this again
          </Checkbox>
        </Flex>
        <Button bg="#6565FF" size="lg" onClick={handleNext}>
          Get Started
        </Button>
      </Flex>
    );
  } else if (step === 1 || step === 2) {
    return (
      <Flex justifyContent="space-between" className="footer-buttons" w="100%" mt={"20px"}>
        <Button size={"lg"} onClick={handleSkip}>
          Skip
        </Button>
        <Flex>
          <Button bg="#6565FF" mr={"4px"} size={"lg"} onClick={() => toggleStep(Math.max(0, step - 1))}>
            Previous
          </Button>
          <Button bg="#6565FF" size={"lg"} onClick={handleNext}>
            Next
          </Button>
        </Flex>
      </Flex>
    );
  }

  return null;
};
