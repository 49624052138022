import { Tr, TableRowProps, forwardRef } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props extends TableRowProps {
  children: ReactNode;
}

const NeoTr = forwardRef(({ children, ...props }: Props, ref) => {
  return (
    <Tr
      ref={ref}
      _dark={{
        backgroundColor: "#062438",
      }}
      {...props}
    >
      {children}
    </Tr>
  );
});

export default NeoTr;
