import { Text, Flex, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SecretBidModal from "../components/SecretBidModal";
import TimesUpModal from "../components/TimesUpModal";
import useTimeLeft from "../hooks/useTimeLeft";
import { useRoom } from "../contexts/roomContext";
import { is } from "immer/dist/internal";

export default function CountdownTimer() {
  const timeLeft = useTimeLeft();
  const { room } = useRoom();
  const roomStatus = room.status;

  interface TimeDisplay {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

  // const [isLive, setisLive] = useState(calulateIsLive());
  const [timeDisplay, setTimeDisplay] = useState({} as TimeDisplay);
  const [timeIsLoading, setTimeIsLoading] = useState(true);
  const [secretBidModalOpen, setSecretBidModalOpen] = useState(false);
  const isLive = room.info.startAt < Date.now() / 1000;

  const convertSeconds = (seconds: number) => {
    var days = Math.floor(seconds / (24 * 60 * 60));
    var hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    var minutes = Math.floor((seconds % (60 * 60)) / 60);
    var remainingSeconds = seconds % 60; // Calculate the remaining seconds

    setTimeDisplay({ days, hours, minutes, seconds: remainingSeconds }); // Add 'seconds' to the 'setTimeDisplay' object
  };

  useEffect(() => {
    if (isLive) {
      convertSeconds(timeLeft);
    } else {
      convertSeconds(room.info.startAt - Date.now() / 1000);
    }
  }, [timeLeft]);

  useEffect(() => {
    setTimeout(() => {
      setTimeIsLoading(false);
    }, 2000);
  }, []);

  const finalMinute = timeLeft < 60;

  const handleAcknowledgement = () => {
    setSecretBidModalOpen(false);
  };

  return (
    <div>
      <Flex
        alignItems={{ base: "center", lg: "flex-start" }}
        gap={"5px"}
        direction={{ base: "row", lg: "column" }}
        minW={"120px"}
      >
        <Text textAlign={{ base: "left", md: "center", lg: "left" }} className="room-settings-text">
          {isLive ? "Ending in:" : "Starting in:"}
        </Text>
        <div>
          {timeIsLoading ? (
            <>Loading</>
          ) : roomStatus === "finalize" ? (
            <Text fontSize="1rem" className="font-bold">Time's up!</Text>
          ) : timeLeft > 0 ? (
            finalMinute ? (
              <div className="time-wrapper-room">
                <Flex
                  className="timer"
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={{ base: "flex-start", md: "center" }}
                  minW={"131px"}
                >
                  <span className="timer-item">
                    <Flex color={"#F24726"} fontSize={"30px"} fontWeight={700}>
                      {Math.round(timeLeft)} seconds
                    </Flex>
                  </span>
                  <Box>
                    <Text
                      textDecoration={"underline"}
                      color={"#6c60ff"}
                      cursor={"pointer"}
                      as={"span"}
                      onClick={() => setSecretBidModalOpen(true)}
                    >
                      Learn More
                    </Text>
                  </Box>
                </Flex>
              </div>
            ) : (
              <Flex gap={"5px"} minW={"187px"}>
                <span className="timer-item">
                  <Text
                    as={"span"}
                    fontFamily={"InterBold, sans-serif"}
                    fontSize={{ base: "initial", lg: "2xl" }}
                    fontWeight={"bold"}
                    className="val"
                  >
                    {timeDisplay.days}d
                  </Text>
                </span>
                <span className="timer-item">
                  <Text
                    as={"span"}
                    fontFamily={"InterBold, sans-serif"}
                    fontSize={{ base: "initial", lg: "2xl" }}
                    fontWeight={"bold"}
                    className="val"
                  >
                    {timeDisplay.hours}h
                  </Text>
                </span>
                <span className="timer-item">
                  <Text
                    as={"span"}
                    fontFamily={"InterBold, sans-serif"}
                    fontSize={{ base: "initial", lg: "2xl" }}
                    fontWeight={"bold"}
                    className="val"
                  >
                    {timeDisplay.minutes}m
                  </Text>
                </span>
                <span className="timer-item">
                  <Text
                    as={"span"}
                    fontFamily={"InterBold, sans-serif"}
                    fontSize={{ base: "initial", lg: "2xl" }}
                    fontWeight={"bold"}
                    className="val"
                  >
                    {Math.round(timeDisplay.seconds)}s
                  </Text>
                </span>
              </Flex>
            )
          ) : (
            <Text fontSize="1rem">Time's up!</Text>
          )}
        </div>
      </Flex>
      <SecretBidModal
        open={secretBidModalOpen}
        handleClose={() => handleAcknowledgement()}
        timeLeft={timeLeft || 0}
      />

      <TimesUpModal timeLeft={timeLeft || 0} />
    </div>
  );
}
