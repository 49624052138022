import React from 'react'
import { Pack } from '../../../types/packs'
import { Box, Flex, ModalHeader } from '@chakra-ui/react'
import { StateFrom } from 'xstate'

type HeaderProps = {
  state: StateFrom<any>
  uiPrice?: string
}

const Header: React.FC<HeaderProps> = ({ state, uiPrice }) => {
  if (state.matches('init')) {
    return (
      <Flex justifyContent="center" alignItems="center" className="buying-modal-header">
        {/* <Box >Loading...</Box> */}
      </Flex>
    )
  }

  const pack = state.context.packDetails as Pack

  return (
    <ModalHeader className="buying-modal-header" alignItems="center">
      <Box textAlign="center">{pack?.name}</Box>

      {/* <Flex justifyContent="space-between" alignItems="center" className="buying-modal-header">
        <Box>{pack?.name}</Box>
        <Box textAlign="center">Total Items: {pack?.items.length}</Box>
        <Box>Price: {uiPrice}</Box>
      </Flex> */}
    </ModalHeader>
  )
}

export default Header
