import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import { getAbbr, getTruncate, roundValue, trucPublicKey } from "../utils";
import { useChain } from "../contexts/chainsContext";
import { useUA } from "../contexts/userTracking";
import useNeoChain from "../hooks/useNeoChain";
import { Link } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { BiLogOut, BiRefresh } from "react-icons/bi";


export default function SignInButton({ version }: { version?: string }) {
  const { gaConnectWalletClick } = useUA();
  const { chain } = useChain();
  const chainFns = useNeoChain() as any;
  const { uid, publicKey, avatar, balance, statement2022, statement2023, activeChainBalance, tokenBalances, getTokenBalance, isFetchingBalance } = useAppContext();
  const { signIn, connecting, signOut } =
    chainFns?.[chain?.name?.toLowerCase() || "not_found"];
  const [copied, setCopied] = useState(false);
  const abbr = getAbbr(tokenBalances, activeChainBalance)
  const truncate = getTruncate(tokenBalances, activeChainBalance)
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloading(true);
      if (!uid) return;
      const [blockchain, address] = uid!.split("-");
      const getStatementUrl = chainFns?.[blockchain]?.getStatementUrl;
      const url = await getStatementUrl(blockchain, address);
      if (!url) throw new Error("No url found");
      window.open(url);
    } catch (e: any) {
      console.log(e);
    } finally {
      setDownloading(false);
    }
  };

  const copyAddress = useCallback(async () => {
    if (publicKey) {
      await navigator.clipboard.writeText(publicKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 400);
    }
  }, [publicKey]);

  const refreshBalance = async () => {
    if (uid) {
      await getTokenBalance(uid);
    }
  }
  if (uid)
    return (
      <Box className="div-signout" w={{ base: "full", md: "initial" }}>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                className="profile-button"
                variant="outline"
                size="md"
                w={{ base: "full", md: "initial" }}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
              >
                <Flex
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  {/* <MenuButton className="" onClick={refreshBalance} float={"right"} ml={"5px"}>
                    <BiRefresh className="" size="25" />
                  </MenuButton> */}

                  <span className="balance text-earth">
                    {isFetchingBalance ? "..." : roundValue(balance ? balance : 0, truncate)} {abbr}
                  </span>

                  <Avatar
                    src={avatar}
                    loading={"lazy"}
                    className="avatar"
                    mr={2}
                  />

                </Flex>
              </MenuButton>

              <BiRefresh className={`${isFetchingBalance ? "animate-spin" : ""} cursor-pointer`} size="25" onClick={() => refreshBalance()} />
              {/* 
              <MenuList zIndex={999} bg="#2c4453">
                <MenuItem
                  onClick={copyAddress}
                  closeOnSelect={false}
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  {trucPublicKey(publicKey || "")} <FaCopy />
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/my-items"
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  My Items
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/my-swaps"
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  My Swaps
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/rooms"
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  My Rooms
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/profile"
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  Edit Profile
                </MenuItem>
                {statement2023 && (
                  // <MenuItem
                  //   as={Link}
                  //   to="/my-items"
                  //   bg="#2c4453"
                  //   _hover={{ bg: "#2d3748" }}
                  //   _focus={{ bg: "#2d3748" }}
                  // >
                  //   Download 2023 Report
                  // </MenuItem>
                  <MenuItem
                    onClick={handleDownload}
                    bg="#2c4453"
                    _hover={{ bg: "#2d3748" }}
                    _focus={{ bg: "#2d3748" }}
                  >
                    Download 2023 Report
                  </MenuItem>
                )}
                <MenuItem
                  as={Link}
                  to="/points"
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  My Points
                </MenuItem>

                <MenuItem
                  onClick={signOut}
                  bg="#2c4453"
                  _hover={{ bg: "#2d3748" }}
                  _focus={{ bg: "#2d3748" }}
                >
                  Sign Out
                </MenuItem>
              </MenuList> */}
            </>
          )}
        </Menu>

        <Box display={{ base: "none", md: "initial" }}>
          <Menu>
            <MenuButton className="logoutbtnb" onClick={signOut}>
              <BiLogOut className="logoutbtn" size="25" />
            </MenuButton>
          </Menu>
        </Box>
      </Box>
    );

  return (
    <Button
      size={version === "connect-page" ? "md" : "sm"}
      isLoading={connecting}
      className="connect buy-pack-btn"
      loadingText="Connecting..."
      background={version === "connect-page" ? "#6C60FF" : ""}
      fontFamily={version === "connect-page" ? "InterBold" : ""}
      fontSize={version === "connect-page" ? "14px" : ""}
      w={version === "connect-page" ? "100%" : ""}
      onClick={() => {
        gaConnectWalletClick();
        signIn();
      }}
    >
      {version === "connect-page" ? "Connect Wallet" : "Connect"}
    </Button>
  );
}
