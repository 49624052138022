export default function IconSmartSilentAuction() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M18.5,28.2h-1c-0.4-2-2.2-3.5-4.3-3.5h-7c-2.1,0-3.9,1.5-4.3,3.5h-1c-0.5,0-0.9,0.4-0.9,0.9C0,29.6,0.4,30,0.9,30
	c5.9,0,11.7,0,17.6,0c0.5,0,0.9-0.4,0.9-0.9C19.3,28.6,18.9,28.2,18.5,28.2z" fill="currentColor"/>
      <path d="M20.4,10.5c0.7,0.7,1.8,0.7,2.5,0c0.7-0.7,0.7-1.8,0-2.5l-7.5-7.5c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5L20.4,10.5
	z" fill="currentColor"/>
      <path d="M8,22.9c0.7,0.7,1.8,0.7,2.5,0c0.7-0.7,0.7-1.8,0-2.5L3,12.9c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5L8,22.9z" fill="currentColor"/>
      <rect x="6.4" y="6.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.8508 11.7026)" width="10.5" height="10.5" fill="currentColor"/>
      <path d="M29.2,25.5L28,24.1l-3.7,3.7l1.3,1.4c1,1,2.7,1,3.7,0C30.3,28.2,30.3,26.5,29.2,25.5z" fill="currentColor"/>
      <path d="M20.8,17.1c0.1-0.6,0-1.2-0.4-1.7c-0.7-0.7-1.8-0.7-2.5,0l-2.5,2.5c-0.7,0.7-0.7,1.8,0,2.5c0.5,0.5,1.1,0.6,1.7,0.4l5.9,5.8
	l3.7-3.7L20.8,17.1z" fill="currentColor"/>
    </svg>
  );
}
