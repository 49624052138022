import {
  JSXElementConstructor,
  memo,
  ReactElement,
  ReactFragment,
  ReactPortal,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Image,
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Tooltip } from "react-tooltip";
import PendingImg from "../../assets/pending.jpg";
import { addListing, removeListing } from "../../services/room.service";
import { createFloor, roundValue, getVolumeLabel } from "../../utils";
import { useUA } from "../../contexts/userTracking";
import { Nft, useRoom } from "../../contexts/roomContext";
import { useAppContext } from "../../contexts/appContext";
import IconPurpleEye from "../Icons/IconPurpleEye";
import IconLink from "../Icons/IconLink";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import TxtBlock from "../TxtBlock";
import HTMLBlock from "../HTMLBlock";

interface Props {
  // onClose: () => void;
  // isOpen: boolean;
  // nft?: Nft;
  listing?: {
    reservePrice: number;
    listingPrice: number;
    startingBid: number;
    userDescription?: string;
  };
  nfts: Nft[];
}

const createImage = (nft?: Nft) => {
  const img = nft?.image || nft?.thumbnail;
  if (nft?.assetType?.includes("text/plain")) {
    return (
      <TxtBlock
        nft={nft}
        width="100%"
        maxW="450px"
        fontSize="12px"
        height="100%"
      />
    );
  }
  if (nft?.assetType?.includes("text/html")) {
    return <HTMLBlock nft={nft} style={{ maxWidth: "450px" }} />;
  }
  return <Image objectFit="cover" src={img} fallbackSrc={PendingImg} />;
};

const ListModal = ({ nfts }: Props) => {
  const { gaAddNft, gaSetReservePrice } = useUA();
  const { uid } = useAppContext();
  const {
    roomId,
    room,
    abbr,
    step,
    decimals,
    handleNext,
    handlePrev,
    availableToList,
    listings,
    setModalId,
    modalId,
    truncate,
  } = useRoom();

  const createInitialPrice = (
    nft?: Nft,
    decimals?: number,
    listing?: { reservePrice: number, listingPrice: number }
  ) => {
    if (!decimals) return 0;
    if (listing?.listingPrice) {
      return roundValue(listing.listingPrice / 10 ** decimals, truncate);
    }
    if (listing?.reservePrice) {
      return roundValue(listing.reservePrice / 10 ** decimals, truncate);
    }

    return room.info.minReservePrice / 10 ** decimals;
  };

  const createInitialNftDetails = (listing: { userDescription?: string }) => {
    if (!listing) return "";
    if (listing?.userDescription) {
      return listing.userDescription;
    }
  };

  const createInitialStartingBid = (
    decimals?: number,
    listing?: { startingBid: number }
  ) => {
    if (!decimals) return 0;
    if (listing?.startingBid) {
      return roundValue(listing.startingBid / 10 ** decimals, truncate);
    }

    return 0;
  };

  const canList =
    Object.values(listings || {}).length <= room?.info?.numNftsToList;

  const toast = useToast();
  // const { modalId, setModalId, listings } = useRoom();
  const nft = nfts?.find((nft) => nft.itemId === modalId);
  const itemIndex = nfts?.findIndex((nft) => nft?.itemId === modalId) + 1;
  const listing = listings?.[modalId || ""];

  const cancelRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [confirm, setConfirm] = useState(false);
  const [nftDetails, setNftDetails] = useState<string | undefined>(
    createInitialNftDetails(listing)
  );
  const [listedPrice, setListedPrice] = useState<number | string>(
    createInitialPrice(nft, decimals)
  );
  const [startingBid, setStartingBid] = useState<number | string>(
    createInitialStartingBid(decimals, listing)
  );
  const [currentTab, setCurrentTab] = useState("listing");

  const reservePrice = listing?.listingPrice;
  const isListed = typeof reservePrice === "number";

  const handleRemove = async () => {
    if (!nft?.itemId) return;
    await removeListing(roomId, uid!, nft?.itemId);
  };

  const [reserveAndStartingMatch, setReserveAndStartingMatch] = useState(true);

  const min = 0;
  const max = room?.info?.maxReservePrice;

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        setConfirm(false);
      }
      if (nfts.length < 2) return;
      if (event.key === "ArrowRight") {
        handleNext(nfts);
      } else if (event.key === "ArrowLeft") {
        handlePrev(nfts);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleNext, handlePrev]
  );

  useEffect(() => {
    if (!nft?.itemId) return;
    setListedPrice(createInitialPrice(nft, decimals, listing));
    setStartingBid(createInitialStartingBid(decimals, listing));
    setNftDetails(createInitialNftDetails(listing));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft?.itemId]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleCheckError = (value: string | number) => {
    const val = Number(value);
    if (val > max || val < min) {
      if (val > max) setError("Price too high for the room!");
      if (val < min) setError("Price too low for the room!");
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    handleCheckError(listedPrice);
  }, [listedPrice]);

  const handleAddListing = (val?: string | number) => {
    const roundedVal = roundValue(Number(listedPrice), truncate);
    if (!canList) {
      return toast({
        title: "You've reached your limit",
        description:
          "You've listed the maximum number of Items for this room. Please remove one to list another.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    if (!nft?.itemId) return;
    const listedNum = Number(val || roundedVal);
    if (!listedNum && listedNum !== 0) {
      return setError("Please enter a valid reserve price");
    }
    if (listedNum > max || listedNum < min) {
      return handleCheckError(listedNum);
    }
    setError(null);
    const minAmount = room?.info?.minReservePrice / 10 ** decimals;
    const maxAmount = room?.info?.maxReservePrice / 10 ** decimals;
    if (listedNum === 0 && !confirm) {
      if (listedNum < minAmount) {
        alert(`The minimum reserve price is ${minAmount} ${abbr}`);
      } else {
        return setConfirm(true);
      }
    } else {
      if (listedNum > maxAmount) {
        alert(`The maximum reserve price is ${maxAmount} ${abbr}`);
        return;
      }
      if (listedNum < minAmount) {
        alert(`The minimum reserve price is ${minAmount} ${abbr}`);
        return;
      }
      if (listedNum < Number(startingBid)) {
        alert("Starting bid must be equal or less than reserve price");
        return;
      }
      setListedPrice(listedNum);
      let listingPrice = listedNum * 10 ** decimals;
      const royaltyRate = nft?.royalty?.rate || 0;
      let adjustedReservePrice = listingPrice / (1 + (room.info.markupPercent / 100) + royaltyRate);
      addListing(roomId, uid!, nft.itemId, {
        reservePrice: adjustedReservePrice,
        listingPrice: listingPrice,
        startingBid: reserveAndStartingMatch ? listingPrice : Number(startingBid) * 10 ** decimals,
        userDescription: nftDetails || null,
      });
      gaAddNft(roomId, nft.itemId);
      gaSetReservePrice(roomId, nft.itemId, listingPrice);
      setConfirm(false);
      return toast({
        title: "Success!",
        description: "Your listing has been updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e?.key === "Enter") {
      handleAddListing();
    }
  };

  const handleQuickSet = (markdown?: number) => {
    if(markdown === undefined) {
      setListedPrice(0);
      handleAddListing(0);
      return;
    }

    if (!nft?.collection?.floorPrice?.floorPrice?.valueConverted) return;

    const collectionFloorPrice =
      nft.collection.floorPrice.floorPrice.valueConverted /
      10 ** nft.collection.floorPrice.floorPrice.decimals;

    // Handle the case when markdown is undefined by providing a default value of 0
    const markdownValue = markdown !== undefined ? markdown : 0;

    const markdownFactor =
      markdownValue === 0 ? 0 : markdownValue === 100 ? 1 : markdownValue / 100;

    const newPrice = roundValue(
      collectionFloorPrice - collectionFloorPrice * markdownFactor,
      truncate
    );

    setListedPrice(newPrice);
    handleAddListing(newPrice);
  };

  const tab = (name: string, slug: string) => {
    return (
      <Box
 
        background={currentTab === slug ? "#052032" : ""}
        p="10px 20px"
        fontSize="18px"
        fontWeight="bold"
        cursor="pointer"
        onClick={() => setCurrentTab(slug)}
      >
        {name}
      </Box>
    );
  };

  const availableToListCount = availableToList?.filter(
    (item) => item.locks.length === 0
  ).length;

  const modifiedAttributes = nft?.attributes.map((attr) => {
    const modifiedValue = attr.value
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { ...attr, value: modifiedValue };
  });

  const handleNftDetailsChange = (e: any) => {
    setNftDetails(e.target.value);
  };

  return (
    <AlertDialog
      id="listing-modal"
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => setModalId(undefined)}
      isOpen={!!nft}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxWidth="1050px" className="ns-dialog-content">
        <AlertDialogHeader>
          <div className="listing-modal-header">
            <h3>List</h3>
          </div>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <div className="listing-modal-body wip">
            <Flex direction="column" gap="20px" className="image-arrows">
              <div className={`asset-container ${isListed ? "is-listed" : ""}`}>
                {createImage(nft)}
              </div>

              {confirm ? (
                <></>
              ) : (
                <Flex
                  gap="4"
                  justifyContent="space-between"
                  alignItems="center"
                  className="nft-items-nav"
                >
                  <Button
                    className="nav-button"
                    onClick={() => handlePrev(nfts)}
                    isDisabled={nfts.length < 2}
                  >
                    <FaArrowLeft className="btn-icon" />
                    <span className="btn-text">Previous</span>
                  </Button>

                  <p>
                    Viewing [{itemIndex}/{availableToListCount}]
                  </p>

                  <Button
                    className="nav-button"
                    onClick={() => handleNext(nfts)}
                    isDisabled={nfts.length < 2}
                    ml="4"
                  >
                    <span className="btn-text">Next</span>
                    <FaArrowRight className="btn-icon" />
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex direction="column" flex="1" w="full">
              <Box mb="-1px" position="relative" zIndex="9">
                <Flex>
                  {tab("Details", "details")}
                  {tab("Listing", "listing")}
                </Flex>
              </Box>
              {currentTab === "listing" ? (
                <>
                  {confirm ? (
                    <Box   background="#052032" p="20px">
                      <Flex
                        backgroundColor="rgba(255,255,255,.1)"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        fontWeight="bold"
                        p="80px 20px"
                      >
                        <Text textAlign="center">
                          Are you sure you want to list this Item for free?
                        </Text>
                        <Flex mt="30px">
                          <Button
                            onClick={() => {
                              setConfirm(false);
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            className="primary"
                            ml={3}
                            onClick={() => handleQuickSet()}
                            autoFocus
                          >
                            Yes
                          </Button>
                        </Flex>
                      </Flex>
                    </Box>
                  ) : (
                    <>
                      <Box
                        padding="18px"
                        background="#052032"
                        flexGrow="1"
                        display="flex"
                        flexDirection="column"
                         minHeight="350px"
                      >
                        <Flex
                          direction="row-reverse"
                          gap="20px"
                          justifyContent="flex-end"
                          mb="4"
                        >
                          {isListed ? (
                            <span className="listed-tag">Listed</span>
                          ) : null}
                          <h4>{nft?.name}</h4>
                        </Flex>

                        <Flex gap={{base: "10px", md: "50px"}} direction={{ base: "column", md: "row" }} pb={{ base: "20px", md: "0px"}}>
                          <Box>
                            <Flex alignItems="center" mb="10px">
                              <Text>Reserve Price</Text>
                              <a // eslint-disable-line
                                data-tooltip-id="listing-tooltip"
                                data-tooltip-content="The absolute minimum price that you would be willing to sell the item for (will not be publicly revealed if secret reserve is enabled)"
                                data-tooltip-place="right"
                                style={{ marginLeft: "5px" }}
                              >
                                <IconPurpleEye />
                              </a>
                            </Flex>
                            <Box className="spending-limit">
                              <Flex direction="column">
                                <InputGroup className="set-limit-form">
                                  <NumberInput
                                    placeholder="Set Reserve Price"
                                    step={step || 0.01}
                                    value={listedPrice}
                                    onChange={(strVal) => {
                                      handleCheckError(strVal);
                                      setListedPrice(strVal);
                                    }}
                                    width={{ base: "100%", md: "150px"}}
                                    max={max}
                                    min={min}
                                    onKeyDown={handleKeyDown}
                                    onBlur={() => handleAddListing()}
                                    keepWithinRange
                                    clampValueOnBlur
                                    allowMouseWheel={false}
                                    lang="en"
                                  >
                                    <Text
                                      position="absolute"
                                      right="27px"
                                      top="5px"
                                      fontSize="sm"
                                    >
                                      {abbr}
                                    </Text>
                                    <NumberInputField
                                      className="spending-limit-input"
                                      pr="60px"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper className="stepper-controls" />
                                      <NumberDecrementStepper className="stepper-controls" />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </InputGroup>

                                <Text mt="10px" mb="10px">
                                  Max Reserve{" "}
                                  <span style={{ fontFamily: "InterBold" }}>
                                    {max / 10 ** decimals} {abbr}
                                  </span>
                                </Text>
                              </Flex>
                            </Box>
                          </Box>

                          <Box mt={{ base: "10px", md: "40px" }} mb={{ base: "18px", md: "0px" }} onClick={() => setReserveAndStartingMatch(!reserveAndStartingMatch)} cursor={"pointer"}>
                            {reserveAndStartingMatch ? (
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill="#ffffff"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-56.4-44.2c39.5-25.6 65.6-70.1 65.6-120.7c0-75.7-58.7-138.5-134.3-143.5l-8.1-.5c-13.2-.9-24.7 9.1-25.5 22.4s9.1 24.7 22.3 25.5l8.1 .5c50.4 3.4 89.5 45.2 89.5 95.6c0 39.8-24.3 74-58.8 88.4L414.8 299.8c20.8-25 33.2-57.1 33.2-91.9C448 128.4 383.6 64 304.1 64H143.9c-9.1 0-18.1 .9-26.8 2.5L38.8 5.1zM175.2 112h129c52.9 0 95.9 42.9 95.9 95.9c0 23.7-8.6 45.5-23 62.3L175.2 112zM446.8 448l-60.9-48H335.9c-53 0-95.9-42.9-95.9-95.9c0-6 .6-12 1.6-17.7l-40.8-32.1c-5.7 15.6-8.9 32.4-8.9 49.8C192 383.6 256.4 448 335.9 448H446.8zM30.1 119.7C11.2 144.1 0 174.7 0 207.9c0 75.7 58.7 138.5 134.3 143.5l8.1 .5c13.2 .9 24.7-9.1 25.5-22.3s-9.1-24.7-22.4-25.5l-8.1-.5C87.1 300.2 48 258.3 48 207.9c0-22 7.4-42.2 19.8-58.4L30.1 119.7z"/></svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill="#ffffff"><path d="M143.9 64C64.4 64 0 128.4 0 207.9c0 75.7 58.7 138.5 134.3 143.5l8.1 .5c13.2 .9 24.7-9.1 25.5-22.3s-9.1-24.7-22.4-25.5l-8.1-.5C87.1 300.2 48 258.3 48 207.9c0-52.9 42.9-95.9 95.9-95.9H304.1c52.9 0 95.9 42.9 95.9 95.9c0 50.5-39.1 92.3-89.5 95.6l-8.1 .5c-13.2 .9-23.2 12.3-22.4 25.5s12.3 23.2 25.5 22.3l8.1-.5c75.6-5 134.3-67.8 134.3-143.5C448 128.4 383.6 64 304.1 64H143.9zM496.1 448C575.6 448 640 383.6 640 304.1c0-75.7-58.7-138.5-134.3-143.5l-8.1-.5c-13.2-.9-24.7 9.1-25.5 22.4s9.1 24.7 22.3 25.5l8.1 .5c50.4 3.4 89.5 45.2 89.5 95.6c0 52.9-42.9 95.9-95.9 95.9H335.9c-53 0-95.9-42.9-95.9-95.9c0-50.5 39.1-92.3 89.5-95.7l7.1-.5c13.2-.9 23.2-12.3 22.3-25.6s-12.3-23.2-25.6-22.3l-7.1 .5C250.7 165.6 192 228.4 192 304.1C192 383.6 256.4 448 335.9 448H496.1z" /></svg>
                            )}
                          </Box>

                          <Box>
                            <Flex alignItems="center" mb="10px">
                              <Text>Starting Bid</Text>
                              <a // eslint-disable-line
                                data-tooltip-id="listing-tooltip"
                                data-tooltip-content="The price at which bidding should start (must be equal or less than the reserve)"
                                data-tooltip-place="right"
                                style={{ marginLeft: "5px" }}
                              >
                                <IconPurpleEye />
                              </a>
                            </Flex>
                            <Box className="spending-limit">
                              <Flex direction="column">
                                <InputGroup className="set-limit-form">
                                  <NumberInput
                                    placeholder="Set Spending Limit"
                                    min={0}
                                    step={0.01}
                                    value={reserveAndStartingMatch ? listedPrice : startingBid || 0}
                                    _dark={{
                                      backgroundColor: "transparent",
                                      opacity: 1,
                                    }}
                                    onChange={(strVal) => {
                                      handleCheckError(strVal);
                                      setStartingBid(strVal);
                                    }}
                                    width={{ base: "100%", md: "150px"}}
                                    // max={maxBudget}
                                    onBlur={() => handleAddListing()}
                                    keepWithinRange
                                    clampValueOnBlur
                                    allowMouseWheel={false}
                                    lang="en"
                                  >
                                    <Text
                                      position="absolute"
                                      right="27px"
                                      top="5px"
                                      fontSize="sm"
                                    >
                                      {abbr}
                                    </Text>
                                    <NumberInputField
                                      className="spending-limit-input"
                                      pr="60px"
                                      disabled={reserveAndStartingMatch}
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper className="stepper-controls" />
                                      <NumberDecrementStepper className="stepper-controls" />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </InputGroup>
                              </Flex>
                            </Box>
                          </Box>
                          <Tooltip
                            id="listing-tooltip"
                            className="custom-tooltip"
                            classNameArrow="custom-tooltip-arrow"
                          />
                        </Flex>

                        <Box mb={6}>
                          <Text>Provide Additional Information (optional)</Text>
                          <Textarea
                            onChange={handleNftDetailsChange}
                            value={nftDetails}
                            placeholder={"Enter details about your Item"}
                            onBlur={() => handleAddListing()}
                          />
                        </Box>

                        <Flex direction="column" gap="10px">
                          <Flex
                            background="rgb(102 100 100 / 13%)"
                            padding="7px 12px"
                            fontSize="14px"
                            fontWeight="700"
                            whiteSpace="nowrap"
                            w="100%"
                            alignItems="center"
                            direction={{ base: "column", md: "row" }}
                          >
                            <Text minWidth="85px" fontFamily="InterBold" textAlign={{ base: "center", md: "left" }}>
                              Floor Price:
                            </Text>
                            <Text fontFamily="InterBold">
                              {nft &&
                                roundValue(
                                  nft.collection.floorPrice.floorPrice
                                    ?.valueConverted /
                                  10 **
                                  nft.collection.floorPrice.floorPrice
                                    ?.decimals,
                                  truncate
                                )}{" "}
                              {abbr}
                            </Text>
                            <Button
                              onClick={() => handleQuickSet(0)}
                              ml={{ base: "0px", md: "auto" }}
                              mt={{ base: "5px", md: "0px" }}
                              size="sm"
                              variant="outline"
                              borderColor="#6C60FF"
                            >
                              Floor
                            </Button>
                          </Flex>

                          <Flex
                            background="rgb(102 100 100 / 13%)"
                            padding="7px 12px"
                            fontSize="14px"
                            fontWeight="700"
                            whiteSpace="nowrap"
                            w="100%"
                            alignItems="center"
                            direction={{ base: "column", md: "row" }}
                          >
                            <Text minWidth="85px" fontFamily="InterBold" textAlign={{ base: "center", md: "left" }}>
                              Clearance:
                            </Text>
                            <Text>
                              <span style={{ fontFamily: "InterBold" }}>
                                -50%
                              </span>{" "}
                              (of floor price)
                            </Text>
                            <Button
                              onClick={() => handleQuickSet(50)}
                              ml={{ base: "0px", md: "auto" }}
                              mt={{ base: "5px", md: "0px" }}
                              size="sm"
                              variant="outline"
                              borderColor="#6C60FF"
                            >
                              Clearance
                            </Button>
                          </Flex>

                          <Flex
                            background="rgb(102 100 100 / 13%)"
                            padding="7px 12px"
                            fontSize="14px"
                            fontWeight="700"
                            whiteSpace="nowrap"
                            w="100%"
                            alignItems="center"
                            direction={{ base: "column", md: "row" }}
                          >
                            <Text minWidth="85px" fontFamily="InterBold" textAlign={{ base: "center", md: "left" }}>
                              Fire Sale:
                            </Text>
                            <Text>
                              <span style={{ fontFamily: "InterBold" }}>
                                -75%
                              </span>{" "}
                              (of floor price)
                            </Text>
                            <Button
                              onClick={() => handleQuickSet(75)}
                              ml={{ base: "0px", md: "auto" }}
                              mt={{ base: "5px", md: "0px" }}
                              size="sm"
                              variant="outline"
                              borderColor="#6C60FF"
                            >
                              Fire Sale
                            </Button>
                          </Flex>

                          <Flex
                            background="rgb(102 100 100 / 13%)"
                            padding="7px 12px"
                            fontSize="14px"
                            fontWeight="700"
                            whiteSpace="nowrap"
                            w="100%"
                            alignItems="center"
                            direction={{ base: "column", md: "row" }}
                          >
                            <Text minWidth="85px" fontFamily="InterBold" textAlign={{ base: "center", md: "left" }}>
                              Free
                            </Text>
                            <Text>
                              <span style={{ fontFamily: "InterBold" }}>
                                -100%
                              </span>{" "}
                              (of floor price)
                            </Text>
                            <Button
                              onClick={() => setConfirm(true)}
                              ml={{ base: "0px", md: "auto" }}
                              mt={{ base: "5px", md: "0px" }}
                              size="sm"
                              variant="outline"
                              borderColor="#6C60FF"
                            >
                              Free
                            </Button>
                          </Flex>
                        </Flex>
                      </Box>
                    </>
                  )}
                </>
              ) : null}

              {currentTab === "details" ? (
                <div className="listing-modal-details">
                  <div className="nft-details-tab">
                    {nft?.collection.name || nft?.collection.twitter ? (
                      <>
                        {nft.collection.twitter ? (
                          <h4>
                            <a target="_blank" href={nft.collection.twitter}>
                              {nft.collection.twitter}
                            </a>
                          </h4>
                        ) : (
                          <h4>{nft.collection.name}</h4>
                        )}
                      </>
                    ) : null}
                    <h3>{nft?.name}</h3>

                    <div className="the-nft-details">
                      <div className="nft-details-item">
                        <p>Floor</p>
                        <span>{createFloor(nft)}</span>
                      </div>

                      <div className="nft-details-item">
                        <p>Volume</p>
                        <span>
                          {nft &&
                            getVolumeLabel(
                              nft.collection.volume,
                              nft?.collection?.floorPrice?.floorPrice
                                ?.decimals || 0,
                              abbr,
                              truncate
                            )}
                        </span>
                      </div>

                      <div className="nft-details-item">
                        <p>Owners</p>
                        <span>{nft?.collection.owners}</span>
                      </div>

                      <div className="nft-details-item">
                        <p>Total</p>
                        <span>{nft?.collection.totalItems}</span>
                      </div>

                      {nft?.marketplace.url ? (
                        <div className="last-link">
                          <a
                            href={nft?.marketplace.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            See on Marketplace
                            <div>
                              <IconLink />
                            </div>
                          </a>
                        </div>
                      ) : null}
                    </div>

                    <Box
                      className=""
                      style={{ maxHeight: "410px", overflowY: "scroll" }}
                    >
                      <h1 className="titleAtributes">Attributes</h1>
                      {nft?.attributes ? (
                        <Table>
                          <Thead>
                            <Tr>
                              <Th pl={"0"}>Type</Th>
                              <Th>Value</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {modifiedAttributes?.map((attr) => (
                              <Tr key={attr.trait_type}>
                                <Td pl={"0"}>{attr.trait_type}</Td>
                                <Td>{attr.value}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <h1>--</h1>
                      )}
                    </Box>

                    {nftDetails ? (
                      <>
                        <h5>Additional Info Provided by Seller</h5>
                        <p>{nftDetails}</p>
                      </>
                    ) : null}

                    <h5>Details</h5>
                    <p>
                      {nft?.description
                        ? nft?.description
                        : "This Item has no description."}
                    </p>
                  </div>
                </div>
              ) : null}

              <Flex justifyContent="flex-end">
                {isListed ? (
                  <Button
                    className="primary remove"
                    ml="auto"
                    mt="12px"
                    onClick={handleRemove}
                    autoFocus
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    ml="auto"
                    mt="12px"
                    bg="#6C60FF"
                    onClick={() => handleAddListing()}
                    autoFocus
                  >
                    List
                  </Button>
                )}
              </Flex>
            </Flex>
          </div>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ListModal;
