import {
  collection,
  doc,
  FieldPath,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  where,
  Timestamp,
} from 'firebase/firestore'
import { FirestoreUser } from 'ns-types'
import Firebase from './firebase'
import { Agreement, Email, FbUser } from '../contexts/appContext'
import { httpsCallable } from 'firebase/functions'

export const updateUser = async (uid: string, data: Partial<FirestoreUser>) => {
  console.log('updateUser', uid, data)
  const ref = doc(Firebase.getDBApp(), 'users', uid)
  return updateDoc(ref, data as FieldPath, { merge: true })
}

export const getUser = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid)
  return getDoc(ref)
}

export const getAiInfo = async (uid: string): Promise<any> => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'ai')
  let data = await getDoc(ref)
  return data.data()
}

export const getChainInfo = async (chain: string) => {
  const ref = doc(Firebase.getDBApp(), 'blockchain', chain)
  return getDoc(ref)
}

export const getChains = async () => {
  const ref = query(collection(Firebase.getDBApp(), 'blockchain'), where('disabled', '==', false))
  return getDocs(ref)
}

export const getWaitlistUser = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'waitlist', uid)
  return getDoc(ref)
}

export const getNotificationCollectionSwap = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'notificationCollectionSwap')
  return getDoc(ref)
}

export const setNotificationCollectionSwap = async (uid: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'notificationCollectionSwap')
  return setDoc(ref, data, { merge: true })
}

export const addToWaitList = async (
  chain: string,
  address: string,
  email: string,
  heardFrom: string
) => {
  const ref = doc(Firebase.getDBApp(), 'waitlist', `${chain}-${address}`)
  return setDoc(ref, { address, email, heardFrom, allowed: false }, { merge: true })
}

export const getEmail = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'email')
  return getDoc(ref)
}

export const getTelegram = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'telegram')
  return getDoc(ref)
}

export const getDiscord = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'discord')
  return getDoc(ref)
}

export const getTwitter = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'twitter')
  return getDoc(ref)
}

export const updateTerms = async (uid: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), `users`, uid!, 'private', 'info')
  return setDoc(ref, data, { merge: true })
}

export const updateInfo = async (uid: string, service: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), `users`, uid, 'contact', service)
  return setDoc(ref, data, { merge: true })
}

export const joinAiWaitlist = async (uid: string, email: string) => {
  const ref = doc(Firebase.getDBApp(), `aiWaitlist`, uid)
  return setDoc(ref, { email }, { merge: true })
}

type FormData = {
  email: string
  blockchains: string[]
  emaillist: boolean
  otherSuggestedBlockchain?: string
}

export const joinBuyDownWaitlist = async (formData: FormData) => {
  const ref = doc(Firebase.getDBApp(), `buydownWaitlist`, formData.email)
  return setDoc(ref, formData, { merge: true })
}

export const addToVerified = async (email: any) => {
  const ref = doc(Firebase.getDBApp(), 'verifedEmails', email)
  return setDoc(ref, { verified: true }, { merge: true })
}

export const getUserData = async (
  uid: string
): Promise<{ user: FbUser; agreements: Agreement; email: Email }> => {
  console.log('UID', uid)
  const userRef = doc(Firebase.getDBApp(), 'users', uid)
  const agreementRef = doc(Firebase.getDBApp(), 'users', uid, 'private', 'info')
  const emailRef = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'email')
  const [user, agreements, email] = await Promise.all([
    getDoc(userRef),
    getDoc(agreementRef),
    getDoc(emailRef),
  ])

  return {
    user: user.data() as FbUser,
    agreements: agreements.data() as Agreement,
    email: email.data() as Email,
  }
}

export const getBtcInfo = async (
  uid: string
): Promise<{
  [purpose: string]: { publicKey: string; address: string }
}> => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'bitcoin')
  let data = await getDoc(ref)
  return data.data() as {
    [purpose: string]: { publicKey: string; address: string }
  }
}

export const storeBtcInfo = (
  uid: string,
  data: { [purpose: string]: { address: string; publicKey: string } }
) => {
  const ref = doc(Firebase.getDBApp(), `users`, uid!, 'private', 'bitcoin')
  return setDoc(ref, data)
}

export const discordAuth = async (encryptedString: string) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'auth-discordAuth')
  return await fn({ encryptedString })
}

export const generateCSSwapImage = async (
  swapData: {
    giveName: string
    giveCollectionName: string
    giveImage: string
    getName: string
    getCollectionName: string
    getImage: string
    makerNeoswapCost: number
    takerNeoswapCost: number
  },
  swapId: string
) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'cs-generateSwapImage')
  return await fn({ swapData, swapId })
}

export const getUserBalance = async (userId: string) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'nft-userBudget')
  return await fn({ userId })
}

export const getTwitterRedirectUrl = async () => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'auth-redirectToTwitterAuthLink')
  return await fn()
}

export const callTwitterConnectCallback = async (oauth_token: string, oauth_verifier: string) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'auth-twitterConnectCallback')
  return await fn({ oauth_token, oauth_verifier })
}

export const tweetWithOptionalImage = async (
  message: string,
  imageUrl?: string,
  swapImageName?: string
) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'twitter-tweetWithOptionalImage')
  const payload: { message: string; imageUrl?: string; swapImageName?: string } = { message }
  if (imageUrl) {
    payload.imageUrl = imageUrl
  }
  if (swapImageName) {
    payload.swapImageName = swapImageName
  }
  return await fn(payload)
}

type AIUserInfo = {
  data: {
    tradesLeft: { value: number; details: string[] }
    nextTradeUnlock: number
    canGetTrade: boolean
    active: boolean
    noTradesReason: string
    lastRoom: {
      room: string
      state: string
      createdAt: number
    }
  }
}

export const aiGetUserInfo = async (userId: string, campaign?: string): Promise<AIUserInfo> => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'ai-getUserInfo')
  return (await fn({
    userId,
    ...(campaign ? { campaign } : {}),
  })) as unknown as AIUserInfo
}
