import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, Flex, IconButton } from "@chakra-ui/react";

interface Props {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export function pagination(current: number, total: number) {
  if (total <= 1) return [1];
  const center: any[] = [
      current - 2,
      current - 1,
      current,
      current + 1,
      current + 2,
    ],
    filteredCenter = center.filter((p) => p > 1 && p < total),
    includeThreeLeft = current === 5,
    includeThreeRight = current === total - 4,
    includeLeftDots = current > 5,
    includeRightDots = current < total - 4;

  if (includeThreeLeft) filteredCenter.unshift(2);
  if (includeThreeRight) filteredCenter.push(total - 1);

  if (includeLeftDots) filteredCenter.unshift("...");
  if (includeRightDots) filteredCenter.push("...");

  return [1, ...filteredCenter, total];
}

export default function Pagination({
  totalPages,
  currentPage,
  setCurrentPage,
}: Props) {
  const buttons = pagination(currentPage, totalPages);
  return (
    <Flex justifyContent="flex-end" className="table-pagination">
      <ButtonGroup>
        <IconButton
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          icon={<ArrowBackIcon />}
          aria-label="back"
          disabled={currentPage === 1}
        />
        {buttons.map((page) => {
          if (page === "...") {
            return (
              <Button
                variant="ghost"
                isDisabled
                key={page}
                isActive={page === currentPage}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            );
          }
          return (
            <Button
              key={page}
              isActive={page === currentPage}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </Button>
          );
        })}
        <IconButton
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
          icon={<ArrowForwardIcon />}
          aria-label="next"
          disabled={currentPage === totalPages}
        />
      </ButtonGroup>
    </Flex>
  );
}
