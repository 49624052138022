import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PendingImg from "../assets/pending.jpg";
import SignupForm from "../components/SignupForm";
import { useAppContext } from "../contexts/appContext";
import { useChain } from "../contexts/chainsContext";
import { Nft } from "../contexts/roomContext";
import { getNftDetails } from "../services/nfts.service";
import { addEvent, runOtc } from "../services/room.service";
import { trucPublicKey } from "../utils";

const UserAddressInput = ({ chain, abbr }: { chain: string; abbr: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [val, setVal] = useState<string>("");
  const formik = useFormikContext() as any;

  if (formik.values.users.length > 1) {
    const user = formik.values.users[1].split("-")[1];
    return (
      <Box borderRadius="7px" border="1px solid #2f4451" width="100%" py="10">
        <Center>
          <VStack>
            <Text>{trucPublicKey(user)}</Text>
            <Button
              onClick={() => {
                formik.setFieldValue("users", [formik.values.users[0]]);
              }}
            >
              Remove
            </Button>
          </VStack>
        </Center>
      </Box>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${formik.values.otc.type === "buy" ? "Seller's" : "Buyer's"
            } ${abbr} address`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{`${formik.values.otc.type === "buy" ? "Seller's" : "Buyer's"
                } ${abbr} address`}</FormLabel>
              <Input
                name="otc.itemId"
                placeholder={`${abbr} address`}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                formik.setFieldValue("users", [
                  ...formik.values.users,
                  `${chain}-${val}`,
                ]);
                onClose();
                setVal("");
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box borderRadius="7px" border="1px solid #2f4451" width="100%" py="10">
        <Center>
          <Button onClick={onOpen}>
            Add Inscription{" "}
            {formik.values.otc.type === "buy" ? "Seller" : "Buyer"}
          </Button>
        </Center>
      </Box>
    </>
  );
};

const ValueInput = ({
  decimals,
  abbr,
  label = "Enter the amount to receive",
}: {
  decimals: number;
  abbr: string;
  label?: string;
}) => {
  const formik = useFormikContext() as any;
  return (
    <FormControl>
      <FormLabel htmlFor="otc.token">{label}</FormLabel>
      <InputGroup>
        <Input
          id="otc.token"
          name="otc.token"
          placeholder="Enter the amount"
          type="number"
          onChange={(e) => {
            const val = (e.target.value || 0) as number;
            const units = 10 ** decimals;
            if (val < 0) return;
            formik.setFieldValue("otc.token", val * units);
          }}
        />
        <InputRightAddon>{abbr}</InputRightAddon>
      </InputGroup>
    </FormControl>
  );
};

const OrdinalsInput = ({ id }: { id?: string }) => {
  const [loading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [val, setVal] = useState<string>("");
  const formik = useFormikContext();
  const [nft, setNft] = useState<Nft>();

  useEffect(() => {
    const fetchNft = async () => {
      try {
        setIsLoading(true);
        const res = await getNftDetails([id!]);
        if (!res?.data?.length) throw new Error("No Item found");
        setNft(res.data[0]);
      } catch (e) {
        console.log("error fetching nft");
      } finally {
        setIsLoading(false);
      }
    };
    if (id) {
      fetchNft();
    }
  }, [id]);

  if (nft) {
    return (
      <Box borderRadius="7px" border="1px solid #2f4451" width="100%" py="10">
        <Center>
          <VStack>
            <Text>{nft.name}</Text>
            <Image
              height="64px"
              objectFit="cover"
              src={nft.thumbnail || nft.image || PendingImg}
              fallbackSrc={PendingImg}
              loading="lazy"
            />
            <Button
              onClick={() => {
                formik.setFieldValue("otc.itemId", undefined);
                setNft(undefined);
              }}
            >
              Remove
            </Button>
          </VStack>
        </Center>
      </Box>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Ordinals Inscription ID</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Enter Ordinals Inscription ID</FormLabel>
              <Input
                name="otc.itemId"
                placeholder="enter id"
                value={val}
                onChange={(e) => setVal(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                formik.setFieldValue("otc.itemId", `ordinals-${val}`);
                onClose();
                setVal("");
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box borderRadius="7px" border="1px solid #2f4451" width="100%" py="10">
        <Center>
          <Button isLoading={loading} onClick={onOpen}>
            Add Inscription ID
          </Button>
        </Center>
      </Box>
    </>
  );
};

const createInitalValues = (
  adminId: string,
  displayName: string,
  chain: string
) => {
  return {
    blockchain: {
      name: chain,
    },
    createdAt: undefined,
    info: {
      accessPolicy: {
        policy: "closed",
        collections: [],
      },
      addToEvents: false,
      adminId,
      isOrdinals: true,
      itemsPreference: {
        preferAdmin: false,
        preferredItems: [],
        preferredUsers: [],
      },
      name: `OTC Swap w/ ${displayName}`,
      ordinalsWallets: [],
      roomType: "OTCOrdinalSwap",
      startAt: DateTime.now().toUTC().toSeconds(),
      chain,
    },
    otc: {
      itemId: undefined,
      token: undefined,
      type: "sell",
    },
    users: [adminId],
  };
};

export default function Otc() {
  const { uid, displayName, agreements } = useAppContext();
  const { getChainDetails } = useChain();
  const navigate = useNavigate();
  const chain = uid!.split("-")[0];

  const roomChain = getChainDetails(chain!);
  const abbr = roomChain?.abbr;
  const decimals = roomChain?.decimals;

  const initialValues = createInitalValues(uid!, displayName!, chain);

  if (!displayName || !agreements?.consentTosAndPp) return <SignupForm />;

  // const canOtc = rights?.["create-otc"];
  const canOtc = true;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: typeof initialValues) => {
        try {
          const res = await addEvent(values);
          await runOtc(res.id);
          navigate(`/rooms/${res.id}`, { replace: true });
        } catch (e) {
          console.log("ERROR", e);
        }
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Center>
            <Container maxW="lg">
              <div className="settings-wrapper box">
                <Tabs
                  onChange={(index: number) => {
                    formik.setFieldValue(
                      "otc.type",
                      index === 0 ? "sell" : "buy"
                    );
                  }}
                >
                  <p className="desc">
                    Discover how to do OTC swaps with ordinals in just a few
                    clicks! Follow{" "}
                    <a
                      href="https://neoswap.ai/otc-swaps-on-ordinals-how-do-they-work/"
                      className="link-a"
                      target="blank"
                    >
                      {" "}
                      this link{" "}
                    </a>{" "}
                    to learn more.{" "}
                  </p>
                  <TabList>
                    <Tab>Sell</Tab>
                    <Tab>Buy</Tab>
                    {/* <Tab  _selected={{bg: "#5e33ff"}}>Sell</Tab>
                    <Tab _selected={{bg: "#5e33ff"}}>Buy</Tab> */}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <VStack p={2} spacing={4}>
                        <OrdinalsInput id={formik.values.otc.itemId} />
                        <ValueInput abbr={abbr} decimals={decimals} />
                        <UserAddressInput abbr={abbr} chain={chain} />
                      </VStack>
                    </TabPanel>
                    <TabPanel>
                      <VStack p={2} spacing={4}>
                        <ValueInput
                          abbr={abbr}
                          decimals={decimals}
                          label="Enter the amount to pay"
                        />
                        <OrdinalsInput id={formik.values.otc.itemId} />
                        <UserAddressInput abbr={abbr} chain={chain} />
                      </VStack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Flex justifyContent="center" alignItems="center">
                  <Button
                    width="50%"
                    isDisabled={!canOtc}
                    isLoading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                    bg="#6C60FF"
                  >
                    Generate Swap
                  </Button>
                </Flex>
                {!canOtc && (
                  <Flex justifyContent="center" alignItems="center">
                    <span className="check-allowlist">
                      <FaExclamationTriangle className="icon" />
                      <span>
                        Sorry, you are not currently on the OTC Swaps allowlist.
                        Please fill out{" "}
                        <a
                          href="https://neoswap.cc/otc-swaps-waitlist"
                          className="link-a"
                          target="blank"
                        >
                          {" "}
                          this form{" "}
                        </a>{" "}
                        to join the waitlist and be notified when you are
                        granted access to OTC Swaps.
                      </span>
                    </span>
                  </Flex>
                )}
              </div>
            </Container>
          </Center>
        </form>
      )}
    </Formik>
  );
}
