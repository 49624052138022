import { useToast } from "@chakra-ui/react";
import { PublicKey } from "@solana/web3.js";
import {
  setPersistence,
  signInWithCustomToken,
  signOut as fbSignOut,
  browserLocalPersistence,
  onAuthStateChanged,
  updateEmail as fbUpdateEmail,
  sendEmailVerification as fbSendEmailVerification,
  User,
  updateProfile,
  reload,
} from "firebase/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNonce, verifySignedMessage, verifySignedTransaction } from "../components/blockchains/utils";
import Firebase from "../services/firebase";
import { getAiInfo } from "../services/user.service";
import {
  updateInfo,
  getEmail,
  getTelegram,
  getDiscord,
  updateUser as updateUserFb,
  getUserData,
  storeBtcInfo,
  getBtcInfo,
  getUserBalance,
} from "../services/user.service";

import { getGameData } from "../services/games";
import { useChain } from "./chainsContext";
import { useUA } from "./userTracking";
// import { roundValue } from "../utils";
// import { useDocument } from "react-firebase-hooks/firestore";
// import { doc } from "firebase/firestore";
// import firebase from "../services/firebase";
import { BtcAddress } from "./btcContext";

interface Props {
  children?: ReactNode;
}

export type Email = {
  value?: string;
  isVerified?: boolean;
  prompt?: boolean;
  optOut?: boolean;
  verificationSent?: boolean;
};

export type Telegram = {
  value?: string;
};

export type Twitter = {
  value?: string;
};

export type Discord = {
  value?: string;
};

type Profile = {
  displayName?: string;
  photoURL?: string;
};

export type Agreement = {
  consentTosAndPp?: boolean;
  consentNotifications?: boolean;
};

export type FbUser = {
  address?: string;
  blockchain?: string;
  displayName?: string;
  rights?: { [key: string]: boolean };
  admin?: boolean;
  statement2022?: boolean;
  statement2023?: boolean;
  avatar?: string;
  twitterConnected?: boolean;
};

interface AppContextInterface extends State {
  signIn: () => void;
  signOut: () => Promise<void>;
  setToken: (token: string) => void;
  updateUser: (body: any) => void;
  setPublicKey: (publicKey: PublicKey) => void;
  returnUrl?: string;
  updateState: (state: Partial<State>) => void;
  setCurrentFilter: (val: string) => void;
  getEncodedNonce: (
    publicKey: string,
    blockchain: string
  ) => Promise<Uint8Array | string>;
  getPartnerGameData: (partner: string) => Promise<any>;
  getToken: (
    address: string,
    blockchain: string,
    signature: string,
    publicKey?: string
  ) => Promise<string>;
  getTokenFromSignedTransaction: (
    transaction: string,
    blockchain: string
  ) => Promise<string>;
  signInWithToken: (token: string) => Promise<void>;
  getUserAIInfo: () => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  sendEmailVerification: (email: string) => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  reloadUser: () => Promise<void>;
  updateUserProfile: ({ displayName, photoURL }: Profile) => Promise<void>;
  getTokenBalance: (uid: string) => Promise<void>;
  isFetchingBalance: boolean;
  setTwitterConnected: (val: boolean) => void;
}

const AppCtx = createContext<AppContextInterface>({} as AppContextInterface);

const initialState = {
  statement2022: false,
  statement2023: false,
  admin: false,
  user: null,
  token: null,
  uid: null,
  publicKey: null,
  displayName: null,
  avatar: null,
  balance: null,
  signingIn: false,
  fetchingBalance: false,
  rights: {},
  currentFilter: "listing",
  signedIn: false,
  email: null,
  agreements: null,
  initialized: false,
  btcInfo: null,
  showContactModal: false,
  tokenBalances: undefined,
  activeChainBalance: "native",
  twitterConnected: false,
};

type Action =
  | { type: "update"; payload: any }
  | { type: "reset"; payload: any };

export type TokenBalances = {
  tokens: {
    id: string;
    value: number;
    decimals: number;
    abbr: string;
    truncate: number;
    name: string;
  }[];
  numNfts: number;
};

type State = {
  admin: boolean;
  user?: User;
  token?: string;
  publicKey?: string;
  uid?: string;
  displayName?: string;
  avatar?: string;
  balance?: number;
  signingIn: boolean;
  fetchingBalance: boolean;
  rights: { [key: string]: boolean };
  currentFilter: string;
  signedIn: boolean;
  statement2022: boolean;
  statement2023: boolean;
  email?: Email;
  telegram?: Telegram;
  discord?: Discord;
  agreements?: Agreement;
  initialized: boolean;
  showContactModal: boolean;
  btcInfo: BtcAddress;
  tokenBalances?: TokenBalances;
  activeChainBalance: string;
  twitterConnected?: boolean;
};

const reducer = (state: State, { type, payload }: Action) => {
  switch (type) {
    case "update":
      localStorage.setItem("neoswap", JSON.stringify({ ...state, ...payload }));
      return {
        ...state,
        ...payload,
      };
    case "reset":
      localStorage.setItem(
        "neoswap",
        JSON.stringify({
          ...initialState,
          ...payload,
          signedIn: false,
        })
      );
      return {
        ...initialState,
        ...payload,
        signedIn: false,
      };
    default:
      return state;
  }
};

export default function AppProvider({ children }: Props) {
  const { getChainDetails, setChain } = useChain();
  const { gaSignIn, gaBalance, gaSignOut } = useUA();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const toast = useToast();
  const returnUrl = searchParams.get("returnUrl");

  const getEncodedNonce = useCallback(
    async (publicKey: string, blockchain: string) => {
      const { nonce } = await getNonce(publicKey, blockchain);
      return nonce;
      // if (blockchain === "stacks") return nonce;
      // const encoded = new TextEncoder().encode(nonce);
      // if (!encoded) throw new Error("Error encoding nonce!");
      // return encoded;
    },
    []
  );

  const getToken = useCallback(
    async (
      address: string,
      blockchain: string,
      signature: string,
      pubKey?: string
    ) => {
      const { token } = await verifySignedMessage(
        address,
        signature,
        blockchain,
        pubKey
      );
      return token;
    },
    []
  );

  const getTokenFromSignedTransaction = useCallback(
    async (transaction: string, blockchain: string) => {
      const { token } = await verifySignedTransaction(
        transaction,
        blockchain
      );
      return token;
    },
    []
  );

  const getUserEmail = async (uid: string) => {
    try {
      const res = await getEmail(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user email!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING EMAIL", e);
    }
  };

  const getUserTelegram = async (uid: string) => {
    try {
      const res = await getTelegram(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user telegram!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING TELEGRAM", e);
    }
  };

  const getUserDiscord = async (uid: string) => {
    try {
      const res = await getDiscord(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user telegram!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING DISCORD", e);
    }
  };

  const getPartnerGameData = async (partner: string) => {
    try {
      const data = await getGameData(partner);
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING GAME DATA", e);
    }
  };

  const wrongUserCaseCorrectionHotfix = async (userId: string) => {
    const [blockchain, address] = userId.split("-");
    const chainLowerCase = blockchain.toLocaleLowerCase();
    const userUid = chainLowerCase + "-" + address;
    console.log(userUid);
    return userUid;
  };

  const handleSignedIn = async () => {
    dispatch({ type: "update", payload: { signingIn: true } });
    const user = state.user;
    const userUid = await wrongUserCaseCorrectionHotfix(user.uid);
    const { user: fbUser } = await getUserData(userUid);
    const [blockchain, publicKey] = userUid.split("-");

    let balanceAddress = publicKey;
    let btcInfo = state.btcInfo;
    if (blockchain === "bitcoin" && !btcInfo) {
      btcInfo = await getBtcInfo(userUid);
      balanceAddress = btcInfo?.payment?.address;
    } else if (blockchain === "bitcoin") {
      balanceAddress = btcInfo?.payment?.address;
    }

    if (btcInfo) {
      await storeBtcInfo(userUid, btcInfo);
    }

    const [email, telegram, discord] = await Promise.all([getUserEmail(userUid), getUserTelegram(userUid), getUserDiscord(userUid)]);

    getTokenBalance(userUid);

    setChain(blockchain);
    dispatch({
      type: "update",
      payload: {
        user,
        uid: userUid,
        publicKey,
        signedIn: true,
        signingIn: false,
        email,
        telegram,
        btcInfo,
        discord,
        twitterConnected: fbUser?.twitterConnected,
      },
    });
    // authWithSendbird(userUid);
    gaSignIn(userUid);
    if (returnUrl) navigate(returnUrl, { replace: true });
  };

  useEffect(() => {
    if (!state.signedIn) return;
    handleSignedIn();
  }, [state.signedIn]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      Firebase.getAuthApp(),
      async (user) => {
        if (user) {
          if (user?.uid) {
            const [blockchain] = user.uid.split("-");
            const chainDetails = getChainDetails(blockchain);
            if (!chainDetails || chainDetails?.disabled) {
              return await signOut();
            }
          }
          const { user: fbUser, ...rest } = await getUserData(user.uid);
          dispatch({
            type: "update",
            payload: {
              initialized: true,
              signedIn: true,
              user,
              ...fbUser,
              ...rest,
            },
          });
        } else {
          console.log("user is signed out");
          dispatch({
            type: "reset",
            payload: {},
          });
        }
      }
    );

    return unsubscribe;
  }, []);

  const reloadUser = useCallback(async () => {
    await reload(state.user);
    const currentUser = Firebase.getAuthApp().currentUser;
    if (!currentUser) return;
    const { user: fbUser, email, ...rest } = await getUserData(currentUser.uid);
    let isVerified = false;
    if (currentUser?.emailVerified) {
      isVerified = true;
      await updateInfo(currentUser.uid, "email", {
        ...email,
        isVerified: true,
      });
    }
    dispatch({
      type: "update",
      payload: {
        user: currentUser,
        email: { ...email, isVerified },
        ...fbUser,
        ...rest,
      },
    });
  }, [state.user]);

  const updateEmail = useCallback(
    async (email: string) => {
      await fbUpdateEmail(state.user, email);
    },
    [state.user]
  );

  const updateUserProfile = useCallback(
    async (data: Profile) => {
      console.log("Updating user profile", state.user, data);
      // await updateProfile(state.user, data);
      await updateUserFb(state.uid, data);
    },
    [state.user, state.uid]
  );

  const sendEmailVerification = useCallback(async () => {
    console.log(state.user);
    await fbSendEmailVerification(state.user);
    await updateInfo(state.uid, "email", { verificationSent: true });
  }, [state.user, state.uid]);

  const sendCustomTokenToExtension = useCallback(async (token: string) => {
    const { chrome } = window as any;

    if (!chrome) return;

    // TODO: use process.env.REACT_APP_EXTENSION_ID
    const extensionId = "imbaekpkagjbaifpfnhkceajinjeplbg";
    try {
      chrome.runtime.sendMessage(
        extensionId,
        {
          type: "SIGN_IN",
          payload: {
            token,
          },
        },
        (response: { success: boolean }) => {
          if (response?.success) {
            toast({
              title: "Successfully connected to NeoSwap!",
              status: "success",
            });
          } else {
            toast({
              title: "Error connecting to NeoSwap!",
              status: "error",
            });
          }
        }
      );
    } catch (error) { }
  }, []);

  const signInWithToken = useCallback(async (token: string) => {
    try {
      await setPersistence(Firebase.getAuthApp(), browserLocalPersistence);
      await signInWithCustomToken(Firebase.getAuthApp(), token);
      console.log("Signing in with token");
      console.log("Sending token to extension");
      sendCustomTokenToExtension(token);
    } catch (e) {
      console.log("SIGNING IN WITH TOKEN FAILED", e);
      throw e;
    }
  }, []);

  const updateUser = async (data: any) => {
    try {
      if (data?.displayName) {
        await updateProfile(state.user, { displayName: data.displayName });
        dispatch({
          type: "update",
          payload: data,
        });
      }
      await updateUserFb(state.uid, data);
    } catch (e: any) {
      toast({
        title: "Error updating your name!",
        description: "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      localStorage.setItem("neoswap", JSON.stringify({ ...state, ...data }));
    }
  };

  // const updateStateEmail = async (data: Partial<Email>) => {
  //   try {
  //     dispatch({
  //       type: "update",
  //       payload: { email: data },
  //     });
  //     if (data.value) {
  //       console.log("fb update email", state.user, data.value);
  //       await fbUpdateEmail(state.user, data.value);

  //       console.log("email updated", { ...state.user, email: data.value });
  //       await sendVerification({ ...state.user, email: data.value });
  //     }
  //     await updateEmail(state.uid, data);
  //   } catch (e: any) {
  //     let message = "Please Try Again!";

  //     if (e.code === "auth/requires-recent-login") {
  //       message = "Please re-login to update your email!";
  //     }
  //     toast({
  //       title: "Error updating your email!",
  //       description: message,
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //     throw e;
  //   }
  // };

  const signOut = async () => {
    try {
      await fbSignOut(Firebase.getAuthApp());
      dispatch({
        type: "reset",
        payload: {},
      });
      gaSignOut();
    } catch (e) {
      console.log("ERROR SIGNING OUT", e);
    }
  };

  const getUserAIInfo = useCallback(async () => {
    const aiInfo = getAiInfo(state.uid);
    dispatch({ type: "update", payload: { aiInfo } });
  }, [state.uid]);

  const getTokenBalance = async (uid: string) => {
    setIsFetchingBalance(true);
    try {
      dispatch({ type: "update", payload: { fetchingTokenBalance: true } });
      const res = (await getUserBalance(uid)) as any;
      dispatch({
        type: "update",
        payload: {
          tokenBalances: res.data,
        },
      });
    } catch (e) {
      console.log(`Error getting token balance for ${uid}`, e);
      toast({
        title: "Error getting your balance",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      dispatch({ type: "update", payload: { fetchingTokenBalance: false } });
      setIsFetchingBalance(false);
    }
  };

  const updateState = useCallback((payload: Partial<State>) => {
    dispatch({ type: "update", payload });
  }, []);

  const setCurrentFilter = useCallback((val: string) => {
    dispatch({ type: "update", payload: { currentFilter: val } });
  }, []);

  const setTwitterConnected = useCallback(async (val: boolean) => {
    dispatch({ type: "update", payload: { twitterConnected: val } });
    await reloadUser();
  }, [dispatch, reloadUser]);

  const createBalance = (
    tokenBalances: TokenBalances,
    activeChainBalance: string
  ) => {
    const found = tokenBalances?.tokens?.find(
      (ele) => ele.id === activeChainBalance
    );
    console.log({ tokenBalances, activeChainBalance });
    if (!tokenBalances || !found) return 0;

    const { value, decimals } = found;
    return value / 10 ** decimals;
  };

  const value = useMemo(() => {
    const balance = createBalance(
      state.tokenBalances,
      state.activeChainBalance
    );
    return { ...state, balance };
  }, [state]);

  return (
    <AppCtx.Provider
      value={{
        ...value,
        signOut,
        updateUser,
        updateState,
        setCurrentFilter,
        getEncodedNonce,
        getToken,
        getTokenFromSignedTransaction,
        signInWithToken,
        getTokenBalance,
        getPartnerGameData,
        isFetchingBalance,
        getUserAIInfo,
        updateEmail,
        updateUserProfile,
        sendEmailVerification,
        reloadUser,
        returnUrl,
        setTwitterConnected
      }}
    >
      {children}
    </AppCtx.Provider>
  );
}

export function useAppContext(): AppContextInterface {
  const ctx = useContext(AppCtx);
  if (ctx === undefined) {
    throw new Error("useAppContext requires AppCtx.Provider");
  }
  return ctx;
}
