import { Icon, IconProps } from "@chakra-ui/react";

export default function IconStacks(props: IconProps) {
  return (
    <Icon id="svg-stacks" viewBox="0 0 30 30" {...props}>
      <path
        d="M19.16,18.93L23.7,26h-3.39l-5.32-8.31L9.67,26H6.3l4.53-7.06h-6.5v-2.68h21.33v2.66H19.16z"
        fill="currentColor"
      ></path>
      <path
        d="M25.67,10.98v2.68v0.02H4.33v-2.7h6.38L6.23,4h3.39l5.37,8.42L20.38,4h3.39l-4.48,6.98H25.67z"
        fill="currentColor"
      ></path>
    </Icon>
  );
}
