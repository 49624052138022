import Slider from "react-slick";
import { BiddableNft } from "../../contexts/roomContext";
import BiddingGridItem from "../NftCollection/BiddingGridItem";

interface Props {
  data?: BiddableNft[];
}

export function BiddingCarousel({ data }: Props) {
  const settings = {
    draggable: true,
    pauseOnHover: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1593,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1385,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1244,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 783,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (data && data?.length < 5) {
    return (
      <div className="grid-view-wrapper normal">
        {data
          ?.sort((a, b) => a.itemId.localeCompare(b.itemId))
          ?.map((nft: BiddableNft) => (
            <BiddingGridItem key={nft.itemId} nft={nft} updateNew={false} />
          ))}
      </div>
    );
  }

  return (
    <div className="grid-view-wrapper">
      <Slider {...settings}>
        {data
          ?.sort((a, b) => a.itemId.localeCompare(b.itemId))
          ?.map((nft: BiddableNft) => (
            <BiddingGridItem key={nft.itemId} nft={nft} updateNew={false} />
          ))}
      </Slider>
    </div>
  );
}
