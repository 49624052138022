import { memo } from "react";
import { useRoom } from "../../contexts/roomContext";
import { getReserveLabel } from "../../utils";

const ReserveCell = memo((props: any) => {
  const { listings, abbr, decimals, truncate } = useRoom();
  const id = props?.row?.original?.itemId;
  const listing = listings?.[id];
  const reservePrice = listing
    ? listing.listingPrice
    : props?.row?.original?.listingPrice?.value;

  const isListed = typeof reservePrice === "number";
  return (
    <>
      {isListed
        ? getReserveLabel(reservePrice, decimals, abbr, truncate)
        : "--"}
    </>
  );
});

export default ReserveCell;
