import { Helmet } from "react-helmet-async";
import Firebase from "../services/firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { DateTime } from "luxon";
import NeoTable from "../components/Table/NeoTable";
import NeoTr from "../components/Table/NeoTr";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useAppContext } from "../contexts/appContext";
import isAuthorized from "../components/Authorized";
import { useChain } from "../contexts/chainsContext";

type Filters = {
  [key: string]: {
    name: string;
    operator: string;
    value: string;
  };
};

const createFilterArgs = (filters: any) => {
  return Object.keys(filters).map((key) => {
    const filter = filters[key];
    return where(filter.name, filter.operator, filter.value);
  });
};

const AuthedButton = isAuthorized(Button, ["create-room"]);

export default function Rooms() {
  const { chain } = useChain();
  const { uid, currentFilter, setCurrentFilter, rights } = useAppContext();
  const [results, setResults] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters] = useState<Filters>({
    "blockchain.name|solana": {
      name: "blockchain.name",
      operator: "==",
      value: chain?.name?.toLowerCase() || "solana",
    },
    "status|listing": { name: "status", operator: "==", value: currentFilter },
  });

  const queryFn = useCallback((fils: Filters) => {
    const db = Firebase.getDBApp();
    const q = query(
      collection(db, "rooms"),
      ...createFilterArgs(fils),
      orderBy("info.startAt", "desc")
    );
    return q;
  }, []);

  // useEffect(() => {
  //   handleFilter(currentFilter);
  // }, [chain]);

  // const more = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const next = query(queryFn(filters), startAfter(results.length - 1));
  //     const querySnapshot = await getDocs(next);
  //     setResults([...results, ...querySnapshot.docs]);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [results, queryFn]);

  const fetchResults = async (fils: Filters) => {
    setIsLoading(true);
    const q = query(queryFn(fils));
    const querySnapshot = await getDocs(q);
    setResults([...querySnapshot.docs]);
    setIsLoading(false);
  };

  const getMyRooms = async () => {
    setCurrentFilter("mine");
    const db = Firebase.getDBApp();
    const q = query(
      collection(db, "rooms"),
      where("users", "array-contains", uid),
      orderBy("info.startAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const q2 = query(
      collection(db, "aiRooms"),
      where("userId", "==", uid),
      orderBy("info.createdAt", "desc")
    );
    const querySnapshot2 = await getDocs(q2);
    setResults([...querySnapshot2.docs, ...querySnapshot.docs]);
  };

  const getMyAiTrades = async () => {
    setCurrentFilter("ai");
    const db = Firebase.getDBApp();
    const q = query(
      collection(db, "rooms"),
      where("info.roomType", "==", "ai"),
      where("users", "array-contains", uid),
      orderBy("info.startAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const q2 = query(
      collection(db, "aiRooms"),
      where("userId", "==", uid),
      orderBy("info.createdAt", "desc")
    );
    const querySnapshot2 = await getDocs(q2);
    setResults([...querySnapshot.docs, ...querySnapshot2.docs]);
  };

  useEffect(() => {
    if (uid) {
      setCurrentFilter("mine");
      getMyRooms();
    }
  }, [uid]);

  // const handleClick = (key: string, isChecked?: boolean) => {
  //   let updates = filters;
  //   const [name, value] = key.split("|");
  //   if (isChecked) {
  //     const { [key]: _remove, ...rest } = filters;
  //     updates = rest;
  //     setFilters(rest);
  //   } else {
  //     updates = {
  //       ...filters,
  //       [key]: {
  //         name: name,
  //         operator: "==",
  //         value: value,
  //       },
  //     };
  //     setFilters(updates);
  //   }

  //   console.log("UPDATES", updates);
  //   fetchResults(updates);
  // };

  const handleFilter = (value: string) => {
    setCurrentFilter(value);
    const key = `status|${value}`;
    const updates = {
      "blockchain.name|solana": {
        name: "blockchain.name",
        operator: "==",
        value: chain?.name?.toLowerCase() || "solana",
      },
      [key]: {
        name: "status",
        operator: "==",
        value: value,
      },
    };
    fetchResults(updates);
  };

  const getLabel = (val: string) => {
    // if (val === "listing") {
    //   return "Listing Rooms";
    // }
    // if (val === "bidding") {
    //   return "Bidding Rooms";
    // }
    // if (val === "finalize") {
    //   return "Completed Rooms";
    // }
    if (val === "ai") {
      return "My AI Trades";
    }
    return "My Rooms";
  };

  const showButton = rights.hasOwnProperty("create-room");

  return (
    <>
      <Helmet>
        <title>{`NeoSwap | ${getLabel(currentFilter)}`}</title>
        <meta
          name="description"
          content={`Viewing ${getLabel(currentFilter)} on NeoSwap`}
        />
      </Helmet>

      <Box>
        <HStack justifyContent="space-between" className="room-title">
          <HStack>
            <Menu>
              <MenuButton
                fontSize="3xl"
                fontWeight="bold"
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
              >
                {getLabel(currentFilter)}
                <ChevronDownIcon />
              </MenuButton>
              <Portal>
                <MenuList>
                  {/* <MenuItem
                    onClick={() => handleFilter("listing")}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                  >
                    Listing Rooms
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleFilter("bidding")}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                  >
                    Bidding Rooms
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleFilter("finalize")}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                  >
                    Completed Rooms
                  </MenuItem> */}
                  {uid && (
                    <>
                      <MenuItem
                        onClick={getMyRooms}
                        _hover={{ bg: "none" }}
                        _focus={{ bg: "none" }}
                      >
                        My Rooms
                      </MenuItem>
                      <MenuItem
                        onClick={getMyAiTrades}
                        _hover={{ bg: "none" }}
                        _focus={{ bg: "none" }}
                      >
                        My AI Trades
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </Portal>
            </Menu>
            {/* <Select
            variant="unstyled"
            defaultValue="upcoming"
            fontSize="3xl"
            fontWeight="bold"
            icon={<EmptyIcon />}
            onChange={(e) => {
              const key = `status|${e.target.value}`;
              const updates = {
                "blockchain.name|solana": {
                  name: "blockchain.name",
                  operator: "==",
                  value: "solana",
                },
                [key]: {
                  name: "status",
                  operator: "==",
                  value: e.target.value,
                },
              };
              fetchResults(updates);
            }}
          >
            <option value="listing">Listing</option>
            <option value="bidding">Bidding</option>
            <option value="finalize">Completed</option>
          </Select> */}
          </HStack>
          {showButton ? (
            <AuthedButton
              size="lg"
              rightIcon={<FaPlus />}
              as={Link}
              to="/rooms/create"
              bg="#6C60FF"
            >
              Create Room
            </AuthedButton>
          ) : null}
        </HStack>
        <HStack mt="5" alignItems="flex-start" spacing={"5"}>
          {/* <Box width="300px">
          <Accordion defaultIndex={[0]} allowMultiple>
            {filterOptions.map((filterOption) => {
              return (
                <AccordionItem key={filterOption.name}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {filterOption.label}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack pl={6} mt={1} spacing={1}>
                      {filterOption.options.map((option) => {
                        const key = `${filterOption.name}|${option.value}`;
                        const isChecked = key in filters;
                        return (
                          <Checkbox
                            key={key}
                            isChecked={isChecked}
                            onChange={() => handleClick(key, isChecked)}
                          >
                            {option.label}
                          </Checkbox>
                        );
                      })}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box> */}
          <Box flexGrow={1} overflow="hidden" overflowX="auto">
            {isLoading &&
              Array.from(Array(10).keys()).map((ele) => {
                return <Skeleton key={ele} height="45px" mb={5} />;
              })}

            {!isLoading && (
              <>
                <TableContainer>
                  {results.length ? (
                    <NeoTable>
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Status</Th>
                          <Th>Type</Th>
                          <Th>Start Time</Th>
                          <Th>End Time</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {results
                          .map((ele: any) => ({
                            id: ele.id,
                            ...(ele?.data() || {}),
                          }))
                          .map((row: any) => {
                            return (
                              <NeoTr key={row.id}>
                                <Td color="#6C60FF">
                                  <Link
                                    to={
                                      row.info.roomType
                                        ? `/rooms/${row.id}`
                                        : `/ai-trades/${row.id}`
                                    }
                                  >
                                    {row.info.name}
                                  </Link>
                                </Td>
                                <Td>{row.status || row.state}</Td>
                                <Td>
                                  {row?.info?.roomType?.toUpperCase() || "AI"}
                                </Td>
                                <Td>
                                  {DateTime.fromSeconds(
                                    row?.info?.startAt ||
                                      +row?.info?.createdAt.seconds
                                  )
                                    .toUTC()
                                    .startOf("minute")
                                    .toLocaleString(DateTime.DATETIME_SHORT)}
                                </Td>
                                <Td>
                                  {row.info.partyDuration &&
                                  row.info.partyDuration > 0
                                    ? DateTime.fromSeconds(
                                        row.info.startAt +
                                          row.info.partyDuration
                                      )
                                        .toUTC()
                                        .startOf("minute")
                                        .toLocaleString(DateTime.DATETIME_SHORT)
                                    : "--"}
                                </Td>
                              </NeoTr>
                            );
                          })}
                      </Tbody>
                    </NeoTable>
                  ) : (
                    <Box
                      width="full"
                      textAlign="center"
                      bg="#052032"
                      m="auto"
                      mt="5%"
                      alignItems="center"
                      justifyContent="center"
                      pt="9rem"
                      pb="9rem"
                    >
                      There is nothing to Show
                    </Box>
                  )}
                </TableContainer>

                {/* <Box display="flex" justifyContent="center" width="100%">
                <Button mt={5} onClick={more}>
                  Load More
                </Button>
              </Box> */}
              </>
            )}
          </Box>
        </HStack>
      </Box>
    </>
  );
}
