import { Image, Tooltip } from "@chakra-ui/react";
import { Nft } from "../contexts/roomContext";
import PendingImg from "../assets/pending.jpg";

export default function NFTThumb({ nft }: { nft: Nft }) {
  const thumbnail = nft?.thumbnail;
  const name = nft?.name;
  return (
    <Tooltip label={name}>
      <Image
        minWidth="64px"
        boxSize="64px"
        objectFit="cover"
        src={thumbnail}
        fallbackSrc={PendingImg}
        alt={name}
      />
    </Tooltip>
  );
}
