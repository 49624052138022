export default function IconExpand() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M13.9,17.3l-1.1-1.1c-0.5-0.5-1.2-0.5-1.7,0l-7,7v-6c0-0.7-0.5-1.2-1.2-1.2H1.2c-0.7,0-1.2,0.5-1.2,1.2v11.6 c0,0.3,0.1,0.6,0.4,0.8c0.2,0.2,0.5,0.4,0.8,0.4h11.6c0.7,0,1.2-0.5,1.2-1.2v-1.6c0-0.7-0.5-1.2-1.2-1.2h-6l7-7 C14.3,18.6,14.3,17.8,13.9,17.3z" fill="currentColor"/>
      <path d="M29.6,0.3c-0.2-0.2-0.5-0.4-0.8-0.4H17.2c-0.7,0-1.2,0.5-1.2,1.2v1.6c0,0.7,0.5,1.2,1.2,1.2h6l-7,7c-0.5,0.5-0.5,1.2,0,1.7 l1.1,1.1c0.5,0.5,1.2,0.5,1.7,0l7-7v6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.2-0.5,1.2-1.2V1.1C30,0.8,29.9,0.5,29.6,0.3z" fill="currentColor"/>
    </svg>
  );
}
