export const getRoomType = (type: string) => {
  switch (type) {
    case "smartSilentAuction":
      return "Smart Auction";
    case "ordinalsSmartAuction":
      return "Ordinals Smart Auction";
    default:
      return "Smart Swap";
  }
};
