import { memo } from "react";
import { Image, AspectRatio } from "@chakra-ui/react";
import PendingImg from "../assets/pending.jpg";
import { checkCollectionMediaType } from "../utils/index";

interface Props {
  url: string | undefined;
  bid?: number | undefined;
  isLocked?: boolean;
  collectionName?: string;
  assetType?: string;
}

const ImageWrapper = memo(
  ({ url, bid, isLocked, collectionName = "Unknown", assetType }: Props) => {
    if (
      (url && url.endsWith(".mp4")) ||
      checkCollectionMediaType(collectionName) === "video" ||
      assetType === "video/mp4"
    ) {
      return (
        <video
          className="image-wrapper"
          src={url}
          muted={true}
          autoPlay={true}
          loop={true}
          controls={false}
        ></video>
      );
    }

    return (
      <AspectRatio
        ratio={1}
        width="100%"
        className={bid || bid === 0 ? "hasbid" : ""}
      >
        <Image
          className="image-wrapper"
          objectFit="cover"
          src={url || PendingImg}
          fallbackSrc={PendingImg}
          style={{
            opacity: isLocked ? 0.3 : 1,
          }}
          loading="lazy"
          boxSize="150px"
        />
      </AspectRatio>
    );
  }
);

export default ImageWrapper;
