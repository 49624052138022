import {
  Box,
  Flex,
  Heading,
  HStack,
  Progress,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function FinalizeLoading() {
  return (
    <HStack alignItems="flex-start" spacing={10}>
      <Box
        flex={1}
        className="box"
        minH="700px"
        display="flex"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >
        <VStack spacing="10" maxWidth="md">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#ed6cd8"
            size="xl"
          />
          <Heading>Finding Trade...</Heading>
          <Flex alignItems="center" width="100%">
            <Box
              __css={{ "[role=progressbar]": { backgroundColor: "#ed6cd8" } }}
              flexGrow={1}
            >
              <Progress isIndeterminate size="sm" />
            </Box>
          </Flex>
          <Text>
            Please wait, we will reveal the final trade once the contract is deployed. Thank you for your patience!
          </Text>
        </VStack>
      </Box>
    </HStack>
  );
}
