import React from 'react'
import { Flex, ModalBody, Spinner, Text } from '@chakra-ui/react'
import { AnyEventObject, StateFrom } from 'xstate'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'

type BodyProps = {
  state: StateFrom<any>
  uiPrice?: string
  canBuy?: boolean
  send: (event: AnyEventObject) => void
}

const Body: React.FC<BodyProps> = ({ state, uiPrice, canBuy, send }) => {
  let transactions = state.context.txs.concat(state.context.previousTxs)
  let previousTxs = state.context.previousTxs

  return (
    <ModalBody>
      {state.matches('init') ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
          <Text className="buying-modal-header" mt={2}>Loading...</Text>
        </Flex>
      ) : (
        <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" className='buying-modal-flex'>
          <LeftPanel pack={state.context.packDetails} state={state} />
          <RightPanel
            pack={state.context.packDetails}
            state={state}
            uiPrice={uiPrice}
            transactions={transactions}
            previousTxs={previousTxs}
            send={send}
          />
        </Flex>
      )}
    </ModalBody>
  )
}

export default Body
