import { useNavigate } from "react-router-dom";

// ICONS
import IconGradientClock from "../Icons/IconGradientClock";

const DownTime = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-20 mt-6 md:mt-16">
      <div>
        <IconGradientClock />
      </div>
      <div className="text-center">
        <h1 className="text-white text-3xl font-medium leading-10">
          Al-Trades is currently down for maintenance
        </h1>
        <p className="text-white text-xl font-normal">
          Our Al-Trades platform is currently undergoing scheduled maintenance.
          We'll be up and running again shortly. We appreciate your patience.
        </p>
      </div>

      <button
        className="w-64 h-16 px-10 py-6 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-end items-center gap-2 inline-flex text-center text-white text-base font-medium uppercase"
        onClick={() => navigate(`/`)}
      >
        Back to Homepage
      </button>
    </div>
  );
};

export default DownTime;
