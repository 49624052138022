interface Props {
  setCurrentTab: (val: string) => void;
  currentTab: string;
  isSeller: boolean;
  isAdmin: boolean;
  roomType: string;
  isPassing?: boolean;
}

export default function Tabs({
  setCurrentTab,
  currentTab,
  isAdmin,
  isSeller,
  roomType,
  isPassing,
}: Props) {
  return (
    <div className="tabs-buttons">
      <div
        onClick={() => setCurrentTab("details")}
        className={`tab ${currentTab === "details" ? "active" : ""}`}
      >
        Details
      </div>
      {(!isSeller && !isAdmin) || roomType === "smartAuction" ? (
        !isPassing && (
          <div
            onClick={() => setCurrentTab("bidding")}
            className={`tab ${currentTab === "bidding" ? "active" : ""}`}
          >
            Bidding
          </div>
        )
      ) : null}
      {!isPassing && (
        <div
          onClick={() => setCurrentTab("top-bids")}
          className={`tab ${currentTab === "top-bids" ? "active" : ""}`}
        >
          Top Bids <span className="tag hide-sm">new</span>
        </div>
      )}
    </div>
  );
}
