import Offer from "./Offer";
import { RadioGroup } from "@headlessui/react";
import { AiRoom, Blockchain, ChainDetails, Images, Offers as OffersType } from "./types";
import { useChain } from "../../contexts/chainsContext";

interface Props {
  offers: OffersType;
  selectedOffer: string;
  selectOffer: (offerId: string) => void;
  images: Images;
  room: AiRoom;
}

export default function Offers({
  offers,
  selectedOffer,
  selectOffer,
  images,
  room,
}: Props) {
  const {getChainDetails} = useChain()
  const chain = getChainDetails(room.info.blockchain as Blockchain) as ChainDetails

  return (
    <RadioGroup value={selectedOffer} onChange={selectOffer}>
      <div className="w-full">
        <div className="flex flex-col w-full rounded-2xl p-2 gap-4">
          {Object.keys(offers).sort((a: string, b: string) => { return (offers?.[a]?.order || 0) - (offers?.[b]?.order || 0) })
          .map((offerId: string, idx: number) => {
            const offer = offers[offerId];
            return (
              <Offer
                key={offerId}
                offerId={offerId}
                idx={idx}
                images={images}
                offer={offer}
                chain={chain!}
                isSelected={selectedOffer === offerId}
                tokenName={room.info.token? room.info.token.toUpperCase() : ""}
              />
            );
          })}
        </div>
      </div>
    </RadioGroup>
  );
}