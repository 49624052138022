import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import { Lock } from "../../contexts/roomContext";
import { LockIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRoom } from "../../services/room.service";

interface Props {
  roomId: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function LockedOverlay({ roomId, onClose, isOpen }: Props) {
  const [roomName, setRoomName] = useState<string>("");
  useEffect(() => {
    const getRoomName = async () => {
      try {
        const res = await getRoom(roomId);
        const data = res.data();
        if (!data) return;
        setRoomName(data.info.name);
      } catch (error: any) {
        console.log(error);
      }
    };
    if (!roomId) return;
    getRoomName();
  }, [roomId]);

  return (
    <Modal
      onClose={onClose}
      isCentered
      isOpen={isOpen}
      scrollBehavior="inside"
      closeOnOverlayClick
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="#052032">
        <ModalCloseButton />
        <ModalBody>
          <div className="locked-modal">
            <LockIcon />
            <p>
              This Item is locked in the following room and cannot be listed:
            </p>
            <h3>{roomName}</h3>

            <hr />

            <Button onClick={onClose} mr={5} className="button border">
              Close
            </Button>

            <Link
              target="_blank"
              to={`/rooms/${roomId}`}
              className="button"
            >
              Open Room
            </Link>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
