import ReactGA from 'react-ga4'

if (!process.env.REACT_APP_MEASUREMENT_ID) throw new Error('REACT_APP_MEASUREMENT_ID is not set')

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID!)

class UserAnalyticsClient {
  packsLaunch(partner: string) {
    ReactGA.event('packs_launch', {
      partner,
    })
  }

  packsSelectPackGroup(partner: string, packGroupId: string) {
    ReactGA.event('packs_select_pack_group', {
      partner,
      pack_group_id: packGroupId,
    })
  }

  packsSelectPack(partner: string, packId: string) {
    ReactGA.event('packs_select_pack', {
      partner,
      pack_id: packId,
    })
  }

  packsBuyPackInitialized(partner: string, packId: string) {
    ReactGA.event('packs_buy_pack_initialized', {
      partner,
      pack_id: packId,
    })
  }

  packsBuyPackSuccess(partner: string, packId: string) {
    ReactGA.event('packs_buy_pack_success', {
      partner,
      pack_id: packId,
    })
  }

  packsBuyPackRetry(partner: string, packId: string) {
    ReactGA.event('packs_buy_pack_retry', {
      partner,
      pack_id: packId,
    })
  }

  packsBuyPackError(partner: string, packId: string, error: string) {
    ReactGA.event('packs_buy_pack_error', {
      partner,
      pack_id: packId,
      error,
    })
  }
}

export const userAnalyticsClient = new UserAnalyticsClient()
