import { memo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { HiEmojiSad, HiEmojiHappy } from "react-icons/hi";
import { RiEmotionSadFill } from "react-icons/ri";
import NeoImage from "./Img";
import { addAIRating } from "../../services/room.service";

import {
  BsFillEmojiNeutralFill,
  BsFillEmojiHeartEyesFill,
} from "react-icons/bs";
import { AiRoom, Blockchain, ChainDetails, Images, Img, Offer } from "./types";
import { roundValue } from "./utils";
import { useChain } from "../../contexts/chainsContext";
import { useLocation } from "react-router-dom";

interface FinalizedProps {
  roomId: string;
  uid: string;
  offer: Offer;
  images: Images;
  room: AiRoom;
  status: string;
}

function Finalized({
  roomId,
  uid,
  offer,
  images,
  room,
  status,
}: FinalizedProps) {
  const location = useLocation()
  const { getChainDetails } = useChain();
  const chain = getChainDetails(
    room.info.blockchain as Blockchain
  ) as ChainDetails;
  const tokenValue = roundValue(offer?.token, chain?.decimals, chain?.truncate);

  const get = offer?.get?.map((id: string) => {
    const img = images[id];
    if (!img) return;
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
    };
  }) as Img[];

  const [hasRated, setHasRated] = useState(false);
  const toast = useToast();

  const handleRating = async (value: number) => {
    if (hasRated) {
      toast({
        title: "Already rated",
        description: "You have already provided your rating.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await addAIRating(roomId, uid, value);
      // console.log("Rating added successfully!");
      toast({
        title: "Rating sent, thank you!",
        duration: 3000,
        isClosable: true,
      });
      setHasRated(true);
    } catch (error) {
      console.error("Error adding rating:", error);
      toast({
        title: "Error adding rating!",
        //description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNav = () => {
    window.open(
      room.swapUrl,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const returnTitle = () => {
    if (status !== "swap-finalized") {
      return (
        <div className="mb-14 mt-10">
          <h1 className="text-[#FF8A00] text-5xl font-medium">
            Transaction Pending
          </h1>
          <p className="text-white text-xl font-medium leading-loose">
            Your signed transaction is pending. This page will automatically
            update once it is complete!
          </p>
        </div>
      );
    }
    return (
      <div className="mb-14 mt-10">
        <h1 className="text-green-400 text-5xl font-medium">Success!</h1>
      </div>
    );
  };

  const handleShare = () => {
    // Opens a pop-up with twitter sharing dialog
    var shareURL = "https://twitter.com/intent/tweet?"; //url base
    //params
    var params: { [key: string]: string } = {
      url: location.pathname.includes("ai-trades")
        ? "https://neoswap.xyz/star-atlas"
        : "https://neoswap.xyz/pay-with-neoswap",
      text: location.pathname.includes("ai-trades")
        ? "I just got a @staratlas starter pack using @neoswap_ai.  Get yours here: "
        : "I just purchased an Item using Pay with @neoswap_ai ! Use your Items to pay for Items.",
    };
    for (var prop in params)
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    window.open(
      shareURL,
      "",
      "left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
  };

  console.log(room);

  return (
    <div>
      {returnTitle()}
      {/* buttons and rating */}
      <div className="flex gap-6 items-center mb-20 flex-col lg:flex-row">
        <div className="flex gap-6 items-center flex-col sm:flex-row">
          <button
            onClick={handleShare}
            className="w-56 h-16 px-6 py-2.5 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md justify-center items-center gap-2.5 inline-flex text-center text-white text-base font-medium uppercase"
          >
            <svg
              width="30"
              height="24"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.7477 2.84375C28.6602 3.325 27.4977 3.65 26.2727 3.8C27.5227 3.05 28.4852 1.8625 28.9352 0.45C27.7664 1.14375 26.4727 1.65 25.0914 1.91875C23.9852 0.7375 22.4102 0 20.6727 0C17.3289 0 14.6227 2.7125 14.6227 6.05625C14.6227 6.53125 14.6727 6.99375 14.7789 7.4375C9.74766 7.1875 5.28516 4.775 2.30391 1.10625C1.78516 2 1.48516 3.04375 1.48516 4.15C1.48516 6.25 2.56016 8.10625 4.18516 9.19375C3.18516 9.16875 2.24766 8.89375 1.43516 8.4375V8.5125C1.43516 11.45 3.52266 13.8938 6.29141 14.45C5.78516 14.5875 5.24766 14.6625 4.69766 14.6625C4.31016 14.6625 3.92891 14.625 3.56016 14.55C4.32891 16.9562 6.56641 18.7063 9.21641 18.7563C7.14766 20.3813 4.53516 21.35 1.69766 21.35C1.21016 21.35 0.728906 21.3188 0.253906 21.2625C2.92266 23 6.10391 24 9.51641 24C20.6602 24 26.7477 14.7688 26.7477 6.7625C26.7477 6.5 26.7414 6.2375 26.7289 5.98125C27.9102 5.125 28.9352 4.0625 29.7477 2.84375Z"
                fill="white"
              />
            </svg>
            Tweet
          </button>
          {room.swapUrl && (
            <button
              onClick={handleNav}
              className="w-72 h-16 py-2.5 rounded-md border border-sky-500 justify-center items-center gap-2.5 inline-flex text-center text-white text-base font-medium uppercase"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999999 9L1 17"
                  stroke="#F8F8F8"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 5L9 17"
                  stroke="#F8F8F8"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 0.999999L17 17"
                  stroke="#F8F8F8"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              View Transactions
            </button>
          )}
        </div>
        {!hasRated && (
          <div className="flex gap-4 items-center flex-col sm:flex-row">
            <p className="text-stone-50 text-base font-normal leading-none">
              Please rate this trade
            </p>
            <div className="flex gap-4 items-center">
              <RiEmotionSadFill
                className="rating-icon red"
                color="red"
                size={40}
                onClick={() => handleRating(1)}
              />

              <HiEmojiSad
                className="rating-icon brown"
                color="brown"
                size={40}
                onClick={() => handleRating(2)}
              />
              <BsFillEmojiNeutralFill
                className="rating-icon yellow"
                color="yellow"
                size={32}
                onClick={() => handleRating(3)}
              />
              <HiEmojiHappy
                className="rating-icon green"
                color="green"
                size={40}
                onClick={() => handleRating(4)}
              />
              <BsFillEmojiHeartEyesFill
                className="rating-icon green"
                color="green"
                size={32}
                onClick={() => handleRating(5)}
              />
            </div>
          </div>
        )}
      </div>

      {/* get Items */}
      <div className="flex gap-6 flex-wrap justify-between lg:justify-start">
        {get?.map(({ id, src, alt, name, collectionName, floorPrice }, idx) => {
          return (
            <div
              key={`get_${idx}_${id}_panel`}
              className="flex-col justify-start items-start gap-2 inline-flex w-[45%] md:w-[30%] lg:w-[23%]"
            >
              <div className="bg-white rounded-2xl border-b-4 border-green-500 justify-center items-center inline-flex overflow-hidden">
                <NeoImage className="w-full" src={src} alt={alt} quantity={offer.getQuantity?.[id]} />
              </div>
              <div className="px-3 py-[10.58px] flex-col justify-start items-start gap-2 flex w-full">
                <div className="self-stretch text-white text-small font-bold leading-tight">
                  {name}
                </div>
                <div className="self-stretch justify-start items-start gap-[6.61px] inline-flex">
                  <div className="grow shrink basis-0 text-white text-xs font-normal">
                    {collectionName}
                  </div>
                  <div className="text-right text-green-500 text-xs font-normal">
                    {floorPrice}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(Finalized)