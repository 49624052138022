import { useEffect, useState } from "react";
import {
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  useToast,
  useDisclosure,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import IconPurpleEye from "../components/Icons/IconPurpleEye";
import { Blockchain } from "./AiTradesV2/types";

import { useRoom } from "../contexts/roomContext";
import { useChain } from "../contexts/chainsContext";
import { setBudget } from "../services/room.service";

import { useAppContext } from "../contexts/appContext";
import { roundValue } from "../utils";
import { useUA } from "../contexts/userTracking";

interface Props {
  roomId: string;
  uid: string;
  budget: number;
  units: number;
  abbr: string;
}

export default function BudgetCard({
  roomId,
  uid,
  budget = 0,
  units,
  abbr,
}: Props) {
  const { gaSetBudget } = useUA();
  const toast = useToast();
  const { balance } = useAppContext();
  const { decimals, truncate, room } = useRoom();
  const { getChainDetails } = useChain()
  const tokenChain = getChainDetails(room.blockchain.name as Blockchain, room.info.token)
  const [value, setValue] = useState<number | string>(
    roundValue(budget / units, truncate, "down")
  );

  let margin = (tokenChain.budgetMargin !== null && tokenChain.budgetMargin !== undefined)
    ? tokenChain.budgetMargin / 10 ** decimals
    : 0.05;

  const maxBudget =
    !balance || balance < margin
      ? 0
      : roundValue(Number(balance - margin || 0), truncate, "down");

  useEffect(() => {
    if (units && units > 0 && budget && budget > 0) {
      setValue(roundValue(budget / units, truncate, "down"));
    }
  }, [budget, units]);

  const handleChange = (strVal: string) => {
    setValue(strVal);
  };

  const handleMax = () => {
    let max = maxBudget;
    if (max === 0)
      return toast({
        title: "Insufficient balance",
        description: `Sorry! You need a little more ${abbr}.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    setValue(max);
    max = max * units;
    setBudget(roomId, uid, { budget: max });
    gaSetBudget(roomId, max);
    toast({
      title: "Spending Limit Updated",
      description: "Spending Limit Set Successfully",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleBlur = async () => {
    try {
      let val = Number(value);
      if (!val) return setBudget(roomId, uid, { budget: 0 });
      if (val > maxBudget)
        throw new Error("Spending Limit is above your Max Spending Limit");
      if (val < 0) throw new Error("Spending Limit cannot be negative");
      val = roundValue(val, truncate, "down") * units;
      await setBudget(roomId, uid, { budget: val });
      gaSetBudget(roomId, val);
      toast({
        title: "Spending Limit Updated",
        description: "Spending Limit Set Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      val = roundValue(val / units, truncate, "down");
      setValue(val);
    } catch (e: any) {
      if (e.message) {
        toast({
          title: "Error Updating Spending Limit",
          description: e.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="spending-limit-wrapper">
      <Flex alignItems={"center"} gap={"8px"} mr={"12px"}>
        <Text as={"h2"} className="  w-[169.61px] h-[18px] text-center text-white text-sm font-bold ">
          Spending limit
        </Text>
        <Box onClick={onOpen} cursor={"pointer"}>
          <IconPurpleEye />
        </Box>
      </Flex>
      <div className="spending-limit">
        <InputGroup className="set-limit-form">
          <NumberInput
            placeholder="Set Spending Limit"
            min={0}
            step={0.01}
            value={value}
            _dark={{
              backgroundColor: "transparent",
              opacity: 1,
            }}
            onChange={handleChange}
            width="200px"
            max={maxBudget}
            onBlur={handleBlur}
            keepWithinRange
            clampValueOnBlur
            allowMouseWheel={false}
            lang="en"
          >
            <Box position="absolute" right="30px" top="4px">
              {abbr}
            </Box>
            <NumberInputField className="spending-limit-input" pr="70px" />
            <NumberInputStepper>
              <NumberIncrementStepper className="stepper-controls" />
              <NumberDecrementStepper className="stepper-controls" />
            </NumberInputStepper>
          </NumberInput>
          {/* <InputRightAddon bg="#051a28">{abbr}</InputRightAddon> */}
        </InputGroup>
        <div className="btndiv">
          <Button
            variant="unstyled"
            onClick={handleMax}
            className="set-max-btn rounded-tr-md rounded-br-md"
          >
            Max
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
            <ModalOverlay />
            <ModalContent>
              {/* <ModalHeader>Modal Title</ModalHeader> */}

              <ModalBody>
                <div className="modal">
                  <h1 className="head">
                    The spending limit prevents overspending. Increasing your
                    spending limit improves your chances of getting a trade.
                  </h1>

                  <p className="body">
                    NeoSwap is not barter - even a little extra currency makes
                    it easier for you to get a trade. To increase the likelihood
                    you'll get a trade, we recommend you set a spending limit at
                    least equal to your smallest bid.
                  </p>

                  <p className="body">
                    A fee of {margin} {abbr} is reserved from your balance to
                    cover the signing fees.
                  </p>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  );
}
