import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Nft } from "../contexts/roomContext";

interface Props {
  nft: Nft;
  isOpen: boolean;
  onClose: () => void;
}

const MYNFTModal: React.FC<Props> = ({ nft, isOpen, onClose }) => {
  const { name, thumbnail, description } = nft;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{name}</ModalHeader>
        <ModalBody>
          <Image
            src={thumbnail}
            alt={name}
            draggable="false"
            //fallbackSrc={PendingImg}
            borderRadius={useBreakpointValue({ base: "md", md: "xl" })}
          />
          <Text mt={4}>{description}</Text>
          {/* <Text mt={4}>
            Creator: {creator} | Owner: {owner}
          </Text> */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MYNFTModal;
