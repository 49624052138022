import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Box,
  Text,
  useToast
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import { useRoom } from "../contexts/roomContext";
import { setBudget } from "../services/room.service";
import IconPurpleEye from "./Icons/IconPurpleEye";

interface Props {
}

export default function NumItemsLimit() {
    const toast = useToast();

  const { uid } = useAppContext();
  const { room, availableForBid, budgets, roomId, passed } = useRoom();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (budgets?.numItemsLimitForBids && budgets.numItemsLimitForBids !== 0 && budgets.numItemsLimitForBids !== -1) {
      setInputValue(budgets.numItemsLimitForBids.toString());
    } else {
      setInputValue((availableForBid?.length - passed?.length).toString() || '1');
    }
  }, [budgets?.numItemsLimitForBids, availableForBid, passed]);

  const handleChange = (_val: string) => {
    setInputValue(_val);
  };

  const handleMax = () => {
    let v = availableForBid?.length - passed.length || 0;
    if (Number.isNaN(v) || v == 0)
      return toast({
        title: "Ooops",
        description: `No items to bid yet.`,
        status: "warning",
        duration: 9000,
        isClosable: true,
      });

    setBudget(roomId, uid!, { numItemsLimitForBids: v });
  };

  // const value = budgets?.numItemsLimitForBids && budgets?.numItemsLimitForBids !=0 &&  budgets?.numItemsLimitForBids != -1 ? budgets?.numItemsLimitForBids : availableForBid?.length || 1 ;

  const handleBlur = async () => {
    let val = Number(inputValue);
    if (isNaN(val) || val < 0 || inputValue === "") {
      toast({
        title: "Invalid Input",
        description: "Please enter a valid number.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    
    await setBudget(roomId, uid!, { numItemsLimitForBids: val });
  };

  return (
    <div className="spending-limit-wrapper">
      <Flex alignItems={"center"} gap={"8px"} mr ={"12px"}>
        <Text as={"h2"} className="  w-[169.61px] h-[18px] text-center text-white text-sm font-bold ">
          Item Limit
        </Text>
        <Box  cursor={"pointer"}>
          <IconPurpleEye />
        </Box>
      </Flex>
      <div className="spending-limit">
        <InputGroup className="set-limit-form">
          <NumberInput
            placeholder="Set limit"
            value={inputValue}
            min={0}
            step={1}
             _dark={{
              backgroundColor: "transparent",
              opacity: 1,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            width="200px"
            keepWithinRange
            clampValueOnBlur
            allowMouseWheel={false}
            lang="en"
          >
            <Box position="absolute" right="30px" top="4px">
              Items
            </Box>
            <NumberInputField className="spending-limit-input" pr="70px" />
            <NumberInputStepper>
              <NumberIncrementStepper className="stepper-controls" />
              <NumberDecrementStepper className="stepper-controls" />
            </NumberInputStepper>
          </NumberInput>
          {/* <InputRightAddon bg="#051a28">{abbr}</InputRightAddon> */}
        </InputGroup>
        <div className="btndiv">
          <Button
            variant="unstyled"
             className="set-max-btn rounded-tr-md rounded-br-md"
             onClick={handleMax}
          >
            Max
          </Button>
 
        </div>
      </div>
    </div>



    // <div className="spending-limit-wrapper">
   
    //   <FormControl>
    //     <HStack className="limit-toggle">
         
    //       <FormLabel htmlFor="email-alerts"  className="w-[169.61px] h-[18px] text-center text-white text-sm-important font-bold">Limit on number of items to win</FormLabel>
    //       <Switch
    //         isChecked={value > -1}
    //         onChange={() => (value > -1 ? handleChange(-1) : handleChange(1))}
    //       />
    //     </HStack>
    //   </FormControl>
    //   {value > -1 && (
        
    //     <div className="spending-limit nft-limit">
    //       <InputGroup size="md" className="set-limit-form custom-number-input">
    //         <NumberInput
    //           placeholder="Set Spending Limit"
    //           min={0}
    //           step={1}
    //           value={value > -1 ? value : ""}
    //           onChange={(_val, val) => handleChange(val)}
    //           width="100px"
    //           variant="primary"
    //           max={max}
    //           keepWithinRange
    //           clampValueOnBlur
    //           allowMouseWheel={false}
    //           lang="en"
    //         >
        
    //           <NumberInputField />
    //           <NumberInputStepper>
    //             <NumberIncrementStepper className="borderless-conrols" />
    //             <NumberDecrementStepper className="borderless-conrols" />
    //           </NumberInputStepper>
    //         </NumberInput>
    //         <InputRightAddon
    //           bg="transparent"
    //           className="custom-number-input-right-addon"
    //         >
    //           {isOrdinals ? "Ordinal" : "Item"}(s)
    //         </InputRightAddon>
    //       </InputGroup>
    //     </div>
    //   )}
    // </div>
  );
}


