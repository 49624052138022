import { ButtonProps, ComponentWithAs } from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";

const isAuthorized =
  (
    WrappedComponent: ComponentWithAs<"button", ButtonProps>,
    checks: string[] | undefined
  ) =>
  (props: any) => {
    const { onIsDisabled } = props;
    const { rights } = useAppContext();
    const isAuthorized = checks
      ? checks?.every((check) => rights[check])
      : true;
    if (!isAuthorized) {
      return (
        <WrappedComponent
          {...props}
          to={undefined}
          isDisabled={!onIsDisabled}
          onClick={onIsDisabled}
        />
      );
    }
    return <WrappedComponent {...props} />;
  };

export default isAuthorized;
