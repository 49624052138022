import React, { createContext, useContext, useMemo, FC } from 'react';
import { useWallet, WalletProvider, ConnectionProvider, WalletContextState } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PublicKey } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl, Cluster } from '@solana/web3.js';
import {
  CoinbaseWalletAdapter,
  // Uncomment other wallets as needed
  // PhantomWalletAdapter,
  // SolletWalletAdapter,
  // SolletExtensionWalletAdapter,
  // LedgerWalletAdapter,
  // SolflareWalletAdapter,
  // TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

require('@solana/wallet-adapter-react-ui/styles.css');

// Define WalletName type
type WalletName = string & { __brand__: "WalletName" };

export interface IWalletPassThrough {
  publicKey: PublicKey | null;
  wallets: WalletContextState['wallets'];
  wallet: WalletContextState['wallet'];
  connect: () => Promise<void>;
  select: (walletName: WalletName | null) => void;
  connecting: boolean;
  connected: boolean;
  disconnect: () => Promise<void>;
  signAllTransactions: WalletContextState['signAllTransactions'];
}

const initialPassThrough: IWalletPassThrough = {
  publicKey: null,
  wallets: [],
  wallet: null,
  connect: async () => { },
  select: () => { },
  connecting: false,
  connected: false,
  disconnect: async () => { },
  signAllTransactions: async () => [],
};

const WalletPassthroughContext = createContext<IWalletPassThrough>(initialPassThrough);

export const useWalletPassThrough = (): IWalletPassThrough => {
  return useContext(WalletPassthroughContext);
};

interface WalletPassthroughProviderProps {
  children: React.ReactNode;
  passThroughWallet?: WalletContextState;
}

const InnerWalletPassthroughProvider: FC<WalletPassthroughProviderProps> = ({ children, passThroughWallet }) => {
  const wallet = useWallet();

  const walletPassthrough = useMemo(() => {
    if (passThroughWallet) {
      return passThroughWallet;
    }
    return wallet;
  }, [wallet, passThroughWallet]);

  return (
    <WalletPassthroughContext.Provider value={walletPassthrough}>
      {children}
    </WalletPassthroughContext.Provider>
  );
};

const WalletPassthroughProvider: FC<WalletPassthroughProviderProps> = ({ children, passThroughWallet }) => {
  const network: Cluster = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => {
    const url = clusterApiUrl(network);
    return url;
  }, [network]);

  const wallets = useMemo(
    () => [
      new CoinbaseWalletAdapter(),
      // new PhantomWalletAdapter(),
      // new SolletWalletAdapter({ network }),
      // new SolletExtensionWalletAdapter({ network }),
      // new LedgerWalletAdapter(),
      // new SolflareWalletAdapter({ network }),
      // new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <InnerWalletPassthroughProvider passThroughWallet={passThroughWallet}>
            {children}
          </InnerWalletPassthroughProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default WalletPassthroughProvider;
