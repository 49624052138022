import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  deleteField,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
  arrayUnion,
  getDocs,
  query,
  where,
  PartialWithFieldValue,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Firebase from "./firebase";
import {  Budget } from "../contexts/roomContext";

export const create = async (data: any, chain: string) => {
  const roomsRef = collection(Firebase.getDBApp(), "rooms");
  const timestampSeconds = Math.floor(new Date().getTime() / 1000);
  return addDoc(roomsRef, {
    createdAt: serverTimestamp(),
    info: data,
    status: "listing",
    blockchain: {
      name: chain,
    },
    users: [data.adminId],
    usersJoinedDate: {
      [data.adminId]: timestampSeconds,
    },
  });
};

export const timeInfoFromServer = async (roomId: string) => {
  const getTime = httpsCallable(Firebase.getFnsApp(), "room-getTime");
  const result = await getTime({ roomId });
  const { serverTime, secondsToEnd, secondsToPreview } = result.data as {
    serverTime: number;
    secondsToEnd: number;
    secondsToPreview: number;
  };

  return { serverTime, secondsToEnd, secondsToPreview };
};

export const addListing = async (
  roomId: string,
  uid: string,
  nftId: string,
  data: {
    reservePrice: number;
    listingPrice?: number;
    startingBid?: number | null;
    userDescription?: string | null;
  }
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "listings", uid);
  data.reservePrice = Math.round(data.reservePrice);
  if (data.startingBid) data.startingBid = Math.round(data.startingBid);
  if (data.listingPrice) data.listingPrice = Math.round(data.listingPrice);
  return setDoc(ref, { [nftId]: data }, { merge: true });
};

export const removeListing = async (
  roomId: string,
  uid: string,
  nftId: string
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "listings", uid);
  return setDoc(ref, { [nftId]: deleteField() }, { merge: true });
};

export const removeAllListings = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "listings", uid);
  return deleteDoc(ref);
};

export const saveProgress = async (
  roomId: string,
  step: string,
  uid: string,
  isFinished: boolean
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "progress", step);
  return setDoc(ref, { [uid]: isFinished }, { merge: true });
};

export const updateRoom = async (roomId: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId);
  return updateDoc(ref, data, { merge: true });
};

export const addUserToRoom = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId);
  return await updateDoc(ref, {
    users: arrayUnion(uid),
  });
};

export const addBid = async (
  roomId: string,
  uid: string,
  nftId: string,
  bidPrice: number
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "bids", uid);
  const timestamp = Math.floor(new Date().getTime() / 1000);
  return setDoc(ref, { [nftId]: { bidPrice, timestamp } }, { merge: true });
};

export const cancelBid = async (roomId: string, uid: string, nftId: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "bids", uid);
  return setDoc(ref, { [nftId]: deleteField() }, { merge: true });
};

export const removeAllBids = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "bids", uid);
  return deleteDoc(ref);
};

export const setBudget = (
  roomId: string,
  uid: string,
  budget: Partial<Budget>
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "budget", uid);
  return setDoc(ref, budget, { merge: true });
};

export const setWalletTicket= (
  uid: string,
  address: string
) => {
  const ref = doc(Firebase.getDBApp(), "ticketCampaign", "tensorian", "address", uid);
  return setDoc(ref, {address}, { merge: true });
};

export const solveTrade = async (roomId: string) => {
  const solver = httpsCallable(Firebase.getFnsApp(), "room-solveTrade");
  return await solver({ roomId });
};

export const initializeSwap = async (roomId: string) => {
  var initializeSwap = httpsCallable(Firebase.getFnsApp(), "swap-initialize");
  return await initializeSwap({ roomId });
};

export const cancelTrades = async (roomId: string) => {
  var cancelTrades = httpsCallable(Firebase.getFnsApp(), "swap-cancelSwaps");
  return await cancelTrades({ roomId });
};

export const getSwapData = async (chain: string, swapId: string) => {
  const ref = doc(Firebase.getDBApp(), "blockchain", chain, "swaps", swapId);
  return getDoc(ref);
};

export const addRating = async (
  roomId: string,
  uid: string,
  rating: number
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "ratings", uid);
  return setDoc(ref, { rating }, { merge: true });
};

export const getRating = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "ratings", uid);
  const ratingSnapshot = await getDoc(ref);

  if (ratingSnapshot.exists()) {
    return ratingSnapshot.data();
  } else {
    return null;
  }
};

export const addFeedback = async (
  roomId: string,
  uid: string,
  feedback: any
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "feedback", uid);
  return setDoc(ref, { feedback }, { merge: true });
};

export const getFeedback = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "feedback", uid);
  const feedbackSnapshot = await getDoc(ref);

  if (feedbackSnapshot.exists()) {
    return feedbackSnapshot.data();
  } else {
    return null;
  }
};

export const updateLockedItem = async (
  userId: string,
  itemId: string,
  quantity: number,
  reason: string
) => {
  const updateKey = `app_${reason}`;

  const ref = doc(Firebase.getDBApp(), "users", userId, "itemsLocked", itemId);

  return setDoc(
    ref,
    {
      [updateKey]: {
        itemId,
        quantity,
        reason,
      },
    },
    { merge: true }
  );
};

export const unlockLockedItem = async (
  userId: string,
  itemId: string,
  reason = "user"
) => {
  const updateKey = `app_${reason}`;
  const ref = doc(Firebase.getDBApp(), "users", userId, "itemsLocked", itemId);
  const itemDoc = await getDoc(ref);

  if (itemDoc.exists()) {
    const lockedData = itemDoc.data();

    // Remove the specific lock based on the reason
    if (lockedData && lockedData[updateKey]) {
      await updateDoc(ref, {
        [updateKey]: deleteField(),
      });
    }

    // Check if the document is now empty
    const updatedDoc = await getDoc(ref);
    if (Object.keys(updatedDoc.data() || {}).length === 0) {
      await deleteDoc(ref);
    }
  }
};

export const getLockStatus = async (userId: string, itemId: string) => {
  const ref = doc(Firebase.getDBApp(), "users", userId, "itemsLocked", itemId);
  const lockSnapshot = await getDoc(ref);

  if (lockSnapshot.exists()) {
    return lockSnapshot.data();
  } else {
    return null;
  }
};

export const getAdmin = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), "profileRestricted", uid);
  const adminSnapshot = await getDoc(ref);

  if (adminSnapshot.exists()) {
    return adminSnapshot.data();
  } else {
    return null;
  }
};

export const saveTransactionInfo = async (data: {
  txId: string[];
  blockchain: string;
  functionName: string;
  userId: string;
  roomId: string;
  swapId: string;
}) => {
  const ref = doc(
    Firebase.getDBApp(),
    "blockchain",
    data.blockchain,
    "userTransactions",
    data.txId[data.txId.length - 1]
  );
  return setDoc(ref, data, { merge: true });
};

export const getConnected = async (roomId: string) => {
  const ref = doc(
    Firebase.getDBApp(),
    "rooms",
    roomId,
    "progress",
    "connected"
  );
  return getDoc(ref);
};

export const cancelSwap = async (swapId: string, fnName: string) => {
  var cancelAndClose = httpsCallable(Firebase.getFnsApp(), fnName);
  return await cancelAndClose({ swapId });
};

export const startBidding = async (roomId: string) => {
  var startBidding = httpsCallable(Firebase.getFnsApp(), "room-startBidding");
  return await startBidding({ roomId });
};

export const getUserRoomData = async (roomId: string) => {
  var userRoomData = httpsCallable(
    Firebase.getFnsApp(),
    "admin-getUserRoomData"
  );
  return await userRoomData({ roomId });
};

export const getPostConditions = async (swapId: string) => {
  const postConditions = httpsCallable(
    Firebase.getFnsApp(),
    "swap-getStacksContractCalls"
  );
  return await postConditions({ swapId });
};
export const getPolygonDepositInstruction = async (
  polygonSc: string,
  poolId: number,
  userAddress: string
) => {
  const polygonDepositInstruction = httpsCallable(
    Firebase.getFnsApp(),
    "swap-getPolygonDepositInstructions"
  );
  return await polygonDepositInstruction({ polygonSc, poolId, userAddress });
};

export const getContractCalls = async (userId: string, swapId: string) => {
  const contractCalls = httpsCallable(
    Firebase.getFnsApp(),
    "swap-getContractCalls"
  );
  return await contractCalls({
    userId,
    swapId,
  });
};
export const joinRoom = async (roomId: string, join?: boolean) => {
  let args = { roomId } as { roomId: string; join?: boolean };
  if (join) args = { ...args, join };
  const handleJoin = httpsCallable(Firebase.getFnsApp(), "room-joinRoom");
  return await handleJoin(args);
};

export const getPreferredItemsInRoom = async (
  roomId: string,
  userIds: string[]
) => {
  const ids = [...userIds];
  const ref = collection(Firebase.getDBApp(), "rooms", roomId!, "listings");
  const batches = [];

  while (ids.length) {
    const batch = ids.splice(0, 10);
    batches.push(
      getDocs(query(ref, where("__name__", "in", [...batch]))).then((res) =>
        res.docs.map((doc) => doc.data())
      )
    );
  }

  return Promise.all(batches).then((nfts) => nfts.flat());
};

export const getRoom = async (roomId: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId);
  return getDoc(ref);
};

export const getAiRoom = async (roomId: string) => {
  const ref = doc(Firebase.getDBApp(), "aiRooms", roomId);
  return getDoc(ref);
};

export const setViewed = async (
  roomId: string,
  uid: string,
  viewed: { [key: string]: boolean }
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "viewed", uid);
  return setDoc(ref, viewed, { merge: true });
};

export const setPassed = async (
  roomId: string,
  uid: string,
  passed: { [key: string]: boolean },
  merge?: boolean
) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "passed", uid);
  return setDoc(ref, passed, merge ? { merge: true } : { merge: false });
};

export const getViewed = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "viewed", uid);
  return getDoc(ref);
};

export const getPassed = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "passed", uid);
  return getDoc(ref);
};

export const removeDeposit = async (roomId: string, uid: string) => {
  const ref = doc(Firebase.getDBApp(), "rooms", roomId, "progress", "deposit");
  return setDoc(ref, { [uid]: deleteField() }, { merge: true });
};

export const getSwaps = async ({
  roomId,
  aiRoomId,
  userId,
}: {
  roomId?: string;
  aiRoomId?: string;
  userId?: string;
}) => {
  var getSwaps = httpsCallable(Firebase.getFnsApp(), "swap-getContractCalls");
  const body: any = { userId };
  if (aiRoomId) {
    body.aiRoomId = aiRoomId;
  }

  if (roomId) {
    body.roomId = roomId;
  }

  return await getSwaps(body);
};

export const addTxId = async (blockchain: string, txId: string, trx: any) => {
  // For each executed transaction, add a doc to blockchain/{blockchain}/transactions collection. It should be:
  const ref = doc(
    Firebase.getDBApp(),
    "blockchain",
    blockchain,
    "transactions",
    txId
  );
  return setDoc(ref, trx, { merge: true });
};

export const updateSwapField = async (
  path: string,
  key: string,
  value: any
) => {
  const ref = doc(Firebase.getDBApp(), path);
  return setDoc(ref, { [key]: value }, { merge: true });
};

export const getRoomSwapState = async (roomId: string, userId?: string) => {
  const ref = httpsCallable(Firebase.getFnsApp(), "swap-getRoomSwapsState");
  return await ref({ roomId, userId });
};

export const addEvent = (data: any) => {
  const roomsRef = collection(Firebase.getDBApp(), "rooms");
  return addDoc(roomsRef, { ...data, createdAt: serverTimestamp() });
};

export const runOtc = async (roomId: string) => {
  const ref = httpsCallable(Firebase.getFnsApp(), "room-runOtc");
  return await ref({ roomId });
};

export const runAi = async (roomId: string) => {
  const ref = httpsCallable(Firebase.getFnsApp(), "room-runAi", {
    timeout: 540000,
  });
  return await ref({ roomId });
};
function field(
  itemId: string
): string | import("@firebase/firestore").FieldPath {
  throw new Error("Function not implemented.");
}

export type GenerateAiRoomArgs = {
  userId: string;
  campaign?: string;
  token? : string
};

export type GenerateAiRoomRes = {
  success: boolean;
  aiRoomId: string;
};

/**
 * Generates an AI room.
 * @param args The arguments for the function.
 * @returns The generated AI room.
 */
export async function generateAiRoom(
  args: GenerateAiRoomArgs
): Promise<GenerateAiRoomRes> {
  const fns = Firebase.getFnsApp();
  const { data } = await httpsCallable<GenerateAiRoomArgs, GenerateAiRoomRes>(
    fns,
    "ai-generateAiRoom",
    { timeout: 540000 }
  )(args);
  return data;
}

export const addAIRating = async (
  roomId: string,
  uid: string,
  rating: number
) => {
  const ref = doc(Firebase.getDBApp(), "aiRooms", roomId, "ratings", uid);
  return setDoc(ref, { rating }, { merge: true });
};

export const generateBuyDown = async (args: {
  userId: string;
  nftUrl: string;
}) => {
  const fns = Firebase.getFnsApp();
  const { data } = await httpsCallable<
    { userId: string; nftUrl: string },
    GenerateAiRoomRes
  >(fns, "ai-generateNsPayRoom", { timeout: 540000 })(args);
  return data;
};

export const addCollectionSwapOffer = async ( 
  data: {
    give: {
      collectionId: string;
      quantity: number;
    },
    get: {
      collectionId: string;
      quantity: number;
    },
    marketplaceCost: number,
    neoswapCost: number,
    savings: number,
    userId: string,
    details: any,
  }
) => {
  if (!data.details) data.details = {};
  
  const csOfferRef = collection(Firebase.getDBApp(), "csOffers");
  return addDoc(csOfferRef, { ...data, createdAt: serverTimestamp() });
};
