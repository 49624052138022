export default function IconArrow() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
    >
      <path d="M13.5,5.12l-7.94,7.94c-0.58,0.58-1.54,0.58-2.12,0s-0.58-1.54,0-2.12l10.5-10.5 c0.58-0.59,1.54-0.59,2.12,0l10.5,10.5c0.58,0.58,0.58,1.54,0,2.12s-1.54,0.58-2.12,0L16.5,5.12V28.5c0,0.83-0.67,1.5-1.5,1.5 s-1.5-0.67-1.5-1.5L13.5,5.12z" fill="currentColor" />
    </svg>
  );
}
