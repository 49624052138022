import React, { useEffect, useState, useRef } from 'react';

import { Helmet } from 'react-helmet-async';
import { Alert, AlertDescription, AlertIcon, Box, Flex, useToast } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import useNeoChain from "../hooks/useNeoChain";
import { useUA } from "../contexts/userTracking";

import IconPURI from '../components/Icons/IconPuri';
import IconJUP from '../components/Icons/IconJup';

import NsModal from '../components/Modal/index';

// SHIP IMAGES
import JupShip from '../assets/ships/1.jpg';
import JupShipAlt from '../assets/ships/4.jpg';

import PuriShipOne from '../assets/ships/3.jpg';
import PuriShipOneAlt from '../assets/ships/6.jpg';
import PuriShipTwo from '../assets/ships/2.jpg';
import PuriShipTwoAlt from '../assets/ships/5.jpg';

import StarAtlas from '../assets/staratlas.png';

import "../css/pages/StarAtlasSkins.scss";

import { useAppContext } from "../contexts/appContext";
import {
  updateInfo,
  updateTerms,
} from "../services/user.service";
import { confetti } from "../services/confetti";
import HandleTime from '../components/HandleTime';
import IconTimeSA from '../components/Icons/IconTimeSA';
import IconX from '../components/Icons/IconX';

interface PriceCheckResultSuccess {
  success: true;
  price: number;
  priceLamports: number;
}

interface PriceCheckResultFailure {
  success: false;
  error: string;
}

type PriceCheckResult = PriceCheckResultSuccess | PriceCheckResultFailure;
type SkinId = 'jup' | 'puri1' | 'puri2';

interface SkinPrices {
  jup?: PriceCheckResult;
  puri1?: PriceCheckResult;
  puri2?: PriceCheckResult;
}

interface SkinProperties {
  id: SkinId;
  image: string;
  imageHover: string;
  name: string;
  token: string;
}

interface SkinPropertiesMap {
  [key: string]: SkinProperties;
}

const skins: SkinPropertiesMap = {
  jup: {
    id: 'jup',
    image: JupShip,
    imageHover: JupShipAlt,
    name: 'JUPITER SKIN',
    token: 'JUP',
  },
  puri1: {
    id: 'puri1',
    image: PuriShipOne,
    imageHover: PuriShipOneAlt,
    name: 'PURI Skin - PTP Variant',
    token: 'PURI',
  },
  puri2: {
    id: 'puri2',
    image: PuriShipTwo,
    imageHover: PuriShipTwoAlt,
    name: 'PURI Skin - PTT Variant',
    token: 'PURI',
  },
};

const StarAtlasSkins: React.FC = () => {
  const {
    gaConnectedWalletStarAtlasSkin,
    gaClickedBuyStarAtlasSkin,
    gaSharedStarAtlasSkin,
    gaSuceessfullyBoughtStarAtlasSkin,
    gaFailedSwapTokenStarAtlasSkin,
    gaFailedBuyStarAtlasSkin
  } = useUA();
  const chainFns = useNeoChain();
  const chain = chainFns["solana"];
  const [searchParams] = useSearchParams();
  const [skinType, setSkinType] = useState<string>('JUP');
  const [skinPrices, setSkinPrices] = useState<SkinPrices>({});
  const [isPurchasing, setIsPurchasing] = useState<{ [key in SkinId]?: boolean }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isFetchingPrice, setIsFetchingPrice] = useState<{ [key in SkinId]?: boolean }>({
    jup: false,
    puri1: false,
    puri2: false,
  });
  const [hovered, setHovered] = useState("");
  const [modalHeader, setModalHeader] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalSecondaryMessage, setModalSecondaryMessage] = useState('');
  const [modalExplorerLink, setModalExplorerLink] = useState('');
  const [modalBuyLabel, setModalBuyLabel] = useState('BUY');
  const [currentBuyingSkin, setCurrentBuyingSkin] = useState<SkinId>('jup');
  const [currentSkinPrice, setCurrentSkinPrice] = useState<Number>();
  const formRef = useRef<HTMLFormElement>(null);
  const [skipSwap, setSkipSwap] = useState<{ [key in SkinId]?: boolean }>({});

  const {
    updateState,
    uid,
    updateUserProfile,
    user,
    email,
    agreements,
    telegram,
    discord,
    displayName
  } = useAppContext();

  const signupFormInitialValues = {
    email: user?.email || email?.value || "",
    telegram: telegram?.value || "",
    discord: discord?.value || "",
    name: user?.displayName || displayName || "",
    terms: agreements?.consentTosAndPp || false,
    newsletter: agreements?.consentNotifications || false,
  };

  useEffect(() => {
    const initialValues = {
      email: user?.email || email?.value || "",
      telegram: telegram?.value || "",
      discord: discord?.value || "",
      name: user?.displayName || displayName || "",
      terms: agreements?.consentTosAndPp || false,
      newsletter: agreements?.consentNotifications || false,
    };
    setFormValues(initialValues);
  }, [user, email, telegram, discord, displayName, agreements]);


  const [formValues, setFormValues] = useState(signupFormInitialValues);

  const toast = useToast();

  const { buySkin, checkPrices, connected, connect, signIn } = chain;

  useEffect(() => {
    if (uid && !connected) {
      connect();
    }
  }, [uid, connect]);

  useEffect(() => {
    const paramSkinType = searchParams.get('skinType');
    if (paramSkinType) {
      setSkinType(paramSkinType);
      updateState({ activeChainBalance: paramSkinType.toLowerCase() });
    } else {
      updateState({ activeChainBalance: "jup" });
    }
  }, [searchParams]);

  const fetchPrice = async (skinId: SkinId) => {
    setIsFetchingPrice(prev => ({ ...prev, [skinId]: true }));
    try {
      let priceResult = (await checkPrices([skinId]))[0];
      setSkinPrices(prev => ({ ...prev, [skinId]: priceResult }));
    } catch (error) {
      console.error(`Failed to fetch price for ${skinId}:`, error);
      toast({
        title: "Error!",
        description: "Unable to retrieve the skin price at this moment. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    }
    setIsFetchingPrice(prev => ({ ...prev, [skinId]: false }));
  };

  const fetchAllPrices = async () => {
    let skinsToFetch: SkinId[] = ['jup', 'puri1', 'puri2'];
    console.log('Fetching prices for:', skinsToFetch);
    skinsToFetch.forEach(skinId => {
      setIsFetchingPrice(prev => ({ ...prev, [skinId]: true }));
    });
    try {
      let priceResults = await checkPrices(skinsToFetch);
      priceResults.forEach((priceResult, i) => {
        setSkinPrices(prev => ({ ...prev, [skinsToFetch[i]]: priceResult }));
      });
    } catch (error) {
      console.error('Failed to fetch prices:', error);
    }
    skinsToFetch.forEach(skinId => {
      setIsFetchingPrice(prev => ({ ...prev, [skinId]: false }));
    });
    // await fetchPrice('jup');
    // await new Promise((resolve) => setTimeout(resolve, 500));
    // await fetchPrice('puri1');
    // await new Promise((resolve) => setTimeout(resolve, 500));
    // await fetchPrice('puri2');
  }

  useEffect(() => {
    fetchAllPrices();

    const intervalId = setInterval(() => {
      fetchAllPrices();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const handleBuySkin = async (skinId: SkinId) => {
    gaClickedBuyStarAtlasSkin(skinType);
    setIsPurchasing(prev => ({ ...prev, [skinId]: true }));
    setCurrentBuyingSkin(skinId);
    const priceInfo = skinPrices[skinId];
    const skipSwapSkin = skipSwap[skinId] || false;

    if (priceInfo && priceInfo.success) {
      setCurrentSkinPrice(priceInfo.price);
      const result = await buySkin({
        skinSelected: skinId,
        maxPaymentLamports: priceInfo.priceLamports,
        skipSwap: skipSwapSkin
      });

      if (result.success) {
        gaSuceessfullyBoughtStarAtlasSkin(skinType);
        const txHash = result.hashes.length === 1 ? result.hashes[0] : result.hashes[1];
        setModalExplorerLink(`https://solscan.io/tx/${txHash}`);
        setIsPurchaseModalOpen(true);

        const displayUsername = user?.displayName || displayName || "";
        if (!displayUsername) {
          setIsSignupModalOpen(true);
        }
        setSkipSwap(prev => ({ ...prev, [skinId]: false }));
        confetti.fire();
      } else {
        if (result.error === "swapping token failed") {
          gaFailedSwapTokenStarAtlasSkin(skinType);
          showModalWithError(skinId, 'swap', priceInfo.price, result.jupHash);
        } else if (result.error === "buying skin failed") {
          gaFailedBuyStarAtlasSkin(skinType);
          setSkipSwap(prev => ({ ...prev, [skinId]: true }));
          showModalWithError(skinId, 'purchase', priceInfo.price, result.skinHash);
        } else if (result.error === "price exceeded") {
          setSkinPrices(prev => ({ ...prev, [skinId]: { success: true, price: result.price, priceLamports: result.priceLamports } }));
          showModalWithError(skinId, 'price_change', result.price);
        } else if (result.error === "not enough funds") {
          toast({
            title: "Error Purchasing!",
            description: `Insufficient ${skinType} balance. Please top up your account then try again.`,
            status: "error",
            duration: 9000,
            isClosable: true,
            styleConfig: {
              zIndex: 999999,
            },
          });
        } else if (result.error === "not enough sol") {
          toast({
            title: "Error Purchasing!",
            description: `Not enough SOL for network fees. Please top up your account then try again.`,
            status: "error",
            duration: 9000,
            isClosable: true,
            styleConfig: {
              zIndex: 999999,
            },
          });
        } else if (result.error === "not connected") {
          toast({
            title: "Error! Not Connected",
            description: `Please connect your wallet and try again.`,
            status: "error",
            duration: 9000,
            isClosable: true,
            styleConfig: {
              zIndex: 999999,
            },
          });
        } else {
          toast({
            title: "Error!",
            description: "Please report a bug through the feedback button.",
            status: "error",
            duration: 9000,
            isClosable: true,
            styleConfig: {
              zIndex: 999999,
            },
          });
        }
      }
    } else {
      toast({
        title: "Error!",
        description: "Unable to retrieve the skin price at this moment. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    }
    setIsPurchasing(prev => ({ ...prev, [skinId]: false }));
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(formRef.current!);

    const name = formData.get('name');
    const email = formData.get('email');
    const telegram = formData.get('telegram');
    const discord = formData.get('discord');
    const terms = formData.get('terms');
    const newsletter = formData.get('newsletter');

    if (name) {
      const strName = name as string;
      updateUserProfile({ displayName: strName });
      updateState({
        displayName: strName
      });
    }

    if (email) {
      const strEmail = email as string;
      updateInfo(uid!, "email", {
        value: strEmail,
        verificationSent: true,
      });
      updateState({
        email: {
          value: strEmail,
        },
      });
    }

    if (discord) {
      const strDiscord = discord as string;
      updateInfo(uid!, "discord", {
        value: strDiscord,
      });
      updateState({
        discord: {
          value: strDiscord,
        },
      });
    }

    if (telegram) {
      const strTelegram = telegram as string;
      updateInfo(uid!, "telegram", {
        value: strTelegram,
      });
      updateState({
        telegram: {
          value: strTelegram,
        },
      });
    }

    if (terms) {
      const bolTerms = terms === "on" ? true : false;
      updateTerms(uid!, {
        consentTosAndPp: bolTerms,
      });
      updateState({
        agreements: {
          consentTosAndPp: bolTerms,
        },
      });
    }

    if (newsletter) {
      const bolNewsletter = newsletter === "on" ? true : false;
      updateTerms(uid!, {
        consentNotifications: bolNewsletter,
      });
      updateState({
        agreements: {
          consentNotifications: bolNewsletter,
        },
      });
    }

    setIsSignupModalOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleShare = () => {
    // Opens a pop-up with twitter sharing dialog
    var shareURL = "https://twitter.com/intent/tweet?"; //url base

    gaSharedStarAtlasSkin(skinType);
    //params
    var params: { [key: string]: string } = {
      url: "https://neoswap.cc/star-atlas-skins",
      text: `I got my $${skinType} @StarAtlas skin on @neoswap_ai! \n\nGet yours at: `,
    };

    for (var prop in params)
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    window.open(
      shareURL,
      "",
      "left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
  };

  const showModalWithError = (
    skinId: SkinId,
    errorType: 'swap' | 'purchase' | 'price_change',
    value: Number | undefined,
    txHash?: String
  ) => {
    let header, message, secondaryMessage, explorerLink;
    if (errorType === 'swap') {
      header = "TRANSACTION FAILED";
      message = `Your transaction didn't go through. Please click the "BUY" button to initiate the transaction once more.`;
      secondaryMessage = `If you keep having issues, please use the 'Feedback' button to report it.`;
      explorerLink = `https://solscan.io/tx/${txHash}`;
      setModalBuyLabel('Buy Again')
    } else if (errorType === 'purchase') {
      header = "Purchase Failed";
      message = `Your transaction to purchase ${skinType} skin didn't go through. Please click the "BUY" button to initiate the transaction once more.`;
      secondaryMessage = `If you keep having issues, please use the 'Feedback' button to report it.`;
      explorerLink = `https://solscan.io/tx/${txHash}`;
      setModalBuyLabel('Buy Again')
      setSkipSwap(prev => ({ ...prev, [skinId]: true }));
    } else {
      header = "Price Updated";
      message = `The price has changed to ${value} ${skinType}.`;
      secondaryMessage = `Would you like to continue with your purchase?`;
      explorerLink = '';
    }

    setModalHeader(header);
    setModalMessage(message);
    setModalSecondaryMessage(secondaryMessage);
    setModalExplorerLink(explorerLink);
    setIsModalOpen(true);

  };

  const deadlineTimestamp = 1707768034; // Convert to milliseconds for comparison
  let compTime = deadlineTimestamp * 1000
  const [expired, setExpired] = useState(Date.now() >= compTime);

  useEffect(() => {
    // If the current time is already past the deadline, don't set an interval
    if (expired) return;

    // Function to check if the deadline has passed
    const checkIfExpired = () => {
      if (Date.now() >= compTime) {
        setExpired(true);
      }
    };

    // Set an interval to check the deadline periodically
    const intervalId = setInterval(checkIfExpired, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [expired, compTime]);

  return (
    <>
      <Helmet>
        <title>NeoSwap | Star Atlas Special Skins</title>
        <meta name="description" content="Star Atlas Special Skins." />
        <meta name="twitter:title" content="Star Atlas Special Skins" />
        <meta name="twitter:card" content="Get your Star Atlas Special Skin" />
        <meta name="twitter:site" content="@NeoSwap_ai" />
        <meta name="twitter:description" content="description" />
        <meta name="twitter:image" content={skins['jup'].image} />
        {/* Add Open Graph tags for broader social media compatibility */}
        <meta property="og:title" content="Star Atlas Special Skins" />
        <meta property="og:description" content="description" />
        <meta property="og:image" content={skins['jup'].image} />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* <button onClick={() => showModalWithError('jup', 'swap', 1, "2yJTAofYdUi2b17Ge5dss7nfdQjXyuRSTeczu3EmkQDgQixMADWHNRijohF73P4F1UFguBzMMasGJm1wFkTBQmor")}>CLICK ME</button> */}
      <NsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header={modalHeader}
        size="2xl"
        footerButtons={[
          { label: "CLOSE", onClick: () => console.log("Closing"), variant: "grey" },
          { label: `${modalBuyLabel}`, onClick: () => handleBuySkin(currentBuyingSkin!), variant: "action" },
        ]}
      >
        <div className='bg-[#15151A]  shadow border border-white border-opacity-5 p-4' style={{ background: "#15151A" }}>
          <p>{modalMessage}</p>
          <p className='mt-2 font-normal'>{modalSecondaryMessage}</p>

        </div>

        {modalExplorerLink && (

          <div className="w-full text-center mt-4 ">
            <a href={modalExplorerLink} target="_blank" className='w-full explorer-link token rogan-regular uppercase px-10 py- text-lg'
            >
              VIEW ON EXPLORER
            </a>
          </div>


        )}
      </NsModal >

      <NsModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
        header=""
        size="2xl"
        footerButtons={[
          { label: "CLOSE", onClick: () => console.log("Closing"), variant: "grey" },
          { label: "SHARE ON X", onClick: () => handleShare(), variant: "action", closeOnClick: false },
        ]}
      >

        <div className='flex justify-center gap-3'>
          <div className='flex flex-col items-center gap-3'>
            <p className="text-xl rogan-bold  uppercase"> PURCHASE COMPLETED</p>

            <div className="flex items-center justify-center">
              <div className="w-full">
                <div className=" p-4">
                  <div className="flex justify-between amber-header items-center p-3 bg-[#2E2514]">
                    <h2 className="text-lg font-bold text-amber-300 rogan-bold">{skins[currentBuyingSkin!].name}</h2>
                    <h2 className="text-md  text-white text-right flex items-center rogan-regular">
                      PRICE // <span className="text-amber-300">

                        <span className='text-center  uppercase flex items-center justify-start gap-1'>{skins[currentBuyingSkin!].token === "JUP" ? <IconJUP /> : <IconPURI />}{`${currentSkinPrice}`}</span>

                      </span>
                    </h2>
                  </div>
                  <div onMouseEnter={() => setHovered('modalHover')}
                    onMouseLeave={() => setHovered('')}>

                    {hovered === "modalHover" ? (
                      <img src={skins[currentBuyingSkin!].image} alt="Ship Two" className='rounded-md' style={{ width: '632px', height: 'auto' }} />
                    ) : (
                      <img src={skins[currentBuyingSkin!].imageHover} alt="Ship One" className='rounded-md' style={{ width: '632px', height: 'auto' }} />
                    )}

                  </div>
                  <div className="w-full flex text-center">
                    <a href="https://play.staratlas.com/inventory/" target="_blank" className='w-full token text-white rogan-regular uppercase px-10 py-3 bg-slate-800 text-lg' rel="noreferrer"
                    >
                      GO TO STAR ATLAS
                    </a>
                  </div>

                  <div className="w-full text-center mt-4 ">
                    <a onClick={() => {
                      window.open(modalExplorerLink, '_blank');
                    }} className='w-full explorer-link token rogan-regular uppercase px-10 py- text-lg'
                    >
                      VIEW ON EXPLORER
                    </a>
                  </div>


                  <div className="w-full text-center mt-4 ">
                    <p>Jumpstart your Star Atlas journey with the NeoSwap Starter Pack—everything you need for your adventure in one transaction.</p>

                  </div>
                  <div className="w-full text-center mt-4 ">
                    <a onClick={() => {
                      window.open("https://neoswap.cc/star-atlas", '_blank');
                    }} className='token text-white rogan-regular uppercase px-10 py-3 bg-slate-800 text-lg mt-4'
                    >
                      TRY NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </NsModal>

      <NsModal
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        header="What should we call you?"
        headerClasses="!pl-10 mt-4"
        headerAlign="left"
        showCloseButton={false}
        size="lg"
        bodyClass="!p-0"
        footer={false}
        footerButtons={[]}
      >
        <form ref={formRef} className='flex justify-center' onSubmit={(event) => {
          handleSave(event);
        }}>

          <div className='w-full'>
            <div className='flex flex-col items-center gap-2 px-10'>
              <div className='w-full'>
                <label className='block text-white text-sm font-bold mb-2' htmlFor="name">NAME <span style={{ color: '#FFBE4D' }}>*</span></label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-white bg-black leading-tight focus:ring-0 focus:border-black' id="name" name="name" type="text" value={formValues.name} onChange={handleInputChange} required />
              </div>

              <div className='w-full'>
                <label className='block text-white text-sm font-bold mb-2' htmlFor="email">EMAIL <span style={{ color: '#FFBE4D' }}>*</span></label>
                <input className='shadow appearance-none border rounded w-full py-2 px-3 text-white bg-black leading-tight focus:ring-0 focus:border-black' id="email" name="email" type="email" value={formValues.email} onChange={handleInputChange} required />
              </div>

              <div className='w-full'>
                <label className='block text-white text-sm font-bold mb-2' htmlFor="telegram">TELEGRAM</label>
                <input className='shadow appearance-none border rounded w-full py-2 px-3 text-white bg-black leading-tight focus:ring-0 focus:border-black' id="telegram" name="telegram" type="text" value={formValues.telegram} onChange={handleInputChange} />
              </div>

              <div className='w-full'>
                <label className='block text-white text-sm font-bold mb-2' htmlFor="discord">DISCORD</label>
                <input className='shadow appearance-none border rounded w-full py-2 px-3 text-white bg-black leading-tight focus:ring-0 focus:border-black' id="discord" name="discord" type="text" value={formValues.discord} onChange={handleInputChange} />
              </div>

              <div className='w-full flex items-center gap-2'>
                <input id="terms" type="checkbox" name="terms" required checked={formValues.terms} onChange={handleInputChange} />
                <label htmlFor="terms" className='text-white text-sm'>I have read and agree to the <a href="/terms" style={{ color: '#FFBE4D' }} target="_blank">terms and conditions</a> <span style={{ color: '#FFBE4D' }}>*</span></label>
              </div>

              <div className='w-full flex items-center gap-2'>
                <input id="newsletter" name="newsletter" type="checkbox" checked={formValues.newsletter} onChange={handleInputChange} />
                <label htmlFor="newsletter" className='text-white text-sm'>Please add me to the email list to receive the NeoSwap news & updates and partners.</label>
              </div>
            </div>

            <div className='w-full flex justify-between pt-4 gap-4'>
              <button
                type="button"
                onClick={() => setIsSignupModalOpen(false)}
                className="px-10 w-full py-3 justify-center items-center inline-flex text-center rogan-regular uppercase bg-white bg-opacity-10 text-white"
              >
                CLOSE
              </button>
              <button
                type="submit"
                className="px-10 w-full py-3 justify-center items-center inline-flex text-center rogan-regular uppercase bg-white bg-opacity-10 text-amber-300 rogan-bold"
              >
                SAVE
              </button>
            </div>
          </div>

        </form>
      </NsModal>
      {/* <button onClick={() => setIsSignupModalOpen(true)}>Open Modal</button> */}
      {/* <button onClick={() => setIsPurchaseModalOpen(true)}>Open Modal</button> */}
      <div className='flex flex-col items-center justify-start w-full'>
        <img src={StarAtlas} alt="Star Atlas" style={{ maxWidth: '200px' }} />
        <h2 className='text-center text-amber-300 text-xl rogan-regular leading-10 tracking-widest uppercase mb-4'>SPECIAL SKINS</h2>
      </div>
      <div>
        <div className='w-fit mx-auto bg-starAtlasBg pb-4'>
          <div className='mx-auto mb-4 max-w-full'>
            <div className="bg-starAtlasBox shadow flex justify-between w-full">
              <h3 className='text-slate-400 text-lg rogan-regular px-4 pt-4 pb-4 flex-shrink-0 uppercase'>OFFERS EXPIRED</h3>

            </div>

          </div>

          <div className='mx-auto mb-4 max-w-full py-4 px-4 text-center' style={{ maxWidth: "550px" }}>
            <p className='mb-4'>
              This mint has now concluded. You can find the JUP and PURI skins on Star Atlas' secondary market here:
            </p>
            <a onClick={() => {
              window.open("https://play.staratlas.com/market/", '_blank');
            }} className='w-full explorer-link token rogan-regular uppercase text-center py-4 px-4  text-lg'
            >
              Star Atlas Marketplace
            </a>


            <div className="w-full text-center mt-4 mb-4 ">
              <p>Jumpstart your Star Atlas journey with the NeoSwap Starter Pack—everything you need for your adventure in one transaction:</p>
            </div>
            <div className="w-full text-center mt-4 py-4">
              <a onClick={() => {
                window.open("https://neoswap.cc/star-atlas", '_blank');
              }} className='token text-white rogan-regular uppercase px-10 py-3 bg-slate-800 text-lg mt-4' style={{ cursor: "pointer" }}
              >
                TRY NOW
              </a>
            </div>

          </div>


        </div>
      </div >
    </>
  );
};

export default StarAtlasSkins;
