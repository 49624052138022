import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    useDisclosure
} from '@chakra-ui/react';

interface NFTVisibilityHelpModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const NFTVisibilityHelpModal: React.FC<NFTVisibilityHelpModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModalContent
                maxWidth="500px"
                bg="#091824"
                color="white"
            >
                <ModalHeader>Having trouble finding your purchased items?</ModalHeader>
                <ModalBody>

                    <Text mt={4}>
                        For Phantom wallet users, click "Manage collectible list" in your NFT tab and enable the assets using the toggle switch.
                    </Text>
                    <Text mt={4}>
                        For other wallets, look for "Hidden Assets" or "Manage Collections" in your NFT settings to make your items visible.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default NFTVisibilityHelpModal;

