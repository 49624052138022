import { Disclosure, RadioGroup } from "@headlessui/react";
import NeoImage from "./Img";
import { ChainDetails, Images, Img, Offer as OfferType } from "./types";
import OfferPanel from "./OfferPanel";
import { roundValue } from "./utils";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { createFloor } from "../../utils";
import { useRoom } from "../../contexts/roomContext";

interface Props {
  offerId: string;
  offer: OfferType;
  idx: number;
  images: Images;
  chain: ChainDetails;
  isSelected: boolean;
  tokenName: string
}

export function getLetterAtIndex(index: number) {
  const alphabetStartCode = "A".charCodeAt(0); // Get the character code of 'A'
  const letterIndex = index % 26; // Map the index to the range 0-25 (for A-Z)
  const letterCode = alphabetStartCode + letterIndex; // Compute the character code of the desired letter
  const letter = String.fromCharCode(letterCode); // Convert the character code to the corresponding letter
  return letter;
}

export default function Offer({
  offerId,
  offer,
  idx,
  images,
  chain,
  isSelected,
  tokenName
}: Props) {
  // const chainName = chain.name.toLowerCase() as Blockchain;
  const tokenValue = roundValue(offer?.token, chain?.tokens[tokenName.toLowerCase()].decimals, chain?.tokens[tokenName.toLowerCase()].truncate);
  const tokenAbbr = chain?.tokens[tokenName.toLowerCase()].abbr || tokenName.toUpperCase();
  // const Icon = getChainLogo(chainName);

  const get = offer.get?.map((id: string) => {
    const img = images[id];
    const collectionFloorPrice = createFloor(img, chain.truncate);
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
      collectionName: img.collection.name,
      floorPrice: collectionFloorPrice,
      marketplace: img.marketplace.url,
      metadata: img,
    };
  }) as Img[];

  const give = offer.give?.map((id: string) => {
    const img = images[id];
    const collectionFloorPrice = createFloor(img, chain.truncate);
    return {
      id,
      src: img.thumbnail || img.image || "",
      alt: img.name,
      name: img.name,
      collectionName: img.collection.name,
      floorPrice: collectionFloorPrice,
      marketplace: img.marketplace.url,
      metadata: img,
    };
  }) as Img[];

  const returnName = () => {
    if (offer?.name) return offer.name;
    return `Trade ${getLetterAtIndex(idx)}`;
  };

  
  return (
    <Disclosure>
      {({ open }) => (
        <div
          className={`${
            isSelected ? "bg-[#151515]" : "bg-black"
          } w-full h-auto min-h-[96px] px-8 py-4 ${
            !isSelected && "bg-opacity-30 backdrop-blur-[65px]"
          } rounded-2xl justify-center items-center gap-2.5 flex flex-col`}
        >
          <div className="w-full flex justify-between">
            <div className="h-auto min-h-[84px] w-full justify-start items-center gap-4 flex flex-wrap">
              <div className="w-full justify-start items-center gap-4 flex">
                <>
                  <RadioGroup.Option value={offerId}>
                    {({ checked }) => {
                      return (
                        <div role="button" className="w-12 h-12 relative">
                          {checked && (
                            <div className="w-8 h-8 left-[8px] top-[8px] absolute bg-green-500 rounded-full" />
                          )}
                          <div className="w-12 h-12 left-0 top-0 absolute rounded-full border-2 border-white" />
                        </div>
                      );
                    }}
                  </RadioGroup.Option>
                </>

                <div className="leading-loose">
                  <span className="text-white text-[21px] font-medium">{returnName()}</span> <br></br> {offer?.subname && ( <span className="text-[#f6be47]  text-[13px]"> // {offer.subname}</span>)}
                </div>

                {/* Closed Desktop Header Section */}
                {!open ? (
                  <div className="flex items-center gap-6">
                    <div className="hidden md:flex justify-start items-center gap-4 flex-col md:flex-row flex-1">
                      {tokenValue && tokenValue > 0 && (
                        <>
                          <div className="justify-center items-center gap-4 flex">
                            <div className="text-center text-white text-[21px] font-medium leading-loose min-w-[130px]">
                              {Math.abs(tokenValue)} {tokenAbbr}{" "}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="justify-start items-start gap-1.5 flex">
                        {get?.map(({ id, src, alt }) => {
                          return (
                            <div
                              key={`get_${id}}`}
                              className="w-16 h-16 rounded-md border-l-2 md:border-l-0 md:border-b-2 border-green-500 justify-center items-center flex overflow-hidden relative"
                            >
                              <NeoImage
                                src={src}
                                alt={alt}
                                className="w-16 h-16"
                              />

                              <div className="w-full absolute bg-black bg-opacity-60 text-right right-0 bottom-0 pr-1 font-bold text-sm">
                                x
                                {offer?.getQuantity
                                  ? offer?.getQuantity[id].toLocaleString()
                                  : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="w-[25.97px] h-[16.97px] hidden md:block relative">
                      <svg
                        width="30"
                        height="20"
                        viewBox="0 0 30 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.96875 10.3169H27.9688"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M19.4835 1.83158L27.9688 10.3169L19.4835 18.8021"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M10.4853 1.83164L2 10.3169L10.4853 18.8022"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>

                    <div className="justify-start items-center gap-[5.87px] hidden md:flex flex-col md:flex-row flex-1">
                      {tokenValue && tokenValue < 0 ? (
                        <>
                          <div className="justify-center items-center gap-4 flex">
                            <div className="text-center text-white text-[21px] font-medium leading-loose">
                              {Math.abs(tokenValue)} {tokenAbbr}{" "}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="h-12" />
                      )}
                      {give?.map(({ id, src, alt }) => {
                        return (
                          <div
                            key={`give_${id}`}
                            className="w-16 h-16 rounded-md border-l-2 md:border-l-0 md:border-b-2 border-red-600 justify-center items-center flex overflow-hidden"
                          >
                            <NeoImage
                              src={src}
                              alt={alt}
                              className="w-16 h-16"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                <div
                  className={`ml-auto transition-all ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <Disclosure.Button>
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 20 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.178 14.569C9.26974 14.7022 9.39249 14.8112 9.53569 14.8864C9.67888 14.9617 9.83823 15.001 10 15.001C10.1618 15.001 10.3211 14.9617 10.4643 14.8864C10.6075 14.8112 10.7303 14.7022 10.822 14.569L19.822 1.569C19.9262 1.41906 19.9873 1.24343 19.9986 1.06121C20.01 0.87898 19.9712 0.697123 19.8865 0.535394C19.8018 0.373665 19.6743 0.238248 19.518 0.143858C19.3617 0.049468 19.1826 -0.000286344 19 1.23965e-06H0.999998C0.817841 0.000753649 0.639336 0.0511479 0.483678 0.145764C0.328021 0.24038 0.201101 0.375639 0.116566 0.536994C0.0320324 0.698349 -0.00691693 0.879696 0.00390703 1.06153C0.014731 1.24337 0.0749187 1.41881 0.177998 1.569L9.178 14.569Z"
                        fill="#F8F8F8"
                      />
                    </svg>
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className={"w-full"}>
            <OfferPanel
              tokenValue={tokenValue}
              chain={chain}
              get={get}
              give={give}
              isSelected={isSelected}
              giveQuantity={offer.giveQuantity}
              getQuantity={offer.getQuantity}
              tokenName={tokenAbbr}
            />
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
