import React from 'react'
import { Button, Flex, ModalFooter, Tooltip } from '@chakra-ui/react'
import { AnyEventObject, StateFrom } from 'xstate'

type FooterProps = {
  state: StateFrom<any>
  onClose: () => void
  send: (event: AnyEventObject) => void
  canBuy?: boolean
}

const Footer: React.FC<FooterProps> = ({ state, onClose, send, canBuy }) => {
  // console.log('Footer canBuy', canBuy)
  return (
    <ModalFooter>
      <Flex w="full" justifyContent="space-between">
        <Button onClick={onClose} className="buying-modal-close-button">
          Close
        </Button>
        {state.matches('connect') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Connecting...
          </Button>
        )}
        {state.matches('loadBalances') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Connecting...
          </Button>
        )}
        {state.matches('notConnected') && (
          <Button className="buying-modal-next-button" onClick={() => send({ type: 'NEXT' })}>
            Buy
          </Button>
        )}
        {state.matches('accept') && (
          <Tooltip
            label="You need more tokens to buy this pack. Go to the exchange tab."
            isDisabled={canBuy}
            hasArrow
            className="game-custom-tooltip"
          >
            <Button
              className="buying-modal-next-button"
              onClick={() => send({ type: 'NEXT' })}
              isDisabled={!canBuy}
            >
              Buy
            </Button>
          </Tooltip>
        )}
        {state.matches('fetchTxs') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Buying...
          </Button>
        )}
        {state.matches('retryTxs') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Buying...
          </Button>
        )}
        {state.matches('signTxs') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Buying...
          </Button>
        )}
        {state.matches('checkTxs') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Buying...
          </Button>
        )}
        {state.matches('success') && (
          <Button className="buying-modal-next-button" onClick={() => send({ type: 'SHARE' })}>
            Share
          </Button>
        )}
        {state.matches('shared') && (
          <Button className="buying-modal-next-button" isDisabled={true}>
            Shared
          </Button>
        )}
        {state.value.match('error') && (
          <Button className="buying-modal-next-button" onClick={() => send({ type: 'RETRY' })}>
            Try again
          </Button>
        )}
      </Flex>
    </ModalFooter>
  )
}

export default Footer
