import { DateTime } from "luxon";
import Offers from "./Offers";
import Button from "./Button";
import { AiRoom, Images } from "./types";

import Stepper from "./Stepper";
import TimeLeft from "./TimeLeft";
import useTimer from "./useTimer";
import { Flex, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";

export default function AiTradesV2({
  room,
  images,
  offerId,
  selectOffer,
  handleAccept,
  handleDecline,
  isLoading,
  // declineIsLoading,
}: {
  room: AiRoom;
  images: Images;
  offerId: string;
  selectOffer: (offerId: string) => void;
  handleAccept: () => void;
  handleDecline: () => void;
  isLoading: boolean;
  // declineIsLoading: boolean;
}) {
  const offers = room?.offers || {};

  const startTime = room?.info?.createdAt?.seconds || 0;
  const endTime = DateTime.fromMillis(room.expirationTime || 0).toSeconds();

  const { expired, timeRemainingSec, timeLeftUnits } = useTimer({
    endTime: endTime,
    timeOffset: 0,
  });

  const count = Object.keys(offers).length

  return (
    <div className="flex flex-col grow gap-16">
      <Stepper activeStep={2} />
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="inline-flex flex-col space-y-4 items-start justify-start">
          {startTime > 0 && (
            <TimeLeft
              expired={expired}
              timeRemainingSec={timeRemainingSec}
              timeLeftUnits={timeLeftUnits}
            />
          )}
          <p className="text-5xl font-medium text-left text-white">
            We found {count} Pack{count > 1 ? "s" : ""}.
          </p>
          <p className="text-xl font-medium leading-loose text-white">
            Please Select Your Pack.
          </p>
        </div>
        <Button
          handleClick={handleAccept}
          isLoading={isLoading}
          isDisabled={isLoading || (!!timeRemainingSec && timeRemainingSec < 1)}
          className="w-full md:w-52 flex items-center justify-center px-10 md:px-4 py-6 mx:py-4 bg-gradient-to-l from-sky-500 via-blue-600 to-indigo-500 rounded-md text-base font-bold text-center text-white capitalize inter-bold"
        >
          Select Pack
        </Button>
      </div>
      <Offers
        room={room}
        offers={offers}
        selectedOffer={offerId}
        selectOffer={selectOffer}
        images={images}
      />

      <div className="flex space-x-12 items-center justify-end">
        <p role="button" className="text-red-500" onClick={handleDecline}>
          Decline All
        </p>
        <Button
          handleClick={handleAccept}
          isLoading={isLoading}
          isDisabled={isLoading || (!!timeRemainingSec && timeRemainingSec < 1)}
          className="flex space-x-2 items-center justify-center px-10 py-6 bg-gradient-to-l from-green-400 via-blue-500 to-indigo-500 rounded-md text-base font-bold text-center text-white capitalize"
        >
          Select Pack
        </Button>
      </div>
    </div>
  );
}
