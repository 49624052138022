import { useEffect, useState, useCallback } from "react";
import {
  Button,
  IconButton,
  Link,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalCloseButton,
  ModalBody,
  // ModalFooter,
  // Input,
  useMediaQuery,
  // Box,
  Center,
} from "@chakra-ui/react";

import BottomBar from "./BottomBar";
import { useRoom } from "../contexts/roomContext";
import useNeoChain from "../hooks/useNeoChain";
import SwapModal from "./SwapModal";
import IconRefresh from "./Icons/IconRefresh";
import { AvatarModal } from "./AvatarList";
import { groupBy, uniq } from "lodash";
import { useAppContext } from "../contexts/appContext";
import { getFeedback, initializeSwap } from "../services/room.service";
import { useChain } from "../contexts/chainsContext";
import { getAiInfo } from "../services/user.service";
import IconWaiting from "./Icons/IconWaiting";
import IconTick from "./Icons/IconTick";
import { useUA } from "../contexts/userTracking";
import DeclineModal from "./DeclineModal";
import AcceptModal from "./AcceptModal";
import SignupForm from "./SignupForm";
// import { EmailVerificationMessage } from "../pages/EmailVerification";

const VerifyModal = ({
  isOpen,
  onClose,
  handleInitialize,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleInitialize: (ignore?: boolean) => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <SignupForm
              handleDone={() => {
                onClose();
                handleInitialize(true);
              }}
            />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function RoomContractButtons() {
  const { gaDeploySwap } = useUA();
  const [initializing, setInitializing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { uid, agreements, displayName, user } = useAppContext();
  const { roomId, room, getSwapState, swapState } = useRoom();
  const chainFns = useNeoChain() as any;
  const roomChain = room?.blockchain?.name?.toLowerCase();
  const [hasFeedback, setHasFeedback] = useState(false);
  const [canRetry, setCanRetry] = useState(false);
  const [feedback, setFeedback] = useState<any>({});
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const { connected, connect, connecting, wallet } =
    chainFns[roomChain || "not_found"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanSm] = useMediaQuery("(min-width: 30em)");
  const { chain } = useChain();
  const {
    isOpen: isDeclineModalOpen,
    onOpen: onDeclineModalOpen,
    onClose: onDeclineModalClose,
  } = useDisclosure();

  const {
    isOpen: verifyModalOpen,
    onOpen: onVerifyModalOpen,
    onClose: onVerifyModalClose,
  } = useDisclosure();

  const handleDeclineSubmit = (reason: string) => {
    // Do something with the reason, e.g., send it to the backend
    console.log("Decline reason:", reason);
  };

  const hasSwaps = !!room?.userSwaps?.[uid!];
  const [swapInfo, setSwapInfo] = useState({
    total: 0,
    signed: 0,
  });

  const handleDecline = () => {
    onDeclineModalOpen(); // Open the decline modal
  };

  const handleAccept = () => {
    setAcceptModalOpen(true); // Open the decline modal
  };

  const handleRefreshSwapState = async () => {
    try {
      setRefreshing(true);
      await getSwapState();
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    handleRefreshSwapState();
  }, []);

  const handleInitialize = async (ignore?: boolean) => {
    try {
      if (!ignore && (!agreements?.consentTosAndPp || !displayName))
        return onVerifyModalOpen();
      setInitializing(true);
      await initializeSwap(roomId);
      await handleRefreshSwapState();
      gaDeploySwap();
    } finally {
      setInitializing(false);
    }
  };
  useEffect(() => {
    if (swapState) {
      const userSwaps = swapState?.userStatus?.filter((u) => u.user === uid);
      const swapStatus = swapState?.userStatus?.filter(
        (u) => u.userState === "signed" && u.user === uid
      );

      setSwapInfo({
        total: userSwaps?.length,
        signed: swapStatus?.length,
      });
    }
  }, [swapState]);

  useEffect(() => {
    const checkIfRated = async () => {
      if (roomId && uid) {
        const userFeedback = await getFeedback(roomId, uid);
        if (userFeedback) {
          setHasFeedback(true);
          setFeedback(userFeedback);
        }
      }
    };
    checkIfRated();
  }, []);

  const grouped = groupBy(swapState?.userStatus, "user");

  const completed = Object.keys(grouped || {}).reduce(
    (acc: string[], id: string) => {
      if (grouped[id].every((ele) => ele.userState === "signed")) {
        acc.push(id);
      }
      return acc;
    },
    []
  );

  const hasDeclinedKey = (obj: any) => {
    for (const key in obj) {
      if (key.startsWith("decline")) {
        return true;
      }
    }
    return false;
  };

  const avatars = uniq(swapState?.userStatus?.map((u) => u.user));

  const checkRetry = async () => {
    try {
      const res = await getAiInfo(uid!);
      if (
        !res.numDeclined ||
        res.numDeclined < (chain?.ai?.maxNumDeclined || 0) - 1
      ) {
        setCanRetry(true);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    checkRetry();
  }, []);

  if (!hasSwaps && room?.info?.roomType === "ai") {
    if (!room.users.includes(uid || "")) return null;
    return (
      <>
        <VerifyModal
          isOpen={verifyModalOpen}
          onClose={onVerifyModalClose}
          handleInitialize={handleInitialize}
        />
        <BottomBar>
          <div className="bottom-bar-content">
            <div className="bottom-bar-title">
              <VStack alignItems="flex-start">
                <Text fontSize="18px">
                  Deploy swap to accept offer and sign
                </Text>
                <Text as={"span"} className="swap-info" pr={"60px"}>
                  <Text fontSize="11px">
                    Not satisfied with the trade? Share your feedback with us{" "}
                    <Link
                      color="#6C60FF"
                      href="https://neoswap.cc/feedback"
                      target="_blank"
                    >
                      here
                    </Link>
                  </Text>
                </Text>
              </VStack>
            </div>

            {hasFeedback && hasDeclinedKey(feedback.feedback) ? (
              <Button backgroundColor="#EB155D" ml="auto">
                {" "}
                Declined{" "}
              </Button>
            ) : (
              <div className="bottom-bar-controls">
                <Button
                  className="Decline-btn"
                  backgroundColor="#EB155D"
                  // onClick={handleInitialize}
                  // isLoading={initializing}
                  onClick={handleDecline}
                  size={isLargerThanSm ? "lg" : "sm"}
                  marginRight="2"
                >
                  {uid && (
                    <DeclineModal
                      isOpen={isDeclineModalOpen}
                      onClose={onDeclineModalClose}
                      onSubmit={handleDeclineSubmit}
                      roomId={roomId}
                      uid={uid}
                      canRetry={canRetry}
                    />
                  )}
                  {canRetry ? "Decline & Try Again" : "Decline"}
                </Button>

                <Button
                  //className="bar-button"
                  backgroundColor="#6C60FF"
                  // onClick={() => handleInitialize()}
                  onClick={() => handleAccept()}
                  isLoading={initializing}
                  loadingText={"Initializing..."}
                  size={isLargerThanSm ? "lg" : "sm"}
                >
                  {uid && (
                    <AcceptModal
                      isOpen={acceptModalOpen}
                      onClose={() => setAcceptModalOpen(false)}
                      onSubmit={handleDeclineSubmit}
                      handleInitialize={() => handleInitialize()}
                      roomId={roomId}
                      uid={uid}
                    />
                  )}
                  Accept
                </Button>
              </div>
            )}
          </div>
        </BottomBar>
      </>
    );
  }



  if (!hasSwaps) return null;

  return (
    <>
      <AvatarModal
        isOpen={isOpen}
        onClose={onClose}
        avatars={avatars}
        completed={completed}
        title={`Global Sign Status: ${completed?.length} out of ${avatars?.length} signed`}
      />
      <BottomBar>
        <div className="bottom-bar-content">
          <div className="bottom-bar-title">
            {swapState && (
              <div className="swap-info-wrapper">
                <IconButton
                  size="sm"
                  variant="unstyled"
                  aria-label="refresh balance"
                  icon={<IconRefresh className="refresh-balance" />}
                  onClick={handleRefreshSwapState}
                  isLoading={refreshing}
                />

                <VStack alignItems="flex-start">
                  {swapInfo.signed === swapInfo.total && (
                    <>
                      {swapInfo.total > 1 ? (
                        <span className="swap-info done">
                          <IconTick />
                          <span>
                            You have signed {swapInfo.signed} / {swapInfo.total}{" "}
                            swaps
                          </span>
                        </span>
                      ) : (
                        <span className="swap-info done">
                          <IconTick />
                          <Text as={"span"} fontSize={"18px"}>
                            You have signed your swap
                          </Text>
                        </span>
                      )}
                    </>
                  )}
                  {swapInfo.signed < swapInfo.total && (
                    <>
                      {swapInfo.signed < 1 ? (
                        <span className="swap-info">
                          <IconWaiting />
                          <span>
                            Please sign
                          </span>
                        </span>
                      ) : (
                        <span className="swap-info">
                          <IconWaiting />
                          <span>
                            You have signed {swapInfo.signed} / {swapInfo.total}{" "}
                            swaps
                          </span>
                        </span>
                      )}
                    </>
                  )}
                  <span className="swap-info">
                    <Text fontSize="11px">
                      Not satisfied with the trade? Share your feedback with us{" "}
                      <Link
                        color="#6C60FF"
                        href="https://neoswap.cc/feedback"
                        target="_blank"
                      >
                        here
                      </Link>
                    </Text>
                  </span>
                </VStack>
              </div>
            )}

            {/* <h3>Waiting on all users to complete signing</h3> */}
            {/* {swapState === "pending" && (
              <h3>Waiting for the Contract to be Deployed</h3>
            )}
            {swapState === "deployed" && canDeposit && (
              <h3>Sign Now to Finalize Trade</h3>
            )}
            {swapState === "deployed" && !canDeposit && (
              <h3>Waiting on all users to complete signing</h3>
            )}
            {swapState === "canceled" && <h3>Someone Cancelled the Trade</h3>}
            {swapState === "closed" && <h3>Trade Complete!</h3>}
            {swapState === "error" && <h3>Oops...there is an error!</h3>} */}
          </div>
          <div className="bottom-bar-controls">
            <Button variant="link" onClick={onOpen} className="issue-button">
              View status
            </Button>
            {/* {canCancel && (
              <Button variant="link" onClick={onOpen} className="issue-button">
                Have an issue?
              </Button>
            )} */}
            {connected && (
              <SwapModal
                handleRefreshSwapState={handleRefreshSwapState}
                hasSwaps={hasSwaps}
                roomId={roomId}
                roomChain={roomChain}
              />
            )}
            {!connected && (
              <Button
                className="bar-button"
                backgroundColor="#6C60FF"
                onClick={connect}
                isLoading={connecting}
                loadingText={"Connecting..."}
                size="lg"
              >
                {!wallet ? "Choose Wallet" : "Connect"}
              </Button>
            )}
          </div>
        </div>
      </BottomBar>
    </>
  );
}
