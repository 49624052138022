import { useToast } from "@chakra-ui/react";
import {
  useAuth,
  useAccount,
  useOpenSignMessage,
  useOpenContractCall,
} from "@micro-stacks/react";
import {
  getMessageToSign,
  verifyMessageToGetURL,
} from "../../components/blockchains/utils";
import { useAppContext } from "../../contexts/appContext";
import { useUA } from "../../contexts/userTracking";

export default function useStacks() {
  const toast = useToast();
  const {
    getEncodedNonce,
    getToken,
    signInWithToken,
    signingIn,
    signOut,
    updateState,
  } = useAppContext();
  const { gaSignWallet } = useUA();
  const { stxAddress } = useAccount();
  const {
    openAuthRequest,
    isRequestPending,
    signOut: stxSignout,
    isSignedIn,
  } = useAuth();
  const { openSignMessage } = useOpenSignMessage();
  const { openContractCall } = useOpenContractCall();

  const signIn = async (addr?: string) => {
    const address = addr || stxAddress;
    try {
      console.log("CONNECTING ON STX", openSignMessage);
      updateState({ signingIn: true });
      if (!address) return await handleConnect();
      if (!address) throw new Error("No public key");
      const nonce = await getEncodedNonce(address, "stacks");
      if (!nonce) throw new Error("No nonce");
      console.log("SIGNATURE", nonce);
      const signature = await openSignMessage({
        message: nonce as string,
      });
      console.log("SIGNATURE", signature);
      if (!signature?.signature) throw new Error("No signature");
      const token = await getToken(
        address,
        "stacks",
        signature?.signature,
        signature?.publicKey
      );
      console.log("TOKEN", token);
      if (!token) throw new Error("No token");
      await signInWithToken(token);
      gaSignWallet();
    } catch (e: any) {
      console.log(e);
      toast({
        title: "Error",
        description:
          e?.message || "Please report a bug through the feedback button.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      updateState({ signingIn: false });
    }
  };

  const handleConnect = async () => {
    if (!stxAddress)
      await openAuthRequest({
        onFinish: (session) => {
          // const network =
          //   process.env.NODE_ENV === "production" ? "mainnet" : "testnet";
          const addr = session.addresses.mainnet;
          signIn(addr);
        },
      });
  };

  const handleSignout = async () => {
    await stxSignout();
    await signOut();
  };

  const deposit = async (config: any) => {
    let hash;
    const {
      contractAddress,
      contractName,
      functionName,
      functionArgs,
      postConditions,
      postConditionMode,
    } = config;

    await openContractCall({
      contractAddress,
      contractName,
      functionName,
      functionArgs,
      postConditions,
      postConditionMode,
      onFinish: async (data) => {
        console.log("ON FINISH", data);
        if (data?.txId) hash = data.txId;
      },
    });
    console.log("STXHASH", hash);
    return { hashes: [hash] };
  };

  const getStatementUrl = async () => {
    if (!stxAddress) throw new Error("No public key");
    const { nonce } = await getMessageToSign("stacks", stxAddress);
    const signature = await openSignMessage({
      message: nonce as string,
    });
    if (!signature?.signature) throw new Error("No signature");
    const { statementUrl } = await verifyMessageToGetURL(
      "stacks",
      stxAddress,
      signature.signature,
      signature.publicKey
    );
    return statementUrl;
  };

  return {
    connect: handleConnect,
    signIn: () => signIn(),
    signOut: handleSignout,
    deposit,
    connected: isSignedIn,
    connecting: isRequestPending || signingIn,
    getStatementUrl,
  };
}
