import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AiRoom,
  GetNftsMetaDataArgs,
  GetNftsMetaDataRes,
  AcceptAiTradeArgs,
  AcceptAiTradeRes,
  DeclineAiTradeArgs,
  DeclineAiTradeRes,
} from "../components/AiTradesV2/types";
import firebase from "../services/firebase";
import AiTrades from "../components/AiTradesV2/AiTradesV2";
import { useQuery, useMutation } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import Declined, { DeclineComplete } from "../components/AiTradesV2/Declined";
import Signing from "../components/AiTradesV2/Signing";
import { useMemo, useState } from "react";
import { useAppContext } from "../contexts/appContext";
import Stepper from "../components/AiTradesV2/Stepper";
import Spinner from "../components/AiTradesV2/Spinner";
import { useDocument } from "react-firebase-hooks/firestore";
import Expired from "../components/AiTradesV2/Expired";
import Finalized from "../components/AiTradesV2/Finalized";
import { getLetterAtIndex } from "../components/AiTradesV2/Offer";
import RoomErrors from "../components/AiTradesV2/RoomErrors";
import { Blockchain, NftMetadata } from "ns-types";
import { useToast } from "@chakra-ui/react";

export async function acceptAiOffer(
  args: AcceptAiTradeArgs
): Promise<AcceptAiTradeRes> {
  const fns = firebase.getFnsApp();
  const { data } = await httpsCallable<AcceptAiTradeArgs, AcceptAiTradeRes>(
    fns,
    "ai-acceptAiOffer"
  )(args);
  return data;
}

/**
 * Declines an AI trade offer.
 * @param args The arguments for the function.
 * @returns The result of declining the AI trade offer.
 */
export async function declineAiOffer(
  args: DeclineAiTradeArgs
): Promise<DeclineAiTradeRes> {
  const fns = firebase.getFnsApp();
  const { data } = await httpsCallable<DeclineAiTradeArgs, DeclineAiTradeRes>(
    fns,
    "ai-declineAiOffer"
  )(args);
  return data;
}

export function createImages(images: NftMetadata[]) {
  const returnVal: { [itemId: string]: NftMetadata } = {};
  images.forEach((img) => {
    returnVal[img.itemId] = img;
  });
  return returnVal;
}

export async function getNftsMetaData(
  args: GetNftsMetaDataArgs
): Promise<GetNftsMetaDataRes> {
  const fns = firebase.getFnsApp();
  const { data } = await httpsCallable<GetNftsMetaDataArgs, GetNftsMetaDataRes>(
    fns,
    "nft-nftMetadata"
  )(args);
  return data;
}

/**
 * Retrieves an aiRoom.
 * @returns The aiRoom.
 */
export async function getAiRoom(id: string): Promise<AiRoom> {
  const db = firebase.getDBApp();
  const privateRef = doc(db, "aiRooms", id);
  return (await getDoc(privateRef)).data() as AiRoom;
}

export function createIds(room?: AiRoom) {
  if (!room) return [];
  const offers = room?.offers || {};
  const ids: string[] = [];
  Object.keys(offers).forEach((offerId) => {
    const { give, get } = offers[offerId];
    ids.push(...[...give, ...get]);
  });
  return [...new Set(ids)];
}

export default function OffersPage() {
  const toast = useToast();
  const { roomId } = useParams();
  const db = firebase.getDBApp();
  const [data, roomFetching, roomError] = useDocument(
    doc(db, "aiRooms", roomId!),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [offerId, selectOffer] = useState("");
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);

  const room = useMemo(() => {
    const d = data?.data() as AiRoom;
    // selectOffer(Object.keys(d?.offers || {})[0]);
    return d;
  }, [data]);

  const ids = createIds(room);

  const { isFetching: imagesFetching, data: images } = useQuery({
    queryKey: ["nfts", roomId],
    queryFn: () => getNftsMetaData({ ids }),
    enabled: !!room,
    staleTime: 5000,
    select: (data: any) => createImages(data),
    refetchOnWindowFocus: false,
  });

  // if (room && Object.values(room.offers).length < 1) {
  //   return <NoOffers />;
  // }

  const { uid, updateState } = useAppContext();

  useEffect(() => {
    if (room?.info?.token) {
      updateState({ activeChainBalance: room.info.token });
    }
  }, [room?.info?.token, updateState]); // Dependencies array

  useEffect(() => {
    // Reset display to native chain balance
    return () => {
      updateState({ activeChainBalance: "native" });
    };
  }, [updateState]);

  const accept = useMutation({
    mutationFn: acceptAiOffer,
    onError: (e: any) => {
      // toast.error(e.message, {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    },
  });

  const handleAccept = () => {
    try {
      if (!offerId) throw new Error("Please select a trade");
      console.log(`USER ACCEPTED TRADE: ${offerId}`);
      accept.mutate({ walletId: uid!, aiRoomId: roomId!, offerId });
    } catch (e: any) {
      toast({
        title:
          e?.message ||
          "Something went wrong. Please report an error through the feedback button.",
        status: "error",
        duration: 9000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });
    }
  };

  const decline = useMutation({
    mutationFn: declineAiOffer,
    onError: (e: any) => {
      // toast.error(e.message, {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    },
  });

  const handleDecline = (reason: any) => {
    decline.mutate({ walletId: uid!, aiRoomId: roomId!, reason });
  };

  if (imagesFetching || roomFetching || room?.state === "initializing")
    return (
      <div className="flex flex-col grow gap-16">
        <Stepper activeStep={2} />
        <div className="inline-flex	flex-col items-center justify-center w-full h-full gap-4">
          <Spinner className="h-24 w-24" />
          <p className="text-lg">Retrieving Trade Details</p>
        </div>
      </div>
    );

  if (roomError?.code) {
    return (
      <RoomErrors
        errors={[roomError.code]}
        roomChain={room?.info?.blockchain as Blockchain}
      />
    );
  }

  if (!roomId || !room || !images) return null;

  if (room.state === "swap-canceled") {
    return <DeclineComplete />;
  }

  if (room?.errors?.length) {
    return (
      <RoomErrors
        errors={room.errors}
        roomChain={room?.info?.blockchain as Blockchain}
      />
    );
  }

  if (
    hasSigned ||
    ["swap-finalized", "swap-signing-pending"].includes(room.state) ||
    (room.state === "swap-signed" && uid === room.userId)
  ) {
    const accceptedOffer = room.acceptedOffer!;
    return (
      <Finalized
        roomId={roomId}
        uid={uid || "0"}
        images={images}
        room={room}
        offer={accceptedOffer}
        status={room.state}
      />
    );
  }

  if (room.state === "declined" || showFeedbackForm) {
    const offerToDecline = offerId || Object.keys(room.offers || {})[0];

    const idx = Object.keys(room.offers).findIndex(
      (id) => id === offerToDecline
    );
    return (
      <Declined
        roomChain={room.info.blockchain}
        roomId={roomId}
        uid={uid || "0"}
        handleDecline={handleDecline}
        declineLoading={decline.isLoading}
        offer={room.offers[offerToDecline]}
        offerSequence={getLetterAtIndex(idx)}
        goBack={() => setShowFeedbackForm(false)}
        images={images}
      />
    );
  }

  if (
    ["swap-inprogress", "accepted", "swap-initializing"].includes(room.state) ||
    (room.state === "swap-signed" && uid !== room.userId)
  ) {
    return (
      <Signing
        room={room}
        images={images}
        roomId={roomId}
        setHasSigned={() => setHasSigned(true)}
      />
    );
  }

  if (["expired", "swap-expired"].includes(room.state)) {
    return <Expired />;
  }

  return (
    <AiTrades
      room={room}
      images={images}
      isLoading={accept.isLoading}
      offerId={offerId}
      selectOffer={selectOffer}
      handleAccept={handleAccept}
      handleDecline={() => setShowFeedbackForm(true)}
    />
  );
}
