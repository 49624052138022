import { ReactElement, memo, useEffect, useId, useReducer, useState } from "react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { orderBy } from "lodash";
import { FixedSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { DateTime } from "luxon";
import HandleTime from "../../components/HandleTime";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { BiddableNft, Nft, useRoom } from "../../contexts/roomContext";
import { filterAndSort, SortOptions } from "../../utils/filterAndSort";
import IconGrid from "../Icons/IconGrid";
import IconRows from "../Icons/IconRows";
import GroupCard from "./GroupCard";
import { CollectionTable } from "./Table";
import NeoSelect from "../NeoSelect";
import ListModal from "../ListingDataTable/ListModal";
import BiddingModal from "../BiddingModal";
import ContactMethodModal from "../ContactMethodModal";
import GridLoader from "../GridLoader";
import TokenGated from "../TokenGated";
import { useAppContext } from "../../contexts/appContext";
import SmartAuctions from "../SmartAuctions";

interface Props {
  title?: string | JSX.Element | null;
  data: Nft[] | BiddableNft[];
  GridComponent: ({ nft }: { nft: Nft | BiddableNft }) => JSX.Element;
  ModalComponent?: ({ nfts }: { nfts: Nft[] | BiddableNft[] }) => JSX.Element;
  columns?: any;
  indexOptions: string[];
  groupBy?: string;
  children?: JSX.Element;
  emptyMessage: string;
  additionalSortOptions?: SortOpt[];
  collectionType?: "listing" | "bidding" | "myNfts";
  isLoading: boolean;
  defaultSort?: SortOptions;
  breakPoints?: { [key: string]: number };
  preferredNfts?: BiddableNft[];
  showGating?: boolean;
  hasJoined?: boolean;
}

enum ActionType {
  UPDATE = "UPDATE",
  SEARCH = "SEARCH",
  SORT = "SORT",
}

type State = {
  data: Nft[] | BiddableNft[];
  isGrid: boolean;
  isGrouped: boolean;
  selectedGroups: string[];
  results: Nft[] | BiddableNft[];
  query?: string;
  indexOptions: string[];
  sortedBy?: SortOptions;
  groups: string[];
  groupBy?: string;
};

type Action = {
  type: ActionType;
  payload: Partial<State>;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SORT:
      return {
        ...state,
        sortedBy: action.payload.sortedBy,
        ...filterAndSort(
          state.data,
          state.indexOptions,
          state.query!,
          action.payload.sortedBy,
          state.groupBy
        ),
      };
    case ActionType.SEARCH:
      return {
        ...state,
        ...filterAndSort(
          state.data,
          state.indexOptions,
          action.payload.query!,
          state.sortedBy,
          state.groupBy
        ),
      };

    default:
      return state;
  }
};

type SortOpt = {
  label: string;
  value: {
    key: string;
    direction: string;
  };
};

const IsLiveOverlay = ({ children }: { children: ReactElement }) => {
  const { room, roomId } = useRoom();

  const calulateIsLive = () => {
    return room.info.startAt < Date.now() / 1000;
  };

  const [isLive, setisLive] = useState(calulateIsLive());

  useEffect(() => {
    const timer = setTimeout(() => {
      setisLive(calulateIsLive());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const start = room?.info?.startAt || 0;

  const end = start + (room?.info?.partyDuration || 0);
  const DTstart = DateTime.fromSeconds(start);
  const DTend = DateTime.fromSeconds(end);
  if (!isLive) {
    return (
      <Box
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        padding="25px"
        minH="600px"
      >
        <Flex
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={999999}
          backgroundColor="#041520"
          opacity={0.8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="block">
            <VStack spacing={4}>
              <Heading fontSize="54px">Starting In</Heading>
              <Box
                className="time-wrapper"
                __css={{
                  ".val": {
                    fontSize: "4rem !important",
                  },
                }}
              >
                <HandleTime time={room?.info?.startAt} />
              </Box>
              <AddToCalendarButton
                name={room?.info?.name}
                options={["Apple", "Google"]}
                startDate={DTstart.toFormat("yyyy-MM-dd")}
                endDate={DTend.toFormat("yyyy-MM-dd")}
                startTime={DTstart.toFormat("HH:mm")}
                endTime={DTend.toFormat("HH:mm")}
                location={window.location.href}
                timeZone="UTC"
                uid={roomId}
                size="8|8|8"
                styleLight="--btn-background: #6C60FF;
           --btn-border: #6C60FF;
           --btn-text: #fffff;
               --btn-background-hover: #062438"
              />
            </VStack>
          </Box>
        </Flex>
        {children}
      </Box>
    );
  }
  return children;
};

const OverLay = ({
  children,
  showIsLive,
}: {
  children: ReactElement;
  showIsLive?: boolean;
}) => {
  if (showIsLive) {
    return <IsLiveOverlay>{children}</IsLiveOverlay>;
  }
  return children;
};

const NftCollection = memo(
  ({
    GridComponent,
    data,
    indexOptions,
    groupBy,
    title,
    columns,
    children,
    emptyMessage,
    additionalSortOptions,
    collectionType,
    isLoading,
    defaultSort,
    breakPoints,
    preferredNfts = [],
    showGating,
    hasJoined,
  }: Props) => {
    const { roomId, isPassing } = useRoom();
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const columnCount =
      useBreakpointValue(
        breakPoints || {
          base: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxxLarge: 3,
          xxxxLarge: 3,
        }
      ) || 3;
    const [state, dispatch] = useReducer(
      reducer,
      {
        sortedBy: defaultSort || {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "asc",
        },
      } as State,
      (state: State) => {
        const initialOptions = filterAndSort(
          data,
          indexOptions,
          "",
          state.sortedBy,
          groupBy
        );
        return {
          ...state,
          ...initialOptions,
          isGrid: true,
          groupBy,
          indexOptions,
          data,
          selectedGroups: [],
        };
      }
    );

    useEffect(() => {
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          data,
        },
      });
      dispatch({
        type: ActionType.SEARCH,
        payload: {
          query: state.query || "",
        },
      });
    }, [data]);

    const toggleGroup = () => {
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          selectedGroups: [],
          isGrouped: !state.isGrouped,
        },
      });
    };

    const addOrRemoveGroup = (groupName: string) =>
      dispatch({
        type: ActionType.UPDATE,
        payload: {
          selectedGroups: state.selectedGroups.includes(groupName)
            ? state.selectedGroups.filter((group) => group !== groupName)
            : [...state.selectedGroups, groupName],
        },
      });

    const handleSort = (sortedBy: SortOptions) =>
      dispatch({
        type: ActionType.SORT,
        payload: {
          sortedBy,
        },
      });

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: ActionType.SEARCH,
        payload: {
          query: e.currentTarget.value || "",
        },
      });

    const setView = (mode: string) =>
      dispatch({
        type: ActionType.UPDATE,
        payload: { isGrid: mode === "grid" ? true : false },
      });

    const clearSearch = () =>
      dispatch({
        type: ActionType.SEARCH,
        payload: { query: "" },
      });

    const clearGroups = () =>
      dispatch({
        type: ActionType.UPDATE,
        payload: { selectedGroups: [] },
      });

    let nfts = state.results;

    if (state.selectedGroups.length > 0) {
      nfts = nfts.filter((nft) => {
        if (state.selectedGroups.includes("null"))
          return !nft?.collection?.name;
        return state.selectedGroups.includes(nft?.collection?.name);
      });
    }

    const sortOptions: SortOpt[] = [
      ...(additionalSortOptions || []),
      {
        label: "Collection Floor Price (ASC)",
        value: {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "asc",
        },
      },
      {
        label: "Collection Floor Price (DESC)",
        value: {
          key: "collection.floorPrice.floorPrice.valueConverted",
          direction: "desc",
        },
      },
    ];
    return (
      <>
        {collectionType === "listing" && (
          <ListModal nfts={nfts.filter((nft) => !nft?.locks?.length)} />
        )}
        {collectionType === "bidding" && (
          <BiddingModal
            nfts={
              nfts
                .concat(preferredNfts)
                .filter((nft) => !nft?.locks?.length) as BiddableNft[]
            }
          />
        )}
        <ContactMethodModal
          open={infoModalOpen}
          handleClose={() => setInfoModalOpen(false)}
        />
        <Box className="grid-view listing-selection" pb={isPassing ? "0" : "100px"}>
          <div className="grid-view-header">
            {title && (
              <Heading as="h2" size="md">
                {title}
              </Heading>
            )}
            <div className="">
              <div className="search-div">
                <InputGroup
                  width="100%"
                  //maxWidth="150px"
                  className="filter-input"
                >
                  <Input
                    placeholder="Search"
                    onChange={handleSearch}
                    value={state.query}
                    borderColor="#6C60FF"
                  />

                  {state?.query && state.query.length > 0 ? (
                    <InputRightElement
                      color="gray.300"
                      children={<CloseIcon onClick={clearSearch} />}
                    />
                  ) : (
                    <InputRightElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                      children={<SearchIcon />}
                    />
                  )}
                </InputGroup>
              </div>
              <div className="filters">
                {/* <Switch
                  className="group-switch  "
                  isChecked={!!state.isGrouped}
                  onChange={toggleGroup}
                  isDisabled={state.groups.length < 1}
                  flexShrink={0}
                >
                  Show grouped
                </Switch> */}
                <NeoSelect
                  placeholder="Select collection"
                  className="filter-input collection"
                  id="input-color"
                  value={null}
                  onChange={(val: any) => {
                    if (!val?.value) return;
                    addOrRemoveGroup(val.value);
                  }}
                  options={orderBy(
                    state.groups
                      .filter((group) => !state.selectedGroups.includes(group))
                      .map((group) => {
                        return {
                          label:
                            group === "null" ? "Unknown Collection" : group,
                          value: group,
                        };
                      }),
                    ["label"],
                    ["asc"]
                  )}
                />
                <NeoSelect
                  placeholder="Sort by"
                  isSearchable={false}
                  id="input-color"
                  className="filter-input sort"
                  onChange={(val: any) => {
                    if (!val?.value) return;
                    handleSort(val.value);
                  }}
                  options={sortOptions}
                />

                {/* <div className="layout-toggle">
                  <span
                    className={`view-toggle-btn grid ${state.isGrid ? "active" : ""
                      }`}
                    onClick={() => setView("grid")}
                  >
                    <IconGrid />
                  </span>
           
                </div> */}
              </div>
            </div>
          </div>
          {state?.selectedGroups.length > 0 && (
            <Flex justifyContent="flex-end" gap={2} overflow="auto" py={2}>
              {state.selectedGroups.map((group) => {
                return (
                  <Tag
                    key={group}
                    borderRadius="full"
                    variant="solid"
                    flexShrink={0}
                  >
                    <TagLabel>
                      {group === "null" ? "Unknown Collection" : group}
                    </TagLabel>
                    <TagCloseButton onClick={() => addOrRemoveGroup(group)} />
                  </Tag>
                );
              })}
              {state.selectedGroups.length > 1 && (
                <Button size="sm" variant="link" onClick={clearGroups}>
                  Clear All
                </Button>
              )}
            </Flex>
          )}

          <Box className="box" display="block" mt="30px" >
            <OverLay showIsLive={showGating}>
              <Flex flexDirection="column" width="100%">

                <div className="grid-view-wrapper">
                  {isLoading && (
                    <GridLoader
                      count={9}
                      minH={collectionType === "myNfts" ? "250px" : "300px"}
                    />
                  )}
                  {!isLoading &&
                    state.isGrouped &&
                    state.selectedGroups.length < 1 &&
                    state.groups?.length > 0 && (
                      <Box className="grid-view-wrapper" height="750px">
                        <AutoSizer>
                          {({ height, width }: { height: number, width: number }) => (
                            <FixedSizeGrid
                              columnCount={columnCount}
                              rowCount={Math.ceil(
                                state.groups.length / columnCount
                              )}
                              columnWidth={width / columnCount}
                              rowHeight={350}
                              height={height}
                              width={width}
                              style={{ overflowX: "hidden" }}
                            >
                              {({ columnIndex, rowIndex, style }) => {
                                const index =
                                  rowIndex * columnCount + columnIndex;
                                const group = state.groups[index];
                                const first = nfts.find(
                                  (ele) => ele?.collection?.name === group
                                );

                                const image =
                                  first?.collection?.image || first?.image;
                                const name =
                                  first?.collection?.name ||
                                  "Unknown Collection";
                                if (!group) return null;
                                return (
                                  <div style={{ ...style, padding: "10px" }}>
                                    <GroupCard
                                      key={`${group}_${index}`}
                                      name={name}
                                      image={image}
                                      onClick={() => addOrRemoveGroup(group)}
                                    />
                                  </div>
                                );
                              }}
                            </FixedSizeGrid>
                          )}
                        </AutoSizer>
                      </Box>
                    )}
                </div>

                {!isLoading &&
                  (!state.isGrouped || state.selectedGroups.length > 0) &&
                  state.isGrid &&
                  nfts?.length > 0 && (
                    <Box className="grid-view-wrapper" height={isPassing ? "400" : "750px"}>
                      <AutoSizer>
                        {/* Edit listing grid */}
                        {({ height, width }: { height: number, width: number }) => (
                          <FixedSizeGrid
                            columnCount={columnCount}
                            rowCount={Math.ceil(nfts.length / columnCount)}
                            columnWidth={width / columnCount}
                            rowHeight={
                              collectionType === "myNfts"
                                ? columnCount < 3
                                  ? columnCount < 2
                                    ? 450
                                    : 300
                                  : 250
                                : 500
                            }
                            height={height}
                            width={width}
                            style={{ overflowX: "hidden" }}
                          >
                            {({ columnIndex, rowIndex, style }) => {
                              const index =
                                rowIndex * columnCount + columnIndex;
                              const nft = nfts[index];
                              if (!nft) return null;
                              return (
                                <div style={{ ...style, padding: "10px" }}>
                                  <GridComponent key={nft.itemId} nft={nft} />
                                </div>
                              );
                            }}
                          </FixedSizeGrid>
                        )}
                      </AutoSizer>
                    </Box>
                  )}
                {!isLoading && nfts?.length < 1 && (
                  <div className="box">{emptyMessage}</div>
                )}
                {!isLoading && columns &&
                  (!state.isGrouped || state.selectedGroups.length > 0) &&
                  !state.isGrid &&
                  nfts?.length > 0 && (
                    <CollectionTable data={nfts} columns={columns} />
                  )}
              </Flex>
            </OverLay>
          </Box>

          <HStack className="listing-section box">
            <SmartAuctions
              chain={"solana"}
              onlyLive={true}
              exclude={[roomId]}
              hideHeader={true}
              customHeader={"Related Events"}
              isRelated={true}
            />
          </HStack>
        </Box>
        {children}
      </>
    );
  }
);

export default NftCollection;
