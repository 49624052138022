import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function BottomBar({ children }: Props) {
  return (
    <Box
      className="bottom-fixed-bar"
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      display="flex"
      background="gray"
      paddingX="5%"
      alignItems="center"
      borderWidth="1px"
      _dark={{
        background: "rgba(108, 96, 255, 0.5)",
        backdropFilter: "blur(39.6002px)",
        boxShadow: "0 0.5rem 1rem #0000001a",
      }}
      py="3"
      zIndex={99}
      minH={"84px"}
    >
      {children}
    </Box>
  );
}
