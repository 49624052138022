import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { memo } from "react";
import IconPurpleEye from "../Icons/IconPurpleEye";
import { Tooltip } from "react-tooltip";
import { CiUnlock } from "react-icons/ci";
import IconLock from "../Icons/IconLock";
import { BiddableNft, createPlaceLabel } from "../../contexts/roomContext";
import QuickActions  from "./QuickActions";
import { createFloor, getReserveLabel, roundValue, formatButtonLabel } from "../../utils";
import BiddingError from "./BiddingError";
import IconTrash from "../Icons/IconTrash";

interface Props {
  nft: BiddableNft;
  finalMinute: boolean;
  max: number;
  budget: number;
  isHighBidder: boolean;
  isTied: boolean;
  highestBidAmount: number;
  hasBid: boolean;
  place: number;
  total: number;
  abbr: string;
  truncate: number;
  isSilentAuction: boolean;
  isSecretReserve: boolean;
  min: number;
  isBidReserveMet: boolean;
  decimals: number;
  startingBid?: number;
  handleBid: (val?: number | string) => void;
  floor: number;
  incrementToken: number;
  incrementPct: number;
  error?: string;
  setAsIncrementAboveHighestBid: () => void;
  canBid: boolean;
  bidVal?: number | string;
  onClose: () => void;
  isOpen: boolean;
  cancel: () => void;
  step?: number;
  setBidVal: (val: number | string) => void;
  bidMaxBudget: () => Promise<void>;
  setBudgetAndBid: (value: number) => Promise<void>;
  isOrdinals: boolean;
  suggestedPrice: string;
}

function BiddingTab({
  nft,
  finalMinute,
  max,
  budget,
  isHighBidder,
  isTied,
  highestBidAmount,
  hasBid,
  place,
  total,
  abbr,
  truncate,
  isSilentAuction,
  isSecretReserve,
  min,
  isBidReserveMet,
  decimals,
  startingBid,
  handleBid,
  floor,
  incrementToken,
  incrementPct,
  error,
  setAsIncrementAboveHighestBid,
  canBid,
  bidVal,
  onClose,
  isOpen,
  cancel,
  step,
  setBidVal,
  bidMaxBudget,
  setBudgetAndBid,
  isOrdinals,
  suggestedPrice
}: Props) {
  const handleKeyDown = (e: any) => {
    if (e?.key === "Enter") {
      handleBid(bidVal);
    }
  };

  const reservePrice = nft.reservePrice?.value;

  return (
    <div className="listing-modal-details">
      <div className="ns-title-and-status">
        <h4>{nft?.name}</h4>
        {isTied ? <Text className="ns-modal-bid-info tied">Tied</Text> : null}
        {isHighBidder && !isTied ? (
          <Text className="ns-modal-bid-info winning">
            <span /> Highest bid
          </Text>
        ) : null}

        {!isHighBidder && highestBidAmount && hasBid ? (
          <Text className="ns-modal-bid-info other" textAlign="center">
            {finalMinute
              ? "Place your final bids"
              : createPlaceLabel(place, total)}
          </Text>
        ) : null}
      </div>
      <div className="listing-modal-inputs">
        <Flex direction="column" gap="20px" flex="1">
          <Box>
            <Flex
              gap="20px"
              direction={{
                sm: "column",
                md: "row",
              }}
            >
              {/* <span className="stat">
                <div className="stat-details">
                  <Flex alignItems="center" gap="6px">
                    <span className="label">Reserve</span>
                    <a // eslint-disable-line
                      data-tooltip-id="reserve-price-tooltip"
                      data-tooltip-content="The price at which the user has listed their Item for sale."
                      data-tooltip-place="right"
                    >
                      <IconPurpleEye />
                    </a>
                  </Flex>
                  <Tooltip
                    id="reserve-price-tooltip"
                    className="custom-tooltip"
                    classNameArrow="custom-tooltip-arrow"
                  />
                  {isSecretReserve ? (
                    <p
                      className="bold"
                      style={{
                        borderRadius: "0px",
                        background: isBidReserveMet ? "#40cd56" : "white",
                        color: isBidReserveMet ? "white" : "#0c416a",
                        padding: "1px 5px",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        fontSize: "12px",
                        justifyContent: "center",
                        letterSpacing: "0.5px",
                        margin: "5px 0px 0px 0px",
                        width: "fit-content",
                        fontFamily: "InterBold",
                      }}
                    >
                      {isBidReserveMet ? <CiUnlock /> : <IconLock />}
                      {isBidReserveMet ? "Met" : "Not met"}
                    </p>
                  ) : (
                    <strong>{`${getReserveLabel(
                      reservePrice,
                      nft?.reservePrice?.decimals || 0,
                      abbr,
                      truncate
                    )} `}</strong>
                  )}
                </div>
              </span> */}
              {!isOrdinals &&
                <span className="stat">
                  <div className="stat-details">
                    <Flex alignItems="center" gap="6px">
                      <span className="label">{suggestedPrice}</span>
                      <a // eslint-disable-line
                        data-tooltip-id="floor-price-tooltip"
                        data-tooltip-content="The minimum price at which Items within a collection can be sold."
                        data-tooltip-place="right"
                      >
                        <IconPurpleEye />
                      </a>
                    </Flex>
                    <Tooltip
                      id="floor-price-tooltip"
                      className="custom-tooltip"
                      classNameArrow="custom-tooltip-arrow"
                    />
                    <strong>{createFloor(nft, truncate)}</strong>
                  </div>
                </span>
              }
               <span className="stat">
                <div className="stat-details">
                  <Flex alignItems="center" gap="6px">
                    <span className="label">Minimum Bid</span>{" "}
                    <a // eslint-disable-line
                      data-tooltip-id="minimum-bid-tooltip"
                      data-tooltip-content="The minimum bid for this Item."
                      data-tooltip-place="right"
                    >
                      <IconPurpleEye />
                    </a>
                  </Flex>
                  <Tooltip
                    id="minimum-bid-tooltip"
                    className="custom-tooltip"
                    classNameArrow="custom-tooltip-arrow"
                  />
                  <strong>
                   <span>
                          {startingBid
                          ? formatButtonLabel(
                              roundValue(startingBid / 10 ** decimals, truncate, 'down'),
                              abbr
                            )
                          : "--"}
                      </span>
                  </strong>
                </div>
              </span>
              <span className="stat">
                <div className="stat-details">
                  <Flex alignItems="center" gap="6px">
                    <span className="label">Highest Bid</span>{" "}
                    <a // eslint-disable-line
                      data-tooltip-id="highest-bid-tooltip"
                      data-tooltip-content="The highest amount offered by a bidder for a specific Item."
                      data-tooltip-place="right"
                    >
                      <IconPurpleEye />
                    </a>
                  </Flex>
                  <Tooltip
                    id="highest-bid-tooltip"
                    className="custom-tooltip"
                    classNameArrow="custom-tooltip-arrow"
                  />
                  <strong>
                    {highestBidAmount === -Infinity ? (
                      <span>-</span>
                    ) : (
                      <span>
                        {roundValue(
                          highestBidAmount / 10 ** decimals,
                          truncate,
                          'down'
                        )}{" "}
                        {abbr}
                      </span>
                    )}
                  </strong>
                </div>
              </span>

            </Flex>
          </Box>

          <div className="stats">
            <Flex>
              <Box>
                <Flex alignItems="center" gap="6px">
                  <p>Set your bid</p>
                  <a // eslint-disable-line
                    data-tooltip-id="set-your-bid-tooltip"
                    data-tooltip-content={`The max you can bid is ${getReserveLabel(
                      max,
                      nft?.reservePrice?.decimals || 0,
                      abbr,
                      truncate
                    )}. Please adjust your spending limit to bid more.`}
                    data-tooltip-place="right"
                  >
                    <IconPurpleEye />
                  </a>
                  <Tooltip
                    id="set-your-bid-tooltip"
                    className="custom-tooltip"
                    classNameArrow="custom-tooltip-arrow"
                  />
                </Flex>
                <div className="set-bid-holder">
                  <InputGroup
                    alignItems="center"
                    className="ns-set-your-bid"
                    zIndex={9}
                  >
                    <Popover
                      returnFocusOnClose={false}
                      isOpen={isOpen}
                      onClose={onClose}
                      placement="bottom"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <NumberInput
                          placeholder="Set Reserve Price"
                          step={step || 0.01}
                          value={bidVal}
                          _dark={{
                            backgroundColor: "#051a28",
                            opacity: 1,
                            borderRadius: "6px 0 0 6px",
                          }}
                          onChange={setBidVal}
                          onBlur={() => hasBid && handleBid(bidVal)}
                          width="140px"
                          className="new-number-input"
                          min={0}
                          onKeyDown={handleKeyDown}
                          keepWithinRange
                          clampValueOnBlur
                          allowMouseWheel={false}
                          lang="en"
                        >
                          <NumberInputField className="number-input" />
                          <NumberInputStepper>
                            <NumberIncrementStepper className="stepper-controls" />
                            <NumberDecrementStepper className="stepper-controls" />
                          </NumberInputStepper>
                          <div className="inline-addon">{abbr}</div>
                        </NumberInput>
                      </PopoverTrigger>
                      <PopoverContent className="new-bg-transparent darker">
                        <PopoverHeader fontWeight="semibold">
                          Increase Spending Limit
                        </PopoverHeader>
                        <PopoverArrow className="new-bg-transparent darker" />
                        <PopoverCloseButton />
                        <PopoverBody>
                          You need to increase your spending limit to at least{" "}
                          {`${Number(bidVal)} ${abbr}`} in order to bid on this
                          Item
                        </PopoverBody>
                        <PopoverFooter display="flex" justifyContent="flex-end">
                          <ButtonGroup size="sm">
                            <Button variant="outline" onClick={onClose}>
                              Close
                            </Button>
                            <Button
                              bg={"#6c60ff"}
                              color={"white"}
                              onClick={() => {
                                const val =
                                  roundValue(Number(bidVal), truncate, 'down') *
                                  10 ** decimals;
                                if (val > 0) {
                                  setBudgetAndBid(val);
                                }
                              }}
                              isDisabled={!!((max || 0) < Number(bidVal))}
                            >
                              Increase
                            </Button>
                          </ButtonGroup>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </InputGroup>

                  {canBid && hasBid && (
                    <Flex justifyContent="flex-end">
                      <div onClick={cancel} className="remove-bid">
                        <IconTrash />
                      </div>
                    </Flex>
                  )}
                </div>
              </Box>

              {error && (
                <BiddingError
                  error={error}
                  highestBidAmount={highestBidAmount}
                  incrementPct={incrementPct}
                  incrementToken={incrementToken}
                  decimals={decimals}
                  truncate={truncate}
                  abbr={abbr}
                  max={max}
                  handleBid={handleBid}
                  setBudgetAndBid={setBudgetAndBid}
                />
              )}
            </Flex>
          </div>

          {/* ACTIONS */}
          <QuickActions
            highestBidAmount={highestBidAmount}
            max={max}
            budget={budget}
            startingBid={startingBid}
            handleBid={handleBid}
            decimals={decimals}
            abbr={abbr}
            floor={floor}
            incrementToken={incrementToken}
            incrementPct={incrementPct}
            truncate={truncate}
            isOrdinals={isOrdinals}
            suggestedPrice={suggestedPrice}
            isHighBidder={isHighBidder}
            isTied={isTied}
          />
          <Flex>
            {isSilentAuction && (
              <Text whiteSpace="pre-line">
                {max
                  ? `The max you can bid is ${getReserveLabel(
                      max,
                      nft?.reservePrice?.decimals || 0,
                      abbr,
                      truncate
                    )}.
            Please adjust your spending limit to bid more.`
                  : "Please set a spending limit to bid"}
              </Text>
            )}
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default memo(BiddingTab);
