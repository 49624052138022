import { useCallback, useEffect, useState } from "react";
import { DateTime, DurationObjectUnits } from "luxon";

type useTimerState = {
  timeOffset: number;
  endTime: number;
  expired?: boolean;
  timeRemainingSec?: number;
  timeLeftUnits?: DurationObjectUnits;
};

export default function useTimer(props: useTimerState): useTimerState {
  const [state, setState] = useState<useTimerState>({ ...props });
  const { endTime, timeOffset } = state;


  useEffect(() => {
    setState(props)
  }, [props.endTime])

  const getCurrentTime = (timeOffset: number) => {
    return Math.floor(Date.now() / 1000 + timeOffset);
  };

  const calculateTimeUnits = (time: number) => {
    let vals = DateTime.fromSeconds(time, { zone: "UTC" })
      .diff(DateTime.now().setZone("UTC"), [
        "years",
        "months",
        "days",
        "hours",
        "minutes",
        "seconds",
      ])
      .toObject();

    Object.keys(vals).forEach((val: string) => {
      const v = val as keyof DurationObjectUnits;
      vals[v] = Math.floor(vals[v] || 0);
    });

    return vals;
  };

  const calculateTimer = useCallback(() => {
    const currTime = getCurrentTime(timeOffset);
    return {
      ...state,
      expired: currTime > endTime,
      timeRemainingSec: endTime - currTime,
      timeLeftUnits: calculateTimeUnits(endTime),
    };
  }, [endTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setState(calculateTimer());
    }, 1000);

    return () => clearInterval(timer);
  }, [endTime, calculateTimer]);

  return state;
}
