import { PackTransaction } from '../types'

export function assertTransactionsSuccess(
  txs: PackTransaction[]
): 'success' | 'failed' | 'incomplete' {
  const incompleteTxs = txs.filter((tx) => tx.status === 'broadcast')
  if (incompleteTxs.length > 0) {
    return 'incomplete'
  }
  const failedTxs = txs.filter((tx) => tx.status === 'failed' || tx.status === 'timeout')
  if (failedTxs.length > 0) {
    return 'failed'
  }

  const otherTxs = txs.filter((tx) => tx.status !== 'success')
  if (otherTxs.length > 0) {
    throw new Error('Unexpected transaction status')
  }

  return 'success'
}

export function handleShare(twitterUrl: string, text: string) {
  // Opens a pop-up with twitter sharing dialog
  var shareURL = 'https://twitter.com/intent/tweet?' //url base

  //params
  var params: { [key: string]: string } = {
    url: twitterUrl,
    text: text,
  }

  for (var prop in params) shareURL += '&' + prop + '=' + encodeURIComponent(params[prop])
  window.open(
    shareURL,
    '',
    'left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
  )
}

export function generateRandomString(length: number) {
  return Array(length)
    .fill(0)
    .map(() => ((Math.random() * 36) | 0).toString(36))
    .join('')
}
