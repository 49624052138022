import React from 'react';
import { Box, SimpleGrid, Heading, Text, Image, VStack, Link, Button } from "@chakra-ui/react";
import potatocat from "../assets/dapps/potatocat.jpg";
import staratlas from "../assets/dapps/staratlas.jpg";
import genopets from "../assets/dapps/genopets.png";

const DAppsCards = () => {
    const cardsData = [
        {
            title: "Potato Cat",
            description: "Get NFTs you want using NFTs you own",
            image: potatocat,
            link: "https://app.potatocat.ai"
        },
        {
            title: "Star Atlas Starter Packs",
            description: "Get started on Star Atlas game with your favorite pack",
            image: staratlas,
            link: "/star-atlas"
        },
        {
            title: "Genopets Starter Packs",
            description: "Get started on Genopets game with your favorite pack",
            image: genopets,
            link: "/genopets"
        }
    ];

    return (
        <SimpleGrid columns={[1, null, 3]} spacing={4} mt={6} w="full">
            {cardsData.map((card, index) => (
                <Link
                    key={index}
                    href={card.link}
                    isExternal={card.link.startsWith('http')}
                    _hover={{ textDecoration: 'none' }}
                    display="block"
                    h="full"
                >
                    <VStack
                        borderWidth="1px"
                        borderRadius="lg"
                        overflow="hidden"
                        h="full"
                        className='packs-widget'
                        transition="transform 0.2s"
                        _hover={{ transform: 'scale(1.05)' }}
                        spacing={0}
                    >
                        <Image src={card.image} alt={card.title} objectFit="cover" h="200px" w="full" />
                        <Box p={4} w="full" flexGrow={1}>
                            <Heading as="h3" size="md" mb={2}>
                                {card.title}
                            </Heading>
                            <Text>{card.description}</Text>
                        </Box>
                        <Button
                            className="buy-pack-btn w-full"
                        >
                            Launch App
                        </Button>
                    </VStack>
                </Link>
            ))}
        </SimpleGrid>
    );
};

export default DAppsCards;